/**
 * See https://github.com/angelozerr/CodeMirror-Extension
 * Highly modified version of this hover example
 */
import { ApplicationScope } from "@superblocksteam/shared";
import { Typography } from "antd";
import CodeMirror from "codemirror";
import React from "react";
import { createRoot } from "react-dom/client";
import styled from "styled-components";
import AutocompleteHint, {
  showAutocompleteHint,
} from "autocomplete/AutocompleteHint";
import {
  AUTOCOMPLETE_CLASS,
  calculateInlineTooltipCoords,
} from "autocomplete/util";
import JsonView from "components/ui/JsonView";
import { DataTree } from "legacy/entities/DataTree/dataTreeFactory";
import { ApiScope } from "utils/dataTree/scope";
import { EditorModes } from "./EditorConfig";
import {
  BindingErrorHover,
  NullMarkerHover,
  FunctionScopeErrorMarkerError,
  AppScopeErrorHoverMarker,
} from "./MarkerHovers";
import { OpenButton } from "./OpenButton";
import { getValue } from "./datatree/getValue";
import { MarkerType } from "./markHelpers";
import {
  isHoveringBindingError,
  getMarkers,
  getTokenData,
  getTokens,
  Marker,
} from "./mouse/mouseHelper";

const { Text } = Typography;

const StyledHeader = styled.div`
  display: flex;
  align-items: center;
`;

declare module "codemirror" {
  export interface EditorConfiguration {
    textHover?: Options | undefined;
    noNewlines?: boolean;
    styleActiveLine?: { nonEmpty: boolean };
  }
}

interface Options {
  data: () => DataTree;
  additionalData: () => Record<string, unknown>;
  apiScope: () => ApiScope | undefined;
  appScope: () => ApplicationScope;
  visible?: boolean;
}

function getValueFrom(instance: CodeMirror.Editor, tokens: string[]) {
  const data = instance.state.textHover.data();
  const additionalData = instance.state.textHover.additionalData();
  const apiScope = instance.state.textHover.apiScope();
  const appScope = instance.state.textHover.appScope();

  return getValue(tokens, {
    data,
    additionalData,
    apiScope,
    appScope,
  });
}

function handleMouseOver(instance: CodeMirror.Editor, e: MouseEvent) {
  // Disabled is controlled internally, while visible is controlled externally
  if (instance.state.textHover.disabled || !instance.state.textHover.visible) {
    return;
  }

  const node = (e.target || e.srcElement) as HTMLElement;

  if (!node || /ai-assistant-marker/.test(node.className)) return;

  const tokenData = getTokenData(instance, e);
  const hasBindingError = isHoveringBindingError(instance, e);

  let tokens: string[] = [];
  let markers: Marker[] = [];
  let value = undefined;
  if (tokenData) {
    tokens = getTokens(instance, tokenData);
    markers = getMarkers(instance, tokenData);
    value = getValueFrom(instance, tokens);
  }

  const isValid = typeof value !== "undefined" && typeof value !== "function";
  const isTernDoc =
    typeof value === "object" && "!doc" in value && showAutocompleteHint(value);

  if (!isValid && !markers.length && !hasBindingError) return;

  if (instance.state.textHover.hide) instance.state.textHover.hide();

  const {
    x: tooltipX,
    y: tooltipY,
    transform,
  } = calculateInlineTooltipCoords(node);

  const tooltip = document.createElement("div");
  tooltip.className = `${AUTOCOMPLETE_CLASS}tooltip ${AUTOCOMPLETE_CLASS}hint-doc visible`;
  tooltip.style.left = `${tooltipX}px`;
  tooltip.style.top = `${tooltipY}px`;
  if (transform) {
    tooltip.style.transform = transform;
  }
  tooltip.style.backgroundColor = `red !important`;
  document.body.appendChild(tooltip);

  const root = createRoot(tooltip);
  root.render(
    <>
      {markers.map((marker) => {
        if (
          marker.type === MarkerType.FunctionScopeError &&
          marker.methodName
        ) {
          return (
            <FunctionScopeErrorMarkerError
              key={marker.content}
              methodName={marker.methodName}
              editorMode={instance.getMode().name as EditorModes}
            />
          );
        }

        if (marker.type === MarkerType.AppScopeError && marker.entityName) {
          return (
            <AppScopeErrorHoverMarker
              key={marker.content}
              entityName={marker.entityName}
            />
          );
        }
        return (
          <NullMarkerHover key={marker.content} message={marker.content} />
        );
      })}
      {hasBindingError && <BindingErrorHover />}
      {isTernDoc && isValid && value && (
        <AutocompleteHint doc={value["!doc"]} type={value["!type"]} />
      )}
      {!isTernDoc && isValid && tokens.length > 0 && (
        <>
          <StyledHeader>
            <Text strong>{tokens.join("")}</Text>
            <OpenButton value={value} />
          </StyledHeader>
          <JsonView
            data={value}
            maxStringLength={20}
            maxBufferLength={20}
            maxArrayLength={100}
            height={260}
            width={230}
          />
        </>
      )}
    </>,
  );

  instance.state.textHover.hide = () => {
    CodeMirror.off(node, "mouseout", instance.state.textHover.waitForMouseOut);
    CodeMirror.off(node, "click", instance.state.textHover.hide);
    CodeMirror.off(tooltip, "mouseover", instance.state.textHover.mouseOver);
    CodeMirror.off(tooltip, "mouseleave", instance.state.textHover.hide);
    instance.off("inputRead", instance.state.textHover.inputRead);
    instance.removeKeyMap(instance.state.textHover.keyMap);

    tooltip.remove();
    root.unmount();

    clearTimeout(instance.state.textHover.timeout);

    delete instance.state.textHover.timeout;
    delete instance.state.textHover.hide;
    delete instance.state.textHover.inputRead;
    delete instance.state.textHover.waitForMouseOut;
    delete instance.state.textHover.mouseOver;
  };

  instance.state.textHover.waitForMouseOut = () => {
    instance.state.textHover.timeout = setTimeout(() => {
      if (instance.state.textHover.hide) instance.state.textHover.hide();
    }, 250);
  };

  instance.state.textHover.mouseOver = () => {
    clearTimeout(instance.state.textHover.timeout);

    delete instance.state.textHover.timeout;
  };

  CodeMirror.on(tooltip, "mouseover", instance.state.textHover.mouseOver);
  CodeMirror.on(tooltip, "mouseleave", instance.state.textHover.hide);
  CodeMirror.on(node, "mouseout", instance.state.textHover.waitForMouseOut);
  CodeMirror.on(node, "click", instance.state.textHover.hide);

  instance.state.textHover.inputRead = () => {
    instance.state.textHover.hide();
    instance.state.textHover.disabled = true;
  };

  instance.state.keyMap = { Esc: instance.state.textHover.hide };

  instance.on("inputRead", instance.state.textHover.inputRead);
  instance.addKeyMap(instance.state.textHover.keyMap);
}

function handleMouseMove(instance: CodeMirror.Editor) {
  instance.state.textHover.disabled = false;
}

function optionHandler(
  instance: CodeMirror.Editor,
  current: Options,
  old: Options | CodeMirror.Init,
) {
  const wrapper = instance.getWrapperElement();

  if (old && old !== CodeMirror.Init) {
    CodeMirror.off(
      wrapper,
      "mouseover",
      instance.state.textHover.handleMouseOver,
    );
    CodeMirror.off(
      wrapper,
      "mousemove",
      instance.state.textHover.handleMouseMove,
    );

    delete instance.state.textHover;
  }

  if (current) {
    instance.state.textHover = {
      ...current,
      visible: true,
      disabled: false,
      keyMap: {},
      handleMouseOver(e: MouseEvent) {
        handleMouseOver(instance, e);
      },
      handleMouseMove(e: MouseEvent) {
        handleMouseMove(instance);
      },
    };

    CodeMirror.on(
      wrapper,
      "mouseover",
      instance.state.textHover.handleMouseOver,
    );
    CodeMirror.on(
      wrapper,
      "mousemove",
      instance.state.textHover.handleMouseMove,
    );
  }
}

CodeMirror.defineOption("textHover", false, optionHandler);
