import { WidgetTypes } from "legacy/constants/WidgetConstants";
import { fastClone } from "utils/clone";
import { ItemWithPropertiesType } from "./ItemKindConstants";
import { upgradePropertyConfig } from "./upgradePropertyConfig";
import type { PropertyPaneConfig } from "legacy/constants/PropertyControlConstants";

/**
 * This map contains the property pane config for items of all kinds other than widget.
 * For widgets, we extract the property pane config from the widget registry, so there is
 * no need to add that information here.
 */
const propertyPaneConfigsRegistry = new Map<
  ItemWithPropertiesType,
  readonly PropertyPaneConfig[]
>();

export function getItemPropertyPaneConfig(
  type: ItemWithPropertiesType,
): readonly PropertyPaneConfig[] {
  const config = propertyPaneConfigsRegistry.get(type);
  if (!config) {
    console.error(`Could not find property pane config for type ${type}`);
    return [];
  }
  return config;
}

const v2PropertyPaneConfigsRegistry = new Map<
  ItemWithPropertiesType,
  readonly PropertyPaneConfig[]
>();

export function getItemV2PropertyPaneConfig(
  type: ItemWithPropertiesType,
): readonly PropertyPaneConfig[] {
  const existingConfig = v2PropertyPaneConfigsRegistry.get(type);
  if (existingConfig) {
    return existingConfig;
  }

  const v1Config = getItemPropertyPaneConfig(type);

  const v2Config = upgradePropertyConfig(type as WidgetTypes, v1Config);
  v2PropertyPaneConfigsRegistry.set(type, v2Config);
  return v2Config;
}

export function registerPropertyPaneConfig(
  itemType: ItemWithPropertiesType,
  config: PropertyPaneConfig[],
) {
  propertyPaneConfigsRegistry.set(itemType, Object.freeze(fastClone(config)));
}
