import { ApplicationScope } from "@superblocksteam/shared";
import { set } from "dot-object";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { Dispatch } from "redux";
import {
  deleteCustomEvent,
  updateCustomEvent,
} from "store/slices/application/events/eventActions";
import { EventDefinition } from "store/slices/application/events/eventConstants";
import { getEventById } from "store/slices/application/events/selectors";
import { fastClone } from "utils/clone";
import { NOOP } from "utils/function";
import { ItemKinds, ItemTypeNonWidget } from "../ItemKindConstants";
import type { ItemKindAccessor } from "../ItemKinds";
import type { AppState } from "store/types";

export const EventAccessor: ItemKindAccessor<ItemKinds.CUSTOM_EVENT> = {
  useItemName: (itemProperties) =>
    useMemo(() => {
      return {
        name: itemProperties.name,
        editable: true,
        requiresValidation: true,
      };
    }, [itemProperties.name]),
  itemType: (itemProperties) => ItemTypeNonWidget.CUSTOM_EVENT,
  useItemProperties: (itemId: string) => {
    return useSelector((state: AppState) => getEventById(state, itemId));
  },
  updateItemProperties: (
    dispatch: Dispatch,
    properties: EventDefinition,
    updates: Partial<EventDefinition>,
    scope: ApplicationScope,
  ) => {
    const changes: EventDefinition = fastClone(properties);
    for (const [key, value] of Object.entries(updates)) {
      set(key, value, changes);
    }

    dispatch(
      updateCustomEvent({
        updates: { [properties.id]: changes },
        scope,
      }),
    );
  },
  deleteItemProperties: NOOP,
  deleteItem: (dispatch, eventId, scope) => {
    dispatch(deleteCustomEvent(eventId, scope));
  },
};
