import { Input, InputProps } from "antd";
import React, { useMemo } from "react";
import { ReactComponent as SearchIcon } from "assets/icons/common/search.svg";
import { colors } from "styles/colors";
import { styleAsClass } from "styles/styleAsClass";

export function filterBySearch<T>(
  obj: T,
  searchTerm: string,
  searchableFields: Array<keyof T>,
): boolean {
  for (const field of searchableFields) {
    const value = obj[field];
    if (
      String(value).toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase())
    ) {
      return true;
    }
  }
  return false;
}

// the container for search, filter or add if not found
export const SearchContainer = styleAsClass`
  display: flex;
  width: 100%;
  margin-bottom: 18px;
  gap: 8px;
  height: 32px;
`;

export const SearchInput = (props: InputProps) => {
  return (
    <Input
      {...props}
      style={{ fontSize: "12px" }}
      prefix={
        <SearchIcon
          style={{
            color: colors.GREY_300,
            width: 16,
            height: 16,
            marginLeft: -4,
          }}
        />
      }
    />
  );
};

export const HighlightedResult = ({
  value,
  highlights,
}: {
  value: string;
  highlights?: Array<[number, number]>;
}) => {
  const contents = useMemo(() => {
    if (!highlights || highlights.length === 0) return value;
    const result = [];
    let lastEnd = 0;

    highlights.forEach(([highlightStart, highlightEnd], index) => {
      if (highlightStart > lastEnd) {
        result.push(value.slice(lastEnd, highlightStart));
      }
      result.push(
        <span
          key={index}
          style={{ backgroundColor: colors.ACCENT_BLUE_500_18 }}
        >
          {value.slice(highlightStart, highlightEnd)}
        </span>,
      );
      lastEnd = highlightEnd;
    });

    if (lastEnd < value.length) {
      result.push(value.slice(lastEnd));
    }

    return result;
  }, [value, highlights]);

  return <span>{contents}</span>;
};
