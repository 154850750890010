import React from "react";
import { ReactComponent as ChevronDown } from "assets/icons/common/chevron-down-dropdown.svg";
import { colors } from "styles/colors";
import { styleAsClass } from "styles/styleAsClass";

const ContainerClassName = styleAsClass`
  display: flex;
  align-items: center;
  height: 100%;
`;

const IconClassName = styleAsClass`
  position: relative;
  color: ${colors.GREY_300};
  transition: transform 0.2s ease-out;
  cursor: pointer;

  &[data-disabled="true"] {
    color: ${colors.GREY_200};
  }
`;

const ExpandIconButton = ({
  isExpanded,
  style,
  onClick,
  className,
  disabled,
}: {
  isExpanded?: boolean;
  onClick?: React.SVGProps<SVGSVGElement>["onClick"];
  style?: React.SVGProps<SVGSVGElement>["style"];
  className?: React.SVGProps<SVGSVGElement>["className"];
  disabled?: boolean;
}) => {
  return (
    <span className={ContainerClassName}>
      <ChevronDown
        data-disabled={disabled}
        className={IconClassName}
        onClick={onClick}
        style={{
          ...style,
          transform: `rotate(${isExpanded ? "0deg" : "-90deg"})`,
        }}
      />
    </span>
  );
};

export default ExpandIconButton;
