import { DebouncedFunc, ThrottleSettings, throttle } from "lodash";
import { useEffect, useRef } from "react";
import useDeepMemo from "./useDeepMemo";

export function useThrottle<T extends (...args: any[]) => any>(
  fn: T,
  ms: number,
  options_?: ThrottleSettings,
) {
  const throttledFnRef = useRef<DebouncedFunc<T>>();

  const options = useDeepMemo(() => options_, [options_]);
  useEffect(() => {
    if (throttledFnRef.current) {
      throttledFnRef.current.cancel();
    }

    throttledFnRef.current = throttle(fn, ms, options);

    return () => {
      if (throttledFnRef.current) {
        throttledFnRef.current.cancel();
        throttledFnRef.current = undefined;
      }
    };
  }, [fn, ms, options]);

  return throttledFnRef.current;
}
