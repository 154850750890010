import { HttpMethod, VcsProvider } from "@superblocksteam/shared";
import { Input, Modal } from "antd";
import { isEmpty } from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { ReactComponent as GitLabIcon } from "assets/icons/home/gitlab.svg";
import { PrimaryButton, SecondaryButton } from "components/ui/Button";
import { DOCS_PAGE_URL, DocsPage } from "legacy/constants/routes";
import { callServer } from "store/utils/client";
import { colors } from "styles/colors";
import { styleAsClass } from "styles/styleAsClass";

const TitleWrapper = styleAsClass`
  display: flex;
  align-items: center;
  gap: 14px;
`;

const FooterWrapper = styleAsClass`
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  gap: 12px;
`;

const ModalInnerWrapper = styleAsClass`
  display: flex;
  flex-direction: column;
  font-size: 12px;
`;

const MainSection = styleAsClass`
  display: flex;
  flex-direction: column;
  gap: 4px;
  .add-token-name {
    font-weight: 500;
  }
  .add-token-help-info {
    color: ${colors.GREY_400};
    font-size: 12px;
  }
  .error-info {
    color: ${colors.RED_500};
  }
  .error-input-wrapper > span {
    border-color: ${colors.RED_500} !important;
    box-shadow: none !important;
  }
`;

const TokenInputModal = ({
  isModalVisible,
  setIsModalVisible,
  setConnectModalVisible,
  isNew,
  provider,
}: {
  isModalVisible: boolean;
  setIsModalVisible: (visible: boolean) => void;
  setConnectModalVisible: (visible: boolean) => void;
  isNew: boolean;
  provider: VcsProvider;
}) => {
  const [token, setToken] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isEdited, setIsEdited] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    const getToken = async () => {
      setError("");
      setIsLoading(true);
      try {
        const res = await callServer<any>(
          {
            method: HttpMethod.GET,
            url: "v1/git-sync/token",
          },
          {
            notifyOnError: false,
            onError: (err) => {
              console.error(`Failed to get provider token: ${err}`);
              setError(err?.message ?? "Failed to get provider token");
            },
          },
        );
        if (res) {
          setToken(res.tokens?.[0]?.token);
        }
      } catch (err: any) {
        console.error(`Failed to get provider token: : ${err}`);
        setError(err?.message ?? "Failed to save token");
      } finally {
        setIsLoading(false);
      }
    };
    if (isModalVisible && !isNew) {
      setToken("");
      getToken();
    } else {
      // if token modal is closed
      setIsEdited(false);
      if (!isNew) {
        setToken("");
      }
    }
  }, [isNew, isModalVisible]);

  const onCancel = useCallback(() => {
    setIsModalVisible(false);
  }, [setIsModalVisible]);

  const onChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (!isEdited) {
        setIsEdited(true);
      }
      setToken(event?.target?.value || "");
    },
    [isEdited],
  );

  const onFocus = useCallback(() => {
    if (!isEdited && !isNew) {
      setToken("");
      setIsEdited(true);
    }
  }, [isEdited, isNew]);

  const onSave = useCallback(async () => {
    setError("");
    try {
      setIsLoading(true);
      const res = await callServer<any>(
        {
          method: HttpMethod.PUT,
          url: "v1/git-sync/token",
          body: {
            token,
            provider: provider,
          },
        },
        {
          notifyOnError: false,
          onError: (err) => {
            console.error(`Failed to save token: ${err}`);
            setError(err?.message ?? "Failed to save token");
          },
        },
      );
      if (res) {
        setIsModalVisible(false);
        if (isNew) {
          setConnectModalVisible(true);
        }
      }
    } catch (err: any) {
      console.error(`Failed to save token: ${err}`);
      setError(err?.message ?? "Failed to save token");
    } finally {
      setIsLoading(false);
    }
  }, [isNew, provider, setConnectModalVisible, setIsModalVisible, token]);

  return (
    <Modal
      open={isModalVisible}
      onCancel={onCancel}
      title={
        <div className={TitleWrapper}>
          <GitLabIcon />
          Connect to GitLab
        </div>
      }
      footer={null}
      destroyOnClose={true}
    >
      <div className={ModalInnerWrapper}>
        <div className={MainSection}>
          <div className="token-input-label">
            Access token <span style={{ color: colors.RED_500 }}>*</span>
          </div>
          <Input
            value={token}
            onChange={onChange}
            autoFocus={isNew}
            onFocus={onFocus}
            type={isEdited || isNew ? "password" : "text"}
          />
          {error && (
            <div className="error-info" data-test="create-branch-info">
              {error}
            </div>
          )}
          <div className="add-token-help-info">
            Access token for your Superblocks service account in GitLab. Update
            this token’s permissions to access additional repositories.{" "}
            <a
              href={DOCS_PAGE_URL(DocsPage.GITSYNC_REPOSITORIES)} // TODO: use add token doc page
              target="_blank"
              rel="noreferrer"
            >
              Learn more
            </a>
          </div>
        </div>
        <div className={FooterWrapper}>
          <SecondaryButton onClick={onCancel} loading={isLoading}>
            Cancel
          </SecondaryButton>
          <PrimaryButton
            type="primary"
            onClick={onSave}
            data-test="add-token-button"
            disabled={isEmpty(token) || !isEdited}
            loading={isLoading}
          >
            Save
          </PrimaryButton>
        </div>
      </div>
    </Modal>
  );
};

TokenInputModal.displayName = "TokenInputModal";
export default TokenInputModal;
