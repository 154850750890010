import { ApplicationScope } from "@superblocksteam/shared";
import { useCallback } from "react";
import { useStore } from "react-redux";

import {
  getExistingWidgetNames,
  getExistingPageNames,
} from "legacy/selectors/sagaSelectors";
import { validateName } from "legacy/utils/helpers";
import { selectAllApiUnionNames } from "store/slices/apisShared/selectors";
import { getScopedEventNames } from "store/slices/application/events/selectors";
import { getScopedStateVarNames } from "store/slices/application/stateVars/selectors";
import { getScopedTimerNames } from "store/slices/application/timers/selectors";
import { AppState } from "store/types";

export default function useEntityNameValidator(
  currentName: string,
  scope: ApplicationScope | undefined,
) {
  const store = useStore<AppState>();

  return useCallback(
    (name?: string) => {
      const state = store.getState();
      const existingPageNames = getExistingPageNames(state);
      const existingWidgetNames = getExistingWidgetNames(state);
      const existingBothApiNames = selectAllApiUnionNames(state);
      const existingTimerNames = getScopedTimerNames(
        state,
        scope ?? ApplicationScope.PAGE,
      );
      const existingVariableNames = getScopedStateVarNames(
        state,
        scope ?? ApplicationScope.PAGE,
      );
      const existingEventNames = getScopedEventNames(
        state,
        scope ?? ApplicationScope.PAGE,
      );

      // pages or apis must be case insensitively unique
      const isCurrentEntityPageOrApi =
        existingBothApiNames.includes(currentName);

      const invalidNames = [
        ...existingPageNames,
        ...existingWidgetNames,
        ...existingBothApiNames,
        ...existingTimerNames,
        ...existingVariableNames,
        ...existingEventNames,
      ].filter((name) => name !== currentName);

      return validateName(name, invalidNames, false, isCurrentEntityPageOrApi);
    },
    [store, currentName, scope],
  );
}
