import { Dimension, Margin } from "@superblocksteam/shared";
import React from "react";
import { ReactComponent as MarginLeft } from "assets/icons/common/margin-left.svg";
import MarginEditor from "pages/Editors/AppBuilder/Sidebar/PaddingMarginEditor";
import BaseControl, { ControlProps } from "./BaseControl";

export const EmptyMargin: Margin = {
  left: Dimension.px(0),
  right: Dimension.px(0),
  top: Dimension.px(0),
  bottom: Dimension.px(0),
};

const MARGIN_PRESETS = {
  none: {
    value: {
      top: Dimension.px(0),
      bottom: Dimension.px(0),
      left: Dimension.px(0),
      right: Dimension.px(0),
    },
    label: "None (0px)",
    shortLabel: "None (0px)",
  },
  small: {
    value: {
      top: Dimension.px(6),
      bottom: Dimension.px(6),
      left: Dimension.px(6),
      right: Dimension.px(6),
    },
    label: "Small (6px)",
    shortLabel: "S (6px)",
  },
  medium: {
    value: {
      top: Dimension.px(12),
      bottom: Dimension.px(12),
      left: Dimension.px(12),
      right: Dimension.px(12),
    },
    label: "Medium (12px)",
    shortLabel: "M (12px)",
  },
  large: {
    value: {
      top: Dimension.px(24),
      bottom: Dimension.px(24),
      left: Dimension.px(24),
      right: Dimension.px(24),
    },
    label: "Large (24px)",
    shortLabel: "L (24px)",
  },
};

const getMarginOptions = ({ useShortLabels }: { useShortLabels: boolean }) => {
  if (useShortLabels) {
    return Object.values(MARGIN_PRESETS).map((preset) => ({
      value: preset.value,
      label: preset.shortLabel ?? preset.label,
    }));
  }
  return Object.values(MARGIN_PRESETS);
};

class MarginControl extends BaseControl<ControlProps> {
  render() {
    return (
      <MarginEditor
        showThemeValue={false}
        propertyValue={this.props.propertyValue ?? EmptyMargin}
        themeValue={this.props.defaultValue ?? EmptyMargin}
        onPropertyChange={(margin) => {
          this.updateProperty(this.props.propertyName, margin);
        }}
        onBlur={this.props.onBlur}
        isDisabled={this.props.isDisabled}
        tooltipLabel="Margin"
        options={getMarginOptions({
          useShortLabels: Boolean(this.props.isV2Panel),
        })}
        allowNegativeValues={true}
        type="margin"
        LeftIconComponent={MarginLeft}
      />
    );
  }

  static getControlType() {
    return "MARGIN_CONTROL";
  }
}

export default MarginControl;
