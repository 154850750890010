import { HttpMethod, getMethodColor } from "@superblocksteam/shared";
import { Button, Collapse, CollapseProps, Tabs } from "antd";
import { last } from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import styled, { css } from "styled-components";
import { ReactComponent as DocsIcon } from "assets/icons/home/docs.svg";
import { Layout } from "components/app";
import { StyledReactMarkdown } from "components/app/SBDynamicForm/DynamicFormItem";
import { CodeBlock } from "components/ui/CodeBlock";
import CodeBlockAdvanced from "components/ui/CodeBlockAdvanced";
import ExpandIconButton from "components/ui/IconButtons/ExpandIconButton";
import { Spinner } from "components/ui/Spinner";
import { scrollbarLight } from "legacy/constants/DefaultTheme";
import { LegacyNamedColors } from "legacy/constants/LegacyNamedColors";
import Entity from "legacy/pages/Editor/Explorer/Entity";
import ExplorerSearch from "legacy/pages/Editor/Explorer/ExplorerSearch";
import { StyledSider } from "pages/components/PageNav";
import { useAppSelector } from "store/helpers";
import { selectMetadataLoadingById } from "store/slices/datasources";
import {
  OpenAPIMethods,
  generateAuthenticationDoc,
  getDetailsFromRequest,
  getDetailsFromResponse,
  getDisplayedTag,
  getIsOpenAPIV2,
  getParamsByType,
  getTypeString,
} from "./openApiUtils";

const { TabPane } = Tabs;

const Title = styled.div`
  font-size: 12px;
  display: flex;
  gap: 6px;
  margin-left: 9px;
`;

const SpecViewerWrapper = styled.div`
  .CodeMirror {
    height: 750px;
    border: 1px solid ${(props) => props.theme.colors.GREY_100};
    border-radius: 4px;
  }
`;

const OverviewAction = {
  path: "",
  method: "overview",
  methodDetails: {},
};

const SpecificationAction = {
  path: "",
  method: "specification",
  methodDetails: {},
};

enum RequestTabsKey {
  EXAMPLE = "EXAMPLE",
  SCHEMA = "SCHEMA",
}

enum ResponseTabsKey {
  EXAMPLE = "EXAMPLE",
  SCHEMA = "SCHEMA",
}

type OpenApiAction = {
  path: string;
  method: string;
  methodDetails: any;
};

type ParamType = {
  name: string;
  in?: string;
  description: string;
  required?: boolean;
  schema?: {
    type: string;
  };
  type?: string;
};

type Tag = {
  description: string;
  name: string;
};

const SearchWrapper = styled.div`
  padding: 0 18px 12px;
  background: white;
  z-index: 1;
`;

const LayoutWrapper = styled.div`
  border: 1px solid ${LegacyNamedColors.GRAY_LIGHT};
  margin-top: 20px;

  .ant-alert-warning {
    background-color: ${(props) => props.theme.colors.ACCENT_ORANGE}14;
    border-color: ${(props) => props.theme.colors.ACCENT_ORANGE}80;
  }
  .ant-layout-has-sider {
    overflow-y: hidden;
    & > div:first-child {
      max-height: 800px;
      min-height: 480px;
      overflow-y: auto;
    }
    & > main.ant-layout-content > div {
      max-height: 800px;
      min-height: 480px;
      overflow-y: auto;
      ${scrollbarLight}
    }
  }

  .ant-layout-content,
  .ant-layout-has-sider > div:first-child {
    ${scrollbarLight}
  }
`;

const FormWrapper = styled.div`
  width: 100%;
  padding: 24px;
  font-size: ${(props) => props.theme.text.sizes.default};
  background-color: ${LegacyNamedColors.WHITE};
`;

const NavWrapper = styled.div`
  border-right: 1px solid ${LegacyNamedColors.GRAY_LIGHT};
  background-color: ${LegacyNamedColors.WHITE};
  padding-top: 10px;
  .api-spec-group.overview {
    border-bottom: 0px;
  }
`;

const ParamTableWrapper = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: minmax(min(100px, 40%), min(150px, 40%)) auto;
  gap: 14px;
  .param-key {
    display: flex;
    flex-direction: column;
    text-align: left;
    .param-type {
      color: ${(props) => props.theme.colors.GREY_500};
    }
  }
  .param-description {
    display: flex;
    padding-bottom: 8px;
  }
  .param-required {
    color: ${(props) => props.theme.colors.DANGER};
  }
`;

const NavPanelWrapper = styled.div<{ siderMode: boolean }>`
  div.search-wrapper {
    background-color: transparent;
    ${(props) =>
      props.siderMode
        ? css`
            padding: 0px;
            padding-bottom: 12px;
          `
        : css`
            padding: 6px 18px;
          `}
  }
  ${(props) =>
    props.siderMode &&
    css`
      .api-tag-group > .entity-item,
      .api-path > .entity-item {
        padding-left: 0px;
        background-color: transparent;
      }
      .api-path > .entity-item {
        cursor: default;
      }
      .hierarchy-divider {
        display: none;
      }
    `}
`;

const ApiDocWrapper = styled.div`
  display: flex;
  flex-direction: column;
  h1.doc-summary {
    font-size: 15px !important;
    font-weight: 600 !important;
  }

  h2 {
    font-size: 14px !important;
    font-weight: 500;
    margin-bottom: 8px;
  }

  code {
    word-wrap: break-word;
  }

  .doc-item {
    margin-bottom: 12px;
  }
  .doc-endpoint {
    font-size: 14px;
    .doc-path {
      background: inherit;
      font-size: 13px;
    }
  }
  .doc-description {
    code {
      padding: 1px 6px;
      border-radius: 2px;
      background-color: ${(props) => props.theme.colors.GREY_100};
      line-height: 1.2;
    }
  }
  .doc-response-codes {
    display: inline-block;
    button {
      margin-right: 6px;
      margin-bottom: 6px;
    }
  }
  .code-block-container {
    max-height: 500px;
    overflow-y: auto;
    ${scrollbarLight}
  }

  .param-name {
    font-size: 12px;
  }
`;

export const OverviewWrapper = styled.div<{ siderMode?: boolean }>`
  ${(props) =>
    props.siderMode
      ? css`
          h1 {
            font-size: 16px !important;
            font-weight: 600 !important;
          }

          h2 {
            font-size: 15px !important;
            font-weight: 500;
            margin-bottom: 8px;
          }
          h3 {
            font-size: 14px !important;
            font-weight: 500;
            margin-bottom: 8px;
          }

          h4 {
            font-size: 13px !important;
            font-weight: 500;
            margin-bottom: 8px;
          }
          li {
            font-size: 12px;
          }
        `
      : css`
          h1 {
            font-size: 21px !important;
            font-weight: 600;
          }
        `}

  p,
  p:last-child {
    font-size: 12px;
    margin-bottom: 12px;
  }
  .overview-version {
    margin-top: -4px;
    margin-bottom: 12px;
    display: inline-block;
    .version-text {
      margin-right: 8px;
      color: ${(props) => props.theme.colors.GREY_500} !important;
    }
  }
`;

const StyledCollapse = styled(Collapse)`
  border: none;

  & > .ant-collapse-item > .ant-collapse-header {
    padding: 8px 8px;
    border-bottom: 0px;
    &:hover {
      background: ${(props) => props.theme.colors.GREY_50};
    }
  }

  & > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box {
    padding: 0px 0px;
  }
`;

const getActionEntityKey = (action: OpenApiAction) =>
  `${action.method}-${action.path}`;

const ParamsTable = ({
  params,
  className,
}: {
  params: ParamType[];
  className?: string;
}) => {
  return (
    <ParamTableWrapper className={className}>
      {params.map((param) => (
        <>
          <div className="param-key">
            <div className="param-name">
              <code>{param.name}</code>
              {param.required && (
                <span className="param-required"> &nbsp;*</span>
              )}
            </div>
            <div className="param-type">
              {getTypeString(param.schema?.type ?? param?.type)}
            </div>
          </div>
          <div className="param-description">
            <StyledReactMarkdown>{param.description}</StyledReactMarkdown>
          </div>
        </>
      ))}
    </ParamTableWrapper>
  );
};

const ActionNavPanel = ({
  searchKeyword,
  setSearchKeyword,
  tagToActions,
  filteredTags,
  selectedAction,
  setSelectedAction,
  siderMode,
  openApiSpecName,
}: {
  searchKeyword: string | undefined;
  setSearchKeyword: (keyword?: string) => void;
  tagToActions: Record<string, OpenApiAction[]>;
  filteredTags: Array<Tag>;
  selectedAction: OpenApiAction | undefined;
  setSelectedAction: (action: OpenApiAction) => void;
  siderMode?: boolean;
  openApiSpecName?: string;
}) => {
  const actionList = useMemo(
    () =>
      filteredTags &&
      filteredTags.map(
        (tag) =>
          (tagToActions[tag.name] ?? []).length > 0 && (
            <Entity
              step={3}
              key={tag.name}
              name={getDisplayedTag(tag.name)}
              className="api-spec-group api-tag-group"
              entityId={tag.name}
              isDefaultExpanded={Boolean(searchKeyword)}
              hideContextMenu={true}
              searchKeyword={searchKeyword}
              disableEdit
              toggleOnClick
            >
              {(tagToActions[tag.name] ?? [])?.map((action: OpenApiAction) => (
                <ActionEntity
                  key={`${action.method}-${action.path}`}
                  selectedAction={selectedAction}
                  action={action}
                  setSelectedAction={setSelectedAction}
                  searchKeyword={searchKeyword}
                  disableClick={siderMode}
                />
              ))}
            </Entity>
          ),
      ),
    [
      filteredTags,
      searchKeyword,
      selectedAction,
      setSelectedAction,
      siderMode,
      tagToActions,
    ],
  );

  const selectOverview = useCallback(() => {
    setSelectedAction(OverviewAction);
  }, [setSelectedAction]);

  const selectSpecification = useCallback(() => {
    setSelectedAction(SpecificationAction);
  }, [setSelectedAction]);

  const renderExpandIcon = useCallback<Required<CollapseProps>["expandIcon"]>(
    (panelProps) => <ExpandIconButton isExpanded={panelProps.isActive} />,
    [],
  );

  return (
    <NavPanelWrapper siderMode={Boolean(siderMode)}>
      {siderMode ? (
        <>
          <SearchWrapper className="search-wrapper">
            <ExplorerSearch value={searchKeyword} onChange={setSearchKeyword} />
          </SearchWrapper>
          {actionList}
        </>
      ) : (
        <>
          <StyledCollapse
            defaultActiveKey={["specification", "documentation"]}
            expandIcon={renderExpandIcon}
            expandIconPosition="end"
            ghost
          >
            <Collapse.Panel
              header={
                <Title data-test="api-spec-specification">
                  <DocsIcon /> Specification
                </Title>
              }
              // for auto collapsing the overview when selecting an action
              key="specification"
            >
              <Entity
                step={2}
                allowExpandingIfEmpty={false}
                key="specification"
                name={openApiSpecName ?? ""}
                className="api-spec-group specification"
                entityId="specification"
                isDefaultExpanded={true}
                hideContextMenu={true}
                searchKeyword={searchKeyword}
                disableEdit
                action={selectSpecification}
                active={selectedAction === SpecificationAction}
              />
            </Collapse.Panel>
            <Collapse.Panel
              header={
                <Title data-test="api-spec-documentation">
                  <DocsIcon /> Documentation
                </Title>
              }
              // for auto collapsing the overview when selecting an action
              key="documentation"
            >
              <SearchWrapper className="search-wrapper">
                <ExplorerSearch
                  value={searchKeyword}
                  onChange={setSearchKeyword}
                />
              </SearchWrapper>
              <Entity
                step={2}
                allowExpandingIfEmpty={false}
                key="overview"
                name="Overview"
                className="api-spec-group overview"
                entityId="overview"
                isDefaultExpanded={true}
                hideContextMenu={true}
                searchKeyword={searchKeyword}
                disableEdit
                action={selectOverview}
                active={selectedAction === OverviewAction}
              />
              <Entity
                step={2}
                key="endpoints"
                name="Endpoints"
                className="api-spec-group endpoints-group"
                entityId="endpoints"
                isDefaultExpanded={true}
                hideContextMenu={true}
                searchKeyword={searchKeyword}
                disableEdit
                toggleOnClick
              >
                {actionList}
              </Entity>
            </Collapse.Panel>
          </StyledCollapse>
        </>
      )}
    </NavPanelWrapper>
  );
};

const SpecificationViewer = ({
  openApiString,
  openApiSpecName,
}: {
  openApiString: string;
  openApiSpecName: string | undefined;
}) => {
  const specFileType = last(openApiSpecName?.split("."));

  const mode = ["yaml", "yml"].includes(specFileType ?? "")
    ? "yaml"
    : "application/json";

  return (
    <SpecViewerWrapper>
      <CodeBlockAdvanced mode={mode} stringValue={openApiString} />
    </SpecViewerWrapper>
  );
};
export const OverviewDoc = ({
  openApiSpec,
  siderMode,
}: {
  openApiSpec: any;
  siderMode?: boolean;
}) => {
  const authenticationDoc = Object.entries(
    openApiSpec?.components?.securitySchemes ?? {},
  ).map(([authKey, authObj]) => {
    return (
      <StyledReactMarkdown key={authKey}>
        {generateAuthenticationDoc(authObj)}
      </StyledReactMarkdown>
    );
  });

  const url = openApiSpec?.externalDocs?.url;

  return (
    <OverviewWrapper siderMode={siderMode}>
      <h1 className="sb-override">{openApiSpec?.info?.title}</h1>
      <div className="overview-version">
        <span className="version-text">
          Version {openApiSpec?.info?.version}
        </span>
        {url && (
          <span className="url-text">
            URL:{" "}
            <a href={url} target="_blank" rel="noreferrer">
              {url}
            </a>
          </span>
        )}
      </div>
      <h2> Overview </h2>
      <div>
        <StyledReactMarkdown>
          {openApiSpec?.info?.description}
        </StyledReactMarkdown>
      </div>
      {authenticationDoc && authenticationDoc?.length > 0 && (
        <h2> Authentication </h2>
      )}
      {authenticationDoc}
      <h2> Servers </h2>
      <p>
        {openApiSpec?.servers?.map((server: any) => {
          return <div key={server.url}>{server.url}</div>;
        }) ??
          `${
            openApiSpec?.schemes?.[0] ? `${openApiSpec?.schemes?.[0]}://` : ""
          }${openApiSpec.host ?? ""}${openApiSpec.basePath ?? ""}`}
      </p>
    </OverviewWrapper>
  );
};

const ActionDoc = ({
  action,
  openApiSpec,
}: {
  action: OpenApiAction;
  openApiSpec: any;
}) => {
  const isOpenAPIV2 = getIsOpenAPIV2(openApiSpec);
  const actionDetails = action.methodDetails;
  // method
  const method = action.method?.toUpperCase();

  // header
  const headerParams = getParamsByType({
    openApiSpec,
    params: [...(actionDetails?.parameters ?? [])],
    type: "header",
  });

  // queryParams
  const queryParams = getParamsByType({
    openApiSpec,
    params: [...(actionDetails?.parameters ?? [])],
    type: "query",
  });

  // pathParams
  const pathParams = getParamsByType({
    openApiSpec,
    params: [...(actionDetails?.parameters ?? [])],
    type: "path",
  });

  // requestBody
  // const requestBody = actionDetails?.requestBody?.content;
  const {
    body: requestBody,
    bodyType: requestBodyType,
    exampleString: requestExampleString,
    schemaString: requestSchemaString,
  } = getDetailsFromRequest({ actionDetails, isOpenAPIV2 });

  // response
  const responses = actionDetails?.responses;
  const responseCodes = useMemo(
    () => Object.keys(responses ?? {}),
    [responses],
  );
  const responseCodeToDetail: Record<
    string,
    { bodyType: string; example: any; schema: any; headers?: ParamType[] }
  > = {};

  Object.entries(responses ?? {}).forEach(([code, responseDetails]) => {
    // const responseBody = (responseDetails as any)?.content;
    const {
      bodyType,
      exampleString: example,
      schemaString: schema,
      headers,
    } = getDetailsFromResponse({
      responseDetails,
      actionDetails,
      isOpenAPIV2,
    });
    responseCodeToDetail[code] = {
      bodyType,
      example,
      schema,
      headers,
    };
  });
  const [selectedResponseCode, setSelectedResponseCode] = useState<string>(
    responseCodes?.[0] ?? "",
  );
  useEffect(() => {
    setSelectedResponseCode(responseCodes?.[0] ?? "");
  }, [responseCodes]);

  const [activeTabRequest, setActiveTabRequest] = useState<string>(
    RequestTabsKey.EXAMPLE,
  );
  const [activeTabResponse, setActiveTabResponse] = useState<string>(
    RequestTabsKey.EXAMPLE,
  );

  return (
    <ApiDocWrapper>
      <h1 className="doc-summary doc-item">{actionDetails?.summary}</h1>
      <div className="doc-endpoint doc-item">
        <span
          className="doc-method"
          style={{ color: getMethodColor(method as HttpMethod) }}
        >
          {method}{" "}
        </span>
        <code className="doc-path">{action.path}</code>
      </div>
      <div className="doc-description doc-item">
        <StyledReactMarkdown>{actionDetails?.description}</StyledReactMarkdown>
      </div>
      {pathParams && (
        <div className="doc-item">
          <h2 className="doc-pathparams-label doc-label">Path Parameters</h2>
          <ParamsTable className="doc-pathparams" params={pathParams} />
        </div>
      )}
      {headerParams && (
        <div className="doc-item">
          <h2 className="doc-headers-label doc-label">Headers</h2>
          <ParamsTable className="doc-headers" params={headerParams} />
        </div>
      )}
      {queryParams && (
        <div className="doc-item">
          <h2 className="doc-queryparams-label doc-label">Query Parameters</h2>
          <ParamsTable className="doc-queryparams" params={queryParams} />
        </div>
      )}
      {requestBody && (
        <div className="doc-item">
          <h2 className="doc-requestbody-label doc-label">Request Body</h2>
          <div className="doc-requestbody-type"> {requestBodyType} </div>
          <Tabs
            activeKey={activeTabRequest}
            defaultActiveKey={RequestTabsKey.EXAMPLE}
            onChange={setActiveTabRequest}
          >
            <TabPane tab="Example" key={RequestTabsKey.EXAMPLE}>
              <div className="doc-requestbody">
                <CodeBlock text={requestExampleString}></CodeBlock>
              </div>
            </TabPane>
            <TabPane tab="Schema" key={RequestTabsKey.SCHEMA}>
              <div className="doc-requestbody">
                {requestSchemaString ? (
                  <CodeBlock text={requestSchemaString}></CodeBlock>
                ) : (
                  `Response body is empty`
                )}
              </div>
            </TabPane>
          </Tabs>
        </div>
      )}
      {responseCodes && (
        <div className="doc-item">
          <h2 className="doc-responsebody-label doc-label">Response</h2>
          <div className="doc-response-codes">
            {responseCodes.map((code) => (
              <Button
                type={selectedResponseCode === code ? "primary" : "default"}
                key={code}
                onClick={() => setSelectedResponseCode(code)}
              >
                {code}
              </Button>
            ))}
          </div>
          <div className="doc-responsebody-type">
            {responseCodeToDetail[selectedResponseCode]?.bodyType}
          </div>
          {(responseCodeToDetail[selectedResponseCode]?.headers ?? []).length >
            0 && (
            <div className="doc-item">
              <h2 className="doc-headers-label doc-label">Response Headers</h2>
              <ParamsTable
                className="doc-headers"
                params={
                  responseCodeToDetail[selectedResponseCode]
                    ?.headers as ParamType[]
                }
              />
            </div>
          )}

          <Tabs
            activeKey={activeTabResponse}
            defaultActiveKey={ResponseTabsKey.EXAMPLE}
            onChange={setActiveTabResponse}
          >
            <TabPane tab="Example" key={ResponseTabsKey.EXAMPLE}>
              <div className="doc-responsebody">
                {responseCodeToDetail[selectedResponseCode]?.example ? (
                  <CodeBlock
                    text={responseCodeToDetail[selectedResponseCode]?.example}
                  ></CodeBlock>
                ) : (
                  `Response body is empty`
                )}
              </div>
            </TabPane>
            <TabPane tab="Schema" key={ResponseTabsKey.SCHEMA}>
              <div className="doc-responsebody">
                {responseCodeToDetail[selectedResponseCode]?.schema ? (
                  <CodeBlock
                    text={responseCodeToDetail[selectedResponseCode]?.schema}
                  ></CodeBlock>
                ) : (
                  `Response body is empty`
                )}
              </div>
            </TabPane>
          </Tabs>
        </div>
      )}
    </ApiDocWrapper>
  );
};

const ActionEntity = ({
  action,
  setSelectedAction,
  searchKeyword,
  selectedAction,
  disableClick,
}: {
  action: OpenApiAction;
  setSelectedAction: (action: OpenApiAction) => void;
  searchKeyword?: string;
  selectedAction: OpenApiAction | undefined;
  disableClick?: boolean;
}) => {
  const onSelectAction = useCallback(() => {
    setSelectedAction(action);
  }, [action, setSelectedAction]);

  const active = selectedAction
    ? getActionEntityKey(action) === getActionEntityKey(selectedAction)
    : false;

  const method = action?.method?.toUpperCase();
  return (
    <Entity
      step={3}
      active={active}
      key={getActionEntityKey(action)}
      name={`${action.methodDetails?.summary ?? action.path} `}
      className="api-spec-group api-path"
      entityId={getActionEntityKey(action)}
      isDefaultExpanded={false}
      hideContextMenu={true}
      action={disableClick ? undefined : onSelectAction}
      searchKeyword={searchKeyword}
      disableEdit
      disableClick={disableClick}
      showTooltip
      prefix={
        <div
          style={{ color: getMethodColor(method as HttpMethod), width: 48 }}
          className="method-prefix"
        >
          {method}
        </div>
      }
    />
  );
};

const ApiSpecification = ({
  integrationId,
  openApiSpec,
  siderMode,
  openApiAction,
  openApiSpecName,
  openApiString,
}: {
  integrationId: string;
  openApiSpec: any;
  siderMode?: boolean; // this is used to indicate if explorer is in sider panel
  openApiAction?: OpenApiAction;
  openApiSpecName?: string;
  openApiString?: string;
}) => {
  const paths = openApiSpec?.paths as Record<string, any>;
  const metadataLoading = useAppSelector((state) =>
    selectMetadataLoadingById(state, integrationId),
  );
  const [selectedAction, setSelectedAction] = useState<
    OpenApiAction | undefined
  >();

  const [searchKeyword, setSearchKeyword] = useState<string | undefined>();

  const searchKeywordLowerCased = searchKeyword?.toLocaleLowerCase();

  const [tagToActions, filteredTags, tags]: [
    Record<string, any>,
    Array<Tag>,
    Array<Tag>,
  ] = useMemo(() => {
    const tagToActionsMap: Record<string, OpenApiAction[]> = {};
    if (!paths) return [{}, [], []];

    Object.entries(paths).forEach(([path, pathDetails]) => {
      const pathHasKeyword =
        searchKeywordLowerCased &&
        (path as string)?.toLocaleLowerCase().includes(searchKeywordLowerCased);
      Object.entries(pathDetails as Record<string, any>).forEach(
        ([method, methodDetails]) => {
          if (method === "parameters") {
            return;
          }
          if (!OpenAPIMethods.includes(method)) {
            return;
          }
          const summaryHasKeyword =
            searchKeywordLowerCased &&
            (methodDetails?.summary as string)
              ?.toLocaleLowerCase()
              ?.includes?.(searchKeywordLowerCased);

          (methodDetails?.tags && methodDetails?.tags?.length > 0
            ? methodDetails?.tags
            : [path]
          )?.forEach((tag: string) => {
            if (
              searchKeywordLowerCased &&
              !(
                tag.toLocaleLowerCase().includes(searchKeywordLowerCased) ||
                pathHasKeyword ||
                summaryHasKeyword
              )
            ) {
              return;
            }
            if (tagToActionsMap[tag]) {
              tagToActionsMap[tag].push({
                path,
                method,
                methodDetails,
              });
            } else {
              tagToActionsMap[tag] = [
                {
                  path,
                  method,
                  methodDetails,
                },
              ];
            }
          });
        },
      );
    });

    const tags: Array<Tag> =
      openApiSpec?.tags === undefined || openApiSpec?.tags?.length === 0
        ? (Object.keys(tagToActionsMap ?? {})?.map((tag: string) => ({
            name: tag,
            description: "",
          })) as Array<Tag>)
        : openApiSpec?.tags;

    const filteredTagsSet = new Set(Object.keys(tagToActionsMap ?? {}));
    const filteredTags = searchKeywordLowerCased
      ? tags.filter((tag) => filteredTagsSet.has(tag.name))
      : tags;
    return [tagToActionsMap, filteredTags, tags];
  }, [openApiSpec, paths, searchKeywordLowerCased]);

  useEffect(() => {
    // do not set selectedAction if it is undefined, let it show action lists
    if (
      !siderMode &&
      !selectedAction &&
      tagToActions &&
      tags &&
      tagToActions?.[tags[0]?.name]?.[0]
    ) {
      setSelectedAction(OverviewAction);
    }
  }, [openApiAction, selectedAction, siderMode, tagToActions, tags]);

  if (siderMode) {
    if (openApiAction) {
      return (
        <div>
          <ActionDoc action={openApiAction} openApiSpec={openApiSpec} />
        </div>
      );
    } else {
      return (
        <div>
          <ActionNavPanel
            searchKeyword={searchKeyword}
            setSearchKeyword={setSearchKeyword}
            tagToActions={tagToActions}
            filteredTags={filteredTags}
            selectedAction={openApiAction}
            setSelectedAction={setSelectedAction}
            siderMode={true}
          />
        </div>
      );
    }
  }

  return (
    <div>
      <Spinner spinning={Boolean(metadataLoading)}>
        <LayoutWrapper className="in-tab-wrapper">
          <Layout
            Sider={
              <NavWrapper>
                <StyledSider width={300}>
                  <ActionNavPanel
                    searchKeyword={searchKeyword}
                    setSearchKeyword={setSearchKeyword}
                    tagToActions={tagToActions}
                    filteredTags={filteredTags}
                    selectedAction={selectedAction}
                    setSelectedAction={setSelectedAction}
                    openApiSpecName={openApiSpecName}
                  />
                </StyledSider>
              </NavWrapper>
            }
          >
            <FormWrapper
              key={selectedAction ? getActionEntityKey(selectedAction) : ""}
            >
              <div>
                {selectedAction && openApiSpec ? (
                  selectedAction?.method === "overview" ? (
                    <OverviewDoc openApiSpec={openApiSpec} />
                  ) : selectedAction?.method === "specification" ? (
                    <SpecificationViewer
                      openApiSpecName={openApiSpecName}
                      openApiString={openApiString ?? ""}
                    />
                  ) : (
                    <ActionDoc
                      action={selectedAction}
                      openApiSpec={openApiSpec}
                    />
                  )
                ) : null}
              </div>
            </FormWrapper>
          </Layout>
        </LayoutWrapper>
      </Spinner>
    </div>
  );
};

export default ApiSpecification;
