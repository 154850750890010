import { Tooltip, Typography } from "antd";
import React, { useCallback } from "react";
import styled from "styled-components";
import { ReactComponent as DocsIcon } from "assets/icons/home/docs.svg";
import { ReactComponent as PinIcon } from "assets/icons/sidebar/pin.svg";
import { ReactComponent as UnpinIcon } from "assets/icons/sidebar/unpin.svg";
import IconButton from "components/ui/IconButtons/IconButton";
import { ReduxActionTypes } from "legacy/constants/ReduxActionConstants";
import { getIsLeftPanePinned } from "legacy/selectors/editorSelectors";
import sessionStorage, { SessionStorageKey } from "legacy/utils/sessionStorage";
import { useAppDispatch, useAppSelector } from "store/helpers";

const Header = styled.div`
  padding: 16px 16px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  .ant-typography {
    margin: 0;
  }
`;

const RightDiv = styled.div`
  display: flex;
`;

const Title = styled(Typography.Text)`
  font-size: 13px;
  font-weight: 500;
  color: ${(props) => props.theme.colors.GREY_800};
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const DescriptionWrapper = styled.div`
  font-size: 12px;
  color: ${(props) => props.theme.colors.GREY_500};
`;

const SidebarHeader = (props: {
  title: string | React.ReactNode;
  docUrl?: string;
  description?: string;
  actionMenu?: JSX.Element;
}) => {
  const dispatch = useAppDispatch();
  const isLeftPanePinned = useAppSelector(getIsLeftPanePinned);
  const toggleSidebar = useCallback(() => {
    sessionStorage.setItem(
      SessionStorageKey.LEFT_PANE_PINNED,
      isLeftPanePinned ? "false" : "true",
    );
    dispatch({
      type: isLeftPanePinned
        ? ReduxActionTypes.UNPIN_LEFT_PANE
        : ReduxActionTypes.PIN_LEFT_PANE,
    });
  }, [dispatch, isLeftPanePinned]);

  return (
    <Header>
      <Row>
        <Title>{props.title}</Title>
        <RightDiv>
          {props.actionMenu}
          {props.docUrl && (
            <a href={props.docUrl} target="_blank" rel="noreferrer">
              <IconButton
                icon={<DocsIcon />}
                title="Documentation"
                tooltipPlacement="bottom"
              />
            </a>
          )}
          <Tooltip
            title={
              isLeftPanePinned ? "Unpin the side panel" : "Pin the side panel"
            }
            trigger="hover"
            mouseEnterDelay={0.3}
            placement="bottom"
          >
            {isLeftPanePinned ? (
              <IconButton
                icon={<UnpinIcon />} // Makes the icon centered
                title="Unpin panel"
                onClick={toggleSidebar}
                data-test="unpin-left-pane"
                tooltipPlacement="bottom"
              />
            ) : (
              <IconButton
                icon={<PinIcon />} // Makes the icon centered
                title="Pin panel"
                onClick={toggleSidebar}
                data-test="pin-left-pane"
                tooltipPlacement="bottom"
              />
            )}
          </Tooltip>
        </RightDiv>
      </Row>
      {props.description && (
        <DescriptionWrapper>{props.description}</DescriptionWrapper>
      )}
    </Header>
  );
};

export default SidebarHeader;
