import { ApplicationScope, getNextEntityName } from "@superblocksteam/shared";
import { call, put, race, select, take } from "redux-saga/effects";
import { updatePartialApiInfo } from "legacy/actions/apiActions";
import { openEditorTab } from "legacy/actions/editorPreferencesActions";
import { stopEvaluation } from "legacy/actions/evaluationActions";
import { pageLoadSuccess } from "legacy/actions/pageActions";
import { EditorOpenTabType } from "legacy/constants/EditorPreferencesConstants";
import {
  ReduxActionErrorTypes,
  ReduxActionTypes,
} from "legacy/constants/ReduxActionConstants";
import { waitForFirstEvaluation } from "legacy/sagas/waitForEvaluation";
import { getDataTree } from "legacy/selectors/dataTreeSelectors";
import { getCurrentPageId } from "legacy/selectors/editorSelectors";
import {
  getAllEntityNames,
  getMainContainer,
} from "legacy/selectors/sagaSelectors";
import { createFakeDataTree } from "store/helpers/refactoring";
import { createDuplicatePartialApiInfo } from "store/slices/apisShared/createDuplicatePartialApiInfo";
import { fastClone } from "utils/clone";
import { callSagas, createSaga } from "../../../utils/saga";
import { Api } from "../backend-types";
import { selectV2ApiById } from "../selectors";
import slice, { ApiDtoWithPb } from "../slice";
import { getV2ApiName } from "../utils/getApiIdAndName";
import { createV2ApiSaga } from "./createV2Api";

function* duplicateV2ApiToPageInternal(params: {
  pageId: string;
  apiId: string;
}): Generator<unknown, unknown, any> {
  try {
    const apiDef: ReturnType<typeof selectV2ApiById> = yield select(
      selectV2ApiById,
      params.apiId,
    );
    if (!apiDef)
      throw new Error(`API with ID "${params.apiId}" does not exist.`);

    const oldPageId = yield select(getCurrentPageId);
    if (oldPageId === params.pageId) {
      throw new Error("Cannot duplicate API to the same page.");
    }

    const mainContainer: ReturnType<typeof getMainContainer> = yield select(
      getMainContainer,
    );
    if (!mainContainer) return;
    const oldApiMap = fastClone(mainContainer?.apis?.apiMap || {});

    yield put({
      type: ReduxActionTypes.EDITOR_VIEW_ROUTE,
      payload: {
        pageId: params.pageId,
      },
    });
    const { error, stopped } = yield race({
      success: take(pageLoadSuccess.type),
      stop: take(stopEvaluation.type),
      error: take(ReduxActionErrorTypes.FETCH_PAGE_ERROR),
    });
    if (error || stopped) throw new Error("Failed to switch to page");

    const payload: Api = {
      ...apiDef.apiPb,
    };

    const apiName = getV2ApiName(apiDef);
    const entityNames = yield select(getAllEntityNames);
    const newName = entityNames.includes(apiName)
      ? getNextEntityName(`${apiName}_copy`, entityNames)
      : apiName;

    const [createdApi]: [ApiDtoWithPb] = yield call(callSagas, [
      createV2ApiSaga.apply({
        payload,
        prepopulatedName: newName,
        pageId: params.pageId,
        skipTriggers: true,
        skipRename: true,
      }),
    ]);
    if (!createdApi) throw new Error("Failed to duplicate API");

    const newApiId = createdApi.id;
    yield put(
      openEditorTab({
        tabType: EditorOpenTabType.API,
        entityId: createdApi.id,
      }),
    );

    const newApiTriggers = oldApiMap[params.apiId] ?? {};

    // Refactor any self-referential triggers such as onError
    yield call(waitForFirstEvaluation);
    const oldApiName = getV2ApiName(apiDef);
    const newApiName = getV2ApiName(createdApi);
    const dataTree: ReturnType<typeof getDataTree> = yield select(getDataTree);
    const fakeDataTree = createFakeDataTree(dataTree);

    // Placeholder for old name which is required for refactoring
    fakeDataTree[ApplicationScope.PAGE][oldApiName] = {};

    const newPartialInfo = yield call(
      createDuplicatePartialApiInfo,
      newApiTriggers,
      {
        newId: newApiId,
        newApiName,
        originalApiName: oldApiName,
        fakeDataTree,
      },
    );
    yield put(
      updatePartialApiInfo({
        id: newApiId,
        data: newPartialInfo,
      }),
    );

    return true;
  } catch (e) {
    console.log("[]", e);
  }
}

export const duplicateV2ApiToPageSaga = createSaga(
  duplicateV2ApiToPageInternal,
  "duplicateV2ApiToPageSaga",
  {
    sliceName: slice.name,
  },
);

slice.saga(duplicateV2ApiToPageSaga, {});
