import { isEqual } from "lodash";
import {
  StepDef,
  isValidStepDef,
  TriggerStepType,
} from "legacy/constants/ActionConstants";
import { AppTimer } from "store/slices/application/timers/TimerConstants";

export function deleteReferencesFromTimerTriggers(
  timers: Record<AppTimer["id"], AppTimer>,
  oldName: string,
  isWidget: boolean,
):
  | Array<{
      id: string;
      propertyName: string;
      propertyValue: unknown;
    }>
  | undefined {
  const updates: Array<{
    id: string;
    propertyName: string;
    propertyValue: unknown;
  }> = [];
  for (const [id, timer] of Object.entries(timers)) {
    if (timer.name === oldName) {
      return;
    }

    if (timer.steps && timer.steps.length) {
      timer.steps.forEach((step: StepDef, index) => {
        if (!isValidStepDef(step)) return;

        switch (step.type) {
          case TriggerStepType.RUN_APIS: {
            if (!isWidget) {
              const newValue = step.apiNames.filter((name) => name !== oldName);
              if (!isEqual(step.apiNames, newValue)) {
                updates.push({
                  id,
                  propertyName: `steps[${index}].apiNames`,
                  propertyValue: newValue,
                });
              }
            }
            break;
          }
          case TriggerStepType.CANCEL_APIS: {
            if (!isWidget) {
              const newValue = step.apiNames.filter((name) => name !== oldName);
              if (!isEqual(step.apiNames, newValue)) {
                updates.push({
                  id,
                  propertyName: `steps[${index}].apiNames`,
                  propertyValue: newValue,
                });
              }
            }
            break;
          }
          case TriggerStepType.CONTROL_SLIDEOUT: {
            if (isWidget && step.name === oldName) {
              updates.push({
                id,
                propertyName: `steps[${index}].name`,
                propertyValue: "",
              });
            }
            break;
          }
          case TriggerStepType.CONTROL_TIMER: {
            if (isWidget && step.name === oldName) {
              updates.push({
                id,
                propertyName: `steps[${index}].name`,
                propertyValue: "",
              });
            }
            break;
          }
          case TriggerStepType.TRIGGER_EVENT:
            break;
          case TriggerStepType.SHOW_ALERT:
          /* falls through */
          case TriggerStepType.CONTROL_MODAL:
          /* falls through */
          case TriggerStepType.RUN_JS:
          /* falls through */
          case TriggerStepType.NAVIGATE_TO:
          /* falls through */
          case TriggerStepType.SET_STATE_VAR:
          /* falls through */
          case TriggerStepType.RESET_STATE_VAR:
          /* falls through */
          case TriggerStepType.RESET_COMPONENT:
          /* falls through */
          case TriggerStepType.SET_COMPONENT_PROPERTY:
          /* falls through */
          case TriggerStepType.NAVIGATE_TO_APP:
            break;
          /* falls through */
          case TriggerStepType.SET_PROFILE:
            break;
          /* falls through */
          case TriggerStepType.NAVIGATE_TO_ROUTE:
            break;
          /* falls through */
          case TriggerStepType.SET_QUERY_PARAMS:
            break;

          default: {
            const exhaustiveCheck: never = step;
            throw new Error(`Unhandled action case: ${exhaustiveCheck}`);
          }
        }
      });
    }
  }

  if (updates.length) {
    return updates;
  }
}
