import {
  AcceptRequestDto,
  AccessRequestDto,
  DeleteSinksResponseDto,
  EntityPermissionsDto,
  GetSinksAllResponseDto,
  GroupBrief,
  OrganizationUserDto,
  PermissionedEntities,
  PostSinksRequestDto,
  PostSinksResponseDto,
  PutSinksRequestDto,
  RbacRole,
  ShareEntryCreationDto,
  ShareEntryDto,
} from "@superblocksteam/shared";
import {
  ADD_SHARE_ENTRIES,
  DELETE_SHARE_ENTRY,
  GET_SHARE_ENTRIES,
  GROUPS_FOR_ORG_ID_URL,
  PERMISSION_ENTITIES_URL_V2,
  PERMISSION_INTEGRATIONS_URL,
  UPDATE_SHARE_ENTRY,
  USERS_FOR_ORG_ID_URL,
} from "pages/routes";
import {
  ErrorHandlingOptions,
  HttpMethod,
  callServer,
} from "store/utils/client";

export function getEntitiesV2() {
  return callServer<EntityPermissionsDto[]>({
    method: HttpMethod.Get,
    url: PERMISSION_ENTITIES_URL_V2,
  });
}

export function getIntegrationsPermissions() {
  return callServer<EntityPermissionsDto[]>({
    method: HttpMethod.Get,
    url: PERMISSION_INTEGRATIONS_URL,
  });
}

// used by other pages outside permission page
export function getObservabilitySinks() {
  return callServer<GetSinksAllResponseDto>({
    method: HttpMethod.Get,
    url: `v1/observability/sinks`,
  });
}

export function createObservabilitySink(body: PostSinksRequestDto) {
  return callServer<PostSinksResponseDto>({
    method: HttpMethod.Post,
    url: `v1/observability/sinks`,
    body,
  });
}

export function updateObservabilitySink(
  sinkId: string,
  body: PutSinksRequestDto,
) {
  return callServer<PostSinksResponseDto>({
    method: HttpMethod.Put,
    url: `v1/observability/sinks/${sinkId}`,
    body,
  });
}

export function deleteObservabilitySink(sinkId: string) {
  return callServer<DeleteSinksResponseDto>({
    method: HttpMethod.Delete,
    url: `v1/observability/sinks/${sinkId}`,
  });
}

// TODO: deprecate this
export function addShareEntriesForResourceId(
  resourceId: string,
  resourceType: PermissionedEntities,
  shareEntriesToAdd: ShareEntryCreationDto[],
) {
  return callServer<ShareEntryDto[]>(
    {
      method: HttpMethod.Post,
      url: ADD_SHARE_ENTRIES(resourceType),
      params: { resourceId },
      body: {
        entries: shareEntriesToAdd,
      },
    },
    {
      notifyOnError: false,
    },
  );
}

export function getUsers(
  orgId: string,
  errorHandlingOptions?: ErrorHandlingOptions,
) {
  return callServer<OrganizationUserDto[]>(
    {
      method: HttpMethod.Get,
      url: USERS_FOR_ORG_ID_URL(),
      params: { orgId },
    },
    errorHandlingOptions,
  );
}

export function getGroupsForOrg(
  orgId: string,
  errorHandlingOptions = {
    notifyOnError: false,
  },
) {
  return callServer<{ groups: GroupBrief[] }>(
    {
      method: HttpMethod.Get,
      url: GROUPS_FOR_ORG_ID_URL(),
      params: { orgId },
    },
    errorHandlingOptions,
  );
}

// to be deprecated
export function updateShareEntryForResourceId(
  resourceId: string,
  resourceType: PermissionedEntities,
  shareEntry: ShareEntryDto,
  isDelete?: boolean,
) {
  return callServer<ShareEntryDto[]>(
    {
      method: isDelete ? HttpMethod.Post : HttpMethod.Put,
      url: isDelete
        ? DELETE_SHARE_ENTRY(resourceType)
        : UPDATE_SHARE_ENTRY(resourceType),
      params: { resourceId, shareEntryId: shareEntry.id },
      body: { entry: shareEntry },
    },
    {
      notifyOnError: false,
    },
  );
}

export function requestAccessForResource(
  resourceId: string,
  resourceType: PermissionedEntities,
  role: RbacRole,
) {
  return callServer<AccessRequestDto>({
    method: HttpMethod.Post,
    url: `v1/access/request/${resourceType}/${resourceId}`,
    body: { role },
  });
}

export function acceptRequestAccess(accessRequestId: string) {
  return callServer<AcceptRequestDto>(
    {
      method: HttpMethod.Post,
      url: `v1/access/accept/${accessRequestId}`,
    },
    {
      notifyOnError: false,
    },
  );
}

export function getApiPermissions(apiId: string) {
  return callServer<ShareEntryDto[]>({
    method: HttpMethod.Get,
    url: GET_SHARE_ENTRIES(PermissionedEntities.API_V3),
    params: { resourceId: apiId },
  });
}

export function addApiPermissions(
  apiId: string,
  entriesToAdd: ShareEntryCreationDto[],
) {
  return callServer<ShareEntryDto[]>(
    {
      method: HttpMethod.Post,
      url: ADD_SHARE_ENTRIES(PermissionedEntities.API_V3),
      params: { resourceId: apiId },
      body: {
        entries: entriesToAdd,
      },
    },
    {
      notifyOnError: false,
    },
  );
}

export function updateShareEntry(
  resourceType: PermissionedEntities,
  resourceId: string,
  shareEntry: ShareEntryDto,
  isDelete: boolean,
) {
  return callServer<ShareEntryDto[]>(
    {
      method: isDelete ? HttpMethod.Post : HttpMethod.Put,
      url: isDelete
        ? DELETE_SHARE_ENTRY(resourceType)
        : UPDATE_SHARE_ENTRY(resourceType),
      params: { resourceId, shareEntryId: shareEntry.id },
      body: { entry: shareEntry },
    },
    {
      notifyOnError: false,
    },
  );
}
