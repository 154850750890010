import { PropertyPaneConfig } from "legacy/constants/PropertyControlConstants";
import {
  AppStateVar,
  AppStateVarPersistance,
} from "store/slices/application/stateVars/StateConstants";
import { getStateIcon } from "./ExplorerIcons";

export const STATE_VAR_PROPERTIES: PropertyPaneConfig<AppStateVar>[] = [
  {
    sectionName: "General",
    children: [
      {
        helpText: "Sets the default value of the state variable",
        propertyName: "defaultValue",
        label: "Default value",
        controlType: "INPUT_TEXT",
        placeholderText: "{'name': 'Billie'}",
        isBindProperty: true,
        isTriggerProperty: false,
        canExpandEditor: true,
      },
      {
        propertyName: "persistance",
        label: "Persistence",
        controlType: "RADIO_BUTTON_GROUP",
        forceVertical: false,
        helpText:
          "Whether the value will be temporarily stored during the current session or will be persisted in the user's browser",
        options: [
          {
            label: "Temp",
            icon: getStateIcon(AppStateVarPersistance.TEMPORARY),
            value: AppStateVarPersistance.TEMPORARY,
          },
          {
            label: "Local",
            icon: getStateIcon(AppStateVarPersistance.LOCAL_STORAGE),
            value: AppStateVarPersistance.LOCAL_STORAGE,
          },
        ],
        isJSConvertible: false,
        isBindProperty: false,
        isTriggerProperty: false,
      },
    ],
  },
];
