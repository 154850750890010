import { ApplicationScope } from "@superblocksteam/shared";
import { Dropdown, Popconfirm, MenuProps } from "antd";
import { MenuInfo } from "rc-menu/lib/interface";
import React, { useCallback, useMemo, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router";
import styled from "styled-components";
import { ReactComponent as EllipsisIcon } from "assets/icons/common/ellipsis.svg";
import { StyledShortcutMenu } from "components/app/ShortcutMenu/components";
import IconButton from "components/ui/IconButtons/IconButton";
import useDuplicateApi from "hooks/api/useDuplicateApi";
import useDuplicateApiToPage from "hooks/api/useDuplicateApiToPage";
import { useIsControlFlowEnabled } from "hooks/api/useIsControlFlowEnabled";
import { useSaga } from "hooks/store";
import { useGetEditorPath } from "hooks/store/useGetEditorPath";
import { useFeatureFlag } from "hooks/ui";
import { updateApplicationSidebarKey } from "legacy/actions/editorPreferencesActions";
import { groupSelectedWidgets } from "legacy/actions/widgetActions";
import { EditorRoute, QueryEditorRouteParams } from "legacy/constants/routes";
import { getCanSelectedWidgetsBeGrouped } from "legacy/sagas/widgets/groupWidgets";
import {
  getCurrentBranch,
  getCurrentPageId,
  getEditorReadOnly,
  getPageList,
} from "legacy/selectors/editorSelectors";
import {
  getIsMultipleWidgetsSelected,
  widgetIsSectionColumnAtIndex,
} from "legacy/selectors/sagaSelectors";
import { useAppDispatch, useAppSelector } from "store/helpers";
import { deleteApiSaga } from "store/slices/apis";
import { deleteV2ApiSaga } from "store/slices/apisV2";
import { duplicateCustomEvent } from "store/slices/application/events/eventActions";
import { duplicateStateVar } from "store/slices/application/stateVars/stateVarsActions";
import { duplicateTimer } from "store/slices/application/timers/timerActions";
import { Flag } from "store/slices/featureFlags";
import { getGroupIntoContainerShortcutString } from "utils/navigator";
import unreachable from "utils/unreachable";
import { ItemKinds } from "../../PropertyPane/ItemKindConstants";
import {
  isStatefulItem,
  ItemKindAccessors,
} from "../../PropertyPane/ItemKinds";
import { SideBarKeys } from "../../constants";
import { useEntityMoveToPage } from "./useEntityMoveToPage";
import type { AppState } from "store/types";

type MenuItem = Required<MenuProps>["items"][number];

interface Props {
  name: string;
  entityId: string;
  active?: boolean;
  entityKind?: ItemKinds;
  hideContextMenu?: boolean;
  isSectionHeader?: boolean;
  isEditing?: boolean;
  showDuplicate?: boolean;
  showReset?: boolean;
  onEnterEditMode: () => void;
  scope: ApplicationScope | undefined;
}

const ContextMenuButton = styled(IconButton)`
  opacity: 0;
`;

export function EntityContextMenu(props: Props) {
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const readOnly = useAppSelector(getEditorReadOnly);
  const dispatch = useAppDispatch();
  const params = useParams<QueryEditorRouteParams>() as QueryEditorRouteParams;
  const navigate = useNavigate();
  const location = useLocation();
  const getEditorPath = useGetEditorPath();

  const { entityId, entityKind, showDuplicate = false } = props;

  // This frequently re-renders
  const isMultipleWidgetsSelected = useAppSelector(
    getIsMultipleWidgetsSelected,
  );

  const widgetIsColumn = useAppSelector(
    (state: AppState) =>
      widgetIsSectionColumnAtIndex(state, props.entityId) !== undefined,
  );

  const selectedWidgetsCanBeGrouped = useAppSelector(
    getCanSelectedWidgetsBeGrouped,
  );

  const stopPropagation = useCallback((ev: React.MouseEvent) => {
    ev.stopPropagation();
  }, []);

  const isControlFlowEnabled = useIsControlFlowEnabled();

  const duplicateApi = useDuplicateApi(params.applicationId);
  const duplicateApiToPage = useDuplicateApiToPage();

  const handleDuplicate = useCallback(
    async ({
      domEvent,
    }: {
      domEvent:
        | React.MouseEvent<HTMLElement>
        | React.KeyboardEvent<HTMLElement>;
    }) => {
      domEvent.stopPropagation();

      if (!entityKind) return;

      switch (entityKind) {
        case ItemKinds.API_V1:
        case ItemKinds.API_V2:
          await duplicateApi(entityId);
          break;
        case ItemKinds.STATE_VAR:
          dispatch(
            duplicateStateVar({
              fromScope: props.scope ?? ApplicationScope.PAGE,
              toScope: props.scope ?? ApplicationScope.PAGE,
              stateVarId: entityId,
            }),
          );
          break;
        case ItemKinds.TIMER:
          dispatch(
            duplicateTimer({
              fromScope: props.scope ?? ApplicationScope.PAGE,
              toScope: props.scope ?? ApplicationScope.PAGE,
              timerId: entityId,
            }),
          );
          break;
        case ItemKinds.CUSTOM_EVENT:
          dispatch(
            duplicateCustomEvent({
              eventId: entityId,
              fromScope: props.scope ?? ApplicationScope.PAGE,
              toScope: props.scope ?? ApplicationScope.PAGE,
            }),
          );
          break;
        case ItemKinds.ROUTE:
        case ItemKinds.EMBED_PROP:
        case ItemKinds.WIDGET:
        case ItemKinds.NESTED_ITEM:
          // not currently cloneable via context menu
          break;
        default:
          unreachable(entityKind);
      }
    },
    [entityKind, duplicateApi, entityId, dispatch, props.scope],
  );

  const handleDuplicateTo = useCallback(
    (entityKind: ItemKinds, entityId: string, pageId: string) => {
      return ({ domEvent }: { domEvent: React.MouseEvent }) => {
        domEvent.stopPropagation();
        duplicateApiToPage(entityId, pageId);
      };
    },
    [duplicateApiToPage],
  );

  const handleDelete = useCallback((info: MenuInfo) => {
    info.domEvent.stopPropagation();
    setDeleteConfirmOpen(true);
  }, []);

  const handleViewState = useCallback(
    async ({
      domEvent,
    }: {
      domEvent:
        | React.MouseEvent<HTMLElement>
        | React.KeyboardEvent<HTMLElement>;
    }) => {
      domEvent.stopPropagation();

      if (entityKind)
        dispatch(
          updateApplicationSidebarKey({
            selectedKey: SideBarKeys.STATE_VARS,
            focusedItem: {
              type: entityKind,
              name: props.name,
              scope: props.scope ?? ApplicationScope.PAGE,
            },
          }),
        );
    },
    [entityKind, dispatch, props.name, props.scope],
  );

  const [deleteV1Api] = useSaga(deleteApiSaga);
  const [deleteV2Api] = useSaga(deleteV2ApiSaga);

  const branch = useAppSelector(getCurrentBranch);
  // TEMP: remove this in favor of ItemKindAccessors[ItemKinds.API].deleteItem when it's implemented
  const handleDeleteApi = useCallback(async () => {
    if (isControlFlowEnabled) {
      await deleteV2Api({ id: entityId, branch: branch?.name });
    } else {
      await deleteV1Api({ id: entityId, branch: branch?.name });
    }

    if (params.apiId === entityId) {
      navigate(
        getEditorPath(EditorRoute.EditApplication, {
          applicationId: params.applicationId,
        }) + location.search,
      );
    }
  }, [
    isControlFlowEnabled,
    params.apiId,
    params.applicationId,
    entityId,
    deleteV2Api,
    branch?.name,
    deleteV1Api,
    navigate,
    getEditorPath,
    location.search,
  ]);

  const onDeleteConfirm = useCallback(
    (e?: React.MouseEvent) => {
      e?.stopPropagation();
      if (entityKind === ItemKinds.API_V1 || entityKind === ItemKinds.API_V2) {
        handleDeleteApi();
        setDeleteConfirmOpen(false);
      } else if (entityKind) {
        const deleteAllSelected =
          entityKind === ItemKinds.WIDGET
            ? props.active && isMultipleWidgetsSelected
            : undefined;

        ItemKindAccessors[entityKind].deleteItem(
          dispatch,
          entityId,
          props.scope ?? ApplicationScope.PAGE,
          deleteAllSelected,
        );
      }
    },
    [
      dispatch,
      entityId,
      entityKind,
      handleDeleteApi,
      isMultipleWidgetsSelected,
      props.active,
      props.scope,
    ],
  );

  const onDeleteCancel = useCallback((e: any) => {
    e.stopPropagation();
    setDeleteConfirmOpen(false);
  }, []);

  const onDeleteConfirmVisibleChange = useCallback((visible: boolean) => {
    if (!visible) {
      // when unfocus from pop confirm
      setDeleteConfirmOpen(false);
    }
  }, []);

  const { onEnterEditMode } = props;
  const onClickRename = useCallback(
    (info: MenuInfo) => {
      info.domEvent.stopPropagation();
      onEnterEditMode();
    },
    [onEnterEditMode],
  );

  const handleGroup = useCallback(
    (info: MenuInfo) => {
      dispatch(groupSelectedWidgets());
    },
    [dispatch],
  );

  const stopPropagationWhenReadOnly = useCallback(
    (ev: React.MouseEvent) => {
      // if not readOnly, stopPropagation will be handled by the menu item
      if (readOnly) ev.stopPropagation();
    },
    [readOnly],
  );

  const { shouldShowMoveMenuItem, handleMoveEntityToPage } =
    useEntityMoveToPage({
      entityId,
      entityKind,
    });

  const itemProperties =
    entityKind && ItemKindAccessors[entityKind].useItemProperties(entityId);
  const isStateful =
    entityKind &&
    isStatefulItem(entityKind, entityId, itemProperties ?? ({} as any));

  const isMultipageEnabled = useFeatureFlag(Flag.ENABLE_MULTIPAGE);

  const currentPageId = useAppSelector(getCurrentPageId);
  const pages = useAppSelector(getPageList);

  const menuItems = useMemo(() => {
    const items: Array<MenuItem & { "data-test": string }> = [];

    if (!widgetIsColumn && (!props.active || !isMultipleWidgetsSelected)) {
      items.push({
        key: "rename",
        onClick: onClickRename,
        "data-test": "global-nav-item-context-menu-rename",
        disabled: readOnly,
        label: "Rename",
      });
    }
    if (entityKind !== ItemKinds.WIDGET && showDuplicate) {
      items.push({
        key: "duplicate",
        onClick: handleDuplicate,
        "data-test": "global-nav-item-context-menu-duplicate",
        disabled: readOnly,
        label: "Duplicate",
      });
    }
    if (entityKind === ItemKinds.API_V2 && isMultipageEnabled) {
      const otherPages = pages
        .filter((page) => {
          return page.pageId !== currentPageId;
        })
        .map((page) => ({
          key: page.pageId,
          label: page.pageName,
          disabled: readOnly,
          onClick: handleDuplicateTo(entityKind, entityId, page.pageId) as any,
        }));

      if (otherPages.length) {
        items.push({
          key: "duplicate-to",
          "data-test": "global-nav-item-context-menu-duplicate-to",
          disabled: readOnly,
          label: "Duplicate to",
          children: otherPages,
        });
      }
    }
    if (isStateful) {
      items.push({
        key: "view-state",
        onClick: handleViewState,
        "data-test": "global-nav-item-context-menu-view-state",
        label: "View state",
      });
    }
    if (shouldShowMoveMenuItem) {
      items.push({
        key: "moveToPage",
        onClick: handleMoveEntityToPage,
        "data-test": "global-nav-item-context-menu-move-to-page",
        disabled: readOnly,
        label: "Move to Page",
      });
    }
    items.push({
      key: "delete",
      onClick: handleDelete,
      "data-test": "global-nav-item-context-menu-delete",
      disabled: readOnly,
      label:
        props.active &&
        isMultipleWidgetsSelected &&
        props.entityKind === ItemKinds.WIDGET
          ? "Delete all selected"
          : "Delete",
    });
    if (selectedWidgetsCanBeGrouped && props.active) {
      items.push({
        key: "group",
        onClick: handleGroup,
        disabled: readOnly,
        label: `Group${
          isMultipleWidgetsSelected ? " selected" : ""
        } ${getGroupIntoContainerShortcutString()}`,
        "data-test": "global-nav-item-context-menu-group",
      });
    }

    return items;
  }, [
    widgetIsColumn,
    props.active,
    props.entityKind,
    isMultipleWidgetsSelected,
    entityKind,
    showDuplicate,
    isMultipageEnabled,
    isStateful,
    shouldShowMoveMenuItem,
    handleDelete,
    readOnly,
    onClickRename,
    handleDuplicate,
    pages,
    currentPageId,
    handleDuplicateTo,
    entityId,
    handleViewState,
    handleMoveEntityToPage,
    selectedWidgetsCanBeGrouped,
    handleGroup,
  ]);

  return (
    <Popconfirm
      title={
        props.active && isMultipleWidgetsSelected
          ? `Delete all selected`
          : `Delete ${props.name}`
      }
      open={deleteConfirmOpen}
      onConfirm={onDeleteConfirm}
      onCancel={onDeleteCancel}
      onOpenChange={onDeleteConfirmVisibleChange}
      okButtonProps={{ "data-test": "delete-confirmation-button" } as any} // the typing on the library is not correct, it accepts data-* props
    >
      {!props.hideContextMenu && !props.isSectionHeader && !props.isEditing && (
        <Dropdown
          trigger={["click"]}
          placement="bottomRight"
          openClassName="context-menu-open"
          overlay={
            <StyledShortcutMenu
              onClickCapture={stopPropagationWhenReadOnly}
              items={menuItems}
            />
          }
        >
          <ContextMenuButton
            icon={<EllipsisIcon width={16} height={4} />}
            onClick={stopPropagation}
            data-test="global-nav-item-context-menu"
            className="context-menu-button"
          />
        </Dropdown>
      )}
    </Popconfirm>
  );
}
