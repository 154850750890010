import { Icon } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { FolderDto } from "@superblocksteam/shared";
import { Typography, Menu, Dropdown, Button } from "antd";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { FullWidthSpace } from "components/ui/Space";
import { MANAGE_FOLDERS } from "constants/rbac";
import { useAuthorizationCheck } from "hooks/ui/rbac/useAuthorizationCheck";
import { getCurrentUser } from "legacy/selectors/usersSelectors";

type FolderHeaderProps = {
  folder: FolderDto;
  entityCount: number;
  handleRename: (folder: FolderDto) => void;
  handleDelete: (folder: FolderDto) => void;
};

const ActionsButton = styled(Button)`
  display: flex;
  height: 20px;
  padding: 2px 6px;
  &:hover {
    background: ${({ theme }) => theme.colors.GREY_100};
  }
`;

const Count = styled.div`
  padding: 1px 8px;
  height: 20px;
  border-radius: 4px;
  background: ${({ theme }) => theme.colors.GREY_100};
  color: ${({ theme }) => theme.colors.GREY_500};
  font-size: 12px;
  font-weight: 500;
`;

export const FolderHeader = ({
  folder,
  entityCount,
  handleRename,
  handleDelete,
}: FolderHeaderProps) => {
  const [isHovered, setIsHovered] = useState(false);
  const currentUser = useSelector(getCurrentUser);
  const [canManageFolder] = useAuthorizationCheck([
    {
      ...MANAGE_FOLDERS,
      preRbacValue:
        folder.creator?.id === currentUser?.id || currentUser?.isAdmin,
    },
  ]);
  return (
    <FullWidthSpace
      data-test="folder-header"
      direction="horizontal"
      align="center"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <Typography.Title level={5} data-test="folder-name">
        {folder.name}
      </Typography.Title>
      <Count>
        <Typography.Text>{entityCount}</Typography.Text>
      </Count>
      {isHovered && canManageFolder && (
        <Dropdown
          overlay={
            <Menu>
              <Menu.Item
                data-test="folder-rename-btn"
                key="rename"
                onClick={(e) => {
                  e.domEvent.stopPropagation();
                  handleRename(folder);
                }}
              >
                Rename
              </Menu.Item>
              <Menu.Item
                data-test="folder-delete-btn"
                key="delete"
                onClick={(e) => {
                  e.domEvent.stopPropagation();
                  handleDelete(folder);
                }}
                danger
              >
                Delete
              </Menu.Item>
            </Menu>
          }
          trigger={["click"]}
        >
          <ActionsButton type="text" onClick={(e) => e.stopPropagation()}>
            <Icon icon={IconNames.MORE} size={14} data-test="folder-more-btn" />
          </ActionsButton>
        </Dropdown>
      )}
    </FullWidthSpace>
  );
};
