import { Button } from "antd";
import React from "react";

import styled from "styled-components";
import { ReactComponent as ChevronDown } from "assets/icons/common/chevron-down.svg";
import { colors } from "styles/colors";
import { styleAsClass } from "styles/styleAsClass";

const CollapseButton = styled(Button)`
  height: 20px;
  width: 20px;
  border: 1px solid transparent;
  padding: 1px;
  cursor: pointer;
  margin-left: -8px;
  margin-right: 8px;
  box-shadow: none;
  &:hover,
  &:active,
  &:focus {
    border-color: ${colors.GREY_100};
    background: ${colors.GREY_25};
  }
`;

const CollapseIconClass = styleAsClass`
  color: ${colors.GREY_500};
  height: 16px;
  width: 16px;
  &[data-collapsed="true"] {
    transform: rotate(-90deg);
  }
`;

export const CollapseSectionButton = ({
  onClick,
  isCollapsed,
}: {
  onClick: () => void;
  isCollapsed: boolean;
}) => {
  return (
    <CollapseButton type="link" onClick={onClick}>
      <ChevronDown className={CollapseIconClass} data-collapsed={isCollapsed} />
    </CollapseButton>
  );
};
