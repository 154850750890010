import { PayloadAction } from "@reduxjs/toolkit";
import {
  DataTreeGroup,
  DataTreeUser,
  IApplicationV2Dto,
  RepositoryDto,
  WidgetPosition,
} from "@superblocksteam/shared";
import {
  updateWidgetAutoHeight,
  updateWidgetAutoWidth,
  updateWidgetProperties,
} from "legacy/actions/controlActions";
import {
  resetWidgetMetaProperty,
  setMetaProp,
  setMetaProps,
} from "legacy/actions/metaActions";
import { showItemPropertyPane } from "legacy/actions/propertyPaneActions";
import {
  addSectionWidget,
  addSectionWidgetColumn,
  copyWidget,
  cutWidget,
  deleteWidgets,
  disableDragAction,
  disableNudgeAction,
  evaluateBindings,
  focusWidget,
  groupSelectedWidgets,
  pasteWidget,
  runEventHandlers,
  selectWidgets,
  setSelectedWidgetsAncestory,
} from "legacy/actions/widgetActions";
import { ERROR_CODES } from "legacy/constants/ApiConstants";
import type { SafeCrashReason } from "legacy/reducers/uiReducers/errorReducer";

export enum ReplayReduxActionTypes {
  UNDO = "undo",
  REDO = "redo",
}

export const ReduxActionTypes = {
  WIDGET_OPERATION_COMPLETE: "WIDGET_OPERATION_COMPLETE",
  REPORT_ERROR: "REPORT_ERROR",
  FLUSH_ERRORS: "FLUSH_ERRORS",
  FLUSH_AND_REDIRECT: "FLUSH_AND_REDIRECT",
  SAFE_CRASH_SUPERBLOCKS: "SAFE_CRASH_SUPERBLOCKS",
  SAFE_CRASH_SUPERBLOCKS_REQUEST: "SAFE_CRASH_SUPERBLOCKS_REQUEST",
  INIT_CANVAS_LAYOUT: "INIT_CANVAS_LAYOUT",
  SET_PAGE_DSL_VERSION: "SET_PAGE_DSL_VERSION",
  FETCH_CANVAS: "FETCH_CANVAS",
  CLEAR_CANVAS: "CLEAR_CANVAS",
  FETCH_PAGE_INIT: "FETCH_PAGE_INIT",
  FETCH_PAGE_SUCCESS: "FETCH_PAGE_SUCCESS",
  UPDATE_APPLICATION_SUCCESS: "UPDATE_APPLICATION_SUCCESS",
  WIDGET_CREATE: "WIDGET_CREATE",
  WIDGET_UPDATE_CHILDREN: "WIDGET_UPDATE_CHILDREN",
  WIDGET_RESIZE_SECTION_COLUMN: "WIDGET_RESIZE_SECTION_COLUMN",
  WIDGET_ADD_SECTION: "WIDGET_ADD_SECTION",
  WIDGET_RESIZE_SECTION: "WIDGET_RESIZE_SECTION",
  WIDGET_REMOVE_CHILD: "WIDGET_REMOVE_CHILD",
  WIDGETS_MOVE: "WIDGETS_MOVE",
  WIDGET_RESIZE: "WIDGET_RESIZE",
  WIDGET_REPARENT: "WIDGET_REPARENT",
  WIDGET_UPDATE_AUTO_HEIGHT: "WIDGET_UPDATE_AUTO_HEIGHT",
  WIDGET_UPDATE_AUTO_WIDTH: "WIDGET_UPDATE_AUTO_WIDTH",
  CLEAR_PROPERTY_PANE: "CLEAR_PROPERTY_PANE",
  UPDATE_WIDGET_DYNAMIC_PROPERTY: "UPDATE_WIDGET_DYNAMIC_PROPERTY",
  SET_SINGLE_WIDGET: "SET_SINGLE_WIDGET",
  RENAME_WIDGETS: "RENAME_WIDGETS",
  FETCH_PUBLISHED_PAGE_SUCCESS: "FETCH_PUBLISHED_PAGE_SUCCESS",
  FETCH_PAGE_LIST_SUCCESS: "FETCH_PAGE_LIST_SUCCESS",
  UPDATE_APPLICATION_HASH_TREE: "UPDATE_APPLICATION_HASH_TREE",
  UPDATE_WIDGET_PROPERTY_VALIDATION: "UPDATE_WIDGET_PROPERTY_VALIDATION",
  HIDE_PROPERTY_PANE: "HIDE_PROPERTY_PANE",
  OPEN_PROPERTY_PANE_PANEL: "OPEN_PROPERTY_PANE_PANEL",
  CLOSE_PROPERTY_PANE_PANEL: "CLOSE_PROPERTY_PANE_PANEL",
  CLOSE_PROPERTY_PANE_PANEL_BY_ID: "CLOSE_PROPERTY_PANE_BY_ID",
  REQUEST_DATASOURCE_AUTH: "REQUEST_DATASOURCE_AUTH",
  SET_CURRENT_ORG: "SET_CURRENT_ORG",
  SET_CURRENT_ORG_ID: "SET_CURRENT_ORG_ID",
  FETCH_CURRENT_ORG: "FETCH_CURRENT_ORG",
  FETCH_USER_INIT: "FETCH_USER_INIT",
  SET_USER_MODE: "SET_USER_MODE",
  SET_WIDGET_DRAGGING: "SET_WIDGET_DRAGGING",
  SET_WIDGET_RESIZING: "SET_WIDGET_RESIZING",
  SET_WIDGET_RESIZING_DIMENSIONS: "SET_WIDGET_RESIZING_DIMENSIONS",
  SET_WIDGET_RESIZING_STACK_WIDTH: "SET_WIDGET_RESIZING_STACK_WIDTH",
  SET_DRAGGING_DROP_TARGET: "SET_DRAGGING_DROP_TARGET",
  SET_DRAGGING_DROP_TARGET_ROWS: "SET_DRAGGING_DROP_TARGET_ROWS",
  SET_WIDGET_DYNAMIC_PROPERTY: "SET_WIDGET_DYNAMIC_PROPERTY",
  CREATE_MODAL_SUCCESS: "CREATE_MODAL_SUCCESS",
  UPDATE_CANVAS_SIZE: "UPDATE_CANVAS_SIZE",
  SET_LAST_USED_EDITOR_PAGE: "SET_LAST_USED_EDITOR_PAGE",
  SET_LAST_SELECTED_PAGE_PAGE: "SET_LAST_SELECTED_PAGE_PAGE",
  FETCH_USER_DETAILS_SUCCESS: "FETCH_USER_DETAILS_SUCCESS",
  SET_USER_PERMISSIONS: "SET_USER_PERMISSIONS",
  FETCH_PAGE_DSLS_SUCCESS: "FETCH_PAGE_DSLS_SUCCESS",
  FETCH_PAGE_DSL_SUCCESS: "FETCH_PAGE_DSL_SUCCESS",
  SET_URL_DATA: "SET_URL_DATA",
  SET_APP_MODE: "SET_APP_MODE",
  UPDATE_APP_STORE: "UPDATE_APP_STORE",
  WIDGET_ADD_CHILD_IF_NOT_EXISTS: "WIDGET_ADD_CHILD_IF_NOT_EXISTS",
  START_EVALUATE_TREE: "START_EVALUATE_TREE",
  START_EVALUATE_QUEUE: "START_EVALUATE_QUEUE",
  TREE_WILL_UPDATE: "TREE_WILL_UPDATE",
  SET_EVALUATED_TREE: "SET_EVALUATED_TREE",
  SET_EVALUATION_DEPENDENCIES: "SET_EVALUATION_DEPENDENCIES",
  APP_FRAME_LOADED: "APP_FRAME_LOADED",
  CURRENT_APPLICATION_NAME_UPDATE: "CURRENT_APPLICATION_NAME_UPDATE",
  CURRENT_APPLICATION_IS_PUBLIC_UPDATE: "CURRENT_APPLICATION_IS_PUBLIC_UPDATE",
  CURRENT_APPLICATION_ENVIRONMENT_UPDATE:
    "CURRENT_APPLICATION_ENVIRONMENT_UPDATE",
  SET_LOADING_ENTITIES: "SET_LOADING_ENTITIES",
  UPDATE_LAST_SUCCESSFUL_WRITE: "UPDATE_LAST_SUCCESSFUL_WRITE",
  RESET_WIDGETS: "RESET_WIDGETS",
  EXTRACTED_PAGE_LOAD_DEPS: "EXTRACTED_PAGE_LOAD_DEPS",
  STARTED_PAGE_LOAD_APIS: "STARTED_PAGE_LOAD_APIS",
  UPDATE_ENVIRONMENT: "UPDATE_ENVIRONMENT",
  AUTH_FINISHED: "AUTH_FINISHED",
  UPDATE_CANVAS_LAYOUT: "UPDATE_CANVAS_LAYOUT",
  UPDATE_RESPONSIVE_CANVAS_METADATA: "UPDATE_RESPONSIVE_CANVAS_METADATA",
  UPDATE_APP_SCROLLBAR_WIDTH: "UPDATE_APP_SCROLLBAR_WIDTH",
  UNDO_REDO_OPERATION: "UNDO_REDO_OPERATION",
  API_UNDO_REDO_OPERATION: "API_UNDO_REDO_OPERATION",
  SUBMIT_SURVEY: "SUBMIT_SURVEY",
  OPEN_QUALIFICATION_MODAL: "OPEN_QUALIFICATION_MODAL",
  OPEN_TRIAL_EXTENSION_MODAL: "OPEN_TRIAL_EXTENSION_MODAL",
  CLOSE_QUALIFICATION_MODAL: "CLOSE_QUALIFICATION_MODAL",
  OPEN_PRICING_MODAL: "OPEN_PRICING_MODAL",
  CLOSE_PRICING_MODAL: "CLOSE_PRICING_MODAL",
  OPEN_PURCHASED_MODAL: "OPEN_PURCHASED_MODAL",
  CLOSE_PURCHASED_MODAL: "CLOSE_PURCHASED_MODAL",
  OPEN_DOWNGRADE_MODAL: "OPEN_DOWNGRADE_MODAL",
  CLOSE_DOWNGRADE_MODAL: "CLOSE_DOWNGRADE_MODAL",
  OPEN_VERSION_MODAL: "OPEN_VERSION_MODAL",
  CLOSE_VERSION_MODAL: "CLOSE_VERSION_MODAL",
  // Routes (todo, switch to redux-toolkit slices)
  CREATE_ROUTE: "CREATE_ROUTE",
  DELETE_ROUTE: "DELETE_ROUTE",
  SET_DELETE_ROUTE_CONFIRMATION_MODAL: "SET_DELETE_CONFIRMATION_ROUTE",
  UPDATE_ROUTE_PROPERTIES: "UPDATE_ROUTE_PROPERTIES",
  DELETE_ROUTES: "DELETE_ROUTES",
  EDITOR_VIEW_ROUTE: "EDITOR_VIEW_ROUTE",
  EDITOR_PROMPT_ROUTE_PARAMS: "EDITOR_PROMPT_ROUTE_PARAMS",
  EDITOR_PROMPT_ROUTE_PARAMS_CLOSE: "EDITOR_PROMPT_ROUTE_PARAMS_CLOSE",
  // Timers
  UPDATE_TIMER_META: "UPDATE_TIMER_META",
  RESET_TIMER_META: "RESET_TIMER_META",
  SAVE_ALL_WIDGETS: "SAVE_ALL_WIDGETS",
  SET_PREVENT_NAVIGATION: "SET_PREVENT_NAVIGATION",
  //Widget selection
  START_CANVAS_SELECTION: "START_CANVAS_SELECTION",
  STOP_CANVAS_SELECTION: "STOP_CANVAS_SELECTION",
  UPDATE_EDITOR_PREFERENCES: "UPDATE_EDITOR_PREFERENCES",

  // Dynamic Layouts
  SET_DYNAMIC_WIDGET_PROPS: "SET_DYNAMIC_WIDGET_LAYOUT",
  CLEAR_DYNAMIC_WIDGET_HEIGHT: "CLEAR_DYNAMIC_WIDGET_HEIGHT",
  CLEAR_DYNAMIC_WIDGET_WIDTH: "CLEAR_DYNAMIC_WIDGET_WIDTH",

  //App Editor layout
  PIN_LEFT_PANE: "PIN_LEFT_PANE",
  UNPIN_LEFT_PANE: "UNPIN_LEFT_PANE",

  //Profile
  SET_PROFILE: "SET_PROFILE", // set selected profile id
  UPDATE_PROFILES: "UPDATE_PROFILES", // update app profiles state with appData and orgData
  UPDATE_WORKFLOW_TEST_PROFILE: "UPDATE_WORKFLOW_TEST_PROFILE", //used to run test and generate test url

  //Split pane drag tracking
  SET_SPLIT_PANE_DRAGGING: "SET_SPLIT_PANE_DRAGGING",

  // AI asssistant drag/resize tracking
  SET_AI_ASSISTANT_DRAGGING: "SET_AI_ASSISTANT_DRAGGING",

  // Local development mode for custom components
  SET_LOCAL_DEV_MODE: "SET_LOCAL_DEV_MODE",
  SET_LAST_HOT_RELOAD_TIME: "SET_LAST_HOT_RELOAD_TIME",
  // Update the status of the local dev server
  SET_LOCAL_DEV_SERVER_STATUS: "SET_LOCAL_DEV_SERVER_STATUS",
  // Show custom components help modal
  SET_SHOW_CUSTOM_COMPONENTS_MODAL: "SET_SHOW_CUSTOM_COMPONENTS_MODAL",

  // Stream API
  HANDLE_STREAM_MESSAGE: "HANDLE_STREAM_MESSAGE",

  // Dynamic form
  RESET_DYNAMIC_FORM: "RESET_DYNAMIC_FORM",
  LOAD_DYNAMIC_FORM_BUTTON_INIT: "LOAD_DYNAMIC_FORM_BUTTON_INIT",
  LOAD_DYNAMIC_FORM_BUTTON_SUCCESS: "LOAD_DYNAMIC_FORM_BUTTON_SUCCESS",
  DYNAMIC_FORM_BUTTON_CLICK: "DYNAMIC_FORM_BUTTON_CLICK",
  DYNAMIC_FORM_BUTTON_CLICK_SUCCESS: "DYNAMIC_FORM_BUTTON_CLICK_SUCCESS",

  // Branch
  SET_CURRENT_BRANCH: "SET_CURRENT_BRANCH",

  UPDATE_GENERATED_THEME: "UPDATE_GENERATED_THEME",
  SET_OVERRIDE_THEME_MODE: "SET_OVERRIDE_THEME_MODE",

  // embedding
  CREATE_EMBED_PROPERTY: "CREATE_EMBED_PROPERTY",
  DELETE_EMBED_PROPERTY: "DELETE_EMBED_PROPERTY",
  UPDATE_EMBED_PROPERTY: "UPDATE_EMBED_PROPERTY",
  UPDATE_EMBED_PROPERTY_META: "UPDATE_EMBED_PROPERTY_META",
  INIT_EMBED_PROPERTY_VALUES: "INIT_EMBED_PROPERTY_VALUES",
  UPDATE_EMBED_PROPERTY_META_ON_CHANGE: "UPDATE_EMBED_PROPERTY_META_ON_CHANGE",
  UPDATE_EMBED_EVENTS: "UPDATE_EMBED_EVENTS",
  TRIGGER_CUSTOM_EVENT: "TRIGGER_CUSTOM_EVENT",

  // multi-page
  UPDATE_DATA_URL: "UPDATE_DATA_URL",
  PERFORM_INTERNAL_NAVIGATION: "PERFORM_INTERNAL_NAVIGATION",

  // feature flags
  UPDATE_FEATURE_FLAG: "UPDATE_FEATURE_FLAG",
} as const;

export type ReduxActionType =
  (typeof ReduxActionTypes)[keyof typeof ReduxActionTypes];

export const ReduxActionErrorTypes = {
  API_ERROR: "API_ERROR",
  UPDATE_APPLICATION_ERROR: "UPDATE_APPLICATION_ERROR",
  FETCH_PAGE_ERROR: "FETCH_PAGE_ERROR",
  SAVE_PAGE_ERROR: "SAVE_PAGE_ERROR",
  WIDGET_OPERATION_ERROR: "WIDGET_OPERATION_ERROR",
  FETCH_PUBLISHED_PAGE_ERROR: "FETCH_PUBLISHED_PAGE_ERROR",
  PUBLISH_APPLICATION_ERROR: "PUBLISH_APPLICATION_ERROR",
  FETCH_USER_DETAILS_ERROR: "FETCH_USER_DETAILS_ERROR",
  CREATE_PAGE_ERROR: "CREATE_PAGE_ERROR",
  FETCH_APPLICATION_ERROR: "FETCH_APPLICATION_ERROR",
  RESET_APPLICATION_ERROR: "RESET_APPLICATION_ERROR",
  UPDATE_PAGE_ERROR: "UPDATE_PAGE_ERROR",
  DELETE_PAGE_ERROR: "DELETE_PAGE_ERROR",
  CREATE_MODAL_ERROR: "CREATE_MODAL_ERROR",
  POPULATE_PAGEDSLS_ERROR: "POPULATE_PAGEDSLS_ERROR",
  FETCH_PAGE_DSL_ERROR: "FETCH_PAGE_DSL_ERROR",
  COPY_SELECTED_WIDGET_ERROR: "COPY_SELECTED_WIDGET_ERROR",
  PASTE_COPIED_WIDGET_ERROR: "PASTE_COPIED_WIDGET_ERROR",
  WIDGET_ADD_CHILD_IF_NOT_EXISTS_ERROR: "WIDGET_ADD_CHILD_IF_NOT_EXISTS_ERROR",
  TIMER_OPERATION_ERROR: "TIMER_OPERATION_ERROR",
  STATE_VAR_OPERATION_ERROR: "STATE_VAR_OPERATION_ERROR",
  SET_COMPONENT_PROPERTY_ERROR: "SET_COMPONENT_PROPERTY_ERROR",
  DYNAMIC_FORM_BUTTON_CLICK_ERROR: "DYNAMIC_FORM_BUTTON_CLICK_ERROR",
  CREATE_COMMIT_ERROR: "CREATE_COMMIT_ERROR",
  FETCH_WORKFLOW_OR_JOB_ERROR: "FETCH_WORKFLOW_OR_JOB_ERROR",
  EMBED_PROP_OPERATION_ERROR: "EMBED_PROP_OPERATION_ERROR",
  CUSTOM_EVENT_OPERATION_ERROR: "CUSTOM_EVENT_OPERATION_ERROR",
  INTERNAL_NAVIGATION_ERROR: "INTERNAL_NAVIGATION_ERROR",
};

// These are actions that originate inside the editor
export const EditOperations = [
  ReduxActionTypes.WIDGETS_MOVE,
  ReduxActionTypes.WIDGET_RESIZE,
  ReduxActionTypes.WIDGET_REPARENT,
  ReduxActionTypes.WIDGET_CREATE,
  // This one is originated externally
  ReduxActionTypes.WIDGET_ADD_CHILD_IF_NOT_EXISTS,
  addSectionWidgetColumn.type,
  addSectionWidget.type,
  ReduxActionTypes.WIDGET_RESIZE_SECTION,
  ReduxActionTypes.WIDGET_RESIZE_SECTION_COLUMN,
  focusWidget.type,
  selectWidgets.type,
  ReduxActionTypes.SET_WIDGET_DRAGGING,
  ReduxActionTypes.SET_WIDGET_RESIZING_DIMENSIONS,
  ReduxActionTypes.SET_WIDGET_RESIZING,
  ReduxActionTypes.UPDATE_CANVAS_SIZE,
  ReduxActionTypes.CLEAR_PROPERTY_PANE,

  ReduxActionTypes.START_CANVAS_SELECTION,
  ReduxActionTypes.STOP_CANVAS_SELECTION,

  setSelectedWidgetsAncestory.type,
  showItemPropertyPane.type,
  disableDragAction.type,
  disableNudgeAction.type,

  setMetaProp.type,
  setMetaProps.type,
  resetWidgetMetaProperty.type,

  // Keyboard shortcuts
  deleteWidgets.type,
  copyWidget.type,
  pasteWidget.type,
  cutWidget.type,
  ReduxActionTypes.UNDO_REDO_OPERATION,
  groupSelectedWidgets.type,

  // for setting local dev mode from inside iframe
  ReduxActionTypes.SET_LOCAL_DEV_MODE,
];

export const WidgetOperationsWithCompletionListeners = Object.freeze([
  ReduxActionTypes.WIDGETS_MOVE,
  ReduxActionTypes.WIDGET_RESIZE,
  ReduxActionTypes.WIDGET_CREATE,
]);

export const AppInitiatedActions = [
  runEventHandlers.type,
  setMetaProp.type,
  setMetaProps.type,
  resetWidgetMetaProperty.type,
  updateWidgetProperties.type,
  updateWidgetAutoHeight.type,
  updateWidgetAutoWidth.type,
  evaluateBindings.type,
  ReduxActionTypes.UPDATE_GENERATED_THEME,
  ReduxActionTypes.SET_DYNAMIC_WIDGET_PROPS,
  ReduxActionTypes.CLEAR_DYNAMIC_WIDGET_HEIGHT,
  ReduxActionTypes.CLEAR_DYNAMIC_WIDGET_WIDTH,
  // If there is an error within the frame
  ReduxActionTypes.SAFE_CRASH_SUPERBLOCKS,
  ReduxActionTypes.UPDATE_APP_SCROLLBAR_WIDTH,
];

type ReduxActionErrorType =
  (typeof ReduxActionErrorTypes)[keyof typeof ReduxActionErrorTypes];

export type ReduxAction<Payload, T = unknown> = PayloadAction<
  Payload,
  T extends string ? T : ReduxActionType | ReduxActionErrorType
>;

export interface ReduxActionErrorPayload {
  message: string;
  source?: string;
  code?: ERROR_CODES;
  reason?: SafeCrashReason;
}

export interface UpdateWidgetPositionsPayload {
  widgets: { [widgetId: string]: Partial<WidgetPosition> };
}

export interface Page {
  pageName: string;
  pageId: string;
}

type VersionPayload = {
  tag: string;
  description: string;
  deployedAt: string;
  deployedBy: {
    email: string;
  };
};

export type ApplicationGlobalPayload = {
  user: DataTreeUser;
  groups: DataTreeGroup[];
  versions: {
    current: VersionPayload; // defined in deployed mode, empty strings in edit/preview mode
  };
  app: {
    id: string;
    name: string;
  };
};

export type PageListPayload = Array<Page>;

export type RepoConnection = { created: Date; repository: RepositoryDto };

export type ApplicationPayload = IApplicationV2Dto;
