import {
  EntityPermissionsDto,
  GroupBrief,
  GroupDetail,
  GroupMember,
  GroupMemberStatus,
  GroupType,
  OrganizationUserDto,
  PermissionEntityType,
  RbacRole,
  ShareEntryDto,
} from "@superblocksteam/shared";

export type MemberToRender = {
  id: string;
  name: string;
  email: string;
  status: GroupMemberStatus;
};

export type GroupToRender = {
  id: string;
  name: string;
  memberCount: number;
  type: GroupType;
  roleId?: string;
  roleAssignmentId?: string;
};

export const convertToGroupToRender = (
  group: GroupBrief | GroupDetail,
): GroupToRender => ({
  id: group.id,
  name: group.name,
  memberCount: group.size,
  type: group.type,
  roleId: (group as any).assignments?.[0]?.assignmentId,
  roleAssignmentId: (group as any).assignments?.[0]?.id,
});

export const convertToMemberToRender = (
  user: GroupMember | OrganizationUserDto,
): MemberToRender => ({
  id: user.id,
  name: user.name,
  email: user.email,
  status: user.status as GroupMemberStatus,
});

type PageType = "users" | "entities" | "integrations";

export const isValidPageType = (
  pageType: string | undefined,
): pageType is PageType => {
  if (!pageType) return false;
  return ["users", "entities", "integrations"].includes(pageType);
};

export type DirtyRecord = {
  role: RbacRole; //used to render checkbox
  // roleActor?: ShareEntryDto; //used for the permission request payload
  type?: PermissionEntityType;
  isDelete: boolean;
  // used for sending request, if no rolerActor, send add request instead of update
  roleActor: ShareEntryDto | undefined;
};

export type GroupEntityToRender = {
  id: string;
  canBuild: boolean;
  canView?: boolean;
  canConfigure?: boolean;
  name: string;
  type: PermissionEntityType;
  key?: string;
  roleActor: ShareEntryDto | undefined;
};

export const convertToGroupEntityToRender = (
  permissionEntity: EntityPermissionsDto,
  groupId: string,
): GroupEntityToRender => {
  const roleActor = permissionEntity.permissions.find(
    (permissionEntry) => permissionEntry.actorId === groupId,
  );
  const role = roleActor?.role;

  return {
    id: permissionEntity.entity.id,
    name: permissionEntity.entity.name,
    key: permissionEntity.entity.id,
    type: permissionEntity.entity.type,
    canBuild: role === RbacRole.BUILDER,
    canView: role === RbacRole.VIEWER,
    canConfigure: role === RbacRole.CONFIGURATOR,
    roleActor,
  };
};

export function groupMemberCompareFn(
  member1: MemberToRender,
  member2: MemberToRender,
): number {
  const sortOrder = {
    [GroupMemberStatus.JOINED]: 0,
    [GroupMemberStatus.UNREGISTERED]: 1,
    [GroupMemberStatus.DEACTIVATED]: 2,
  };
  // undefined status should be last
  const member1Order =
    sortOrder[member1.status] ?? Object.keys(sortOrder).length;
  const member2Order =
    sortOrder[member2.status] ?? Object.keys(sortOrder).length;
  // first, compare by status
  const statusComparison = member1Order - member2Order;
  if (statusComparison !== 0) {
    return statusComparison;
  }
  // finally, compare by email
  return member1.email.localeCompare(member2.email);
}

export function groupCompareFn(
  group1: GroupToRender,
  group2: GroupToRender,
): number {
  const sortOrder = {
    [GroupType.ALL_USERS]: 0,
    [GroupType.ADMIN]: 1,
    [GroupType.SUPER_USER]: 2,
    [GroupType.CUSTOM]: 3,
  };
  // undefined status should be last
  const group1Order = sortOrder[group1.type] ?? Object.keys(sortOrder).length;
  const group2Order = sortOrder[group2.type] ?? Object.keys(sortOrder).length;
  // first, compare by status
  const typeComp = group1Order - group2Order;
  if (typeComp !== 0) {
    return typeComp;
  }
  // finally, compare by name
  return group1.name.localeCompare(group2.name);
}
