import { Switch as AntSwitch, SwitchProps, Typography } from "antd";
import React from "react";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
`;

const SwitchLabel = styled(Typography.Text)`
  cursor: pointer;
  user-select: none;
`;

type Props = {
  label?: string | JSX.Element;
} & SwitchProps;

export const Switch = (props: Props) => {
  const onChange = props.onChange || props.onClick;
  return (
    <Container>
      <SwitchLabel
        onClick={(e: any) => onChange && onChange(!props.checked ?? false, e)}
      >
        {props.label}
      </SwitchLabel>
      <AntSwitch {...props} />
    </Container>
  );
};

// eslint-disable-next-line import/no-unused-modules
export default Switch;
