import { FolderDto, IntegrationKind } from "@superblocksteam/shared";
import { Button, Input, Modal, Select } from "antd";
import { isEmpty } from "lodash";
import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import {
  DescriptionContainer,
  FormItem,
  FormWrapper,
} from "components/ui/Form";
import {
  ModalWrapClass,
  FooterWrapperWide,
  ModalInnerWrapper,
  DefaultModalWidth,
} from "components/ui/Modal";
import { APPLICATIONS_URL } from "legacy/constants/routes";
import { INTEGRATION_URL, SECRETS_MANAGEMENT_URL } from "pages/routes";
import { getFolders } from "store/slices/folders/client";
import { createApplicationInit } from "store/slices/homepage/slice";
import { createAppFromTemplate } from "store/slices/template/client";

const CreateAppModal = ({
  createAppModalVisible,
  setCreateAppModalVisible,
  kind,
  pluginId,
  datasourceId,
  templateInstanceId,
  pluginName,
  createAppModeBlank,
  organizationId,
  username,
  templateName,
}: {
  createAppModalVisible: boolean;
  setCreateAppModalVisible: (visible: boolean) => void;
  kind: string;
  pluginId: string;
  datasourceId: string;
  templateInstanceId: string;
  pluginName: string | undefined;
  createAppModeBlank: boolean;
  organizationId: string;
  username: string | undefined;
  templateName: string;
}) => {
  const navigate = useNavigate();

  const [appName, setAppName] = useState("");
  const [folderId, setFolderId] = useState("");
  const [nameError, setNameError] = useState<string | undefined>(undefined);
  const [folders, setFolders] = useState<FolderDto[]>([]);

  useEffect(() => {
    let defaultAppName = "";
    if (createAppModeBlank) {
      defaultAppName = `${username}'s Magnificient App`;
    } else {
      defaultAppName = `${username} - ${pluginName} ${templateName}`;
    }
    setAppName(defaultAppName);
  }, [createAppModeBlank, setAppName, username, pluginName, templateName]);

  useEffect(() => {
    (async () => {
      const fetchedFolders = await getFolders();
      const userFolders = fetchedFolders.filter(
        (folder) => folder.name !== "Demo Apps, Workflows, and Jobs",
      );
      setFolders(userFolders);
    })();
  }, []);

  const folderOptions = useMemo(
    () =>
      folders.map((folder) => ({
        value: folder.id,
        label: folder.name,
        displayName: folder.name,
      })),
    [folders],
  );

  const parentRef = useRef<HTMLDivElement>(null);

  const dispatch = useDispatch();

  const onCancel = useCallback(() => {
    setCreateAppModalVisible(false);
    navigate({
      pathname:
        kind === IntegrationKind.SECRET
          ? `${SECRETS_MANAGEMENT_URL}/${pluginId}/${datasourceId}`
          : `${INTEGRATION_URL}${pluginId}/${datasourceId}`,
    });
  }, [setCreateAppModalVisible, navigate, kind, pluginId, datasourceId]);

  const onFolderChange = useCallback(
    (newOption: string) => {
      setFolderId(newOption ?? "");
    },
    [setFolderId],
  );

  const validate = useCallback(() => {
    let validated = true;
    if (appName === "") {
      setNameError("Name is required");
      validated = false;
    }
    return validated;
  }, [appName]);

  const createNewApplication = useCallback(
    (applicationName: string, orgId: string, folderId?: string) => {
      return dispatch(
        createApplicationInit({
          applicationName,
          orgId,
          folderId,
        }),
      );
    },
    [dispatch],
  );

  const onCreate = useCallback(async () => {
    if (!validate()) {
      return;
    }

    if (!createAppModeBlank && !isEmpty(templateInstanceId)) {
      const response = await createAppFromTemplate(
        templateInstanceId,
        appName,
        folderId,
        datasourceId,
      );
      const applicationId = response.id;
      navigate({ pathname: APPLICATIONS_URL + `/edit/${applicationId}` });
    } else {
      createNewApplication(appName, organizationId, folderId);
    }
  }, [
    validate,
    navigate,
    createNewApplication,
    createAppModeBlank,
    templateInstanceId,
    appName,
    organizationId,
    folderId,
    datasourceId,
  ]);

  const handleAppNameChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setAppName(e.target.value);
    },
    [setAppName],
  );

  return (
    <Modal
      open={createAppModalVisible}
      closable
      onCancel={() => {
        setCreateAppModalVisible(false);
        navigate({
          pathname:
            kind === IntegrationKind.SECRET
              ? `${SECRETS_MANAGEMENT_URL}/${pluginId}/${datasourceId}`
              : `${INTEGRATION_URL}${pluginId}/${datasourceId}`,
        });
      }}
      wrapClassName={ModalWrapClass}
      title={`Use ${pluginName} ${templateName} template`}
      footer={null}
      width={DefaultModalWidth}
    >
      <div className={ModalInnerWrapper} ref={parentRef}>
        <div className={FormWrapper}>
          <FormItem
            label={
              <div className={DescriptionContainer}>
                You are about to import the {pluginName} {templateName}
                template. Check that the name and folder are correct, then click
                Create app to open.
              </div>
            }
          ></FormItem>
          <FormItem label="Application name" error={nameError} required={true}>
            <Input
              placeholder="Describe application name"
              value={appName}
              onChange={handleAppNameChange}
              data-test="create-app-modal-appName"
            />
          </FormItem>
          <FormItem label="Folder">
            <Select
              showSearch
              allowClear
              placeholder="Select a folder"
              options={folderOptions}
              filterOption={(input, option) => {
                const label = option?.label as string;
                return label?.toLowerCase().includes(input.toLowerCase());
              }}
              onChange={onFolderChange}
            />
          </FormItem>
        </div>
        <div className={FooterWrapperWide}>
          <Button
            data-test="create-app-modal-cancel"
            key="cancel"
            type="default"
            onClick={onCancel}
          >
            Cancel
          </Button>
          <Button
            data-test="create-app-modal-confirm"
            key="submit"
            type="primary"
            onClick={onCreate}
          >
            Create app
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default CreateAppModal;
