import React from "react";
import { colors } from "styles/colors";
import { styleAsClass } from "styles/styleAsClass";

export const FormWrapper = styleAsClass`
  display: flex;
  flex-direction: column;
  gap: 20px;
  .form-item {
    display: flex;
    flex-direction: column;
    gap: 6px;
  }
  .form-item-label {
    font-size: 12px;
    line-height: 12px;
    font-weight: 500;
  }
 
  .form-item-subtext {
    color: ${colors.GREY_400};
    font-size: 12px;
  }
 
  .form-item-error {
    color: ${colors.RED_500};
    font-size: 12px;
  }

  .form-item-with-error .form-value-wrapper > span {
    border-color: ${colors.RED_500} !important;
    box-shadow: none !important;
  }

  .ant-select {
    width: 100%;
  }
`;

export const DescriptionContainer = styleAsClass`
font-weight:400;
color: ${colors.GREY_500};
line-height:20px;
`;

export const FormItem = ({
  label,
  children,
  required,
  error,
  subText,
  hidden,
}: {
  label?: string | React.ReactNode;
  children?: React.ReactNode;
  required?: boolean;
  error?: string | React.ReactNode;
  subText?: string | React.ReactNode;
  hidden?: boolean;
}) => {
  if (hidden) return null;
  return (
    <div className={`form-item ${error ? "form-item-with-error" : ""}`}>
      {label && (
        <div className="form-item-label">
          {label} {required && <span style={{ color: colors.RED_500 }}>*</span>}
        </div>
      )}
      {children && <div className={`form-value-wrapper`}>{children}</div>}
      {subText && <div className="form-item-subtext">{subText}</div>}
      {error && <div className="form-item-error">{error}</div>}
    </div>
  );
};
