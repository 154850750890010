import { Typography } from "antd";
import React from "react";
import { isProduction } from "utils/env";
import envs from "../../../env";
import { CodeBlock } from "../CodeBlock";
import { AgentDeploymentInstructionsProps } from "../common";
import { Docker_AgentKey } from "../constants";

const Docker = ({
  agentUrl,
  agentDataDomain,
  latestOpaVersion,
  showInstruction,
}: AgentDeploymentInstructionsProps) => {
  const location = window.location;
  // Display the private server URL env variable if we are not in production.
  // This is useful for development and testing of the OPA.
  const serverUrl = isProduction()
    ? ""
    : `\n__SUPERBLOCKS_AGENT_SERVER_URL="${
        location.hostname === "localhost"
          ? location.origin.replace("localhost", "host.docker.internal")
          : location.origin
      }" \\`;
  if (showInstruction) {
    return (
      <Typography.Paragraph>
        <Typography.Text>
          Run the agent locally using docker-compose to try out the Superblocks
          <br />
          agent without installing on a centralized host
        </Typography.Text>
        <CodeBlock
          content={`curl -s ${envs.get(
            "SUPERBLOCKS_UI_DEPLOYMENT_DOCKER_URL",
          )} | \\${serverUrl}
  ${Docker_AgentKey}="<AGENT_KEY>" \\
  SUPERBLOCKS_AGENT_HOST_URL="${agentUrl}" \\
  SUPERBLOCKS_AGENT_DATA_DOMAIN="${agentDataDomain}" \\
  SUPERBLOCKS_DOCKER_AGENT_TAG="${latestOpaVersion}" \\
  docker compose -p superblocks -f - up
  `}
        />
      </Typography.Paragraph>
    );
  } else {
    return null;
  }
};

export default Docker;
