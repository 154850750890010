import { capitalize, chunk, isArray, isObject, isPlainObject } from "lodash";
import React, { useMemo } from "react";
import { TreeWalker, FixedSizeNodePublicState } from "react-vtree";
import "./jsonview.css";
import { NodeComponentProps } from "react-vtree/dist/es/Tree";
import CollapseToggle from "legacy/pages/Editor/Explorer/Entity/CollapseToggle";
import { FixedSizeTree } from "./FixedSizeTree";

export type ScrollbarBehavior = "hover" | "visible" | "hidden";

export const JSON_INDENTATION_SIZE = 18;

type TypeOffType =
  | "string"
  | "number"
  | "bigint"
  | "boolean"
  | "symbol"
  | "undefined"
  | "object"
  | "function";
type DataType = TypeOffType | "array" | "null" | "unknown" | "map" | "set";

interface Node {
  id: string;
  isOpenByDefault: boolean;
  value: unknown;
  key: string | null;
  virtualKey: boolean;
  type: DataType | null;
  isRoot: boolean;
  isPrimitive: boolean;
  size?: number;
  index?: number;
  children: Node[];
  isExpanded: boolean;
  depth: number;
  parent: Node | null;
}

interface JsonViewOptions {
  // Limit the character count of strings with truncation
  maxStringLength?: number;
  maxBufferLength?: number;
  maxDepth?: number;
  maxArrayLength?: number;
  lineHeight?: number;
  isOpenByDefault?: boolean;
  displayObjectSize?: boolean;
  height?: number;
  maxHeight?: number;
  width: number | string;
  scrollbarBehavior?: ScrollbarBehavior;
  style?: React.CSSProperties;
  className?: string;
}

type NodeWithOptions = Node & { options: JsonViewOptions };

function getDataType(val: unknown): DataType {
  return Array.isArray(val)
    ? "array"
    : val instanceof Map
    ? "map"
    : val instanceof Set
    ? "set"
    : val === null
    ? "null"
    : val instanceof Uint8Array
    ? "string"
    : typeof val;
}

/**
 * Captures objects such as Date, RegExp, etc.
 */
function isNativeObject(data: any) {
  return (
    !isArray(data) &&
    isObject(data) &&
    !isPlainObject(data) &&
    !(data instanceof Map) &&
    !(data instanceof Set)
  );
}

function truncateString(input: string, numChars: number) {
  return input.length > numChars
    ? input.substring(0, numChars - 1) + "…"
    : input;
}

function formatByteString(value: Uint8Array, maxStrLen: number) {
  const maxLen = Math.floor((maxStrLen - "<bytes>".length) / 3);
  return `<bytes ${[...value.subarray(0, maxLen)]
    .map((x) => x.toString(16).padStart(2, "0"))
    .join(" ")}${value.length > maxLen ? "…" : ""}>`;
}

const guardedJSONStringify = (value: any) => {
  try {
    return JSON.stringify(value);
  } catch (e) {
    return "<cannot display>";
  }
};

function formatValue(
  value: unknown,
  options: JsonViewOptions & { skipSubObjectExpansion?: boolean },
) {
  if (typeof value === "string") {
    if (options.maxStringLength) {
      return '"' + truncateString(value, options.maxStringLength) + '"';
    } else {
      return `"${value}"`;
    }
  }
  if (typeof value === "undefined") {
    return "undefined";
  }
  if (value instanceof Uint8Array) {
    return formatByteString(value, options.maxBufferLength || Infinity);
  }
  if (typeof value === "function") {
    return "Function";
  }
  if (isNativeObject(value)) {
    return guardedJSONStringify(value)?.replace?.(/^"(.*)"$/, "$1");
  }
  if (
    !!value &&
    typeof value === "object" &&
    !Array.isArray(value) &&
    Object.entries(value as Record<string, unknown>).length === 0
  ) {
    return "{}";
  }
  if (
    !!value &&
    typeof value === "object" &&
    Array.isArray(value) &&
    value.length === 0
  ) {
    return "[]";
  }
  if (options.skipSubObjectExpansion && value && typeof value === "object") {
    if (Array.isArray(value)) {
      return `[${value.length} items]`;
    } else {
      return "{...}";
    }
  }
  if (typeof value === "bigint") {
    return `${value.toString()}n`;
  }
  return guardedJSONStringify(value);
}

function createNode(opt: Partial<Node>, nextId: () => string): Node {
  return {
    id: nextId(),
    isOpenByDefault: opt.isOpenByDefault ?? true,
    key: opt.key || null,
    virtualKey: opt.virtualKey || false,
    parent: opt.parent || null,
    value: "value" in opt ? opt.value : null,
    type: opt.type || null,
    children: opt.children || [],
    isExpanded: opt.isExpanded || true,
    depth: opt.depth || 0,
    isRoot: opt.isRoot || false,
    isPrimitive: opt.isPrimitive || false,
  };
}

function createSubnode(
  data: any,
  node: Node,
  depth: number,
  options: JsonViewOptions,
  nextId: () => string,
  getKey = (k: string) => k,
) {
  if (
    options.maxDepth &&
    depth >= options.maxDepth &&
    (node.type === "object" ||
      node.type === "array" ||
      node.type === "map" ||
      node.type === "set")
  ) {
    node.value = `[${capitalize(node.type)} not shown]`;
    return;
  }

  const maxLength = options.maxArrayLength || 100;
  const arrayGroupSize = Math.min(maxLength, 100);

  if (node.type === "array" && data.length > arrayGroupSize) {
    let groups = [];
    const truncateList = data.length > maxLength;

    if (truncateList) {
      groups = [data.slice(0, maxLength)];
    } else {
      groups = chunk(data, arrayGroupSize);
    }

    for (let i = 0; i < groups.length; i++) {
      const sliceData = groups[i];
      const startIdx = i * arrayGroupSize;
      const endIdx =
        sliceData.length < arrayGroupSize
          ? (i + 1) * arrayGroupSize - sliceData.length
          : (i + 1) * arrayGroupSize - 1;

      const child = createNode(
        {
          value: sliceData,
          key: `[${startIdx}-${endIdx}]`,
          depth,
          type: "array",
          parent: node,
          isOpenByDefault: options.isOpenByDefault,
        },
        nextId,
      );
      node.children.push(child);
      createSubnode(sliceData, child, depth + 1, options, nextId, (k) =>
        (Number.parseInt(k) + startIdx).toString(),
      );
    }

    // if we truncated the list, we should alert the user that we did that
    if (truncateList) {
      node.children.push(
        createNode(
          {
            value: `Only showing first ${maxLength} values`,
            key: `[${maxLength}-${data.length}]`,
            depth,
            type: "string",
            parent: node,
            isOpenByDefault: options.isOpenByDefault,
          },
          nextId,
        ),
      );
    }
  } else if (node.type === "map") {
    const child = createNode(
      {
        value: data,
        key: "[[Entries]]",
        virtualKey: true,
        depth: depth,
        type: "object",
        parent: node,
        isOpenByDefault: options.isOpenByDefault,
      },
      nextId,
    );
    node.children.push(child);
    createSubnode(Object.fromEntries(data), child, depth + 1, options, nextId);
  } else if (node.type === "set") {
    const child = createNode(
      {
        value: data,
        key: "[[Entries]]",
        virtualKey: true,
        depth: depth,
        type: "array",
        parent: node,
        isOpenByDefault: options.isOpenByDefault,
      },
      nextId,
    );
    node.children.push(child);
    createSubnode(Array.from(data), child, depth + 1, options, nextId);
  } else if (node.type === "object" || node.type === "array") {
    for (const key in data) {
      const child = createNode(
        {
          value: data[key],
          key: getKey(key),
          depth: depth,
          type: getDataType(data[key]),
          parent: node,
          isOpenByDefault: options.isOpenByDefault,
        },
        nextId,
      );
      node.children.push(child);
      createSubnode(data[key], child, depth + 1, options, nextId);
    }
  }
}

function createTree(data: any, options: JsonViewOptions): Node {
  let id = 0;
  function nextId() {
    return `${++id}`;
  }

  let isPrimitive = false;
  const type = getDataType(data);
  switch (type) {
    case "string":
    case "boolean":
    case "number":
    case "function":
    case "null":
    case "symbol":
    case "unknown":
    case "bigint":
    case "undefined": {
      isPrimitive = true;
      break;
    }
    case "map":
    case "set": {
      isPrimitive = false;
      break;
    }
    case "array":
    case "object": {
      if ((data && Object.entries(data).length === 0) || isNativeObject(data)) {
        isPrimitive = true;
      }
      break;
    }
    default: {
      // This should never happen
      const exhaustiveCheck: never = type;
      throw new TypeError(`unknown type ${exhaustiveCheck}`);
    }
  }
  const rootNode = createNode(
    {
      value: data,
      key: type,
      type,
      isRoot: true,
      isPrimitive: isPrimitive,
      isOpenByDefault: options.isOpenByDefault,
    },
    nextId,
  );
  createSubnode(data, rootNode, 0, options, nextId);
  return rootNode;
}

// The treewalker is a generator function that is called by a third-party
// library, but is basically the same logic we've written to traverse JSON.
function treeWalker(data: Node, options: JsonViewOptions) {
  const walker: TreeWalker<NodeWithOptions> = function* walk() {
    // Step 1: Generate top-level nodes
    if (data.isPrimitive || !data.isRoot) {
      yield {
        data: { ...data, options },
        nestingLevel: 0,
      };
    }
    if (data.children?.length > 0) {
      for (const child of data.children) {
        yield {
          data: { ...child, options },
          nestingLevel: child.depth,
        };
      }
    }

    while (true) {
      // The third-party library provides us with the iteration logic
      const parent = yield;

      for (let i = 0; i < parent.data.children.length; i++) {
        const child = parent.data.children[i];
        yield {
          data: {
            ...child,
            options,
          },
          nestingLevel: child.depth,
        };
      }
    }
  };
  return walker;
}

function expandableTemplate(
  {
    key,
    virtualKey,
    isOpen,
    setOpen,
    previewString: size,
  }: {
    key: string | null;
    virtualKey: boolean;
    isOpen: boolean;
    setOpen: (newVal: boolean) => void;
    previewString: string;
  },
  options: JsonViewOptions,
) {
  return (
    <div
      className={`line is-expandable ${key}-key`}
      onClick={() => {
        setOpen(!isOpen);
      }}
    >
      <CollapseToggle
        isOpen={isOpen}
        className={`caret-icon`}
        icon={null}
        isVisible={true}
        onClick={() => {
          setOpen(!isOpen);
        }}
        disabled={false}
      />
      <div className={virtualKey ? "virtual-key" : "json-key"}>
        {key?.toString()}:
      </div>
      {options.displayObjectSize && <div className="json-size">{size}</div>}
    </div>
  );
}

function notExpandableTemplate(
  { key, virtualKey, value, type }: Partial<Node> = {},
  options: JsonViewOptions,
) {
  return (
    <div className={`line ${key}-key`}>
      <div className={virtualKey ? "virtual-key" : "json-key"}>
        {key?.toString()}:
      </div>
      <div className={`json-value json-${type}`}>
        {formatValue(value, options)}
      </div>
    </div>
  );
}

function primitiveTemplate(
  { value, type }: Partial<Node> = {},
  options: JsonViewOptions,
) {
  return (
    <div className="line">
      <div className="empty-icon"></div>
      <div className={`json-value json-${type}`}>
        {formatValue(value, options)}
      </div>
    </div>
  );
}

function getPreviewString(node: NodeWithOptions, isOpen: boolean) {
  const size =
    node.type === "array" && Array.isArray(node.value)
      ? node.value.length
      : node.children.length;

  const maxProperties = 3;

  let previewString = "";
  switch (node.type) {
    case "array":
      previewString = `${
        node.value instanceof Uint8Array ? "bytestring " : ""
      }[${size} item${size > 1 ? "s" : ""}]`;
      break;
    case "map": {
      const mapSize = node.value instanceof Map ? node.value.size : size;
      previewString = `Map(${mapSize})`;
      break;
    }
    case "set": {
      const setSize = node.value instanceof Set ? node.value.size : size;
      previewString = `Set(${setSize})`;
      break;
    }
    case "object": {
      if (!isOpen) {
        if (size <= maxProperties) {
          const v = node.value as any;
          const options = {
            ...node.options,
            skipSubObjectExpansion: true,
          };
          const stringRepresentation = `{ ${Object.keys(v)
            .map((key) => `${key}: ${formatValue(v[key], options)}`)
            .join(", ")} }`;
          if (stringRepresentation.length <= (options.maxStringLength ?? 20)) {
            previewString = stringRepresentation;
          } else {
            previewString = `{...}`;
          }
        } else {
          previewString = `{...}`;
        }
      }
      break;
    }
    default:
      break;
  }
  return previewString;
}

function createNodeElement(
  node: NodeWithOptions,
  isOpen: boolean,
  setOpen: (newOpen: boolean) => void,
) {
  if (node.isPrimitive) {
    return primitiveTemplate(
      {
        value: node.value,
        type: getDataType(node.value),
      },
      node.options,
    );
  } else if (node.children.length > 0) {
    // we have to account for grouping of array items for display purposes

    return expandableTemplate(
      {
        key: node.key,
        virtualKey: node.virtualKey,
        isOpen: isOpen,
        setOpen: setOpen,
        previewString: getPreviewString(node, isOpen),
      },
      node.options,
    );
  }
  return notExpandableTemplate(
    {
      key: node.key,
      virtualKey: node.virtualKey,
      value: node.value,
      type: getDataType(node.value),
    },
    node.options,
  );
}

function RenderNode({
  data,
  style,
  isOpen,
  setOpen,
}: NodeComponentProps<
  NodeWithOptions,
  FixedSizeNodePublicState<NodeWithOptions>
>) {
  const nodeMargin = data.depth * JSON_INDENTATION_SIZE;

  return (
    <div>
      {[...Array(data.depth).keys()].map((i) => {
        return (
          <div
            style={{
              ...style,
              borderLeft: "1px solid #E8EAED",
              marginLeft: i * JSON_INDENTATION_SIZE + 7,
              paddingBottom: 4,
              width: 1,
            }}
            key={"line" + i}
          />
        );
      })}
      <div
        style={{
          ...style,
          marginLeft: nodeMargin,
          width:
            style.width === "100%" ? `calc(100% - ${nodeMargin})` : style.width,
        }}
      >
        {createNodeElement(data, isOpen, setOpen)}
      </div>
    </div>
  );
}

export default function JsonView({
  data,
  maxStringLength,
  maxBufferLength = 100,
  isOpenByDefault = true,
  maxDepth = 5,
  maxArrayLength,
  displayObjectSize = true,
  lineHeight = 20,
  maxHeight,
  height,
  width,
  style,
  scrollbarBehavior = "hover",
  className = "",
}: {
  data: unknown;
} & JsonViewOptions) {
  const walker = useMemo(() => {
    const treeData = createTree(data, {
      maxStringLength,
      maxBufferLength,
      maxDepth,
      isOpenByDefault,
      width,
      maxArrayLength,
      displayObjectSize,
    });
    return treeWalker(treeData, {
      maxStringLength,
      maxBufferLength,
      maxDepth,
      isOpenByDefault,
      width,
      maxArrayLength,
      displayObjectSize,
    });
  }, [
    data,
    maxStringLength,
    maxBufferLength,
    maxDepth,
    isOpenByDefault,
    width,
    maxArrayLength,
    displayObjectSize,
  ]);

  return (
    <FixedSizeTree
      treeWalker={walker}
      itemSize={lineHeight}
      height={height}
      width={width}
      expandToHeight={height === undefined}
      maxHeight={maxHeight}
      className={`json-container scrollbar-${scrollbarBehavior} ${className}`}
      style={style}
    >
      {RenderNode}
    </FixedSizeTree>
  );
}

export { createTree, formatValue };
