import { SUPPORTED_FORMATTER_TYPE } from "code-formatting/constants";
import { EditorModes } from "components/app/CodeEditor/EditorConfig";
import { ItemKinds } from "legacy/pages/Editor/PropertyPane/ItemKindConstants";
import { EventDefinitionScoped } from "store/slices/application/events/eventConstants";
import { AppStateVarScoped } from "store/slices/application/stateVars/StateConstants";
import { AppTimerScoped } from "store/slices/application/timers/TimerConstants";
import { ApiInfo } from "./ApiConstants";
import type { WidgetProps } from "legacy/widgets";
import type { ApiUnion } from "store/slices/apisShared";

type EditorTabWithName = EditorOpenTab & {
  name: string;
};

export type EditorOpenTabWithAPI = EditorTabWithName & {
  entityType: EditorOpenTabType.API;
  entity: ApiUnion;
};

export type EditorOpenTabWithAPIInfo = EditorTabWithName & {
  entityType: EditorOpenTabType.API_INFO;
  entity: ApiInfo;
};

export type EditorOpenTabWithWidget = EditorTabWithName & {
  entityType: EditorOpenTabType.WIDGET;
  entity: WidgetProps;
};

export type EditorOpenTabWithTimer = EditorTabWithName & {
  entityType: EditorOpenTabType.TIMER;
  entity: AppTimerScoped;
};

export type EditorOpenTabWithStateVar = EditorTabWithName & {
  entityType: EditorOpenTabType.STATE_VAR;
  entity: AppStateVarScoped;
};

export type EditorOpenTabWithEvent = EditorTabWithName & {
  entityType: EditorOpenTabType.CUSTOM_EVENT;
  entity: EventDefinitionScoped;
};

export type EditorOpenTabWithEntity =
  | EditorOpenTabWithAPI
  | EditorOpenTabWithAPIInfo
  | EditorOpenTabWithWidget
  | EditorOpenTabWithTimer
  | EditorOpenTabWithStateVar
  | EditorOpenTabWithEvent;

export enum EditorOpenTabType {
  API = "API",
  API_INFO = "API_INFO",
  WIDGET = "WIDGET",
  TIMER = "TIMER",
  STATE_VAR = "STATE_VAR",
  CUSTOM_EVENT = "CUSTOM_EVENT",
}

export type EditorOpenTab = {
  id?: string;
  entityType: EditorOpenTabType;
  entityId: string;
  widgetProperty?: string;
};

type PageId = string;
export type EditorAppPreferences = Record<PageId, PagePreferences>;
export type PagePreferences = {
  openBottomPanelTabs: EditorOpenTab[];
};

export enum DeveloperPreferencesKeys {
  Shared = "shared",
  Application = "application",
}

// Developer preferences that can be customized per EntityType
export type ApplicationDeveloperPreferences = {
  triggerFrontendEventHandlersOnManualRun: boolean;
};

export enum KeyBindings {
  Default = "default",
  Vim = "vim",
}

// Developer preferences shared between EntityTypes
export type SharedDeveloperPreferences = {
  formatCodeOnBlur: boolean;
  formatCodeOnRun: boolean;
  languagesToFormat: SUPPORTED_FORMATTER_TYPE[];
  keyBindings: KeyBindings;
};

// Developer preferences that users can actually customize
const DEFAULT_APPLICATION_DEVELOPER_PREFERENCES: ApplicationDeveloperPreferences =
  {
    triggerFrontendEventHandlersOnManualRun: true,
  };

const DEFAULT_SHARED_DEVELOPER_PREFERENCES: SharedDeveloperPreferences = {
  formatCodeOnRun: false,
  formatCodeOnBlur: true,
  languagesToFormat: [EditorModes.JAVASCRIPT, EditorModes.PYTHON],
  keyBindings: KeyBindings.Default,
};

export type DeveloperPreferences = {
  [DeveloperPreferencesKeys.Shared]: SharedDeveloperPreferences;
  [DeveloperPreferencesKeys.Application]: ApplicationDeveloperPreferences;
};

export const DEFAULT_DEVELOPER_PREFERENCES: DeveloperPreferences = {
  [DeveloperPreferencesKeys.Shared]: DEFAULT_SHARED_DEVELOPER_PREFERENCES,
  [DeveloperPreferencesKeys.Application]:
    DEFAULT_APPLICATION_DEVELOPER_PREFERENCES,
};

export const ItemKindToEditorTabType: Record<
  ItemKinds,
  EditorOpenTabType | undefined
> = {
  [ItemKinds.WIDGET]: EditorOpenTabType.WIDGET,
  [ItemKinds.TIMER]: EditorOpenTabType.TIMER,
  [ItemKinds.STATE_VAR]: EditorOpenTabType.STATE_VAR,
  [ItemKinds.CUSTOM_EVENT]: EditorOpenTabType.CUSTOM_EVENT,
  [ItemKinds.API_V1]: EditorOpenTabType.API_INFO,
  [ItemKinds.API_V2]: EditorOpenTabType.API_INFO,
  [ItemKinds.EMBED_PROP]: undefined,
  [ItemKinds.ROUTE]: undefined,
  [ItemKinds.NESTED_ITEM]: undefined,
};

export const EditorTabTypeToItemKind: Record<EditorOpenTabType, ItemKinds> = {
  [EditorOpenTabType.WIDGET]: ItemKinds.WIDGET,
  [EditorOpenTabType.TIMER]: ItemKinds.TIMER,
  [EditorOpenTabType.STATE_VAR]: ItemKinds.STATE_VAR,
  [EditorOpenTabType.CUSTOM_EVENT]: ItemKinds.CUSTOM_EVENT,
  [EditorOpenTabType.API]: ItemKinds.API_V2,
  [EditorOpenTabType.API_INFO]: ItemKinds.API_V2,
};
