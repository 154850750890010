import { Button, Card, Tooltip } from "antd";
import { CardProps } from "antd/lib/card";
import Title from "antd/lib/typography/Title";
import React, { ReactElement, useMemo, useState } from "react";
import styled from "styled-components";
import ObservabilityModal from "./ObservabilityModal";
import { Sink } from "./sink";

const StyledCard = (props: CardProps) => (
  <Card
    hoverable={false}
    bordered={false}
    bodyStyle={{
      ...props.bodyStyle,
      width: "270px",
      padding: 0,
      border: "1px solid #d8d8d8",
      borderRadius: "4px",
    }}
  >
    {props.children}
  </Card>
);

const CardImgWrapper = styled.div`
  border-bottom: 1px solid #d8d8d8;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ObservabilityIcon = styled.img`
  height: 50px;
  max-width: 50px;
  margin: 20px 0;
`;

const CardContent = styled.div`
  height: 110px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f7f5f7;
  flex-direction: column;
  text-align: center;
`;

interface ObservabilityCardProps {
  sink: Sink<any>;
  newSink: boolean;
  buttonText: string;
  buttonIcon: JSX.Element;
  onChange: () => void;
  enabled: boolean;
  disableToolTip?: string;
}

export default function ObservabilityCard({
  sink,
  newSink,
  buttonText,
  buttonIcon,
  onChange,
  enabled,
  disableToolTip,
}: ObservabilityCardProps): ReactElement {
  const [visible, setVisible] = useState(false);

  const contents = useMemo(() => {
    return (
      <>
        <div
          data-test={`observability-card-test-${sink}`}
          style={{
            cursor: "pointer",
          }}
          onClick={() => {
            if (enabled) {
              setVisible(true);
            }
          }}
        >
          <StyledCard bodyStyle={{ opacity: enabled ? 1 : 0.5 }}>
            <CardImgWrapper>
              <ObservabilityIcon src={sink.iconLocation} />
            </CardImgWrapper>
            <CardContent>
              <Title level={5}>{sink.label}</Title>
              <Tooltip title={disableToolTip}>
                <Button
                  color={"#9BDCAD"}
                  style={{
                    color: "#9BDCAD",
                    borderColor: "#d8d8d8",
                    borderRadius: "5px",
                  }}
                  icon={buttonIcon}
                  disabled={!enabled}
                  data-test={`observability-card-button-test-${sink}`}
                >
                  {buttonText}
                </Button>
              </Tooltip>
            </CardContent>
          </StyledCard>
        </div>
        <ObservabilityModal
          sink={sink}
          newSink={newSink}
          isOpen={visible}
          onOk={() => {
            setVisible(false);
            onChange();
          }}
          onCancel={() => {
            setVisible(false);
          }}
          iconLocation={sink.iconLocation}
        />
      </>
    );
  }, [
    buttonIcon,
    buttonText,
    disableToolTip,
    enabled,
    newSink,
    onChange,
    sink,
    visible,
  ]);

  return contents;
}
