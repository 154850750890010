import {
  AccessMode,
  SuperblocksError,
  UIErrorType,
} from "@superblocksteam/shared";
import { all, call, put, select, takeLatest } from "redux-saga/effects";
import { API_STATUS_CODES, ERROR_CODES } from "legacy/constants/ApiConstants";
import {
  ReduxAction,
  ReduxActionErrorTypes,
  ReduxActionTypes,
} from "legacy/constants/ReduxActionConstants";
import { ANONYMOUS_USERNAME } from "legacy/constants/userConstants";
import AnalyticsUtil from "legacy/utils/AnalyticsUtil";
import PerformanceTracker, {
  PerformanceTransactionName,
} from "legacy/utils/PerformanceTracker";
import { getFeatureFlagSaga } from "store/slices/featureFlags";
import { getUserAnalyticsSaga } from "store/slices/user";
import { callSagas, forkSagas } from "store/utils/saga";
import {
  OutgoingMessage,
  isOnEmbedRoute,
  sendEventToEmbedder,
} from "utils/embed/messages";
import logger, { stringifyError } from "utils/logger";
import { getAgentsSaga } from "../../store/slices/agents";
import {
  getOrganizationsSaga,
  selectOnlyOrganization,
} from "../../store/slices/organizations";
import UserApi, { type UserMeDto } from "../api/UserApi";
import { validateResponse } from "./ErrorSagas";
import type { ApiResponse } from "legacy/api/ApiResponses";

interface GetCurrentUserSagaPayload {
  accessMode: AccessMode;
  probeAllAgents: boolean;
  trackingParams?: Record<string, string>;
}

export function* getCurrentUserSaga(
  action: ReduxAction<GetCurrentUserSagaPayload>,
): Generator<any, any, any> {
  const { accessMode, probeAllAgents, trackingParams } = action.payload;
  try {
    PerformanceTracker.startAsyncTracking(
      PerformanceTransactionName.USER_ME_API,
    );
    let response: ApiResponse<UserMeDto>;
    switch (accessMode) {
      case AccessMode.VISITOR: {
        response = yield call(UserApi.getCurrentVisitor, trackingParams);
        break;
      }
      case AccessMode.AUTH_USER:
      case AccessMode.EXTERNAL_USER: {
        response = yield call(UserApi.getCurrentUser, trackingParams);
        break;
      }
    }
    const isValidResponse = yield validateResponse(response);
    if (isValidResponse) {
      if (
        !response.data.user.isAnonymous &&
        response.data.user.username !== ANONYMOUS_USERNAME
      ) {
        AnalyticsUtil.identifyUser(response.data.user);
      }

      yield put({
        type: ReduxActionTypes.FETCH_USER_DETAILS_SUCCESS,
        payload: response.data.user,
      });

      yield put({
        type: ReduxActionTypes.SET_USER_PERMISSIONS,
        payload: {
          permissions: response.data.permissions,
        },
      });

      yield getOrganizationsSaga.setStore(response.data.organizations);
      const organization = yield select(selectOnlyOrganization);
      yield put({
        type: ReduxActionTypes.SET_CURRENT_ORG_ID,
        payload: {
          orgId: organization.id,
        },
      });

      PerformanceTracker.stopAsyncTracking(
        PerformanceTransactionName.USER_ME_API,
      );
      // Important that user initialization waits for feature flags
      yield callSagas([
        getFeatureFlagSaga.apply({
          organizationId: organization.id,
          organizationBillingPlan: organization.billing?.plan,
          flags: response.data.flagBootstrap,
        }),
      ]);

      // always fetch agents because control flow could be dynamically determined
      yield forkSagas([
        getAgentsSaga.apply({
          organization,
          probeAll: probeAllAgents,
          agents: response.data.agents,
        }),
      ]);

      if (!isOnEmbedRoute()) {
        yield forkSagas([getUserAnalyticsSaga.apply({})]);
      }
    }
  } catch (error: any) {
    sendEventToEmbedder({
      type: OutgoingMessage.AUTH_ERROR,
      data: {
        error:
          error?.errorType === SuperblocksError.JWTExpired
            ? "token_expired"
            : "unauthorized",
        message: error?.errorMessage,
      },
    });

    PerformanceTracker.stopAsyncTracking(
      PerformanceTransactionName.USER_ME_API,
      { failed: true },
    );
    yield put({
      type: ReduxActionErrorTypes.FETCH_USER_DETAILS_ERROR,
      payload: {
        error,
      },
    });
    logger.error(
      `Page crashed because getCurrentUser call failed, error:
${stringifyError(error)}`,
      {
        superblocks_ui_error_type: UIErrorType.CRASH_APP_ERROR,
      },
    );
    let errorCode =
      API_STATUS_CODES[(error as Record<string, any>)?.code] ??
      ERROR_CODES.SERVER_ERROR;
    if (error?.errorType === SuperblocksError.JWTExpired) {
      errorCode = ERROR_CODES.JWT_EXPIRED;
    }
    yield put({
      type: ReduxActionTypes.SAFE_CRASH_SUPERBLOCKS,
      payload: {
        code: errorCode,
      },
    });
  }
}

export default function* userSagas() {
  yield all([takeLatest(ReduxActionTypes.FETCH_USER_INIT, getCurrentUserSaga)]);
}
