import {
  AgentType,
  ENVIRONMENT_ALL,
  Profile,
  ProfileType,
} from "@superblocksteam/shared";
import { Button, Form, Input, Modal, Space, Tooltip } from "antd";
import TextArea from "antd/lib/input/TextArea";
import Typography from "antd/lib/typography";
import React, { useCallback, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import styled from "styled-components";
import { Layout, MainWrapper } from "components/app";
import { StyledReactMarkdown } from "components/app/SBDynamicForm/DynamicFormItem";
import AddButton from "components/ui/AddButton";
import { HeaderWrapper } from "components/ui/Page";
import RecommendedTable, { RecColumn } from "components/ui/RecommendedTable";
import { MANAGE_PROFILES, VIEW_AGENTS } from "constants/rbac";
import { useAuthorizationCheck } from "hooks/ui/rbac/useAuthorizationCheck";
import Header from "pages/components/Header";
import { PageNav } from "pages/components/PageNav";
import { PageWrapper, PROFILES_TITLE } from "pages/components/PageWrapper";
import { useAppSelector } from "store/helpers";
import { selectActiveAgents } from "store/slices/agents";
import { generateKey } from "utils/generateKey";
import { useSaga } from "../../hooks/store";
import { LegacyNamedColors } from "../../legacy/constants/LegacyNamedColors";
import {
  DOCS_PAGE_URL,
  DocsPage,
  OPA_URL,
} from "../../legacy/constants/routes";
import { getUserCurrentOrgId } from "../../legacy/selectors/organizationSelectors";
import { selectOrganizationById } from "../../store/slices/organizations";
import { createProfileSaga } from "../../store/slices/organizations/sagas/createProfile";
import { deleteProfileSaga } from "../../store/slices/organizations/sagas/deleteProfile";
import { updateProfileSaga } from "../../store/slices/organizations/sagas/updateProfile";

const StyledForm = styled(Form)`
  .ant-form-item {
    margin-bottom: 20px;
  }
`;

const StyledModal = styled(Modal)`
  .ant-modal-body {
    padding: 16px;
  }
  .ant-modal-header {
    padding: 16px;
  }
`;

const AgentsColumn = styled.div`
  text-align: center;
`;

const DUPLICATE_DISPLAY_NAME = "Display Name is already in use";
const DUPLICATE_NAME = "Key is already in use";

//A react function component to show count of agents given profile
const AgentsCount = (props: { profileKey: string }) => {
  const { profileKey } = props;
  const navigate = useNavigate();
  const agents = useSelector(
    selectActiveAgents(AgentType.ONPREMISE, profileKey),
  );
  const hasAllEnvAgents = agents.some(
    (agent) => agent.environment === ENVIRONMENT_ALL,
  );
  const activeCount = agents.length;
  return (
    <AgentsColumn>
      <Tooltip
        title={`Supported by ${activeCount} active agent${
          activeCount !== 1 ? "s" : ""
        }`}
        placement="left"
      >
        <Button
          onClick={() => {
            navigate({
              pathname:
                activeCount === 0
                  ? OPA_URL
                  : `${OPA_URL}?status=active${
                      hasAllEnvAgents ? "&tag=*" : ""
                    }&tag=${profileKey}`,
            });
          }}
          type="link"
        >
          {activeCount}
        </Button>
      </Tooltip>
    </AgentsColumn>
  );
};

type ProfileItem = {
  id: string;
  displayName: string;
  key: string;
  type: ProfileType;
  description: string;
  isDescriptionMarkdown: boolean;
};

export default function Profiles() {
  const organizationId = useSelector(getUserCurrentOrgId) ?? "";
  const organization = useAppSelector((state) =>
    selectOrganizationById(state, organizationId),
  );
  // opa profiles
  const isOPA = organization.agentType === AgentType.ONPREMISE;
  const [updateProfile] = useSaga(updateProfileSaga);
  const [createProfile] = useSaga(createProfileSaga);
  const [deleteProfile] = useSaga(deleteProfileSaga);

  const [createProfileModalVisible, setCreateProfileModalVisible] =
    useState(false);
  const [createForm] = Form.useForm();
  const handleCreateProfile = useCallback(async () => {
    createForm.validateFields().then(async (values) => {
      await createProfile({
        orgId: organizationId,
        created: {
          key: generateKey(values.displayName),
          displayName: values.displayName,
          description: values.profileDescription,
        },
      });
      setCreateProfileModalVisible(false);
    });
  }, [createForm, organizationId, createProfile]);

  const handleDisplayNameChange = useCallback(() => {
    const key = generateKey(createForm.getFieldValue("displayName"));
    createForm.setFieldsValue({ key: key });
  }, [createForm]);
  // Validate that the display name is not already being used by another profile
  const newDisplayNameValidator = useCallback(
    async (rule: any, value: any) => {
      if (value) {
        const profile = organization.profiles?.find(
          (profile) => profile.displayName === value,
        );
        if (profile) {
          return Promise.reject(DUPLICATE_DISPLAY_NAME);
        }
        return Promise.resolve();
      }
    },
    [organization.profiles],
  );
  const newNameValidator = useCallback(
    async (rule: any, value: any) => {
      if (value) {
        const profile = organization.profiles?.find(
          (profile) => profile.key === value,
        );
        if (profile) {
          return Promise.reject(DUPLICATE_NAME);
        }
        return Promise.resolve();
      }
    },
    [organization.profiles],
  );
  const createProfileModal = useMemo(() => {
    return (
      <StyledModal
        data-test="create-profile-modal"
        open={createProfileModalVisible}
        closable
        onCancel={() => {
          setCreateProfileModalVisible(false);
        }}
        title={`Create new profile`}
        footer={null}
        width={450}
      >
        <div>
          <StyledForm form={createForm} name="basic" layout="vertical">
            <Form.Item
              label="Display Name"
              name="displayName"
              rules={[
                { required: true, message: "Please enter a display name!" },
                {
                  validator: newDisplayNameValidator,
                  message: DUPLICATE_DISPLAY_NAME,
                },
              ]}
            >
              <Input
                data-test="profile-key-input-create"
                placeholder="Example Profile"
                onChange={handleDisplayNameChange}
              />
            </Form.Item>
            <Tooltip
              title="The key is generated based on the display name"
              placement="bottom"
            >
              <Form.Item
                label="Key"
                name="key"
                rules={[
                  {
                    validator: newNameValidator,
                    message: DUPLICATE_NAME,
                  },
                ]}
              >
                <Input
                  placeholder="example_profile"
                  disabled={true}
                  data-test={"profile-key-create"}
                />
              </Form.Item>
            </Tooltip>
            <Form.Item label="Description" name="profileDescription">
              <TextArea
                placeholder="Enter a description"
                data-test={"profile-description-input-create"}
                maxLength={100}
              />
            </Form.Item>
          </StyledForm>
        </div>
        <div
          style={{ display: "flex", justifyContent: "flex-end", gap: "1em" }}
        >
          <Button
            data-test="create-profile-modal-close"
            key="close"
            type="default"
            onClick={() => {
              setCreateProfileModalVisible(false);
            }}
          >
            Cancel
          </Button>
          <Button
            type="primary"
            onClick={handleCreateProfile}
            data-test={"create-profile-button"}
          >
            Create
          </Button>
        </div>
      </StyledModal>
    );
  }, [
    createProfileModalVisible,
    createForm,
    newDisplayNameValidator,
    handleDisplayNameChange,
    newNameValidator,
    handleCreateProfile,
  ]);

  const profileById = useCallback(
    (id: string) => {
      return (organization.profiles ?? []).find((profile) => {
        return profile.id === id;
      });
    },
    [organization.profiles],
  );
  const [editForm] = Form.useForm();
  const [profileIdBeingEdited, setProfileIdBeingEdited] = useState<
    string | undefined
  >(undefined);
  const startEditingForProfileId = useCallback(
    (id: string) => {
      const profBeingEdited = profileById(id);
      // Fill in the initial form values.
      editForm.setFieldsValue({
        displayName: profBeingEdited?.displayName ?? "",
        key: profBeingEdited?.key ?? "",
        profileDescription: profBeingEdited?.description ?? "",
      });
      // `profileIdBeingEdited` gives the `editForm` access to the profile
      // being edited and activates the edit modal.
      setProfileIdBeingEdited(id);
    },
    [editForm, profileById, setProfileIdBeingEdited],
  );

  const handleUpdateProfile = useCallback(async () => {
    editForm.validateFields().then(async (values) => {
      const profBeingEdited = profileById(profileIdBeingEdited ?? "");
      await updateProfile({
        orgId: organizationId,
        profileId: profBeingEdited?.id ?? "",
        updated: {
          // NOTE: Do not regenerate the key here because the key must not
          // change once it is persisted in the create flow.
          key: profBeingEdited?.key ?? "",
          displayName: values.displayName,
          description: values.profileDescription,
        },
      });
      setProfileIdBeingEdited(undefined);
    });
  }, [
    editForm,
    profileById,
    profileIdBeingEdited,
    updateProfile,
    organizationId,
  ]);
  // Validate that the display name is not already being used by another profile
  const editedDisplayNameValidator = useCallback(
    async (rule: any, value: any) => {
      if (value) {
        const profile = organization.profiles?.find(
          (profile) =>
            profile.displayName === value &&
            profile.id !== profileIdBeingEdited,
        );
        if (profile) {
          return Promise.reject(DUPLICATE_DISPLAY_NAME);
        }
        return Promise.resolve();
      }
    },
    [organization.profiles, profileIdBeingEdited],
  );

  // TODO(aayush): DRY with the edit/create modals
  const editProfileModal = useMemo(() => {
    return (
      <StyledModal
        data-test="edit-profile-modal"
        open={Boolean(profileIdBeingEdited)}
        closable
        onCancel={() => {
          setProfileIdBeingEdited(undefined);
        }}
        title={`Edit this profile`}
        footer={null}
        width={450}
      >
        <div>
          <StyledForm form={editForm} name="basic" layout="vertical">
            <Form.Item
              label="Display Name"
              name="displayName"
              rules={[
                { required: true, message: "Please enter a display name!" },
                {
                  validator: editedDisplayNameValidator,
                  message: DUPLICATE_DISPLAY_NAME,
                },
              ]}
            >
              <Input
                placeholder="Example Profile"
                data-test="profile-key-input-edit"
              />
            </Form.Item>
            <Tooltip
              title="The key cannot be edited in order to avoid breaking existing usages"
              placement="bottom"
            >
              <Form.Item label="Key" name="key">
                <Input
                  placeholder="example_profile"
                  disabled={true}
                  data-test="profile-key-edit"
                />
              </Form.Item>
            </Tooltip>
            <Form.Item label="Description" name="profileDescription">
              <TextArea
                placeholder="Enter a description"
                data-test="profile-description-input-edit"
                maxLength={100}
              />
            </Form.Item>
          </StyledForm>
        </div>
        <div
          style={{ display: "flex", justifyContent: "flex-end", gap: "1em" }}
        >
          <Button
            data-test="create-profile-modal-close"
            key="close"
            type="default"
            onClick={() => {
              setProfileIdBeingEdited(undefined);
            }}
          >
            Cancel
          </Button>
          <Button
            type="primary"
            onClick={handleUpdateProfile}
            data-test={"save-profile-button"}
          >
            Save
          </Button>
        </div>
      </StyledModal>
    );
  }, [
    editForm,
    editedDisplayNameValidator,
    handleUpdateProfile,
    profileIdBeingEdited,
  ]);

  const [deleteProfileId, setDeleteProfileId] = useState<string | undefined>(
    undefined,
  );
  const handleDeleteProfile = useCallback(async () => {
    await deleteProfile({
      orgId: organizationId,
      profileId: deleteProfileId ?? "",
    });
    setDeleteProfileId(undefined);
  }, [deleteProfile, deleteProfileId, organizationId]);

  const deleteProfileModal = useMemo(() => {
    return (
      <StyledModal
        data-test="delete-profile-modal"
        open={Boolean(deleteProfileId)}
        closable
        onCancel={() => {
          setDeleteProfileId(undefined);
        }}
        title={`Delete this profile?`}
        footer={null}
        width={450}
      >
        <Space direction="vertical" style={{ marginBottom: "20px" }}>
          <div>
            <Typography.Text
              style={{
                color: LegacyNamedColors.GRAY_DARK,
              }}
            >
              This action cannot be undone and may break Applications,
              Workflows, or Scheduled Jobs using the{" "}
              <Typography.Text strong>
                {profileById(deleteProfileId ?? "")?.displayName}
              </Typography.Text>{" "}
              profile.
            </Typography.Text>
          </div>
        </Space>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            gap: "10px",
          }}
        >
          <Button
            data-test="profile-delete-cancel"
            key="cancel"
            type="default"
            onClick={() => {
              setDeleteProfileId(undefined);
            }}
          >
            Cancel
          </Button>
          <Button
            data-test="profile-delete-confirm"
            key="delete"
            type="primary"
            danger
            onClick={handleDeleteProfile}
          >
            <span data-test="confirm-delete-profile-button">Delete</span>
          </Button>
        </div>
      </StyledModal>
    );
  }, [deleteProfileId, handleDeleteProfile, profileById]);

  const availableProfiles = useMemo(() => {
    const allProfileItems = (organization.profiles ?? []).map((profile) => {
      return {
        id: profile.id,
        displayName: profile.displayName,
        key: profile.key,
        type: profile.type,
        description: profile.description
          ? profile.description
          : `Configure integrations for your **${profile.displayName}** profile`,
        isDescriptionMarkdown: profile.description ? false : true,
      };
    });
    return allProfileItems.slice().sort((a, b) => {
      if (a.displayName === `Production`) {
        return -1;
      }
      if (b.displayName === `Production`) {
        return 1;
      }
      if (a.displayName === `Staging`) {
        return -1;
      }
      if (b.displayName === `Staging`) {
        return 1;
      }
      return 0;
    });
  }, [organization.profiles]);

  const startCreatingProfile = useCallback(() => {
    createForm.resetFields();
    setCreateProfileModalVisible(true);
  }, [createForm, setCreateProfileModalVisible]);

  const [canViewAgents, canManageProfiles] = useAuthorizationCheck([
    VIEW_AGENTS,
    MANAGE_PROFILES,
  ]);
  const showAgentCol = isOPA && canViewAgents;

  const columns: RecColumn<ProfileItem>[] = useMemo(() => {
    return [
      {
        Header: "Id",
        accessor: "id",
        hidden: true,
      },
      {
        Header: "Display name",
        accessor: "displayName",
      },
      {
        Header: "Key",
        accessor: "key",
      },
      {
        Header: "Description",
        accessor: "description",
        Cell: ({ value, row }) => {
          return row.original.isDescriptionMarkdown ? (
            <StyledReactMarkdown>{value ?? ""}</StyledReactMarkdown>
          ) : (
            value
          );
        },
      },
      {
        Header: "Agent count",
        accessor: "isDescriptionMarkdown", // just need a unique accessor
        Cell: ({ row }) => <AgentsCount profileKey={row.original.key ?? ""} />,
        hidden: !showAgentCol,
      },
    ] as RecColumn<ProfileItem>[];
  }, [showAgentCol]);

  const menuItems = useCallback(
    (profile: Profile) => {
      const profileIsReserved = profile.type === ProfileType.RESERVED;
      return [
        {
          key: "1",
          label: "Edit",
          onClick: () => {
            startEditingForProfileId(profile.id);
          },
          "data-test": "edit-profile-button",
          disabled: profileIsReserved,
          tooltip: profileIsReserved
            ? "Reserved profiles cannot be edited"
            : undefined,
        },
        {
          key: "2",
          label: "Delete",
          onClick: () => {
            setDeleteProfileId(profile.id);
          },
          "data-test": "delete-profile-button",
          disabled: profileIsReserved,
          tooltip: profileIsReserved
            ? "Reserved profiles cannot be deleted"
            : undefined,
        },
      ];
    },
    [startEditingForProfileId],
  );

  return (
    <PageWrapper pageName={PROFILES_TITLE}>
      <Layout Header={<Header />} Sider={<PageNav />}>
        <MainWrapper data-test="profiles-home">
          <div className={HeaderWrapper}>
            <div className="page-header-title"> {PROFILES_TITLE} </div>
            <div
              className="page-header-description"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <span>
                Configure Superblocks to connect all of your organization’s
                profiles.{" "}
                <a
                  href={DOCS_PAGE_URL(DocsPage.INTEGRATION_PROFILES)}
                  target="_blank"
                  rel="noreferrer"
                >
                  Learn more
                </a>
              </span>
              <AddButton
                onAdd={startCreatingProfile}
                disabled={!canManageProfiles}
                dataTest={"add-profile-button"}
                text="Add Profile"
                disabledText="You do not have permission to create profiles"
              />
            </div>
          </div>
          <RecommendedTable<ProfileItem>
            data={availableProfiles}
            columns={columns}
            uniqueKey="id"
            actionMenuItems={canManageProfiles ? menuItems : undefined}
          />
        </MainWrapper>
        {createProfileModal}
        {deleteProfileModal}
        {editProfileModal}
      </Layout>
    </PageWrapper>
  );
}
