import { RouteDef } from "@superblocksteam/shared";
import { generatePath, matchRoutes, RouteObject } from "react-router";
import {
  ApplicationRoute,
  EditorRoute,
  EmbedRoute,
} from "legacy/constants/routes";
import type { Page } from "legacy/constants/ReduxActionConstants";

const DISALLOWED_ROUTE_PREFIXES = ["sb_tab", "applications"];

const ALLOWED_CHARACTERS = /^[a-zA-Z0-9_\/:.~*\-]+$/;
const DISALLOWED_CHARACTER_MATCHER = /[a-zA-Z0-9_\/:.~*\-]+/g;

const applicationRoutes = [
  {
    path: ApplicationRoute.Base,
    children: [
      { path: ApplicationRoute.Preview },
      { path: ApplicationRoute.Viewer },
      {
        path: EditorRoute.EditApplication,
        children: [
          { path: EditorRoute.EditApi },
          { path: EditorRoute.EditApiActionBase },
          { path: EditorRoute.EditApiAction },
          { path: EditorRoute.EditApiInputs },
          { path: EditorRoute.EditApiResponse },
          { path: EditorRoute.EditEntityProperty },
        ],
      },
    ],
  },
  {
    path: EmbedRoute.Base,
    children: [
      { path: ApplicationRoute.Preview },
      { path: ApplicationRoute.Viewer },
    ],
  },
] as RouteObject[];

/**
 * Check for errors:
 * - If the route path is already in use
 * - If the path does not begin with a / or contains too many leading slashes
 * - If the path contains a prefix we don't allow, sb_tab or application
 * - If the path contains disallowed characters
 *
 * Will return `null` if there is no error
 */
export function getRoutePathError(
  existingRoutes: RouteDef[],
  pages: Page[],
  newRoutePath: string,
) {
  const sanitizedPath = newRoutePath.toLowerCase();

  if (!sanitizedPath.startsWith("/")) {
    return "Route must start with /";
  }

  if (/^\/{2,}/g.test(sanitizedPath)) {
    return "Route cannot contain multiple leading slashes";
  }

  if (sanitizedPath.includes("//")) {
    return "Route cannot contain consecutive slashes";
  }

  if (!ALLOWED_CHARACTERS.test(sanitizedPath)) {
    const unsupportedCharacters = sanitizedPath.replaceAll(
      DISALLOWED_CHARACTER_MATCHER,
      "",
    );
    const characterSet = new Set(unsupportedCharacters);
    if (characterSet.size > 0) {
      const formatter = new Intl.ListFormat("en", {
        style: "long",
        type: "conjunction",
      });
      return `Unsupported character${
        characterSet.size > 1 ? "s" : ""
      }: ${formatter.format(characterSet)} cannot be included in route`;
    }
  }

  const disallowedPrefix = DISALLOWED_ROUTE_PREFIXES.find((prefix) =>
    sanitizedPath.startsWith(`/${prefix}`),
  );
  if (disallowedPrefix) {
    return "Route cannot start with sb_tab or applications";
  }

  const dynamicSegments = extractDynamicSegments(sanitizedPath);
  if (new Set(dynamicSegments).size < dynamicSegments.length) {
    return "Route parameters must be unique";
  }

  // brute force way to check if there is a duplicate route,
  // because we dont care about the name of the dynamic segment, just
  // that there is one, i.e. /users/:userID and /users/:id are the same, but /user/:id and /user/admin are different
  let routeToCheck = sanitizedPath.replace(/\/:[^/]+/g, "/:param");
  if (routeToCheck.endsWith("/")) {
    routeToCheck = routeToCheck.slice(0, -1);
  }
  const existingRoute = existingRoutes.find((r) => {
    let path = r.path.toLowerCase();
    if (r.path.endsWith("/")) {
      path = r.path.slice(0, -1);
    }

    return path.replace(/\/:[^/]+/g, "/:param") === routeToCheck;
  });

  if (existingRoute) {
    if ("pageId" in existingRoute) {
      const page = pages.find((p) => p.pageId === existingRoute.pageId);
      if (page) {
        return `Route is already in use on ${page?.pageName}`;
      }
    }

    return "Route is already in use";
  }

  return null;
}

export function findParentPageIfPossible(
  routes: RouteDef[],
  path: string,
  pageId: string,
): string | null {
  const samePageRoutesWithPrefix = routes.filter(
    (r) =>
      "pageId" in r &&
      r.pageId === pageId &&
      path.startsWith(r.path) &&
      path !== r.path,
  );
  return samePageRoutesWithPrefix.at(-1)?.path ?? null;
}

export function getUserFacingPath(currentURL: string): string | null {
  const currentPath = new URL(currentURL).pathname;
  const matchedRoutes = matchRoutes(applicationRoutes, currentPath);

  if (!matchedRoutes) {
    return null;
  }

  // Matches are sorted from left to right, so this is the most specific match
  const matchedRoute = matchedRoutes.at(-1);
  let path = matchedRoute?.params["*"];
  if (path == null) {
    return null;
  }

  if (!path.startsWith("/")) {
    path = `/${path}`;
  }
  return path;
}

export function getMatchingRoute(
  currentURL: string,
  routes: RouteDef[],
): {
  params?: Record<string, unknown>;
  routeDefinition?: RouteDef;
  path?: string;
} {
  const path = getUserFacingPath(currentURL);
  if (!path) return {};

  const innerMatch = matchRoutes(routes, path)?.at(-1);
  if (!innerMatch) return {};

  return {
    routeDefinition: innerMatch.route,
    params: innerMatch.params,
    path: innerMatch.pathnameBase,
  };
}

export function extractDynamicSegments(routePath: string) {
  try {
    const matched = Array.from(routePath.matchAll(/\/(:[^/]+)/g)) || [];
    return matched.map((match) => match[1].substring(1));
  } catch {
    return [];
  }
}

export const getFinalRoutePath = generatePath;
