import { Input } from "antd";
import React, { useCallback } from "react";
import { ReactComponent as CheckedIcon } from "assets/icons/common/checkmark.svg";
import { ReactComponent as CopyIcon } from "assets/icons/common/copy.svg";
import { ReactComponent as EyeIcon } from "assets/icons/common/eye.svg";
import { ReactComponent as HideIcon } from "assets/icons/common/hide.svg";
import { useCopyToClipboard } from "hooks/ui/useCopyToClipboard";
import { colors } from "styles/colors";
import { styleAsClass } from "styles/styleAsClass";

const PasswordIconsWrapper = styleAsClass`
  display: flex;
  flex-direction: row;
`;

const InputWrapperClass = styleAsClass`
  .ant-input-affix-wrapper {
    border: 1px solid ${colors.GREY_100} !important;
    box-shadow: none !important;
  }
  input {
    caret-color: transparent;
    font-size: 12px !important;
  }
`;

const IconWrapper = styleAsClass`
  display: flex;
  align-items: center;
  cursor: pointer;
  color: rgba(0, 0, 0, 0.45);
  &:hover {
    color: rgba(0, 0, 0, 0.85);
  }
  svg.stroke-outline path {
    stroke: rgba(0, 0, 0, 0.45);
  }
  &:hover svg.stroke-outline path {
    stroke: rgba(0, 0, 0, 0.85);
  }
  &:not(:last-child) {
    margin-right: 3px;
  }
  svg.check-icon path {
    stroke: ${colors.ACCENT_GREEN_600} !important;
  }
`;

export const SecretInput = ({
  token,
  disableHide,
}: {
  token: string;
  disableHide?: boolean;
}) => {
  const [tokenVisible, setTokenVisible] = React.useState(disableHide);
  const [tokenCopied, setTokenCopied] = useCopyToClipboard(2000);
  const stopPropagation = useCallback((e: React.MouseEvent) => {
    e.stopPropagation();
  }, []);

  const onCopyToken = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation();
      setTokenCopied(token);
    },
    [setTokenCopied, token],
  );

  if (disableHide) {
    return (
      <div className={InputWrapperClass}>
        <Input
          value={token}
          suffix={
            <div>
              <div className={PasswordIconsWrapper}>
                {!tokenCopied ? (
                  <div className={IconWrapper} onClick={onCopyToken}>
                    <CopyIcon />
                  </div>
                ) : (
                  <div className={IconWrapper} onClick={stopPropagation}>
                    <CheckedIcon className="check-icon" />
                  </div>
                )}
              </div>
            </div>
          }
        />
      </div>
    );
  }

  return (
    <div className={InputWrapperClass}>
      <Input.Password
        className={InputWrapperClass}
        value={token}
        iconRender={(visible) => (
          <div>
            <div className={PasswordIconsWrapper}>
              {!tokenVisible ? (
                <div
                  className={IconWrapper}
                  onClick={() => setTokenVisible(true)}
                >
                  <EyeIcon />
                </div>
              ) : (
                <div
                  className={IconWrapper}
                  onClick={() => setTokenVisible(false)}
                >
                  <HideIcon className="stroke-outline" />
                </div>
              )}
              {!tokenCopied ? (
                <div className={IconWrapper} onClick={onCopyToken}>
                  <CopyIcon />
                </div>
              ) : (
                <div className={IconWrapper} onClick={stopPropagation}>
                  <CheckedIcon className="check-icon" />
                </div>
              )}
            </div>
          </div>
        )}
      />
    </div>
  );
};
