import { CheckOutlined, PlusOutlined } from "@ant-design/icons";
import {
  Observability,
  GetSinksAllResponseDto,
  ObservabilityVendorType,
  SUPERBLOCKS_ORG_NAME,
} from "@superblocksteam/shared";
import { Col, Row } from "antd";
import React, {
  ReactElement,
  useEffect,
  useCallback,
  useMemo,
  useState,
} from "react";
import { useSelector } from "react-redux";
import { useTheme } from "styled-components";
import { Layout, MainWrapper } from "components/app";
import { HeaderWrapper } from "components/ui/Page";
import { MANAGE_OBSERVABILITY } from "constants/rbac";
import { useAuthorizationCheck } from "hooks/ui/rbac/useAuthorizationCheck";
import Header from "pages/components/Header";
import { PageNav } from "pages/components/PageNav";
import { OBSERVABILITY_TITLE, PageWrapper } from "pages/components/PageWrapper";
import { Heading3 } from "styles/Typography";
import { styleAsClass } from "styles/styleAsClass";
import { selectOnlyOrganization } from "../../store/slices/organizations";
import { getObservabilitySinks } from "../Permissions/client";
import ObservabilityCard from "./ObservabilityCard";
import { Sink } from "./sink";

const SectionHeader = styleAsClass`
  margin-top: 10px;
  margin-bottom: 10px;
`;

export default function ObservabilityComponent(): ReactElement {
  const [connected, setConnected] = useState<Sink<any>[]>([]);
  const currentOrg = useSelector(selectOnlyOrganization);
  const theme = useTheme();
  const [canManageObservability] = useAuthorizationCheck([
    MANAGE_OBSERVABILITY,
  ]);

  const [editEnabled, editDisabledText] = useMemo(() => {
    if (!canManageObservability) {
      return [
        false,
        "Please contact your organization administrator to modify the observability sink.",
      ];
    }
    return [true];
  }, [canManageObservability]);

  const [createEnabled, createDisabledText] = useMemo(() => {
    if (!canManageObservability) {
      return [
        false,
        "Please contact your organization administrator to modify the observability sink.",
      ];
    }
    if (connected.length) {
      return [
        false,
        "Please remove all connected observability integrations before adding a new one.",
      ];
    }
    return [true];
  }, [canManageObservability, connected]);

  const [connectedSinkTypes, setConnectedSinkTypes] = useState<
    Set<ObservabilityVendorType>
  >(new Set([]));

  const init = useCallback(() => {
    (async () => {
      const getSinksAllResponseDto: GetSinksAllResponseDto =
        await getObservabilitySinks();
      const sinks = getSinksAllResponseDto.observabilities.map(
        (observability: Observability) => Sink.fromObservability(observability),
      );
      setConnected(sinks);
      const observabilityVendorTypes = new Set(sinks.map((sink) => sink.type));
      setConnectedSinkTypes(observabilityVendorTypes);
    })();
  }, []);

  useEffect(() => {
    init();
  }, [init]);

  return (
    <PageWrapper pageName={OBSERVABILITY_TITLE}>
      <Layout Header={<Header />} Sider={<PageNav />}>
        <MainWrapper data-test="integration-home">
          <div className={HeaderWrapper}>
            <div className="page-header-title"> {OBSERVABILITY_TITLE} </div>
          </div>
          {connected && connected.length > 0 && (
            <>
              <div className={`${Heading3} ${SectionHeader}`}>Connected</div>
              <Row data-test="connected-integrations" gutter={[16, 16]}>
                {connected.map((configuredSink: Sink<any>) => (
                  <Col key={configuredSink.type}>
                    <ObservabilityCard
                      sink={configuredSink}
                      enabled={editEnabled}
                      disableToolTip={editDisabledText}
                      newSink={false}
                      buttonText={"Connected"}
                      buttonIcon={
                        <CheckOutlined
                          style={{ color: theme.colors.LIGHT_GREEN }}
                        />
                      }
                      onChange={() => {
                        init();
                      }}
                    />
                  </Col>
                ))}
              </Row>
            </>
          )}
          <>
            <div className={`${Heading3} ${SectionHeader}`}>Available</div>
            <Row gutter={[16, 16]}>
              {Object.values(Sink.BUILDERS)
                .map((sinkBuilder) => sinkBuilder())
                .filter((sink) => !connectedSinkTypes.has(sink.type))
                .filter(
                  (sink) =>
                    sink.supported || currentOrg.name === SUPERBLOCKS_ORG_NAME,
                )
                .map((sink) => {
                  return (
                    <Col key={sink.type}>
                      <ObservabilityCard
                        sink={sink}
                        enabled={createEnabled}
                        disableToolTip={createDisabledText}
                        newSink={true}
                        buttonText={"Add"}
                        buttonIcon={
                          <PlusOutlined
                            style={{ color: theme.colors.LIGHT_GREEN }}
                          />
                        }
                        onChange={() => {
                          init();
                        }}
                      />
                    </Col>
                  );
                })}
            </Row>
          </>
        </MainWrapper>
      </Layout>
    </PageWrapper>
  );
}
