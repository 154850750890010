import {
  Agent,
  UIErrorType,
  OBS_TAG_ENTITY_ID,
  OBS_TAG_ENTITY_NAME,
  OBS_TAG_ENTITY_TYPE,
  RouteDef,
  WidgetTypes,
  ApplicationScope,
  containsBindingsAnywhere,
} from "@superblocksteam/shared";
import copy from "copy-to-clipboard";
import downloadjs from "downloadjs";
import _, { isArray, isString } from "lodash";
import * as log from "loglevel";
import {
  all,
  call,
  put,
  select,
  takeEvery,
  getContext,
  race,
  take,
  cancelled,
} from "redux-saga/effects";
import { getEditorBasePath } from "hooks/store/useGetEditorPath";
import { setProfile } from "legacy/actions/controlActions";
import {
  restartEvaluation,
  stopEvaluation,
} from "legacy/actions/evaluationActions";
import {
  resetChildrenMetaProperty,
  resetWidgetMetaProperty,
  setMetaProp,
} from "legacy/actions/metaActions";
import { updateAppStore, updateDataUrl } from "legacy/actions/pageActions";
import {
  runEventHandlers,
  runEventHandlersError,
  runEventHandlersSuccess,
} from "legacy/actions/widgetActions";
import { Toaster } from "legacy/components/ads/Toast";
import { Variant } from "legacy/components/ads/common";
import {
  EventType,
  ExecuteActionPayloadEvent,
  isValidStepDef,
  TriggerStepType,
  MultiStepDef,
} from "legacy/constants/ActionConstants";
import { ApiInfo } from "legacy/constants/ApiConstants";
import { getAppStoreName } from "legacy/constants/AppConstants";
import { ALL_PROPERTIES_PROP } from "legacy/constants/EventTriggerPropertiesConstants";
import {
  Page,
  PageListPayload,
  ReduxAction,
  ReduxActionErrorTypes,
  ReduxActionTypes,
} from "legacy/constants/ReduxActionConstants";
import {
  GetPredefinedFunctionPayload,
  PredefinedFunctionDescription,
} from "legacy/constants/TriggerConstants";
import { WIDGET_TYPE_VALIDATION_ERROR } from "legacy/constants/messages";
import {
  convertToQueryParams,
  getApplicationEmbedURL,
  getApplicationPreviewURL,
  getApplicationDeployedURL,
  EditorRoute,
} from "legacy/constants/routes";
import { DataTreeEntity } from "legacy/entities/DataTree/dataTreeFactory";
import { Profiles } from "legacy/reducers/entityReducers/appReducer";
import { FlattenedWidgetProps } from "legacy/reducers/entityReducers/canvasWidgetsReducer";
import { APP_MODE } from "legacy/reducers/types";
import {
  getAppMode,
  getAppProfilesInCurrentMode,
} from "legacy/selectors/applicationSelectors";
import {
  getCurrentApplicationId,
  getCurrentPageId,
  getPageList,
  getWidgetParentIds,
} from "legacy/selectors/editorSelectors";
import {
  getAppStoreState,
  getCanvasWidgets,
} from "legacy/selectors/entitiesSelector";
import {
  getCurrentRoute,
  getRoutesList,
} from "legacy/selectors/routeSelectors";
import {
  getApiInfoById,
  getEmittedEmbedEvents,
  getV2ApiAppInfoById,
  getWidgetByName,
} from "legacy/selectors/sagaSelectors";
import { getModalDropdownList } from "legacy/selectors/widgetSelectors";
import AnalyticsUtil from "legacy/utils/AnalyticsUtil";
import { getType, Types } from "legacy/utils/TypeHelpers";
import { createRunEventHandlersPayload } from "legacy/utils/actions";

import localStorage from "legacy/utils/localStorage";
import { getSystemQueryParams } from "legacy/utils/queryParams";
import {
  getPropertiesToReset,
  shouldResetChildren,
  getPropertiesToSet,
  getValueMapper,
  getValidationType,
} from "legacy/widgets/eventHandlerPanel";
import { VALIDATORS } from "legacy/workers/validators/validations";
import { selectActiveAgents } from "store/slices/agents";
import {
  ApiV1,
  ApiV1ExecutionResponseDto,
  clearResponseV1Api,
  executeV1ApiSaga,
  selectV1ApiById,
} from "store/slices/apisShared";
import { logoutApisV3 } from "store/slices/apisShared/client-auth";
import { executeApiUnionWithViewMode } from "store/slices/apisShared/executeApiUnionWithViewMode";
import { selectControlFlowEnabledDynamic } from "store/slices/apisShared/selectors";
import {
  BackendTypes,
  selectV2ApiById,
  clearResponseV2Api,
  executeV2ApiSaga,
  selectV2ApiRunCancelledById,
} from "store/slices/apisV2";
import { getV2ApiName } from "store/slices/apisV2/utils/getApiIdAndName";
import { getEventById } from "store/slices/application/events/selectors";
import {
  resetStateVar,
  setPropertyStateVar,
  setStateVarValue,
} from "store/slices/application/stateVarsMeta/stateVarsMetaActions";
import {
  startTimer,
  stopTimer,
  toggleTimer,
} from "store/slices/application/timers/timerActions";
import { Flag } from "store/slices/featureFlags";
import { selectFlagById } from "store/slices/featureFlags/selectors";
import { selectOnlyOrganization } from "store/slices/organizations";
import {
  addNewPromise,
  clearMultiCallback,
  resolveById,
} from "store/utils/resolveIdSingleton";
import UITracing from "tracing/UITracing";
import { eventNameToSpanName } from "tracing/utils";
import { ENTITY_TYPE } from "utils/dataTree/constants";
import {
  OutgoingMessage,
  isOnEmbedRoute,
  sendEventToEmbedder,
} from "utils/embed/messages";
import logger from "utils/logger";
import { buildUrlForRoute, getTargetNavigationURL } from "utils/navigation";
import { stringToJS } from "utils/string";
import {
  NotificationPosition,
  sendErrorUINotification,
  sendInfoUINotification,
  sendSuccessUINotification,
  sendWarningUINotification,
} from "../../utils/notification";
import {
  evaluateActionBindings,
  evaluateDynamicTrigger,
} from "./TriggerSagaHelpers";
import {
  waitForNextEvaluationToComplete,
  waitForEvaluationToComplete,
  waitForFirstEvaluation,
} from "./waitForEvaluation";

const MAX_CALL_DEPTH = 10;

function* evaluateStringWithBindings(
  strWithBindings: string,
  scope: ApplicationScope,
  additionalNamedArguments?: Record<string, unknown>,
  spanId?: string,
) {
  if (!containsBindingsAnywhere(strWithBindings)) {
    // `strWithBindings` does not contain any dynamic bindings, no need to invoke the evaluator
    return strWithBindings;
  } else {
    yield call(waitForEvaluationToComplete);
    const skipUnescape: boolean = yield select(
      selectFlagById,
      Flag.SKIP_UNESCAPE_IN_EVALUATOR,
    ); // TODO. To be removed: EG-17106
    const jsExpr = stringToJS(strWithBindings, skipUnescape);
    const evaluationResult: [string] = yield call(
      evaluateActionBindings,
      [jsExpr],
      scope,
      undefined,
      additionalNamedArguments,
      spanId,
    );
    return evaluationResult[0];
  }
}

function* evaluateParamsString(
  strWithBindings: string | undefined,
  scope: ApplicationScope,
  errorMessage: string,
  additionalNamedArguments?: Record<string, unknown>,
  spanId?: string,
): Generator<any, Record<string, string>, any> {
  if (!strWithBindings) {
    return {};
  }
  try {
    const objectString = yield call(
      evaluateStringWithBindings,
      strWithBindings,
      scope,
      additionalNamedArguments,
      spanId,
    );
    return JSON.parse(objectString);
  } catch (e) {
    throw new Error(errorMessage);
  }
}

function* evaluateObjectWithBindings(
  object: Record<string, string> | undefined,
  scope: ApplicationScope,
  additionalNamedArguments?: Record<string, unknown>,
  spanId?: string,
) {
  if (!object) {
    return {};
  }

  const objectEntries = Object.entries(object ?? {});

  const skipUnescape: boolean = yield select(
    selectFlagById,
    Flag.SKIP_UNESCAPE_IN_EVALUATOR,
  ); // TODO. To be removed: EG-17106

  const needsEvaluation = objectEntries.some(([, value]) =>
    containsBindingsAnywhere(value),
  );
  if (needsEvaluation) {
    yield call(waitForEvaluationToComplete);
  }

  if (objectEntries.length > 0) {
    const values = objectEntries.map(([, value]) =>
      stringToJS(value, skipUnescape),
    );
    const evaluationResult: string[] = yield call(
      evaluateActionBindings,
      values,
      scope,
      undefined,
      additionalNamedArguments,
      spanId,
    );
    object = Object.fromEntries(
      objectEntries.map(([key], index) => [key, evaluationResult[index]]),
    );
  }

  return object;
}

enum NavigationTargetType {
  SAME_WINDOW = "SAME_WINDOW",
  NEW_WINDOW = "NEW_WINDOW",
}

const isValidUrlScheme = (url: string): boolean => {
  return (
    // Standard http call
    url.startsWith("http://") ||
    // Secure http call
    url.startsWith("https://") ||
    // Mail url to directly open email app prefilled
    url.startsWith("mailto:") ||
    // Tel url to directly open phone app prefilled
    url.startsWith("tel:")
  );
};

function* navigateActionSaga(
  action: GetPredefinedFunctionPayload<"NAVIGATE_TO">,
  event: ExecuteActionPayloadEvent,
): Generator<any, any, any> {
  const pageList: PageListPayload = yield select(getPageList);
  const applicationId: string | undefined = yield select(
    getCurrentApplicationId,
  );
  const {
    pageNameOrUrl,
    params,
    target = NavigationTargetType.SAME_WINDOW,
  } = action;

  const currentSystemParams = getSystemQueryParams();
  const joinedParams = { ...currentSystemParams, ...(params ?? {}) };

  const page = _.find(
    pageList,
    (page: Page) => page.pageName === pageNameOrUrl,
  );
  const navigate = yield getContext("navigate");
  const appMode: APP_MODE | undefined = yield select(getAppMode);
  if (page) {
    AnalyticsUtil.logEvent("NAVIGATE", {
      pageName: pageNameOrUrl,
      pageParams: params,
    });
    let path: string;
    if (isOnEmbedRoute()) {
      path = getApplicationEmbedURL(applicationId, "", joinedParams);
    } else if (appMode === APP_MODE.EDIT) {
      path = getEditorBasePath(EditorRoute.EditApplication, {
        applicationId: applicationId ?? "",
      });
    } else if (appMode === APP_MODE.PREVIEW) {
      path = getApplicationPreviewURL(applicationId, "", joinedParams);
    } else {
      path = getApplicationDeployedURL(applicationId, "", joinedParams);
    }
    if (target === NavigationTargetType.SAME_WINDOW) {
      navigate(path, {
        replace: action.replaceHistory ?? false,
        search: convertToQueryParams(joinedParams),
      });
      yield put(updateDataUrl()); // skip immediate render
    } else if (target === NavigationTargetType.NEW_WINDOW) {
      window.open(path, "_blank");
    }
    if (event.callbackId) resolveById(event.callbackId, { success: true });
  } else if (pageNameOrUrl.startsWith("/application")) {
    // we need to support the legacy behavior for /application/:appId, which
    // is currently used by customers, so we do not treat these as relative URLs
    const url = new URL(pageNameOrUrl, window.location.origin);
    if (target === NavigationTargetType.SAME_WINDOW) {
      if (action.replaceHistory) {
        window.history.replaceState({}, "", url);
      } else {
        window.location.assign(url);
      }
    } else {
      window.open(url, "_blank");
    }
  } else if (pageNameOrUrl.startsWith("/")) {
    const url = getTargetNavigationURL({
      targetPath: pageNameOrUrl,
      applicationId: applicationId ?? "",
      appMode: appMode,
      params: joinedParams,
    });

    // Treat destinations that start with a slash as a relative URL.
    if (target === NavigationTargetType.SAME_WINDOW) {
      navigate(url.pathname + url.search, {
        replace: action.replaceHistory ?? false,
      });
      yield put(updateDataUrl()); // skip immediate render
    } else if (target === NavigationTargetType.NEW_WINDOW) {
      // It was explicitly requested to open in a new tab.
      window.open(url, "_blank");
    }
  } else {
    AnalyticsUtil.logEvent("NAVIGATE", {
      navUrl: pageNameOrUrl,
    });
    let url = pageNameOrUrl + convertToQueryParams(params);
    // Add a default protocol if it doesn't exist.
    if (!isValidUrlScheme(url)) {
      url = "https://" + url;
    }
    if (target === NavigationTargetType.SAME_WINDOW) {
      window.location.assign(url);
    } else if (target === NavigationTargetType.NEW_WINDOW) {
      window.open(url, "_blank");
    }
    if (event.callbackId) resolveById(event.callbackId, { success: true });
  }
}

function* navigateToRouteSaga(
  action: GetPredefinedFunctionPayload<"NAVIGATE_TO_ROUTE">,
  event: ExecuteActionPayloadEvent,
): Generator<any, any, any> {
  const appMode: APP_MODE | undefined = yield select(getAppMode);
  const navigate = yield getContext("navigate");
  const applicationId: ReturnType<typeof getCurrentApplicationId> =
    yield select(getCurrentApplicationId);
  const currentPageId: ReturnType<typeof getCurrentPageId> = yield select(
    getCurrentPageId,
  );
  const routes: RouteDef[] = yield select(getRoutesList);

  const route = routes.find((r) => r.id === action.routeId);

  if (!route) {
    if (appMode === APP_MODE.EDIT) {
      const errorSuffix = action.entityName ? ` on ${action.entityName}` : "";
      let errorMessage = `You are attempting to navigate to an unknown route. Please update your Navigate to Page action configuration${errorSuffix}.`;
      if (action.routePathDescriptor) {
        errorMessage = `You are attempting to navigate to an unknown route with the path ${action.routePathDescriptor}. Please update your Navigate to Page action configuration${errorSuffix}.`;
      }
      sendErrorUINotification({
        message: errorMessage,
        isUISystemInitiated: true,
      });
    }
    if (event.callbackId) resolveById(event.callbackId, { success: false });
    return;
  }

  const result = buildUrlForRoute(route, action, {
    applicationId,
    appMode,
    currentPageId,
  });

  if (!result.ok) {
    if (appMode === APP_MODE.EDIT) {
      const errorSuffix = action.entityName ? ` on ${action.entityName}` : "";
      let errorMessage = `Missing route parameters detected. Please pass values for all route parameters in the Navigate to Page action${errorSuffix}.`;
      if (result.error.missingParams.length) {
        const listFormatter = new Intl.ListFormat("en", {
          style: "long",
          type: "conjunction",
        });
        errorMessage = `Missing route parameters detected. Please pass ${
          result.error.missingParams.length > 1 ? "values" : "a value"
        } for ${listFormatter.format(
          result.error.missingParams.map((param) => `"${param}"`),
        )} in the Navigate to Page action${errorSuffix}.`;
      }

      sendErrorUINotification({
        message: errorMessage,
        isUISystemInitiated: true,
      });
      if (event.callbackId) resolveById(event.callbackId, { success: false });
    }
    return;
  }

  const url = result.value;

  if (action.target === NavigationTargetType.SAME_WINDOW) {
    navigate(url.pathname + url.search);
    yield put(updateDataUrl()); // skip immediate render
  } else {
    // It was explicitly requested to open in a new tab.
    window.open(url, "_blank");
  }

  if (event.callbackId) resolveById(event.callbackId, { success: true });
}

function* updateQueryParamsSaga(
  action: GetPredefinedFunctionPayload<"SET_QUERY_PARAMS">,
  event: ExecuteActionPayloadEvent,
): Generator<any, any, any> {
  const appMode: ReturnType<typeof getAppMode> = yield select(getAppMode);
  const navigate = yield getContext("navigate");
  const applicationId: ReturnType<typeof getCurrentApplicationId> =
    yield select(getCurrentApplicationId);
  const currentPageId: ReturnType<typeof getCurrentPageId> = yield select(
    getCurrentPageId,
  );
  const currentRoute: ReturnType<typeof getCurrentRoute> = yield select(
    getCurrentRoute,
  );

  if (!currentRoute) {
    console.error(
      "Not currently on a route, cannot update query parameters. This shouldn't happen.",
    );
    if (event.callbackId) resolveById(event.callbackId, { success: false });
    return;
  }

  const { queryParams, keepQueryParams } = action;

  const result = buildUrlForRoute(
    currentRoute.routeDef,
    {
      queryParams,
      routeParams: {
        ...currentRoute.params,
      },
      keepQueryParams,
    },
    {
      applicationId,
      appMode,
      currentPageId,
    },
  );

  if (result.ok) {
    const url = result.value;
    // we use the state portion of the history API because we need to inform the sync listener to not
    // trigger page load events, which is done in sagas, and we cannot rely on the URL because you
    // might be navigating to the same page intentionally (and therefore will trigger page load stuff)
    navigate(url.pathname + url.search, {
      state: {
        skipPageLoad: true,
      },
    });
    yield put(updateDataUrl());
  }

  if (event.callbackId) resolveById(event.callbackId, { success: true });
}

function* storeValueLocally(
  action: GetPredefinedFunctionPayload<"STORE_VALUE">,
  event: ExecuteActionPayloadEvent,
): Generator<any, any, any> {
  try {
    const appId: string | undefined = yield select(getCurrentApplicationId);

    if (!appId) {
      logger.error("Cannot store value locally, appId is not present");

      if (event.callbackId) resolveById(event.callbackId, { success: false });

      return;
    }

    if (action.temporary) {
      const storeObj = yield select(getAppStoreState);
      const tempStoreObj = { ...storeObj };
      tempStoreObj[action.key] = action.value;
      yield put(updateAppStore(tempStoreObj));
    } else {
      const appStoreName = getAppStoreName(appId);
      const existingStore = localStorage.getItem(appStoreName) || "{}";
      const storeObj = JSON.parse(existingStore);
      storeObj[action.key] = action.value;
      const storeString = JSON.stringify(storeObj);

      localStorage.setItem(appStoreName, storeString);
      yield put(updateAppStore(storeObj));
    }

    if (event.callbackId) resolveById(event.callbackId, { success: true });
  } catch (err) {
    if (event.callbackId) resolveById(event.callbackId, { success: false });
  }
}

async function downloadSaga(
  action: GetPredefinedFunctionPayload<"DOWNLOAD">,
  event: ExecuteActionPayloadEvent,
) {
  try {
    const { data, name, type } = action;
    const dataType = getType(data);
    if (dataType === Types.ARRAY || dataType === Types.OBJECT) {
      const jsonString = JSON.stringify(data, null, 2);
      downloadjs(jsonString, name, type);
    } else {
      downloadjs(data, name, type);
    }
    if (event.callbackId) resolveById(event.callbackId, { success: true });
  } catch (err) {
    Toaster.show({
      text: `Download failed. ${err}`,
      variant: Variant.danger,
    });
    if (event.callbackId) resolveById(event.callbackId, { success: false });
  }
}

function* copySaga(
  payload: GetPredefinedFunctionPayload<"COPY_TO_CLIPBOARD">,
  event: ExecuteActionPayloadEvent,
): Generator<any, any, any> {
  const result = copy(payload.data, payload.options);
  if (event.callbackId && result)
    resolveById(event.callbackId, { success: result });
  yield;
}

function* logoutSaga(
  event: ExecuteActionPayloadEvent,
): Generator<any, any, any> {
  const organization = yield select(selectOnlyOrganization);

  const profiles = yield select(getAppProfilesInCurrentMode);
  const profile = profiles?.selected;

  const agents: Agent[] = yield select(
    selectActiveAgents(organization.agentType, profile?.key ?? ""),
  );
  const controlFlowEnabled: boolean = yield select(
    selectControlFlowEnabledDynamic,
  );

  const result: Awaited<ReturnType<typeof logoutApisV3>> = yield call(
    logoutApisV3,
    controlFlowEnabled
      ? {
          orchestrator: true,
          agents,
          organization,
        }
      : {
          orchestrator: false,
          agents,
          organization,
        },
  );

  if (event.callbackId && result.success)
    resolveById(event.callbackId, { success: result.success });
  yield;
}

function* setComponentPropertySaga(
  payload: GetPredefinedFunctionPayload<"SET_COMPONENT_PROPERTY">,
  event: ExecuteActionPayloadEvent,
): Generator<any, any, any> {
  try {
    if (typeof payload.widgetName !== "string") {
      throw Error(
        `Widget name needs to be a string: ${JSON.stringify(
          payload.widgetName,
        )}.`,
      );
    }
    if (typeof payload.propertyName !== "string") {
      throw Error(
        `Property name needs to be a string: ${JSON.stringify(
          payload.propertyName,
        )}.`,
      );
    }
    const widget: FlattenedWidgetProps | undefined = yield select(
      getWidgetByName,
      payload.widgetName,
    );
    if (!widget) {
      throw Error(`Widget ${payload.widgetName} not found.`);
    }
    const propertiesToSet = getPropertiesToSet(
      widget.type,
      payload.propertyName,
    );
    if (!propertiesToSet || !Array.isArray(propertiesToSet)) {
      throw Error(
        `Property ${JSON.stringify(payload.propertyName)} cannot be set.`,
      );
    }
    let value = payload.propertyValue;
    const { validationType, expectedType } = getValidationType(
      widget.type,
      payload.propertyName,
    );
    if (validationType) {
      const { isValid, parsed, message } = VALIDATORS[validationType](
        payload.propertyValue,
        widget as DataTreeEntity,
      );
      if (isValid) {
        value = parsed;
      } else if (message === WIDGET_TYPE_VALIDATION_ERROR && expectedType) {
        // Provide a more specific error message when possible
        throw Error(
          `Expected type ${expectedType} for ${payload.widgetName}.${
            payload.propertyName
          } but received ${typeof value} instead.`,
        );
      } else {
        throw Error(message);
      }
    }
    const valueMapper = getValueMapper(widget.type, payload.propertyName);
    if (typeof valueMapper === "function") {
      value = valueMapper(widget, value);
    }

    const actions = propertiesToSet.map((property) =>
      setMetaProp(widget.widgetId, property as string, value),
    );
    actions.push(setMetaProp(widget.widgetId, "isTouched", true));
    yield all(actions.map((action) => put(action)));
    UITracing.addEvent(
      payload.spanId,
      `Widget ${payload.widgetName}'s ${payload.propertyName} set to ${value}.`,
    );
    if (event.callbackId) resolveById(event.callbackId, { success: true });
  } catch (e: any) {
    UITracing.addEvent(payload.spanId, e.message, UIErrorType.VALIDATION_ERROR);
    yield put({
      type: ReduxActionErrorTypes.SET_COMPONENT_PROPERTY_ERROR,
      payload: { error: e, crash: false, show: true },
    });
    if (event.callbackId) resolveById(event.callbackId, { success: false });
  }
}

function* resetWidgetMetaByNameRecursiveSaga(
  payload: GetPredefinedFunctionPayload<"RESET_WIDGET_META_RECURSIVE_BY_NAME">,
  event: ExecuteActionPayloadEvent,
): Generator<any, any, any> {
  const fail = (msg: string) => {
    console.error(msg);
    UITracing.addEvent(payload.spanId, msg, UIErrorType.VALIDATION_ERROR);
    if (event.callbackId) resolveById(event.callbackId, { success: false });
  };
  if (typeof payload.widgetName !== "string") {
    return fail(
      `Widget name needs to be a string: ${JSON.stringify(
        payload.widgetName,
      )}.`,
    );
  }

  if (
    payload.propertyNames &&
    (!isArray(payload.propertyNames) ||
      payload.propertyNames.some((name) => typeof name !== "string"))
  ) {
    return fail(
      `Property names needs to be a string array: ${JSON.stringify(
        payload.propertyNames,
      )}.`,
    );
  }

  const widget: FlattenedWidgetProps | undefined = yield select(
    getWidgetByName,
    payload.widgetName,
  );
  if (!widget) {
    return fail(`Widget ${payload.widgetName} not found.`);
  }

  yield put(
    resetWidgetMetaProperty(widget.widgetId, {
      propertyNames: payload.propertyNames,
    }),
  );

  if (payload.propertyNames?.length) {
    UITracing.addEvent(
      payload.spanId,
      `${payload.widgetName}'s ${payload.propertyNames.join(", ")} reset.`,
    );
  }

  if (payload.resetChildren) {
    yield put(resetChildrenMetaProperty(widget.widgetId, payload.spanId));
  }
  if (event.callbackId) resolveById(event.callbackId, { success: true });
}

function* showAlertSaga(
  payload: GetPredefinedFunctionPayload<"SHOW_ALERT">,
  event: ExecuteActionPayloadEvent,
): Generator<any, any, any> {
  if (typeof payload.message !== "string") {
    console.error("Alert message needs to be a string");
    UITracing.addEvent(
      payload.spanId,
      "Alert message needs to be a string.",
      UIErrorType.VALIDATION_ERROR,
    );
    if (event.callbackId) resolveById(event.callbackId, { success: false });
    return;
  }
  if (payload.alertDuration && typeof payload.alertDuration !== "number") {
    console.error("Alert duration needs to be a number");
    UITracing.addEvent(
      payload.spanId,
      "Alert duration needs to be a number.",
      UIErrorType.VALIDATION_ERROR,
    );
    if (event.callbackId) resolveById(event.callbackId, { success: false });
    return;
  }
  let notify;
  switch (payload.style) {
    case "info":
      notify = sendInfoUINotification;
      break;
    case "success":
      notify = sendSuccessUINotification;
      break;
    case "warning":
      notify = sendWarningUINotification;
      break;
    case "error":
      notify = sendErrorUINotification;
      break;
  }
  if (payload.style && !notify) {
    const err = `Alert type "${payload.style}" needs to be one of: info, success, warning, or error).`;
    console.error(err);
    UITracing.addEvent(payload.spanId, err, UIErrorType.VALIDATION_ERROR);
    if (event.callbackId) resolveById(event.callbackId, { success: false });
    return;
  }
  // We should use maxCount when antd is upgraded to a version that supports it.
  notify = notify || sendInfoUINotification;
  notify({
    message: payload.message,
    duration: payload.alertDuration ?? 4,
    placement:
      (payload.alertPosition as NotificationPosition) ??
      NotificationPosition.bottomRight,
    isUISystemInitiated: false,
  });

  if (event.callbackId) resolveById(event.callbackId, { success: true });
  yield;
}

function* executeActionSaga(
  apiAction: GetPredefinedFunctionPayload<"RUN_ACTION">,
  event: ExecuteActionPayloadEvent,
  version: "v1" | "v2",
): Generator<any, any, any> {
  // onSuccess/onFailure does not work as expected - TODO implement
  const { actionId, onSuccess, onError } = apiAction;
  // TODO(API_SCOPE): allow different scopes
  const scope = ApplicationScope.PAGE;
  const commitId =
    new URLSearchParams(window.location.search).get("commitId") ?? undefined;
  try {
    const res:
      | ApiV1ExecutionResponseDto
      | BackendTypes.ApiV2ExecutionResponse
      | undefined = yield call(
      executeApiUnionWithViewMode,
      actionId,
      event.type,
      version,
      event.callStack,
      event.spanId,
      commitId,
    );

    if (
      version === "v1" &&
      (res as ApiV1ExecutionResponseDto)?.context?.error
    ) {
      throw new Error((res as ApiV1ExecutionResponseDto)?.context?.error);
    } else if (
      version === "v2" &&
      (res as BackendTypes.ApiV2ExecutionResponse)?.errors?.length
    ) {
      const error = (res as BackendTypes.ApiV2ExecutionResponse)?.errors?.[0]
        ?.message;
      throw new Error(error);
    } else if (res && "systemError" in res) {
      // This is a system error, not an execution error
      // don't run onSuccess OR onError
      UITracing.addEvent(event.spanId, `System error: ${res.systemError}`);
      if (event.callbackId)
        resolveById(event.callbackId, { success: false, systemError: true });
      return;
    } else {
      UITracing.addEvent(event.spanId, `Successfully ran api.`);
    }
    if (res && onSuccess) {
      yield put(
        runEventHandlers({
          steps: [
            {
              id: "0",
              type: TriggerStepType.RUN_JS,
              code: onSuccess,
            },
          ],
          event: {
            ...event,
            type: EventType.ON_SUCCESS,
          },
          currentScope: scope,
          propertyPath: eventNameToSpanName(EventType.ON_SUCCESS),
          additionalNamedArguments: {},
        }),
      );
    }
    if (event.callbackId) resolveById(event.callbackId, { success: true });

    return;
  } catch (error: any) {
    // TODO:
    // Some actual internal errors like "There are no workers in the fleet that can execute this step." is not catched, because res?.context?.error is not defined.
    // We can add context.error for such errors, and then console.error here if responseMeta.status is 500
    // Before that, we need to set syntax error's responseMeta.status to 400 (currently 500)
    logger.info(`Action execution failed. ${error?.message}`);
    UITracing.addEvent(
      event.spanId,
      `Action execution failed. ${error?.message}.`,
      UIErrorType.EXECUTION_ERROR,
    );
    if (onError) {
      yield put(
        runEventHandlers({
          currentScope: scope,
          steps: [
            {
              id: "0",
              type: TriggerStepType.RUN_JS,
              code: onError,
            },
          ],
          event: {
            ...event,
            type: EventType.ON_ERROR,
          },
          propertyPath: eventNameToSpanName(EventType.ON_ERROR),
          additionalNamedArguments: {},
        }),
      );
    } else {
      if (event.callbackId) resolveById(event.callbackId, { success: false });
    }
  }
}

function* runPredefinedFunction({
  trigger,
  currentScope,
  event,
  spanId,
  additionalAttributes,
}: {
  trigger: PredefinedFunctionDescription;
  currentScope: ApplicationScope;
  event: ExecuteActionPayloadEvent;
  spanId?: string;
  additionalAttributes?: Record<string, undefined | string>;
}): Generator<any, any, any> {
  trigger.payload.spanId = spanId;
  try {
    let causesEval = false;
    switch (trigger.type) {
      // This causes an eval but handles the wait internally
      case "RUN_ACTION": {
        const apiV1: ApiV1 | undefined = yield select(
          selectV1ApiById,
          trigger.payload.actionId,
        );
        const apiV2: ReturnType<typeof selectV2ApiById> = yield select(
          selectV2ApiById,
          trigger.payload.actionId,
        );
        if (!apiV1 && !apiV2) {
          break;
        }
        const childSpanId = UITracing.startSpan("runAPI", spanId, {
          ...additionalAttributes,
          [OBS_TAG_ENTITY_ID]: trigger.payload.actionId,
          [OBS_TAG_ENTITY_NAME]: apiV1
            ? apiV1?.name
            : apiV2 && getV2ApiName(apiV2),
          [OBS_TAG_ENTITY_TYPE]: ENTITY_TYPE.API,
        });

        yield call(
          executeActionSaga,
          { ...trigger.payload, spanId: childSpanId },
          { ...event, spanId: childSpanId },
          apiV1 ? "v1" : "v2",
        );
        UITracing.endSpan(childSpanId);
        break;
      }
      case "CANCEL_API_ACTION": {
        const apiV1: ApiV1 | undefined = yield select(
          selectV1ApiById,
          trigger.payload.actionId,
        );
        const apiV2: ReturnType<typeof selectV2ApiById> = yield select(
          selectV2ApiById,
          trigger.payload.actionId,
        );
        if (!apiV1 && !apiV2) {
          break;
        }
        if (apiV1) {
          yield put({
            type: executeV1ApiSaga.cancel.type,
            payload: { apiId: trigger.payload.actionId },
          });
        } else {
          yield put({
            type: executeV2ApiSaga.cancel.type,
            payload: { apiId: trigger.payload.actionId },
          });
        }
        let apiResponseInfo: ApiInfo | undefined;
        if (apiV1) {
          apiResponseInfo = yield select(
            getApiInfoById,
            trigger.payload.actionId,
          );
        } else {
          apiResponseInfo = yield select(
            getV2ApiAppInfoById,
            trigger.payload.actionId,
          );
        }

        let apiName;
        if (apiV1) apiName = apiV1.name;
        if (apiV2) apiName = getV2ApiName(apiV2);

        if (
          apiResponseInfo &&
          apiResponseInfo.onCancel &&
          apiResponseInfo.onCancel.length > 0
        ) {
          yield put(
            runEventHandlers(
              createRunEventHandlersPayload({
                steps: apiResponseInfo.onCancel,
                type: EventType.ON_CANCEL,
                currentScope,
                entityName: apiName ?? "<API>",
                callStack: event.callStack,
                spanId,
              }),
            ),
          );
        }
        if (event.callbackId) resolveById(event.callbackId, { success: true });
        break;
      }
      case "CLEAR_RESPONSE_ACTION": {
        causesEval = true;
        yield put(clearResponseV1Api.create({ id: trigger.payload.actionId }));
        yield put(clearResponseV2Api.create({ id: trigger.payload.actionId }));
        if (event.callbackId) resolveById(event.callbackId, { success: true });
        break;
      }
      case "NAVIGATE_TO":
        yield call(navigateActionSaga, trigger.payload, event);
        break;
      case "NAVIGATE_TO_ROUTE": {
        yield call(navigateToRouteSaga, trigger.payload, event);
        break;
      }
      case "SET_QUERY_PARAMS": {
        yield call(updateQueryParamsSaga, trigger.payload, event);
        break;
      }
      case "SHOW_ALERT":
        yield call(showAlertSaga, trigger.payload, event);
        break;
      case "SHOW_MODAL":
        // This does causeEval but currently for speed we don't wait
        yield put(trigger);
        if (event.callbackId) resolveById(event.callbackId, { success: true });
        break;
      case "CLOSE_MODAL":
        // This does causeEval but currently for speed we don't wait
        yield put(trigger);
        if (event.callbackId) resolveById(event.callbackId, { success: true });
        break;
      case "STORE_VALUE":
        causesEval = true;
        yield call(storeValueLocally, trigger.payload, event);
        break;
      case "DOWNLOAD":
        yield call(downloadSaga, trigger.payload, event);
        break;
      case "COPY_TO_CLIPBOARD":
        yield call(copySaga, trigger.payload, event);
        break;
      case "RESET_WIDGET_META_RECURSIVE_BY_NAME":
        causesEval = true;
        yield call(resetWidgetMetaByNameRecursiveSaga, trigger.payload, event);
        break;
      case "SET_COMPONENT_PROPERTY":
        causesEval = true;
        yield call(setComponentPropertySaga, trigger.payload, event);
        break;
      case "DELETE_USER_SESSION_TOKENS":
        yield call(logoutSaga, event);
        break;
      case resetStateVar.type:
      case setPropertyStateVar.type:
      case setStateVarValue.type:
      case startTimer.type:
      case stopTimer.type:
      case toggleTimer.type:
      case "SET_PROFILE": {
        causesEval = true;
        yield put(trigger);
        if (event.callbackId) resolveById(event.callbackId, { success: true });
        break;
      }
      case "TRIGGER_EVENT": {
        if (event.callStack && event.callStack.length > MAX_CALL_DEPTH) {
          const msg =
            "Infinite loop detected. Breaking out of the loop, max depth: " +
            MAX_CALL_DEPTH +
            ". Triggers executed: " +
            [...event.callStack]
              .reverse()
              .map((item) => item.propertyPath)
              .join(" ➜ ");
          console.warn(msg);
          UITracing.addEvent(spanId, msg, UIErrorType.VALIDATION_ERROR);
          sendWarningUINotification({
            message: msg,
          });
          return;
        }
        const {
          onTrigger,
          evaluatedArguments,
          event: { name, scope },
        } = trigger.payload;
        yield put(
          runEventHandlers({
            currentScope: scope ?? ApplicationScope.PAGE,
            steps: onTrigger,
            event: {
              ...event,
              callStack: [
                ...(event.callStack ?? []),
                {
                  propertyPath: name,
                  type: EventType.ON_TRIGGER_EVENT,
                },
              ],
              type: EventType.ON_CUSTOM_EVENT,
            },

            additionalNamedArguments: {
              currentEvent: evaluatedArguments,
            },
          }),
        );
        break;
      }
      default:
        log.error("Trigger type unknown", (trigger as any).type);
    }
    if (causesEval) {
      yield call(waitForNextEvaluationToComplete);
    }
  } catch (e) {
    if (event.callbackId) resolveById(event.callbackId, { success: false });
  } finally {
    if (yield cancelled()) {
      if (trigger.type === "RUN_ACTION") {
        yield call(runPredefinedFunction, {
          trigger: {
            ...trigger,
            type: "CANCEL_API_ACTION",
          },
          currentScope,
          event,
          spanId,
          additionalAttributes,
        });
      }
    }
  }
}

export function* runEventHandlersSaga(
  action: ReturnType<typeof runEventHandlers>,
): Generator<any, any, any> {
  const {
    currentScope,
    steps = [],
    additionalEventAttributes = {},
  } = action.payload;
  const hasValidStep = steps.some((s) => isValidStepDef(s));
  if (!hasValidStep) {
    yield put(runEventHandlersSuccess(action.payload.event.callbackId));
    if (action.payload.event.callbackId)
      resolveById(action.payload.event.callbackId, { success: true });
    return;
  }

  const eventHandlerSpanId = UITracing.startSpan(
    additionalEventAttributes.pathToDisplay
      ? additionalEventAttributes.pathToDisplay
      : eventNameToSpanName(action.payload.event.type),
    action.payload.event.spanId,
    additionalEventAttributes,
  );

  // Ensure that the event handler is run after ALL the actions are executed
  let hasExecutionError = false;
  let hasSystemError = false;
  const originalCallbackId = action.payload.event.callbackId;
  const replacementCallbackId = addNewPromise((result: any) => {
    if (!result.success) {
      if (result.systemError) {
        hasSystemError = true;
      } else {
        hasExecutionError = true;
      }
    }
  }, true);
  const event: ExecuteActionPayloadEvent = {
    ...action.payload.event,
    callbackId: replacementCallbackId,
  };

  try {
    yield race({
      cancelled: take(restartEvaluation.type),
      stopped: take(stopEvaluation.type),
      success: call(function* (): Generator<any, any, any> {
        yield call(waitForFirstEvaluation);

        const controllableComponents: ReturnType<typeof getModalDropdownList> =
          yield select(getModalDropdownList);

        // Detect case where children of grid are missing "currentCell" binding
        if (
          !action.payload.additionalNamedArguments?.currentCell &&
          additionalEventAttributes[OBS_TAG_ENTITY_TYPE] ===
            ENTITY_TYPE.WIDGET &&
          additionalEventAttributes[OBS_TAG_ENTITY_ID]
        ) {
          const widgetId = additionalEventAttributes[OBS_TAG_ENTITY_ID];
          const parentWidgets: ReturnType<typeof getWidgetParentIds> =
            yield select(getWidgetParentIds, widgetId);
          if (parentWidgets) {
            const canvasWidgets: ReturnType<typeof getCanvasWidgets> =
              yield select(getCanvasWidgets);
            const possibleGrid = parentWidgets?.find(
              (id: string) =>
                canvasWidgets[id].type === WidgetTypes.GRID_WIDGET,
            );
            if (possibleGrid) {
              const gridName = canvasWidgets[possibleGrid].widgetName;
              const currentCell: unknown = yield call(
                evaluateStringWithBindings,
                `{{${gridName}.selectedCell}}`,
                currentScope,
                action.payload.additionalNamedArguments,
              );
              action.payload.additionalNamedArguments ??= {};
              action.payload.additionalNamedArguments.currentCell = currentCell;
            }
          }
        }

        for (const [index, step] of steps.entries()) {
          if (!isValidStepDef(step)) continue;
          let triggerList: PredefinedFunctionDescription[] = [];
          const spanId = UITracing.startSpan(
            step.type,
            eventHandlerSpanId,
            additionalEventAttributes,
          );
          const stepPath = action.payload.propertyPath + "[" + index + "]";
          switch (step.type) {
            case TriggerStepType.RUN_APIS: {
              if (!("apiNames" in step) || step.apiNames.length === 0) {
                UITracing.addEvent(
                  spanId,
                  "No APIs to run.",
                  UIErrorType.VALIDATION_ERROR,
                );
                break;
              }
              // TODO: This updates the dataTree in the worker, which is always correct but slower
              // than needed.
              // The ideal solution is to only update if necessary, such as by having a "dirty" flag
              const triggers: PredefinedFunctionDescription[] | undefined =
                yield call(evaluateDynamicTrigger, {
                  // Example: API1.run(); API2.run()
                  dynamicTrigger: step.apiNames
                    .map((name) => `${name}.run()`)
                    .join("; "),
                  propertyPath: stepPath,
                  currentScope,
                  additionalNamedArguments:
                    action.payload.additionalNamedArguments,
                  spanId,
                });
              UITracing.addEvent(spanId, `Run ${step.apiNames.join(", ")}.`);
              if (triggers && triggers.length > 0) {
                triggerList = triggers;
              }
              break;
            }
            case TriggerStepType.CANCEL_APIS: {
              if (!("apiNames" in step) || step.apiNames.length === 0) {
                UITracing.addEvent(
                  spanId,
                  "No APIs to cancel.",
                  UIErrorType.VALIDATION_ERROR,
                );
                break;
              }
              const triggers: PredefinedFunctionDescription[] | undefined =
                yield call(evaluateDynamicTrigger, {
                  // Example: API1.run(); API2.run()
                  dynamicTrigger: step.apiNames
                    .map((name) => `${name}.cancel()`)
                    .join("; "),
                  propertyPath: stepPath,
                  currentScope,
                  additionalNamedArguments:
                    action.payload.additionalNamedArguments,
                  spanId,
                });
              UITracing.addEvent(spanId, `Cancel ${step.apiNames.join(", ")}.`);
              if (triggers && triggers.length > 0) {
                triggerList = triggers;
              }
              break;
            }
            case TriggerStepType.RUN_JS: {
              const triggers: PredefinedFunctionDescription[] | undefined =
                yield call(evaluateDynamicTrigger, {
                  dynamicTrigger: step.code ?? "",
                  propertyPath: stepPath,
                  currentScope,
                  additionalNamedArguments:
                    action.payload.additionalNamedArguments,
                  spanId,
                });
              if (triggers && triggers.length > 0) {
                triggerList = triggers;
              }
              break;
            }
            case TriggerStepType.NAVIGATE_TO: {
              if (!step.url || !step.url?.trim()) {
                UITracing.addEvent(
                  spanId,
                  "Navigation URL is not set.",
                  UIErrorType.VALIDATION_ERROR,
                );
                break;
              }

              const newWindow = step.newWindow ?? true;

              let url: string = yield call(
                evaluateStringWithBindings,
                step.url,
                currentScope,
                action.payload.additionalNamedArguments,
                spanId,
              );
              if (!isString(url)) {
                UITracing.addEvent(
                  spanId,
                  "Navigation URL is not a string.",
                  UIErrorType.VALIDATION_ERROR,
                );
                break;
              }
              url = url.trim();
              if (!url) {
                UITracing.addEvent(
                  spanId,
                  "Navigation URL is not set.",
                  UIErrorType.VALIDATION_ERROR,
                );
                break;
              }
              UITracing.addEvent(
                spanId,
                `Navigate to ${url} in ${newWindow ? "new" : "same"} window.`,
              );
              triggerList = [
                {
                  type: "NAVIGATE_TO",
                  payload: {
                    pageNameOrUrl: url,
                    params: {},
                    replaceHistory: step.replaceHistory,
                    target: newWindow
                      ? NavigationTargetType.NEW_WINDOW
                      : NavigationTargetType.SAME_WINDOW,
                  },
                },
              ];
              break;
            }
            case TriggerStepType.NAVIGATE_TO_APP: {
              if (!step.targetApp || !step.targetApp?.url) {
                UITracing.addEvent(
                  spanId,
                  "Target application not set.",
                  UIErrorType.VALIDATION_ERROR,
                );
                break;
              }

              let queryParams = {};

              if (step.queryParams) {
                try {
                  const queryParamsStringEvaled: string = yield call(
                    evaluateStringWithBindings,
                    step.queryParams,
                    currentScope,
                    action.payload.additionalNamedArguments,
                    spanId,
                  );
                  queryParams = JSON.parse(queryParamsStringEvaled);
                } catch (e) {
                  const errorMsg = `Query Params are invalid when redirecting to application: ${step.targetApp.name}. Check if the binding in {{}} exists or is wrapped in "" (e.g., "{{Input1.value}}")`;
                  UITracing.addEvent(
                    spanId,
                    errorMsg,
                    UIErrorType.VALIDATION_ERROR,
                  );
                  sendErrorUINotification({
                    message: errorMsg,
                  });
                  console.log(e);
                }
              }
              UITracing.addEvent(
                spanId,
                `Navigate to application ${
                  step.targetApp?.name
                } with query parameters ${queryParams} in ${
                  step.newWindowApp ? "new" : "same"
                } window.`,
              );
              triggerList.push({
                type: "NAVIGATE_TO",
                payload: {
                  pageNameOrUrl: isOnEmbedRoute()
                    ? new URL(
                        `embed${step.targetApp.url}`,
                        window.location.origin,
                      ).toString()
                    : new URL(
                        step.targetApp?.url,
                        window.location.origin,
                      ).toString(),
                  params: queryParams,
                  target: step.newWindowApp
                    ? NavigationTargetType.NEW_WINDOW
                    : NavigationTargetType.SAME_WINDOW,
                },
              });

              break;
            }
            case TriggerStepType.NAVIGATE_TO_ROUTE: {
              if (!step.routeId) {
                UITracing.addEvent(
                  spanId,
                  "Route ID is not set.",
                  UIErrorType.VALIDATION_ERROR,
                );
                break;
              }

              // There is a built-in wait in these fns if we need to evaluate any params
              const queryErrorMessage = `Query parameters are invalid when navigating to the page. Check if the binding in {{}} exists or is wrapped in "" (e.g., "{{Input1.value}}")`;
              try {
                const [queryParams, routeParams] = yield all([
                  call(
                    evaluateParamsString,
                    step.queryParams,
                    currentScope,
                    queryErrorMessage,
                    action.payload.additionalNamedArguments,
                    spanId,
                  ),
                  call(
                    evaluateObjectWithBindings,
                    step.routeParams,
                    currentScope,
                    action.payload.additionalNamedArguments,
                    spanId,
                  ),
                ]);

                triggerList = [
                  {
                    type: "NAVIGATE_TO_ROUTE",
                    payload: {
                      routeId: step.routeId,
                      routeParams,
                      queryParams,
                      routePathDescriptor: step.routePathDescriptor,
                      keepQueryParams: step.keepQueryParams,
                      target: step.newWindow
                        ? NavigationTargetType.NEW_WINDOW
                        : NavigationTargetType.SAME_WINDOW,
                      entityName:
                        action.payload.additionalEventAttributes?.[
                          "entity-name"
                        ],
                    },
                  },
                ];
              } catch (e) {
                const error = e as Error;
                UITracing.addEvent(
                  spanId,
                  error.message,
                  UIErrorType.VALIDATION_ERROR,
                );
                sendErrorUINotification({
                  message: error.message,
                });
                console.log(e);
              }

              break;
            }
            case TriggerStepType.SET_QUERY_PARAMS: {
              const queryErrorMessage = `Query parameters are invalid when navigating to the page. Check if the binding in {{}} exists or is wrapped in "" (e.g., "{{Input1.value}}")`;
              const queryParams = yield call(
                evaluateParamsString,
                step.queryParams,
                currentScope,
                queryErrorMessage,
                action.payload.additionalNamedArguments,
                spanId,
              );

              UITracing.addEvent(spanId, "Update URL params");
              triggerList.push({
                type: "SET_QUERY_PARAMS",
                payload: {
                  queryParams,
                  keepQueryParams: step.keepQueryParams ?? true,
                },
              });
              break;
            }
            case TriggerStepType.CONTROL_SLIDEOUT: {
              if (step.direction === "close") {
                UITracing.addEvent(spanId, `Close slideout ${step.name}.`);
                triggerList = [
                  { type: "CLOSE_MODAL", payload: { modalName: step.name } },
                ];
              } else {
                const existingSlideout = controllableComponents?.find(
                  (component) => component.label === step.name,
                );
                if (existingSlideout) {
                  UITracing.addEvent(spanId, `Open slideout ${step.name}.`);
                  triggerList = [
                    {
                      type: "SHOW_MODAL",
                      payload: { modalId: existingSlideout.id },
                    },
                  ];
                } else {
                  UITracing.addEvent(
                    spanId,
                    `Could not find slideout ${step.name}.`,
                    UIErrorType.VALIDATION_ERROR,
                  );
                }
              }
              break;
            }
            case TriggerStepType.CONTROL_MODAL: {
              if (step.direction === "close") {
                UITracing.addEvent(spanId, `Close modal ${step.name}.`);
                triggerList = [
                  { type: "CLOSE_MODAL", payload: { modalName: step.name } },
                ];
              } else {
                const existingModal = controllableComponents?.find(
                  (component) => component.label === step.name,
                );
                if (existingModal) {
                  UITracing.addEvent(spanId, `Open modal ${step.name}.`);
                  triggerList = [
                    {
                      type: "SHOW_MODAL",
                      payload: { modalId: existingModal.id },
                    },
                  ];
                } else {
                  UITracing.addEvent(
                    spanId,
                    `Could not find modal ${step.name}.`,
                    UIErrorType.VALIDATION_ERROR,
                  );
                }
              }
              break;
            }
            case TriggerStepType.CONTROL_TIMER: {
              const actions = {
                start: startTimer,
                stop: stopTimer,
                toggle: toggleTimer,
              };
              UITracing.addEvent(
                spanId,
                `${
                  step.command
                    ? step.command.charAt(0).toUpperCase() +
                      step.command.substring(1)
                    : "Start"
                } timer ${step.name}`,
              );
              triggerList = [
                actions[step.command ?? "start"](
                  step.state?.scope ?? ApplicationScope.PAGE,
                  step.name,
                ),
              ];
              break;
            }
            case TriggerStepType.RESET_COMPONENT: {
              if (step.widget) {
                UITracing.addEvent(
                  spanId,
                  step.propertyName === ALL_PROPERTIES_PROP
                    ? `Reset all properties of ${step.widget.name}.`
                    : `Reset ${step.widget.name}'s ${step.propertyName}.`,
                );
                triggerList.push({
                  type: "RESET_WIDGET_META_RECURSIVE_BY_NAME",
                  payload: {
                    widgetName: step.widget?.name,
                    propertyNames:
                      step.propertyName === ALL_PROPERTIES_PROP
                        ? undefined
                        : getPropertiesToReset(step.widget, step.propertyName),
                    resetChildren: shouldResetChildren(
                      step.widget,
                      step.propertyName,
                    ),
                  },
                });
              } else {
                UITracing.addEvent(
                  spanId,
                  "Widget not specified.",
                  UIErrorType.VALIDATION_ERROR,
                );
              }
              break;
            }
            case TriggerStepType.SET_COMPONENT_PROPERTY: {
              if (step.widget) {
                const evaluatedValue: string = yield call(
                  evaluateStringWithBindings,
                  step.propertyValue,
                  currentScope,
                  action.payload.additionalNamedArguments,
                  spanId,
                );
                UITracing.addEvent(
                  spanId,
                  `Set ${step.widget.name}'s ${step.propertyName} to ${evaluatedValue}.`,
                );
                triggerList.push({
                  type: "SET_COMPONENT_PROPERTY",
                  payload: {
                    widgetName: step.widget?.name,
                    propertyName: step.propertyName,
                    propertyValue: evaluatedValue,
                  },
                });
              } else {
                UITracing.addEvent(
                  spanId,
                  "Widget not specified.",
                  UIErrorType.VALIDATION_ERROR,
                );
              }
              break;
            }
            case TriggerStepType.SHOW_ALERT: {
              let alertDuration = step.alertDuration;
              if (!step.message || !step.message?.trim()) {
                UITracing.addEvent(
                  spanId,
                  "Alert message is not set.",
                  UIErrorType.VALIDATION_ERROR,
                );
                break;
              }
              if (typeof alertDuration === "string") {
                try {
                  alertDuration = parseFloat(alertDuration);
                } catch (err) {
                  UITracing.addEvent(
                    spanId,
                    "Alert duration is not a valid number.",
                    UIErrorType.VALIDATION_ERROR,
                  );
                  break;
                }
              }
              // Alert duration needs to be specifically set to 0 to make it
              // sticky. If it is not set, it will take the default value of 4.5
              if (alertDuration !== 0 && !alertDuration) {
                alertDuration = 4;
              }
              if (typeof alertDuration !== "number") {
                UITracing.addEvent(
                  spanId,
                  "Alert duration is not a valid number.",
                  UIErrorType.VALIDATION_ERROR,
                );
                break;
              }
              // Finally, if the alert duration is set to a negative value, it is a sticky alert
              if (alertDuration < 0) {
                alertDuration = 1000000;
              }
              const msg = yield call(
                evaluateStringWithBindings,
                step.message,
                currentScope,
                action.payload.additionalNamedArguments,
                spanId,
              );
              const message = isString(msg) ? msg : JSON.stringify(msg);
              UITracing.addEvent(
                spanId,
                `Show ${
                  step.style ?? "success"
                } alert with message "${message}".`,
              );
              triggerList = [
                {
                  type: "SHOW_ALERT",
                  payload: {
                    message: message ?? "",
                    style: step.style ?? "success",
                    alertDuration: alertDuration,
                    alertPosition:
                      step.alertPosition ?? NotificationPosition.bottomRight,
                  },
                },
              ];
              break;
            }
            case TriggerStepType.SET_STATE_VAR: {
              if (step.state) {
                const evalValue: string = yield call(
                  evaluateStringWithBindings,
                  step.value,
                  currentScope,
                  action.payload.additionalNamedArguments,
                  spanId,
                );
                UITracing.addEvent(
                  spanId,
                  `Set variable ${step.state.id} to ${evalValue}.`,
                );
                triggerList = [
                  setStateVarValue(
                    step.state.scope ?? currentScope,
                    step.state.id,
                    evalValue,
                  ),
                ];
              } else {
                UITracing.addEvent(
                  spanId,
                  "variable is undefined.",
                  UIErrorType.VALIDATION_ERROR,
                );
              }
              break;
            }
            case TriggerStepType.RESET_STATE_VAR: {
              if (step.state) {
                UITracing.addEvent(spanId, `Reset variable ${step.state.id}.`);
                triggerList = [
                  resetStateVar(
                    step.state.scope ?? currentScope,
                    step.state.id,
                  ),
                ];
              } else {
                UITracing.addEvent(
                  spanId,
                  "variable is undefined.",
                  UIErrorType.VALIDATION_ERROR,
                );
              }
              break;
            }
            case TriggerStepType.SET_PROFILE: {
              yield call(waitForEvaluationToComplete);
              const profiles: Profiles = yield select(
                getAppProfilesInCurrentMode,
              );
              let profileId: string;
              if (step.profileAction === "unset") {
                profileId = profiles.default?.id;
              } else {
                profileId = yield call(
                  evaluateStringWithBindings,
                  step.profileId,
                  currentScope,
                  action.payload.additionalNamedArguments,
                  spanId,
                );
                if (
                  !profiles.available.find(
                    (profile) => profile.id === profileId,
                  )
                ) {
                  const mode: APP_MODE = yield select(getAppMode);
                  const errorMessage =
                    mode === APP_MODE.EDIT
                      ? `There was an error with the Configure Profile action. The specified profile ID is not a valid available profile.`
                      : `There was an error configuring the selected profile. Please contact maintainer of this app to report the issue.`;
                  sendErrorUINotification({
                    message: errorMessage,
                  });
                  throw new Error(errorMessage);
                }
              }
              triggerList = [setProfile(profileId)];
              break;
            }
            case TriggerStepType.TRIGGER_EVENT: {
              yield call(waitForEvaluationToComplete);
              const {
                eventPayload = {},
                event: { id },
              } = step;
              const event: ReturnType<typeof getEventById> = yield select(
                getEventById,
                id,
              );
              if (!event) return;
              // evaluate all of the arguments
              const skipUnescape: boolean = yield select(
                selectFlagById,
                Flag.SKIP_UNESCAPE_IN_EVALUATOR,
              ); // TODO. To be removed: EG-17106
              const evaluatedArgumentsArray = yield call(
                evaluateActionBindings,
                Object.values(eventPayload).map((arg) =>
                  stringToJS(arg, skipUnescape),
                ),
                currentScope,
                undefined,
                action.payload.additionalNamedArguments,
                spanId,
                true,
              );
              if (evaluatedArgumentsArray.errors.length) {
                const errorMessage = evaluatedArgumentsArray.errors
                  .map((err: any) => err.message)
                  .join(", ");
                UITracing.addEvent(
                  spanId,
                  `Error evaluating arguments: ${errorMessage}`,
                  UIErrorType.VALIDATION_ERROR,
                );
                sendErrorUINotification({
                  message: `Error evaluating event arguments: ${errorMessage}`,
                });
                break;
              }
              // map arg ids to arg names
              const argIdToNameMap = event.arguments.reduce(
                (acc, arg) => ({ ...acc, [arg.id]: arg.name }),
                {} as Record<string, string>,
              );
              const evaluatedArgumentsObject = Object.keys(eventPayload).reduce(
                (acc, argumentId, index) => {
                  // get the name of the argument from event.arguments
                  const argName = argIdToNameMap[argumentId];
                  if (argName) {
                    acc[argName] = evaluatedArgumentsArray.values[index];
                  }
                  return acc;
                },
                {} as Record<string, string>,
              );
              if (event) {
                triggerList = [
                  {
                    type: "TRIGGER_EVENT",
                    payload: {
                      onTrigger: event.onTrigger,
                      evaluatedArguments: evaluatedArgumentsObject,
                      event,
                    },
                  },
                ];
              }
              break;
            }
            default: {
              const exhaustiveCheck: never = step;
              throw new Error(`Unhandled action case: ${exhaustiveCheck}`);
            }
          }
          // Execute each step completely before starting the next step
          // TODO: wait for evaluation?
          if (triggerList.length === 0) {
            UITracing.endSpan(spanId);
          }
          yield all(
            triggerList.map((trigger: PredefinedFunctionDescription) =>
              call(runPredefinedFunction, {
                trigger,
                event,
                currentScope,
                spanId,
                additionalEventAttributes,
              } as Parameters<typeof runPredefinedFunction>[0]),
            ),
          );

          if (step.type === TriggerStepType.RUN_APIS) {
            yield call(
              runCompletionHandlers,
              step,
              {
                ...action,
                payload: {
                  ...action.payload,
                  event,
                },
              },
              hasExecutionError,
              hasSystemError,
              spanId,
              {
                ...additionalEventAttributes,
                pathToDisplay: undefined,
              },
            );
          }

          // for custom events, check if this event should be emitted to embedding applications
          if (
            triggerList.length > 0 &&
            triggerList[0].type === "TRIGGER_EVENT"
          ) {
            const { payload } = triggerList[0];
            if (payload.event) {
              const emittedEvents: ReturnType<typeof getEmittedEmbedEvents> =
                yield select(getEmittedEmbedEvents);
              if (emittedEvents?.[payload.event.id]) {
                sendEventToEmbedder({
                  type: OutgoingMessage.EMIT_EVENT,
                  data: {
                    eventName: payload.event.name,
                    payload: payload.evaluatedArguments,
                  },
                });
              }
            }
          }
          UITracing.endSpan(spanId);
        }
      }),
    });

    yield put(runEventHandlersSuccess(originalCallbackId));
  } catch (e) {
    console.error(e);
    hasExecutionError = true;
    yield put(runEventHandlersError(originalCallbackId));
  } finally {
    if (originalCallbackId) {
      resolveById(originalCallbackId, {
        success: !hasExecutionError,
        systemError: hasSystemError,
      });
    }
    clearMultiCallback(replacementCallbackId);
    UITracing.endSpan(eventHandlerSpanId);
  }
}

function* runCompletionHandlers(
  step: {
    id: string;
    type: TriggerStepType.RUN_APIS;
    apiNames: string[];
    onSuccess: MultiStepDef;
    onError: MultiStepDef;
  },
  action: ReturnType<typeof runEventHandlers>,
  hasExecutionError: boolean,
  hasSystemError: boolean,
  parentSpanId?: string,
  additionalEventAttributes?: Record<string, string | undefined>,
) {
  if (hasSystemError) return;
  if (step.onSuccess && !hasExecutionError) {
    yield call(runEventHandlersSaga, {
      type: action.type,
      payload: {
        ...action.payload,
        steps: step.onSuccess,
        event: {
          ...action.payload.event,
          type: EventType.ON_API_SUCCESS,
          spanId: parentSpanId,
        },
        propertyPath: `${action.payload.propertyPath}.${eventNameToSpanName(
          EventType.ON_API_SUCCESS,
        )}`,
        additionalEventAttributes,
      },
    });
  } else if (step.onError && hasExecutionError) {
    yield call(runEventHandlersSaga, {
      type: action.type,
      payload: {
        ...action.payload,
        steps: step.onError,
        event: {
          ...action.payload.event,
          type: EventType.ON_API_ERROR,
          spanId: parentSpanId,
        },
        propertyPath: `${action.payload.propertyPath}.${eventNameToSpanName(
          EventType.ON_API_ERROR,
        )}`,
        additionalEventAttributes,
      },
    });
  }
}

function* handleStreamingMessage(
  action: ReduxAction<{
    apiId: string;
    message: any;
    callbackId: string;
    onMessage: ApiInfo["onMessage"];
  }>,
) {
  const { apiId, message, callbackId, onMessage } = action.payload;

  const isCancelled: ReturnType<typeof selectV2ApiRunCancelledById> =
    yield select(selectV2ApiRunCancelledById, apiId);
  if (isCancelled) {
    const apiResponseInfo: ReturnType<typeof getApiInfoById> = yield select(
      getApiInfoById,
      apiId,
    );

    if (apiResponseInfo?.onCancel) {
      const api: ReturnType<typeof selectV2ApiById> = yield select(
        selectV2ApiById,
        apiId,
      );

      yield runEventHandlers(
        createRunEventHandlersPayload({
          steps: apiResponseInfo.onCancel,
          type: EventType.ON_CANCEL,
          // TODO(API_SCOPE): modify when app-scoped apis exist
          currentScope: ApplicationScope.PAGE,
          entityName: api?.name ?? "<API>",
          callbackId,
        }),
      );
    }
  } else {
    yield put(
      runEventHandlers({
        steps: onMessage ?? [],
        additionalNamedArguments: {
          message,
          currentMessage: message, // backward compatibility after we changed currentMessage -> message
        },
        // TODO(API_SCOPE): modify when app-scoped apis exist
        currentScope: ApplicationScope.PAGE,
        event: {
          type: EventType.ON_MESSAGE,
          callbackId,
        },
      }),
    );
  }
}

export function* watchTriggerSagas() {
  yield all([
    takeEvery(runEventHandlers.type, runEventHandlersSaga),
    takeEvery(ReduxActionTypes.HANDLE_STREAM_MESSAGE, handleStreamingMessage),
  ]);
}
