import { createAction } from "@reduxjs/toolkit";
import {
  ApplicationDeveloperPreferences,
  DeveloperPreferencesKeys,
  EditorOpenTabType,
  PagePreferences,
  SharedDeveloperPreferences,
} from "legacy/constants/EditorPreferencesConstants";
import { ApplicationPayload } from "legacy/constants/ReduxActionConstants";
import type { FocusedItem } from "legacy/reducers/uiReducers/editorPreferencesReducer";

type OpenTabPayload = {
  entityId: string;
  tabType: EditorOpenTabType;
  property?: string;
  actionId?: string;
  // if true, we check for the existence of the property before opening the tab
  strict?: boolean;
};

type UpdateEditorPreferencesPayload = {
  applicationId: ApplicationPayload["id"];
  pageId: string;
  updatedPreferences: PagePreferences;
};

export const openEditorTab = createAction<OpenTabPayload>("OPEN_EDITOR_TAB");

export const setEditorHeight = createAction(
  "SET_EDITOR_HEIGHT",
  (height: number) => ({ payload: { height } }),
);

export const closeEditorTab = createAction(
  "CLOSE_EDITOR_TAB",
  (payload: string) => ({
    payload: {
      id: payload,
    },
  }),
);

export const updateEditorPreferences =
  createAction<UpdateEditorPreferencesPayload>("UPDATE_EDITOR_PREFERENCES");

export const updateApplicationSidebarKey = createAction<{
  selectedKey: string | undefined;
  focusedItem?: FocusedItem;
}>("UPDATE_APPLICATION_SIDEBAR_KEY");

export const updateWorkflowSidebarKey = createAction<{
  selectedKey: string | undefined;
}>("UPDATE_WORKFLOW_SIDEBAR_KEY");

type UpdateDeveloperPreferencesPayload =
  | {
      key: DeveloperPreferencesKeys.Application;
      update: Partial<ApplicationDeveloperPreferences>;
    }
  | {
      key: DeveloperPreferencesKeys.Shared;
      update: Partial<SharedDeveloperPreferences>;
    };

export const updateDeveloperPreferences =
  createAction<UpdateDeveloperPreferencesPayload>(
    "UPDATE_DEVELOPER_PREFERENCES",
  );

export const toggleSidebarItem = createAction<{ [name: string]: boolean }>(
  "SIDEBAR_TOGGLE_ITEM",
);

export const editorPreferencesError = createAction<{
  action: string;
  error: string;
}>("EDITOR_PREFERENCES_ERROR");
