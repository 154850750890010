import confirm from "antd/lib/modal/confirm";
import React, { useCallback, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { BlockerFunction, useBlocker } from "react-router-dom";
import { ReactComponent as WarningHaloIcon } from "assets/icons/common/warninghalo.svg";
import { ReactComponent as Success } from "assets/icons/header/system-success.svg";
import { ConfirmModalClass } from "components/ui/Modal";
import { Spinner } from "components/ui/Spinner";
import { ReduxActionTypes } from "legacy/constants/ReduxActionConstants";
import { useAppDispatch } from "store/helpers";
import { colors } from "styles/colors";

const MESSAGE = "Leave without saving?";

export function usePreventNavigation(
  mode: "system" | "app-editor" | "app-deployed",
) {
  if (mode === "system") {
    return (
      saveInProgress: boolean,
      options?: {
        baseUrl?: string;
        showSpinnerWhileActive?: boolean;
      },
    ) => {
      // Safe because mode never changes
      // eslint-disable-next-line react-hooks/rules-of-hooks
      useSystemBlocker(saveInProgress, options);
    };
  } else {
    return (
      saveInProgress: boolean,
      options?: {
        baseUrl?: string;
      },
    ) => {
      // Safe because mode never changes
      // eslint-disable-next-line react-hooks/rules-of-hooks
      useApplicationBlocker(saveInProgress, options);
    };
  }
}

function useSystemBlocker(
  saveInProgress: boolean,
  options?: { baseUrl?: string; showSpinnerWhileActive?: boolean },
) {
  const dispatch = useAppDispatch();
  const isBlocking = useRef<null | ReturnType<typeof confirm>>(null);

  const blockerFn: BlockerFunction = ({ nextLocation }) => {
    if (saveInProgress) {
      const ignore =
        options?.baseUrl && nextLocation.pathname.startsWith(options.baseUrl);
      return ignore ? false : true;
    }
    return false;
  };

  const blocker = useBlocker(blockerFn);

  useEffect(() => {
    if (saveInProgress) {
      const listener = (event: Event) => {
        event.preventDefault();
        event.returnValue = true;

        return MESSAGE;
      };

      window.addEventListener("beforeunload", listener, {
        capture: true,
      });

      dispatch({
        type: ReduxActionTypes.SET_PREVENT_NAVIGATION,
        payload: { preventNavigation: true },
      });

      return () => {
        window.removeEventListener("beforeunload", listener, {
          capture: true,
        });
      };
    } else {
      dispatch({
        type: ReduxActionTypes.SET_PREVENT_NAVIGATION,
        payload: { preventNavigation: false },
      });
    }
  }, [saveInProgress, dispatch, blocker]);

  if (blocker.state === "blocked" && !isBlocking.current) {
    // This needs to happen outside the regular React render tree due because otherwise we'd unmount the page contents
    isBlocking.current = confirm({
      icon: <WarningHaloIcon width={30} height={30} />,
      title: MESSAGE,
      content: options?.showSpinnerWhileActive && (
        <div>
          <div style={{ display: "flex", gap: "10px" }}>
            <Spinner color={colors.GREY_500} /> Waiting for save to complete...
          </div>
        </div>
      ),
      okText: "Leave without saving",
      cancelText: "Stay",
      onOk() {
        blocker.proceed?.();
        isBlocking.current = null;
      },
      onCancel() {
        blocker.reset?.();
        isBlocking.current = null;
      },
      className: `${ConfirmModalClass}`,
    });
  }
  if (isBlocking.current) {
    if (
      options?.showSpinnerWhileActive &&
      (!saveInProgress || blocker.state !== "blocked")
    ) {
      // Clear if save completes in the background
      isBlocking.current.update({
        title: "Your changes were saved in the background",
        content: (
          <div style={{ display: "flex", gap: "10px" }}>
            <Success /> Done saving
          </div>
        ),
        okText: "Proceed",
      });
    } else if (!saveInProgress) {
      isBlocking.current.destroy();
      blocker.proceed?.();
      isBlocking.current = null;
    }
  }
}

function useApplicationBlocker(
  saveInProgress: boolean,
  options?: { baseUrl?: string },
) {
  const dispatch = useDispatch();
  const isBlocking = useRef<null | ReturnType<typeof confirm>>(null);

  const blockerFn: BlockerFunction = useCallback(
    ({ nextLocation }) => {
      if (saveInProgress) {
        const ignore =
          options?.baseUrl && nextLocation.pathname.startsWith(options.baseUrl);
        return ignore ? false : true;
      }
      return false;
    },
    [saveInProgress, options?.baseUrl],
  );

  const blocker = useBlocker(blockerFn);

  useEffect(() => {
    if (saveInProgress) {
      const listener = (event: Event) => {
        event.preventDefault();
        event.returnValue = true;

        return MESSAGE;
      };

      window.addEventListener("beforeunload", listener, {
        capture: true,
      });

      dispatch({
        type: ReduxActionTypes.SET_PREVENT_NAVIGATION,
        payload: { preventNavigation: true },
      });

      return () => {
        window.removeEventListener("beforeunload", listener, {
          capture: true,
        });
      };
    } else {
      dispatch({
        type: ReduxActionTypes.SET_PREVENT_NAVIGATION,
        payload: { preventNavigation: false },
      });
    }
  }, [saveInProgress, dispatch, blocker]);

  if (blocker.state === "blocked" && !isBlocking.current) {
    // This needs to happen outside the regular React render tree due because otherwise we'd unmount the page contents
    isBlocking.current = confirm({
      icon: <WarningHaloIcon width={30} height={30} />,
      title: MESSAGE,
      content: <ModalBody saveInProgress={saveInProgress} />,
      okText: "Leave without saving",
      cancelText: "Stay",
      onOk() {
        blocker.proceed?.();
        isBlocking.current = null;
      },
      onCancel() {
        blocker.reset?.();
        isBlocking.current = null;
      },
      className: `${ConfirmModalClass}`,
    });
  }
  // Automatically clear the blocker when all conditions are met
  if (isBlocking.current && blocker.state === "blocked" && !saveInProgress) {
    isBlocking.current.destroy();
    blocker.proceed();
    isBlocking.current = null;
  }
}

function ModalBody({ saveInProgress }: { saveInProgress: boolean }) {
  return (
    <div style={{ display: "flex", gap: "10px" }}>
      <Spinner color={colors.GREY_500} />{" "}
      {saveInProgress && <div>Waiting for save to complete...</div>}
    </div>
  );
}
