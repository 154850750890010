import { GroupType } from "@superblocksteam/shared";
import { GroupDetail } from "@superblocksteam/shared";
import { Tabs, Tooltip } from "antd";
import React, { useCallback, useEffect, useState } from "react";

import { useSelector } from "react-redux";

import { useNavigate, useParams } from "react-router";
import { Layout, MainWrapper } from "components/app";
import { HeaderWrapper, MainWrapperStyles } from "components/ui/Page";
import { MANAGE_ROLE_MEMBERS } from "constants/rbac";
import { useFeatureFlag } from "hooks/ui";
import { useAuthorizationCheck } from "hooks/ui/rbac/useAuthorizationCheck";
import { getCurrentUser } from "legacy/selectors/usersSelectors";
import Header from "pages/components/Header";
import { PageNav } from "pages/components/PageNav";
import { PageWrapper } from "pages/components/PageWrapper";
import { BreadCrumb } from "pages/components/navigation/BreadCrumb";
import { GROUP_PAGE_URL } from "pages/routes";
import { Flag } from "store/slices/featureFlags";
import { selectOnlyOrganizationId } from "store/slices/organizations";
import { truncate } from "utils/string";
import GroupEntityList from "./GroupEntityList";
import MemberList from "./MemberList";
import { getGroup } from "./client";
import { isValidPageType } from "./constants";

const GroupDetailPage = () => {
  const params = useParams();
  const groupId = params.groupId;
  const pageType = isValidPageType(params.pageType) ? params.pageType : "users";

  const currentUser = useSelector(getCurrentUser);
  const disableV1Permissions = useFeatureFlag(
    Flag.ENABLE_RBAC_ROLE_ASSIGNMENTS,
  );
  const [canEditGroupPermissions] = useAuthorizationCheck([
    {
      ...MANAGE_ROLE_MEMBERS,
      preRbacValue: currentUser?.isAdmin && !disableV1Permissions,
    },
  ]);

  const navigate = useNavigate();
  const orgId = useSelector(selectOnlyOrganizationId);
  const [groupsLoading, setGroupsLoading] = useState(false);

  const [group, setGroup] = useState<GroupDetail>();

  useEffect(() => {
    async function loadGroup() {
      if (!orgId || !groupId) return;
      setGroupsLoading(true);
      const { group } = await getGroup(orgId, groupId);
      if (group) {
        setGroup(group);
      }
      setGroupsLoading(false);
    }

    loadGroup();
  }, [orgId, groupId]);

  const title = truncate(group?.name ?? "");

  const handleTabChange = useCallback(
    (key: string) => {
      navigate({ pathname: GROUP_PAGE_URL(groupId, key) });
    },
    [groupId, navigate],
  );

  return (
    <PageWrapper pageName="Groups">
      <Layout Header={<Header />} Sider={<PageNav />}>
        <MainWrapper className={MainWrapperStyles}>
          <BreadCrumb
            paths={[
              {
                title: "Groups",
                link: "/groups",
              },
              { title },
            ]}
          />

          <div className={HeaderWrapper}>
            <div className="page-header-title"> {group?.name ?? "Group"} </div>
          </div>
          {canEditGroupPermissions ? (
            <Tabs activeKey={pageType} onTabClick={handleTabChange}>
              <Tabs.TabPane
                tab={
                  <span data-test="permissions-groups-users-tab">{`Members`}</span>
                }
                key="users"
              >
                <MemberList groupLoading={groupsLoading} group={group} />
              </Tabs.TabPane>
              <Tabs.TabPane
                tab={
                  <Tooltip
                    title={
                      group?.type === GroupType.ADMIN
                        ? "Admins have full permissions to all Apps, Workflows & Jobs"
                        : undefined
                    }
                  >
                    <span data-test="permissions-groups-entities-tab">{`Apps, Workflows & Jobs`}</span>
                  </Tooltip>
                }
                disabled={group?.type === GroupType.ADMIN}
                key="entities"
              >
                {groupId && group && (
                  <GroupEntityList
                    groupId={groupId}
                    groupName={group.name}
                    isIntegration={false}
                  />
                )}
              </Tabs.TabPane>
              <Tabs.TabPane
                tab={
                  <Tooltip
                    title={
                      group?.type === GroupType.ADMIN
                        ? "Admins have full permissions to all Integrations"
                        : undefined
                    }
                  >
                    <span data-test="permissions-groups-integrations-tab">{`Integrations`}</span>
                  </Tooltip>
                }
                key="integrations"
                disabled={group?.type === GroupType.ADMIN}
              >
                {groupId && group && (
                  <GroupEntityList
                    groupId={groupId}
                    groupName={group.name}
                    isIntegration={true}
                  />
                )}
              </Tabs.TabPane>
            </Tabs>
          ) : (
            <MemberList groupLoading={groupsLoading} group={group} />
          )}
        </MainWrapper>
      </Layout>
    </PageWrapper>
  );
};

export default GroupDetailPage;
