import { ApplicationScope } from "@superblocksteam/shared";
import { createSelector } from "reselect";
import { AppTimer, AppTimerScoped } from "./TimerConstants";
import type { AppState } from "store/types";

const getTimers = (state: AppState) => state.application.timers;

export const getAllTimers = createSelector([getTimers], (timers) => {
  return {
    ...timers[ApplicationScope.PAGE],
    ...timers[ApplicationScope.APP],
  };
});

export const getTimerScope = createSelector(
  [getTimers, (_, id: AppTimer["id"]) => id],
  (timers, id) => {
    if (timers[ApplicationScope.APP][id]) {
      return ApplicationScope.APP;
    }
    return ApplicationScope.PAGE;
  },
);

export const getScopedTimers = createSelector(
  [getTimers, (_, scope: ApplicationScope) => scope],
  (timers, scope) => timers[scope],
);

export const getTimerById = createSelector(
  [getAllTimers, (_timers, id: string) => id],
  (timers, id) => timers[id],
);

export const getTimerByName = createSelector(
  [
    getTimers,
    (timers, name: string) => name,
    (_timers, _id, scope: ApplicationScope) => scope,
  ],
  (timers, name, scope) => {
    const match = Object.values(timers[scope] ?? {}).find(
      (timer) => timer.name === name,
    );
    if (!match) {
      return undefined;
    }
    return { ...match, scope } as AppTimerScoped;
  },
);

export const getAllTimerNames = createSelector([getAllTimers], (timers) =>
  Object.values(timers).map((timer) => timer.name),
);

export const getScopedTimerNames = (state: AppState, scope: ApplicationScope) =>
  Object.values(getScopedTimers(state, scope)).map((timer) => timer.name);
