import { all, call, getContext, takeEvery } from "redux-saga/effects";
import { put, select } from "redux-saga/effects";
import { getEditorBasePath } from "hooks/store/useGetEditorPath";
import {
  closeEditorTab,
  editorPreferencesError,
  openEditorTab,
  updateEditorPreferences,
} from "legacy/actions/editorPreferencesActions";
import { deleteWidgets } from "legacy/actions/widgetActions";
import {
  EditorOpenTabType,
  PagePreferences,
} from "legacy/constants/EditorPreferencesConstants";
import { ReduxAction } from "legacy/constants/ReduxActionConstants";
import { EditorRoute } from "legacy/constants/routes";
import { getCurrentPageId } from "legacy/selectors/editorSelectors";
import { getCurrentRoutePathWithParams } from "legacy/selectors/routeSelectors";
import {
  getPageEditorPreferences,
  getWidget,
} from "legacy/selectors/sagaSelectors";
import { formatPropertyPathWithIndices } from "legacy/utils/BottomPanelTabUtils";
import { getTabId } from "legacy/utils/EditorPreferencesUtils";
import { isTabPropertyValid } from "legacy/utils/EditorPreferencesUtils";
import { deleteV1ApiSaga } from "store/slices/apisShared";
import { deleteV2ApiSaga } from "store/slices/apisV2";
import { getCurrentApplication } from "store/slices/application/selectors";
import { fastClone } from "utils/clone";

const initEditorPreferencesForPage = (
  existingPreferences: PagePreferences | undefined,
) => {
  if (!existingPreferences) {
    existingPreferences = {
      openBottomPanelTabs: [],
    };
  }
  return existingPreferences;
};

function* getPreferences(
  applicationId: string,
  pageId: string,
): Generator<any, PagePreferences, any> {
  let preferences: PagePreferences = yield select(
    getPageEditorPreferences,
    applicationId,
    pageId,
  );
  if (!preferences) {
    preferences = { openBottomPanelTabs: [] };
  } else {
    preferences = fastClone(initEditorPreferencesForPage(preferences));
  }

  return preferences;
}

function* openEditorTabSaga(
  action: ReturnType<typeof openEditorTab>,
): Generator<any, any, any> {
  try {
    const { tabType, entityId, property, strict } = action.payload;

    const application: ReturnType<typeof getCurrentApplication> = yield select(
      getCurrentApplication,
    );
    const pageId = yield select(getCurrentPageId);
    if (!application) throw new Error("No current application");

    // Confirm this tab maps to a real widget property
    if (tabType === EditorOpenTabType.WIDGET) {
      const widget = yield select(getWidget, entityId);
      const path = formatPropertyPathWithIndices(widget, property);

      if (!widget || !property || !isTabPropertyValid(widget, path, strict)) {
        throw new Error("Widget property not found for new tab");
      }
    }
    const currentRoute = yield select(getCurrentRoutePathWithParams);

    // ensure that the tab is visible, even if it was already available in tab bar
    if (tabType === EditorOpenTabType.API) {
      const navigate = yield getContext("navigate");
      const route = action.payload.actionId
        ? EditorRoute.EditApiAction
        : EditorRoute.EditApi;
      const path = getEditorBasePath(route, {
        applicationId: application.id,
        apiId: entityId,
        actionId: action.payload.actionId,
        currentRoute,
      });
      navigate(path + window.location.search);
    }

    const preferences: PagePreferences = yield call(
      getPreferences,
      application.id,
      pageId,
    );

    const openBottomPanelTabsIds = preferences.openBottomPanelTabs.map(
      (tab) => tab.id,
    );

    const newTabId = getTabId({
      ...action.payload,
      widgetProperty: property,
      entityType: tabType,
    });

    // Disallow opening the same type of tab twice
    if (openBottomPanelTabsIds.includes(newTabId)) return;

    // widgetProperty is the name due to backwards compatibility with whats stored in localStorage
    preferences.openBottomPanelTabs = [
      ...preferences.openBottomPanelTabs,
      {
        id: newTabId,
        entityType: tabType,
        entityId,
        ...(property ? { widgetProperty: property } : {}),
      },
    ];

    yield put(
      updateEditorPreferences({
        applicationId: application.id,
        pageId,
        updatedPreferences: preferences,
      }),
    );
  } catch (error: any) {
    yield put(
      editorPreferencesError({ action: action.type, error: error.message }),
    );
  }
}

function* closeEditorTabSaga(
  action: ReturnType<typeof closeEditorTab>,
): Generator<any, any, any> {
  try {
    const tabIdToClose = action.payload.id;

    const application = yield select(getCurrentApplication);
    const pageId = yield select(getCurrentPageId);
    if (!application) {
      throw new Error("No current application");
    }

    const preferences: PagePreferences = yield call(
      getPreferences,
      application.id,
      pageId,
    );

    preferences.openBottomPanelTabs = preferences.openBottomPanelTabs.filter(
      (tab) => {
        return tab.id !== tabIdToClose;
      },
    );

    yield put(
      updateEditorPreferences({
        pageId,
        applicationId: application.id,
        updatedPreferences: preferences,
      }),
    );
  } catch (error: any) {
    yield put(
      editorPreferencesError({ action: action.type, error: error.message }),
    );
  }
}

function* closeEditorTabsForDeletedWidgets(
  action: ReturnType<typeof deleteWidgets>,
): Generator<any, any, any> {
  try {
    const { widgetIds } = action.payload;
    const application = yield select(getCurrentApplication);
    const pageId = yield select(getCurrentPageId);

    const preferences: PagePreferences = yield call(
      getPreferences,
      application.id,
      pageId,
    );

    preferences.openBottomPanelTabs = preferences.openBottomPanelTabs.filter(
      (tab) => {
        return !(
          tab.entityType === "WIDGET" &&
          (widgetIds || []).includes(tab.entityId)
        );
      },
    );

    yield put(
      updateEditorPreferences({
        pageId,
        applicationId: application.id,
        updatedPreferences: preferences,
      }),
    );
  } catch (error: any) {
    yield put(
      editorPreferencesError({ action: action.type, error: error.message }),
    );
  }
}

function* closeEditorTabsForDeletedApi(
  action: ReduxAction<{
    id: string;
  }>,
): Generator<any, any, any> {
  try {
    const { id } = action.payload;
    const application = yield select(getCurrentApplication);
    const pageId = yield select(getCurrentPageId);

    if (!application) {
      // We can delete apis from the homepage, so we don't always have an application
      return;
    }

    const preferences: PagePreferences = yield call(
      getPreferences,
      application.id,
      pageId,
    );

    preferences.openBottomPanelTabs = preferences.openBottomPanelTabs.filter(
      (tab) => {
        return !(tab.entityType === "API" && tab.entityId === id);
      },
    );

    yield put(
      updateEditorPreferences({
        pageId,
        applicationId: application.id,
        updatedPreferences: preferences,
      }),
    );
  } catch (error: any) {
    yield put(
      editorPreferencesError({ action: action.type, error: error.message }),
    );
  }
}

export default function* editorPreferencesSagas() {
  yield all([
    takeEvery(openEditorTab.type, openEditorTabSaga),
    takeEvery(closeEditorTab.type, closeEditorTabSaga),
    takeEvery(deleteWidgets.type, closeEditorTabsForDeletedWidgets),
    takeEvery(deleteV1ApiSaga.success.type, closeEditorTabsForDeletedApi),
    takeEvery(deleteV2ApiSaga.success.type, closeEditorTabsForDeletedApi),
  ]);
}
