import {
  IntegrationKind,
  SupersetIntegrationDto,
} from "@superblocksteam/shared";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useSaga } from "hooks/store";
import { useInterval } from "hooks/ui/useInterval";
import { selectPluginDatasources } from "store/slices/datasources";
import { getSupersetDatasourcesSaga } from "store/slices/datasources/sagas/getSupersetDatasources";
import { EntityType } from "utils/entity";
import { getPluginById } from "utils/integrations";

export enum CreateSteps {
  CHOOSE_ENTITY = "CHOOSE_ENTITY",
  JOB_SCHEDULE = "JOB_SCHEDULE",
  JOB_FIRST_ACTION = "JOB_FIRST_ACTION",
  WORKFLOW_VARIABLES = "WORKFLOW_VARIABLES",
  WORKFLOW_CALL = "WORKFLOW_CALL",
  WORKFLOW_FIRST_ACTION = "WORKFLOW_FIRST_ACTION",
}

type StepShortcuts<T extends CreateSteps> = {
  [K in T]: {
    stepId: T;
    nextStepId: T | undefined;
    prevStepId: T | undefined;
  };
};
export const createStepShortcuts = <T extends CreateSteps>(
  order: ReadonlyArray<T>,
): StepShortcuts<T> => {
  return order.reduce((acc: Partial<StepShortcuts<T>>, step, index) => {
    acc[step] = {
      stepId: step,
      nextStepId: order[index + 1],
      prevStepId: order[index - 1],
    };
    return acc;
  }, {}) as StepShortcuts<T>;
};

export type CommonCardProps = {
  processFieldValues: () => Promise<{
    name: string;
    folder?: string;
  }>;
  resetFields: () => void;
  highlight: boolean;
  setGeneratedEntityType: (type: EntityType) => void;
  organizationId: string;
  setActiveStep: (step: CreateSteps) => void;
  isDisabled: boolean;
};

export type CommonStepProps = {
  activeStep: CreateSteps;
};

export type CreateWorkflowProps = {
  workflowApiId: string;
  setWorkflowApiId: (id: string) => void;
};

export const useCreateWorkflowProps = (): CreateWorkflowProps => {
  const [workflowApiId, setWorkflowApiId] = useState<string>("");
  return {
    workflowApiId,
    setWorkflowApiId,
  };
};

export const useApiDatasources = (
  currentOrganizationId?: string,
  shouldPoll?: boolean,
) => {
  const [datasources, setDatasources] = useState<SupersetIntegrationDto[]>([]);

  const [getDatasources] = useSaga(getSupersetDatasourcesSaga);
  const stateDatasources = useSelector(selectPluginDatasources);
  useEffect(() => {
    if (stateDatasources && Object.keys(stateDatasources).length > 0) {
      setDatasources(Object.values(stateDatasources));
    }
  }, [stateDatasources]);

  const refreshDatasources = useCallback(async () => {
    const datasources = await getDatasources({
      organizationId: currentOrganizationId ?? "",
      kind: IntegrationKind.PLUGIN,
    });
    setDatasources(Object.values(datasources));
  }, [getDatasources, currentOrganizationId]);
  useEffect(() => {
    refreshDatasources();
  }, [refreshDatasources]);
  useInterval(() => {
    if (shouldPoll) {
      refreshDatasources();
    }
  }, 10000);

  const [searchString, setDatasourcesSearch] = useState<null | string>(null);
  const searchDatasources = useMemo(() => {
    if (searchString) {
      return Object.values(datasources).filter((ds) =>
        `${ds.name} ${getPluginById(ds.pluginId)?.name}`
          .toLowerCase()
          .includes(searchString.toLowerCase()),
      );
    }
    return datasources;
  }, [searchString, datasources]);

  return {
    searchDatasources,
    setDatasourcesSearch,
  };
};
