import { KVPair } from "@superblocksteam/shared";
import { ButtonProps as AntButtonProps } from "antd/lib/button/button";
import { isEmpty } from "lodash";
import React, { useContext, useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { ReactComponent as ChevronLeft } from "assets/icons/common/chevron-left.svg";
import { ReactComponent as PlusIcon } from "assets/icons/common/plus.svg";
import { Button } from "components/ui/Button";
import { FullWidthSpace } from "components/ui/Space";
import { DynamicFormItemProps } from "../DynamicFormItem";
import { FormContext } from "../FormContext";
import { FormLabel } from "../FormLabel";
import { FormTooltip } from "../FormTooltip";
import { DynamicFormFieldItem } from "./DynamicFormFieldItem";
import type { ChangeEvent } from "react";

const AddButton = styled(Button)<AntButtonProps>`
  font-size: ${({ theme }) => theme.text.sizes.default};
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-left: 10px;
  gap: 6px;

  &,
  &:hover,
  &:active,
  &:focus {
    color: ${(props) => props.theme.colors.GREY_700};
    border-color: ${(props) => props.theme.colors.GREY_100};
    svg {
      fill: ${(props) => props.theme.colors.GREY_500};
    }
  }
  :hover {
    background-color: ${(props) => props.theme.colors.GREY_25};
  }
  :active {
    background-color: ${(props) => props.theme.colors.GREY_50};
  }
  :disabled,
  :hover:disabled,
  :active:disabled,
  :focus:disabled {
    color: ${(props) => props.theme.colors.GREY_300};
    background-color: ${(props) => props.theme.colors.GREY_50};
    border-color: ${(props) => props.theme.colors.GREY_50};
    cursor: not-allowed !important;
  }
`;

const CollapseIcon = styled(ChevronLeft)<{
  isActive?: boolean;
  stickyHovered?: boolean;
}>`
  transition: transform 0.2s ease-out;
  margin-left: 6px;
  align-items: center;
  justify-content: center;
  transform: ${({ isActive }) => `rotate(${isActive ? -90 : 90}deg)`};

  path {
    stroke: ${({ theme, isActive }) =>
      isActive ? theme.colors.WHITE : theme.colors.GREY_500};
  }
`;

const getAddTextFromLabel = (label: string) => {
  const words = label.split(" ");
  const newWords = words.map((word: string) => {
    if (word.length > 1) {
      // if second letter is uppercase, return the word as the word is expected to be uppercased
      return word[1].toUpperCase() === word[0]
        ? word
        : word[0].toLowerCase() + word.slice(1);
    }
    return word;
  });
  const addText = newWords.join(" ");
  return addText.endsWith("s") ? addText.slice(0, -1) : addText;
};

// TODO(jason) clean this up in a followup PR (merging this one to unblock OpenAPI auth headers)
const CollapseButton = styled(Button)<{
  isActive?: boolean;
}>`
  background-color: ${({ theme }) => theme.colors.ACCENT_BLUE_500};
  height: 20px !important;
  font-size: 12px !important;
  color: ${({ theme }) => theme.colors.WHITE};
  margin-top: 6px;
  box-shadow: none;
  border: 1px solid transparent;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding-left: 8px !important;
  padding-right: 4px !important;

  &.force-blur {
    background-color: ${({ theme, isActive }) =>
      isActive ? theme.colors.ACCENT_BLUE_500 : theme.colors.GREY_50};
    color: ${({ theme, isActive }) =>
      isActive ? theme.colors.WHITE : theme.colors.GREY_500};
    border: 1px solid
      ${({ theme, isActive }) =>
        isActive ? theme.colors.ACCENT_BLUE_500 : theme.colors.GREY_50};
  }

  &.force-hover {
    background-color: ${({ theme, isActive }) =>
      isActive ? theme.colors.ACCENT_BLUE_600 : theme.colors.GREY_100};
    color: ${({ theme, isActive }) =>
      isActive ? theme.colors.WHITE : theme.colors.GREY_500};
    border: 1px solid
      ${({ theme, isActive }) =>
        isActive ? theme.colors.ACCENT_BLUE_600 : theme.colors.GREY_50};
  }

  &.force-click {
    background-color: ${({ theme, isActive }) =>
      isActive ? theme.colors.ACCENT_BLUE_700 : theme.colors.GREY_200};
    color: ${({ theme, isActive }) =>
      isActive ? theme.colors.WHITE : theme.colors.GREY_500};
    border: 1px solid
      ${({ theme, isActive }) =>
        isActive ? theme.colors.ACCENT_BLUE_700 : theme.colors.GREY_50};
  }
`;

const DynamicFormFieldListContents = React.memo(
  ({
    path,
    label,
    readOnly,
    value,
    setValue,
    isCollapsed,
  }: DynamicFormItemProps & {
    value: KVPair[];
    setValue: any;
    isCollapsed?: boolean;
  }) => {
    const context = useContext(FormContext);

    const keyChangeHandler = useCallback(
      (e: ChangeEvent<HTMLInputElement> | string | number, idx: number) => {
        const val =
          typeof e === "string" || typeof e === "number"
            ? e.toString()
            : e.target.value;

        const arr = [...value];
        const newVal = {
          ...arr[idx],
          key: val,
        };
        arr[idx] = newVal;
        setValue(arr);
        context.onChange(path, arr, { debounced: true });
      },
      [context, path, value, setValue],
    );
    const valueChangeHandler = useCallback(
      (val: string, idx: number) => {
        const arr = [...value];
        const newVal = {
          ...arr[idx],
          value: val,
        };
        arr[idx] = newVal;
        context.onChange(path, arr);
      },
      [value, context, path],
    );
    const remove = useCallback(
      (idx: number) => {
        const arr = [...value];
        arr.splice(idx, 1);
        context.onChange(path, arr);
      },
      [value, context, path],
    );
    const add = useCallback(() => {
      const arr = [...value];
      arr.push({ key: "", value: "" });
      context.onChange(path, arr);
    }, [value, context, path]);

    return (
      <div>
        {value?.map((kvPair, idx) => (
          <DynamicFormFieldItem
            idx={idx}
            key={idx}
            kvPair={kvPair}
            path={path}
            readOnly={readOnly}
            autocompleteConfiguration={context.autocompleteConfiguration}
            autocompleteAdditionalData={context.autocompleteAdditionalData}
            onKeyChange={keyChangeHandler}
            onValueChange={valueChangeHandler}
            onRemove={remove}
            hidden={
              isCollapsed &&
              (kvPair.system === true || kvPair.editable !== undefined)
            }
          />
        ))}
        <AddButton
          onClick={add}
          data-test={`${path}_add_button`}
          type="default"
          disabled={readOnly}
        >
          <PlusIcon height={16} width={16} />
          <div>Add {getAddTextFromLabel(label)}</div>
        </AddButton>
      </div>
    );
  },
);

const DynamicFormFieldList = React.memo(
  ({ path, label, tooltip, readOnly, collapseValue }: DynamicFormItemProps) => {
    const context = useContext(FormContext);
    const [stickyHovered, setStickyHovered] = useState(false);
    const [clicked, setClicked] = useState(false);

    const [value, setValue] = useState<KVPair[]>([]);
    const [active, setActive] = useState<boolean>(false);

    // separate subscribe from context first because context could mutate frequently
    const subscribe = context.subscribe;
    useEffect(() => {
      const unsubscribe = subscribe(path, (value) => {
        setValue((value as KVPair[]) ?? []);
        return;
      });

      return () => unsubscribe();
    }, [path, subscribe]);

    const ExpandIcon = (props: {
      isActive?: boolean;
      stickyHovered?: boolean;
    }) => (
      <CollapseIcon // TODO: Kevin H - Use <ExpandIconButton /> instead, can't map it to the UI yet
        isActive={props.isActive}
        stickyHovered={props.stickyHovered}
      />
    );

    const hasHidden =
      (value?.filter((kvPair) => kvPair.system || kvPair.editable !== undefined)
        .length ?? 0) !== 0;

    return (
      <FullWidthSpace direction="vertical" style={{ marginBottom: "8px" }}>
        <div>
          {!isEmpty(collapseValue) && hasHidden ? (
            <FullWidthSpace
              direction="horizontal"
              style={{ marginBottom: "8px " }}
            >
              <FormLabel>{label}</FormLabel>
              <CollapseButton
                className={
                  stickyHovered
                    ? clicked
                      ? "force-click"
                      : "force-hover"
                    : "force-blur"
                }
                onMouseOut={() => {
                  setStickyHovered(false);
                  setClicked(false);
                }}
                onMouseOver={() => setStickyHovered(true)}
                onMouseDown={() => setClicked(true)}
                onMouseUp={() => setClicked(false)}
                shape="round"
                size="small"
                data-test={`${path}_collapsible_button`}
                onClick={() => setActive(!active)}
                isActive={active}
              >
                {!active
                  ? `${
                      value?.filter(
                        (kvPair) =>
                          kvPair.system || kvPair.editable !== undefined,
                      ).length ?? 0
                    } hidden`
                  : collapseValue}
                <ExpandIcon isActive={active} stickyHovered={stickyHovered} />
              </CollapseButton>
            </FullWidthSpace>
          ) : (
            <FormLabel>{label}</FormLabel>
          )}
          <FormTooltip tooltip={tooltip} />
          <DynamicFormFieldListContents
            path={path}
            label={label}
            readOnly={readOnly}
            value={value}
            setValue={setValue}
            isCollapsed={!isEmpty(collapseValue) && !active}
          />
        </div>
      </FullWidthSpace>
    );
  },
);

DynamicFormFieldListContents.displayName = "DynamicFormFieldListContents";
DynamicFormFieldList.displayName = "DynamicFormFieldList";
export default DynamicFormFieldList;
