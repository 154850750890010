import { has, isPlainObject } from "lodash";
import {
  EditorOpenTab,
  EditorOpenTabWithWidget,
  EditorOpenTabType,
  EditorAppPreferences,
  DEFAULT_DEVELOPER_PREFERENCES,
  DeveloperPreferences,
  DeveloperPreferencesKeys,
} from "legacy/constants/EditorPreferencesConstants";
import { getAllParentPaths } from "utils/dottedPaths";
import { isPathADynamicTrigger } from "./DynamicBindingUtils";

export const getTabId = (tab: EditorOpenTab) => {
  if (tab.entityType === EditorOpenTabType.API) {
    return tab.entityId;
  } else {
    return `${tab.entityId}-${tab.widgetProperty}`;
  }
};

export const isTabPropertyValid = (
  entity: EditorOpenTabWithWidget["entity"],
  property?: string,
  strict?: boolean,
): boolean => {
  if (!entity || !property) return false;

  const [parentPath, parentPathWithLocation] = getAllParentPaths(property);
  const isDynamicProperty = isPathADynamicTrigger(entity, parentPath);

  // enforce a dynamic trigger check, because we want to ensure that the object at this location still exists
  // in case it was deleted
  if (isDynamicProperty) {
    return has(entity, parentPathWithLocation);
  }

  if (strict) {
    return has(entity, property);
  }

  // we dont always want to enforce a strict check because you can have a valid property that is not present on the widget
  // because it hasnt been set yet
  return true;
};

export const formatEditorPreferences = (rawLocalStorageObject: any) => {
  if (
    !isPlainObject(rawLocalStorageObject) ||
    !rawLocalStorageObject.editorPreferences
  ) {
    return {};
  }

  const prefsByAppId: Record<string, EditorAppPreferences> =
    rawLocalStorageObject.editorPreferences;
  const formattedPrefsByAppId: Record<string, EditorAppPreferences> = {};

  Object.entries(prefsByAppId).forEach(([appId, appPreferences]) => {
    if (!isPlainObject(appPreferences)) {
      return;
    }

    Object.entries(appPreferences).forEach(([pageId, pagePrefs]) => {
      // account for any lingering legacy data that might be in localstorage, since we
      // need it to be migrated to the new format down the line once a page is loaded
      if (pageId === "openBottomPanelTabs") {
        formattedPrefsByAppId[appId] = {
          [pageId]: pagePrefs,
        };
        return;
      }

      if (!isPlainObject(pagePrefs)) {
        return;
      }

      const { openBottomPanelTabs } = pagePrefs;
      let validTabs: EditorOpenTab[];
      if (!Array.isArray(openBottomPanelTabs)) {
        validTabs = [];
      } else {
        // Format openBottomPanelTabs
        validTabs = openBottomPanelTabs
          .map((tab: any) => {
            if (!isPlainObject(tab)) return null;
            if (!tab.entityType) return null;

            const entityType = tab.entityType as EditorOpenTabType;
            switch (entityType) {
              case EditorOpenTabType.API:
                if (!tab.entityId) return null;
                break;
              case EditorOpenTabType.WIDGET:
              case EditorOpenTabType.API_INFO:
              case EditorOpenTabType.CUSTOM_EVENT:
              case EditorOpenTabType.STATE_VAR:
              case EditorOpenTabType.TIMER:
                if (!tab.entityId || !tab.widgetProperty) return null;
                break;
              default: {
                const exhaustiveCheck: never = entityType;
                console.warn("unknown tab type", exhaustiveCheck);
                return null;
              }
            }

            tab.id = tab.id ?? getTabId(tab);
            return tab;
          })
          .filter(Boolean);

        formattedPrefsByAppId[appId] = {
          ...(formattedPrefsByAppId[appId] ?? {}),
          [pageId]: {
            openBottomPanelTabs: validTabs,
          },
        };
      }
    });
  });

  return formattedPrefsByAppId;
};

/**
 * Sets default preferences that aren't defined in rawLocalStorageObject
 * @param rawLocalStorageObject
 * @returns a complete DeveloperPreferenceState
 */
export const formatDeveloperPreferences = (
  rawLocalStorageObject: any,
): DeveloperPreferences => {
  if (
    !isPlainObject(rawLocalStorageObject) ||
    !rawLocalStorageObject.developerPreferences
  ) {
    return DEFAULT_DEVELOPER_PREFERENCES;
  }

  // Add any new 1-level deep keys to the developer preferences object
  return {
    [DeveloperPreferencesKeys.Shared]: {
      ...DEFAULT_DEVELOPER_PREFERENCES.shared,
      ...rawLocalStorageObject.developerPreferences.shared,
    },
    [DeveloperPreferencesKeys.Application]: {
      ...DEFAULT_DEVELOPER_PREFERENCES.application,
      ...rawLocalStorageObject.developerPreferences.application,
    },
  };
};
