import { ActionTypeEnum } from "@superblocksteam/shared";
import { noop } from "lodash";
import { HttpMethod, callServer } from "store/utils/client";

export const getApplicationPermissions = ({
  applicationId,
}: {
  applicationId: string;
}) => {
  return callServer<{
    permissions?: Array<ActionTypeEnum>;
  }>(
    {
      method: HttpMethod.Get,
      url: `v3/applications/${applicationId}/permissions`,
    },
    {
      notifyOnError: false,
      shouldCrashApp: false,
      onError: noop,
    },
  );
};
