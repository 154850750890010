import { IntegrationKind, TemplateResponseDto } from "@superblocksteam/shared";
import { Button, Input, Space, Tooltip, Typography } from "antd";
import { isEmpty } from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { flushSync } from "react-dom";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import styled from "styled-components";
import { FormRef } from "components/app/SBDynamicForm";
import { DeleteModal } from "components/ui/Modal";
import { LegacyNamedColors } from "legacy/constants/LegacyNamedColors";
import { AUDIT_LOGS_BASE_URL } from "legacy/constants/routes";
import { getCurrentUser } from "legacy/selectors/usersSelectors";
import { SECRETS_MANAGEMENT_URL } from "pages/routes";
import { colors } from "styles/colors";
import CreateAppModal from "./CreateAppModal";
import CreateFromTemplateOptionModal from "./CreateFromTemplateOptionModal";
import { ConfigMeta } from "./utils";

const ButtonContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
  margin-bottom: 12px;
`;

const IntegrationFooter = ({
  loading,
  readOnly,
  onFinish,
  isNew,
  configMeta,
  validateDatasourceName,
  formRef,
  canDelete,
  pluginName,
  onDelete,
  onCancel,
  kind,
  integrationName,
  createFromTemplateOptionModalVisible,
  setCreateFromTemplateOptionModalVisible,
  createAppModalVisible,
  setCreateAppModalVisible,
  template,
  pluginId,
  datasourceId,
  organizationId,
}: {
  loading: boolean;
  readOnly: boolean;
  onFinish: () => void;
  onCancel: () => void;
  isNew: boolean;
  configMeta: ConfigMeta | undefined;
  validateDatasourceName: () => Promise<boolean>;
  formRef: React.RefObject<FormRef> | undefined;
  pluginName: string | undefined;
  onDelete: () => void;
  isIntegrationModified: boolean;
  kind: IntegrationKind;
  integrationName: string | undefined;
  createFromTemplateOptionModalVisible: boolean;
  setCreateFromTemplateOptionModalVisible: (visible: boolean) => void;
  createAppModalVisible: boolean;
  setCreateAppModalVisible: (visible: boolean) => void;
  template: TemplateResponseDto | null;
  organizationId: string;
  pluginId: string;
  datasourceId: string;
  canDelete: boolean;
}) => {
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const currentUser = useSelector(getCurrentUser);
  const nameForKind =
    kind === IntegrationKind.SECRET ? "secret manager" : "integration";

  const handleFinish = useCallback(async () => {
    flushSync(async () => {
      if (!configMeta || !formRef) return;
      // NB: We call both validation methods before actually checking them because
      // we want the validation errors to all show up on the form together.
      const configValid =
        (!configMeta.isDefault && isEmpty(configMeta.profileIds)) ||
        formRef.current?.validate();
      const nameValid = await validateDatasourceName();

      if (configValid && nameValid) {
        onFinish();
      }
    });
  }, [configMeta, formRef, validateDatasourceName, onFinish]);

  const navigate = useNavigate();

  const [confirmInput, setConfirmInput] = useState("");

  const [createAppModeBlank, setCreateAppModeBlank] = useState(false);

  useEffect(() => {
    setConfirmInput("");
  }, [deleteModalVisible]);

  return (
    <ButtonContainer
      style={
        isNew
          ? {
              justifyContent: "flex-end",
            }
          : undefined
      }
    >
      {!isNew && (
        <Tooltip
          title={
            canDelete
              ? undefined
              : "You do not have permission to delete this integration"
          }
        >
          <Button
            disabled={!canDelete || loading}
            onClick={() => setDeleteModalVisible(true)}
            type="text"
            style={{
              border: "1px solid",
              borderColor: LegacyNamedColors.GRAY_LIGHT,
            }}
            size={"middle"}
            danger
            data-test="delete-integration-button"
          >
            Delete {nameForKind}
          </Button>
        </Tooltip>
      )}
      {!isNew && (
        <DeleteModal
          open={deleteModalVisible}
          onCancel={() => {
            setDeleteModalVisible(false);
          }}
          title={`Delete ${pluginName} ${nameForKind}?`}
          dataTestName="integration"
          confirmText={
            kind === IntegrationKind.PLUGIN ? "Delete" : "Delete anyway"
          }
          disableDelete={
            kind === IntegrationKind.PLUGIN && confirmInput !== integrationName
          }
          onDelete={onDelete}
          isDeleting={loading}
          width={kind === IntegrationKind.PLUGIN ? 384 : 450}
        >
          {kind === IntegrationKind.PLUGIN ? (
            <>
              <p>
                You are attempting to delete the{" "}
                <b style={{ color: colors.GREY_800 }}> {integrationName}</b>{" "}
                integration. This action cannot be undone.
              </p>

              <p>
                Deleting an integration used in backend APIs will cause those
                APIs to fail immediately. This could impact end-users of
                deployed apps and services calling workflows.
              </p>
              <p style={{ marginBottom: 4 }}>
                <b
                  style={{ color: colors.GREY_700 }}
                >{`To confirm, type "${integrationName}" in the box below`}</b>{" "}
                <span style={{ color: colors.RED_500 }}>*</span>
              </p>
              <Input
                data-test="confirm-delete-integration-input"
                placeholder={`${integrationName}`}
                value={confirmInput}
                onChange={(e) => setConfirmInput(e.target.value)}
              />
            </>
          ) : (
            <>
              <p>
                This action cannot be undone, all settings must be made from
                scratch if you want to return it.
              </p>

              <p>
                This action will impact all APIs using this {nameForKind}. APIs
                will start failing and need to be fixed manually. Monitor for
                failures in the
                <Typography.Link href={AUDIT_LOGS_BASE_URL} target="_blank">
                  {" "}
                  Audit Logs.{" "}
                </Typography.Link>
              </p>
            </>
          )}
        </DeleteModal>
      )}
      <Space size="middle">
        <Button
          loading={loading}
          type="default"
          size={"middle"}
          data-test="cancel-integration-button"
          onClick={() => {
            onCancel();
            kind === IntegrationKind.SECRET
              ? navigate(SECRETS_MANAGEMENT_URL)
              : navigate("/integrations");
          }}
        >
          Cancel
        </Button>
        <Button
          loading={loading}
          disabled={readOnly}
          type="primary"
          size={"middle"}
          data-test="save-integration-button"
          onClick={handleFinish}
        >
          {isNew ? "Create" : "Save"}
        </Button>
      </Space>
      {template && (
        <CreateFromTemplateOptionModal
          createFromTemplateOptionModalVisible={
            createFromTemplateOptionModalVisible
          }
          setCreateFromTemplateOptionModalVisible={
            setCreateFromTemplateOptionModalVisible
          }
          setCreateAppModalVisible={setCreateAppModalVisible}
          kind={kind}
          pluginId={pluginId}
          datasourceId={datasourceId}
          pluginName={pluginName}
          createAppModeBlank={createAppModeBlank}
          setCreateAppModeBlank={setCreateAppModeBlank}
          templateDescription={template.description}
          templateCoverImgUrl={template.coverImgUrl}
          templateName={template.name}
        />
      )}
      {template && (
        <CreateAppModal
          createAppModalVisible={createAppModalVisible}
          setCreateAppModalVisible={setCreateAppModalVisible}
          kind={kind}
          pluginId={pluginId}
          datasourceId={datasourceId}
          templateInstanceId={template.id}
          pluginName={pluginName}
          createAppModeBlank={createAppModeBlank}
          organizationId={organizationId}
          username={currentUser?.name}
          templateName={template.name}
        />
      )}
    </ButtonContainer>
  );
};

export default IntegrationFooter;
