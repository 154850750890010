import { snakeCaseToDisplay } from "@superblocksteam/shared";
import React from "react";
import ErrorComponent from "components/app/Error/ErrorComponent";
import {
  PermissionToCheck,
  useAuthorizationCheck,
} from "hooks/ui/rbac/useAuthorizationCheck";
import { API_STATUS_CODES } from "legacy/constants/ApiConstants";
import { HOME_URL, USERS_PAGE_URL } from "legacy/constants/routes";
import ErrorPageHeader from "legacy/pages/common/ErrorPageHeader";
import { USER_FRIENDLY_RESOURCE_NAMES } from "pages/Permissions/constants";

export const AuthorizedPageWrapper: React.FC<{
  children: React.ReactNode;
  permission: PermissionToCheck;
}> = ({ children, permission }) => {
  const [isAuthorized] = useAuthorizationCheck([permission]);

  if (!isAuthorized) {
    const resourceName =
      USER_FRIENDLY_RESOURCE_NAMES[permission.resourceType] ??
      snakeCaseToDisplay(permission.resourceType.replaceAll(".", "_"));
    return (
      <>
        <ErrorPageHeader />
        <ErrorComponent
          errorCode={API_STATUS_CODES.RESOURCE_FORBIDDEN}
          title={"Your don’t have access"}
          errorMessage={`You need the ${resourceName} read permission to use this feature. Ask your admins to update your permissions.`}
          buttonText="Find admins"
          buttonPath={USERS_PAGE_URL}
          secondaryButtonText="Return home"
          secondaryButtonAction={() => (window.location.pathname = HOME_URL)}
        />
      </>
    );
  }

  return <>{children}</>;
};
