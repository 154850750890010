import { Spinner } from "@blueprintjs/core";
import {
  ActionTypeEnum,
  IntegrationKind,
  Plugin,
} from "@superblocksteam/shared";
import { Button, Input } from "antd";
import React, { useCallback, useEffect, useState } from "react";

import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { Layout, MainWrapper } from "components/app";
import { HeaderWrapper } from "components/ui/Page";
import { Table, TableClass } from "components/ui/Table";
import { CREATE_SECRET_STORE } from "constants/rbac";
import { useSaga } from "hooks/store";
import { useFeatureFlag } from "hooks/ui";
import { useAuthorizationCheck } from "hooks/ui/rbac/useAuthorizationCheck";
import { getCurrentUser } from "legacy/selectors/usersSelectors";
import Header from "pages/components/Header";
import { PageNav } from "pages/components/PageNav";
import {
  PageWrapper,
  SECRETS_MANAGEMENT_TITLE,
} from "pages/components/PageWrapper";
import { EDIT_SECRETS_MANAGEMENT_URL } from "pages/routes";
import { selectSecretDatasources } from "store/slices/datasources";
import { getSupersetDatasourcesSaga } from "store/slices/datasources/sagas/getSupersetDatasources";
import { Flag } from "store/slices/featureFlags";
import { colors } from "styles/colors";
import { styleAsClass } from "styles/styleAsClass";
import {
  SECRET_INTEGRATIONS_LIST,
  SECRET_INTEGRATIONS_MAP,
} from "utils/integrations";
import { EmptyState } from "./EmptyState";
import { SecretPluginCard } from "./SecretPluginCard";
import { AvailablePlugin, ConnectedIntegration } from "./types";

const SectionHeader = styleAsClass`
    font-size: 13px;
    font-weight: 600;
    line-height: 16px;
    color: ${colors.BLACK};
    margin-bottom: 16px;
`;

const SecretsManagement = () => {
  const [available, setAvailable] = useState<AvailablePlugin[]>([]);
  const [connected, setConnected] = useState<ConnectedIntegration[]>([]);
  const [searchConnected, setSearchConnected] = useState<
    ConnectedIntegration[]
  >([]);
  const [getDatasources] = useSaga(getSupersetDatasourcesSaga);
  const [connectedLoading, setConnectedLoading] = useState(true);
  const user = useSelector(getCurrentUser);
  const datasources = useSelector(selectSecretDatasources);
  const plugins = SECRET_INTEGRATIONS_MAP;
  const [showAllPlugins, setShowAllPlugins] = useState(false);

  useEffect(() => {
    (async () => {
      if (user?.currentOrganizationId) {
        setConnectedLoading(true);
        await getDatasources({
          organizationId: user.currentOrganizationId,
          kind: IntegrationKind.SECRET,
        });
        setConnectedLoading(false);
      }
    })();
  }, [user, getDatasources]);

  useEffect(() => {
    const connectedIntegrations = Object.values(datasources).map(
      (datasource) => {
        const plugin = plugins[datasource.pluginId ?? ""];
        return {
          datasourceName: datasource.name,
          datasourceId: datasource.id,
          datasourceSlug: datasource.slug,
          iconLocation: plugin?.iconLocation,
          pluginId: plugin?.id,
          pluginName: plugin?.name,
          pluginType: plugin?.type,
          permissions: datasource.permissions,
        };
      },
    );

    const availIntegrations = SECRET_INTEGRATIONS_LIST.filter(
      (plugin) =>
        plugin.datasourceTemplate &&
        plugin.datasourceTemplate.sections.length > 0,
    ).map((plugin: Plugin) => {
      return {
        iconLocation: plugin?.iconLocation,
        pluginId: plugin?.id,
        pluginName: plugin?.name,
        pluginType: plugin?.type,
      };
    });

    setAvailable(availIntegrations);

    if (!connectedLoading) {
      setConnected(connectedIntegrations);
      setSearchConnected(connectedIntegrations);
    }
  }, [user, datasources, plugins, connectedLoading]);

  const onSearch = useCallback(
    (e: any) => {
      const value = e.target.value;
      if (value) {
        setSearchConnected(
          connected.filter((integration) =>
            integration.datasourceName
              .toLowerCase()
              .includes(value.toLowerCase()),
          ),
        );
      } else {
        setSearchConnected(connected);
      }
    },
    [connected],
  );

  const [canCreate] = useAuthorizationCheck([CREATE_SECRET_STORE]);

  const rbacV2Enforced = useFeatureFlag(Flag.ENABLE_RBAC_V2_ENFORCEMENT);
  const getCanEdit = useCallback(
    (secretManager: ConnectedIntegration) => {
      return rbacV2Enforced
        ? (secretManager.permissions ?? []).includes(ActionTypeEnum.UPDATE)
        : true;
    },
    [rbacV2Enforced],
  );

  const navigate = useNavigate();
  const onRow = useCallback(
    (secretManager: ConnectedIntegration) => {
      return {
        onClick: () => {
          if (!getCanEdit(secretManager)) {
            return;
          }
          navigate({
            pathname: EDIT_SECRETS_MANAGEMENT_URL(
              secretManager.pluginId,
              secretManager.datasourceId,
            ),
          });
        },
      };
    },
    [navigate, getCanEdit],
  );

  return (
    <PageWrapper pageName={SECRETS_MANAGEMENT_TITLE}>
      <Layout Header={<Header />} Sider={<PageNav />}>
        <MainWrapper
          data-test="secrets-management-home"
          style={{ display: "flex", flexDirection: "column" }}
        >
          <div className={HeaderWrapper}>
            <div className="page-header-title">{SECRETS_MANAGEMENT_TITLE} </div>
            <div className="page-header-description">
              Enhance security and streamline development by accessing your
              secrets in Superblocks. Learn more about{" "}
              <a
                href="https://docs.superblocks.com/development-lifecycle/build/secrets-management"
                target="_blank"
                rel="noreferrer"
              >
                Secrets Management.
              </a>
            </div>
          </div>
          {connectedLoading ? (
            <div style={{ padding: 20 }}>
              <Spinner size={42} />
            </div>
          ) : connected.length === 0 ? (
            <EmptyState availablePlugins={available} />
          ) : (
            <div>
              <div>
                <div className={SectionHeader}>Secret Managers</div>
                <div
                  style={{
                    display: "flex",
                    gap: "16px",
                    flexWrap: "wrap",
                    marginBottom: available.length <= 3 ? "24px" : "16px",
                  }}
                >
                  {(showAllPlugins ? available : available.slice(0, 3)).map(
                    (plugin) => (
                      <div
                        style={{
                          minWidth: "220px",
                          maxWidth: "400px",
                          flex: 1,
                        }}
                        key={plugin.pluginId}
                      >
                        <SecretPluginCard
                          pluginInfo={plugin}
                          buttonStyle="SECONDARY"
                          isDisabled={!canCreate}
                        />
                      </div>
                    ),
                  )}
                </div>
                {available.length <= 3 ? null : showAllPlugins ? (
                  <Button
                    style={{ marginBottom: "24px" }}
                    type="ghost"
                    onClick={() => setShowAllPlugins(false)}
                  >
                    Show less
                  </Button>
                ) : (
                  <Button
                    style={{ marginBottom: "24px" }}
                    type="ghost"
                    onClick={() => setShowAllPlugins(true)}
                  >
                    Show more
                  </Button>
                )}
              </div>
              <div>
                <div className={SectionHeader}>Connected Secret Stores</div>
                <Input
                  style={{ width: "100%", marginBottom: "16px" }}
                  onChange={onSearch}
                  allowClear
                  placeholder={"Search"}
                />
                <Table
                  dataSource={searchConnected}
                  rowKey={"datasourceSlug"}
                  onRow={onRow}
                  className={TableClass}
                  pagination={false}
                  rowClassName={(record) =>
                    getCanEdit(record) ? "clickable-row" : "non-clickable-row"
                  }
                >
                  <Table.Column
                    title="Name"
                    dataIndex="datasourceName"
                    key="datasourceName"
                    render={(_, secretManager: ConnectedIntegration) => {
                      return (
                        <div
                          style={{ display: "flex", alignItems: "center" }}
                          data-test={`secret-manager-${secretManager.datasourceName}`}
                        >
                          <img
                            style={{ height: "16px", width: "16px" }}
                            src={secretManager.iconLocation ?? ""}
                            alt=""
                          />
                          <span style={{ marginLeft: "16px", fontWeight: 500 }}>
                            {secretManager.datasourceName}
                          </span>
                        </div>
                      );
                    }}
                  />
                  <Table.Column
                    title="Key"
                    dataIndex="datasourceSlug"
                    key="datasourceSlug"
                    render={(key) => (
                      <div className="monospace-cell">{key}</div>
                    )}
                  />
                </Table>
              </div>
            </div>
          )}
        </MainWrapper>
      </Layout>
    </PageWrapper>
  );
};

export default SecretsManagement;
