import styled, { css } from "styled-components";
import { FullWidthSpace } from "components/ui/Space";

export const Section = styled(FullWidthSpace)<{ $hasBorder?: boolean }>`
  border: ${(props) =>
    props.$hasBorder && `1px solid ${props.theme.palettes.gray[8]}}`};
  border-radius: ${(props) => props.$hasBorder && `5px`};
  padding: ${(props) => props.$hasBorder && `16px`};
`;

export const DynamicFormWrapper = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow: auto;
`;

export const HeaderWrapper = styled.div<{ $isCollapsible: boolean }>`
  display: flex;
  align-items: center;
  ${({ $isCollapsible }) =>
    $isCollapsible &&
    css`
      cursor: pointer;
      &:hover {
        opacity: 0.6;
      }
    `}
`;
