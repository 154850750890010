import { Classes as BlueprintClasses } from "@blueprintjs/core";
import { compile, middleware, prefixer, serialize, stringify } from "stylis";
import tinycolor from "tinycolor2";
import { Layers } from "legacy/constants/Layers";
import {
  MODAL_TARGET_ID,
  WIDGET_DRAG_PREVIEW_CLASS,
} from "legacy/constants/WidgetConstants";
import { CLASS_NAMES } from "../classnames";
import { BASE_THEME_EXISTING_APPS } from "../constants";
import { generateTheme } from "../generateTheme";
import { generateTypographies } from "../generateTypographies";
import { GeneratedTheme } from "../types";
import { generateButtonStyles } from "./buttonStyles";
import { generateContainerStyles } from "./containerStyles";
import { generateDatepickerStyles } from "./datepickerStyles";
import { generateDropdownStyles } from "./dropdownStyles";
import { generateEditorStyles } from "./editorStyles";
import { generateIconStyles } from "./iconStyles";
import { generateInputStyles } from "./inputStyles";
import { generatePaginatorStyles } from "./paginatorStyles";
import { generatePopperStyles } from "./popperStyles";
import { generateTextStyles } from "./textStyles";
import { styleFromStyleBlock } from "./utils";
import { generateBorderStyle } from "./utils";

const createStyleSheetFromString = (sheetId: string, styleSheet: string) => {
  const styles = serialize(
    compile(styleSheet),
    middleware([prefixer, stringify]),
  );
  const existingStyleTag = document.getElementById(sheetId);
  const styleTag = existingStyleTag ?? document.createElement("style");
  styleTag.setAttribute("id", sheetId);
  styleTag.replaceChildren(document.createTextNode(styles));
  if (existingStyleTag) return;
  document.head.appendChild(styleTag);
};

export const generateThemedStyleSheet = (
  theme: GeneratedTheme,
  rootClassName?: string,
) => {
  // TODO check & fill can be removed after GA
  if (!theme.typographies) {
    theme.typographies = generateTypographies(theme.typographies, {
      colors: theme.colors,
      defaultFontFamily: theme.fontFamily,
    });
  }
  if (!theme.builtinTypographies) {
    theme.builtinTypographies = generateTheme(
      BASE_THEME_EXISTING_APPS,
    ).builtinTypographies;
  }

  createStyleSheetFromString(
    "sb-theme",
    `
      ${
        rootClassName
          ? `.${rootClassName}`
          : `body, .${BlueprintClasses.PORTAL}`
      },
      {
        // CSS Variables
        --label-margin: ${theme.version >= 5 ? "0px" : "2px"};

        scrollbar-color: ${theme.mode === "DARK" ? "dark" : "light"};
        color-scheme: ${theme.mode === "DARK" ? "dark" : "light"};
        background: ${theme.colors.appBackground};
        ${generateInputStyles(
          theme.inputs,
          theme.typographies.inputPlaceholder,
        )}
        ${generateTextStyles(
          theme.typographies,
          theme.builtinTypographies,
          theme.fontFamily,
        )}
        ${generateButtonStyles(theme.buttons)}
        ${generatePopperStyles(theme.popover)}
        ${generateDropdownStyles(theme)}
        ${generateDatepickerStyles(theme)}
        ${generateContainerStyles(theme)}
        ${generatePaginatorStyles(theme)}
        ${generateEditorStyles(theme)}
        ${generateIconStyles(theme.icon)}
        font-family: ${theme.fontFamily};
        .superblocks_widget_0, .${WIDGET_DRAG_PREVIEW_CLASS} {
          background: ${theme.colors.appBackground};
        }
        [data-test="canvas-root"], [data-superblocks="${MODAL_TARGET_ID}"] {
          background: ${theme.colors.appBackground};
        }
        // Note: canvas-root cannot have overflow: auto or it will break sticky sections
        [data-superblocks="${MODAL_TARGET_ID}"] {
          overflow: auto;
        }

        .${CLASS_NAMES.BORDER_BOTTOM} {
          border-bottom: ${generateBorderStyle(theme.defaultBorder)};
        }
        .${CLASS_NAMES.BORDER_TOP} {
          border-top: ${generateBorderStyle(theme.defaultBorder)};
        }
        .${CLASS_NAMES.ERROR_MODIFIER} {
          color: ${theme.colors.danger};
        }

        // sticky sections
        [data-superblocks=sticky-section] {
          transition: box-shadow 0.2s ease-in-out;
          box-shadow: none;
          z-index: ${Layers.stickySections};
          &.sticky-bottom-shadow {
            box-shadow: 0 8px 10px -5px rgba(0, 0, 0, 0.2)
          }
          &.sticky-stuck {
            // increase z-index to cover all widget focus highlights from below sections but not exceeding drawer
            z-index: ${Layers.drawer};
          }
        }
    }
`,
  );
};

// We now have a gutter that only affects edit mode
export const generatedThemeStylesEditMode = (
  theme: GeneratedTheme,
  rootClassName?: string,
) => {
  const appBg = tinycolor(theme.colors.appBackground).toString();

  createStyleSheetFromString(
    "sb-theme-editor",
    `
      ${
        rootClassName
          ? `.${rootClassName}`
          : `body, .${BlueprintClasses.PORTAL}`
      } {
        &, [data-test="canvas-root"], [data-superblocks="${MODAL_TARGET_ID}"] {
          background: ${appBg};
        }
      }
    `,
  );
};

// the notifications are rendered OUTSIDE of the iframe, but still need to be themed
// we create a separate stylesheet on the outer dom
export const generateWrapperStyles = (theme: GeneratedTheme) => {
  createStyleSheetFromString(
    "sb-notification-theme",
    `
    .${CLASS_NAMES.NOTIFICATION} {
      ${styleFromStyleBlock(theme.container.notification)}
      .ant-notification-notice-message {
        color: inherit;
      }
      .ant-notification-notice-close {
        color: inherit;
        &:hover {
          color: inherit;
        }
      }
    }
  `,
  );
};

// We now have a gutter that only affects edit mode
export const generatedEditorWrapperStyles = (
  theme: GeneratedTheme,
  rootClassName?: string,
) => {
  createStyleSheetFromString(
    "sb-theme-editor-wrapper",
    `
      ${
        rootClassName
          ? `.${rootClassName}`
          : `body, .${BlueprintClasses.PORTAL}`
      } {
        ${generateDatepickerStyles(theme)}
      }
    `,
  );
};
