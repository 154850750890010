import "url-search-params-polyfill";
import { ApplicationScope } from "@superblocksteam/shared";
import { produce } from "immer";
import { createSelector } from "reselect";
import { accessibleEntityProps } from "autocomplete/dataTreeTypeDefCreator";
import {
  DataTree,
  DataTreeAction,
  DataTreeEntity,
  DataTreeFactory,
  DataTreeWidget,
  ScopedDataTree,
} from "legacy/entities/DataTree/dataTreeFactory";
import {
  getApiAppInfo,
  getEmbedPropsAndMeta,
  getV2ApiAppInfo,
  getWidgets,
  getWidgetsMeta,
} from "legacy/selectors/sagaSelectors";
import { selectAllApis, selectApiMeta } from "store/slices/apis/selectors";
import { selectUserAccessibleScopeForApiUnion } from "store/slices/apisShared/selectors/selectUserAccessibleScopeForApiUnion";
import {
  selectAllV2Apis,
  selectV2ApiMeta,
} from "store/slices/apisV2/selectors";
import { getAllEvents } from "store/slices/application/events/selectors";
import { getStateVarsAndMeta } from "store/slices/application/stateVarsMeta/selectors";
import { getAllTimers } from "store/slices/application/timers/selectors";
import { getAllTimersMeta } from "store/slices/application/timersMeta/selectors";
import { selectFlags } from "store/slices/featureFlags";
import { ENTITY_TYPE } from "utils/dataTree/constants";
import { ApiScope } from "utils/dataTree/scope";
import { selectOnlyOrganization } from "../../store/slices/organizations";
import { getMergedDataTree as getMergedDataTreeFunction } from "../../utils/dataTree/MergedDataTree";
import { resolveScopes } from "../../utils/dataTree/resolveScopes";
import { getAppData } from "./entitiesSelector";
import { getHasStartedPageLoad } from "./evaluatorSelectors";
import { getAvailableIcons } from "./iconSelectors";
import { getDynamicLayoutWidgets } from "./layoutSelectors";
import { createMarkedSelector } from "./markedSelector";
import { getUserAvailableTheme } from "./themeSelectors";
import type { AppState } from "store/types";

export const getEvaluationInverseDependencyMap = (state: AppState) =>
  state.legacy.evaluations.dependencies.inverseDependencyMap;

export const getEvaluationEntityDependencyMap = (state: AppState) =>
  state.legacy.evaluations.dependencies.entityDependencyMap;

export const getEvaluationEntityToApiDepMap = (state: AppState) =>
  state.legacy.evaluations.dependencies.entityToApiDepMap;

const getEvaluationActionTriggerMap = (state: AppState) =>
  state.legacy.evaluations.dependencies.actionTriggerMap;

const getEvaluationActionTriggers = createSelector(
  getEvaluationActionTriggerMap,
  (_state: AppState, entityId: string) => entityId,
  (actionTriggerMap, entityId) => actionTriggerMap[entityId] ?? [],
);

export const getApiRunTriggers = createSelector(
  getEvaluationActionTriggers,
  (triggers) => {
    // We only want triggers that are run triggers
    return triggers.filter((trigger) => trigger.referencedPath === "run");
  },
);

export const isEntityLoading = (state: AppState, entityId: string) => {
  if (!getHasStartedPageLoad(state)) {
    return true;
  }
  return state.legacy.evaluations.loadingEntities[entityId] ?? false;
};

export const getNameIteratorValue = (state: AppState) =>
  state.legacy.evaluations.nameIterator;

const getEvaluationType = (state: AppState) =>
  state.legacy.evaluations.evaluationType;

export const getUnevaluatedDataTree = createMarkedSelector(
  "getUnevaluatedDataTree",
)(
  getEvaluationType,
  selectAllApis,
  selectApiMeta,
  getApiAppInfo,
  selectAllV2Apis,
  selectV2ApiMeta,
  getV2ApiAppInfo,
  getWidgets,
  getWidgetsMeta,
  getDynamicLayoutWidgets,
  getAllTimers,
  getAllTimersMeta,
  getStateVarsAndMeta,
  (state: AppState) => state.legacy.entities.pageList.pages,
  getAppData,
  selectOnlyOrganization,
  getUserAvailableTheme,
  selectFlags,
  getEmbedPropsAndMeta,
  getAllEvents,
  (
    evaluationType,
    apiEntities,
    apiOutputs,
    apiAppInfo,
    apiV2Entities,
    apiV2Outputs,
    apiV2AppInfo,
    widgets,
    widgetsMeta,
    widgetsDynamics,
    timers,
    timersMeta,
    stateVarsAndMeta,
    pageListPayload,
    appData,
    orgData,
    themeData,
    featureFlags,
    embedPropsAndMeta,
    eventMap,
  ) => {
    const iconData = getAvailableIcons();
    const pageList = pageListPayload || [];
    const unevalData =
      evaluationType === "ui"
        ? DataTreeFactory.create({
            apiEntities,
            apiOutputs,
            apiAppInfo,
            apiV2Entities,
            apiV2Outputs,
            apiV2AppInfo,
            widgets,
            widgetsMeta,
            widgetsDynamics,
            timers,
            timersMeta,
            stateVarsAndMeta,
            embedPropsAndMeta,
            pageList,
            appData,
            orgData,
            themeData,
            iconData,
            featureFlags,
            eventMap,
          })
        : DataTreeFactory.create({
            apiEntities,
            apiOutputs,
            apiAppInfo: {},
            apiV2Entities,
            apiV2Outputs,
            apiV2AppInfo: {},
            widgets: {},
            widgetsMeta: {},
            widgetsDynamics: {},
            timers: {},
            timersMeta: {
              [ApplicationScope.GLOBAL]: {},
              [ApplicationScope.APP]: {},
              [ApplicationScope.PAGE]: {},
            },
            stateVarsAndMeta: {},
            embedPropsAndMeta: undefined,
            pageList: [],
            appData,
            orgData,
            themeData,
            iconData: [],
            featureFlags,
            eventMap: {},
          });
    return unevalData;
  },
);

/**
 * returns evaluation tree object
 *
 * @param state
 */
export const getDataTree = (state: AppState) => state.legacy.evaluations.tree;

// TODO(APP_SCOPE): Remove this function as there may be name conflicts
/**
 * Retrieves the flattened data tree from the data tree.
 * DON'T USE THIS FUNCTION. Use `getDataTree` instead.
 *
 * @returns The flattened data tree.
 * @deprecated This function is deprecated and will be removed in future versions. Use `getDataTree` instead.
 */
export const getLegacyDataTree = createSelector(getDataTree, (dataTree) => {
  const flattenedDataTree: ScopedDataTree = {};
  Object.keys(dataTree.APP).forEach((key) => {
    flattenedDataTree[key] = dataTree.APP[key];
  });
  Object.keys(dataTree.PAGE).forEach((key) => {
    flattenedDataTree[key] = dataTree.PAGE[key];
  });
  return flattenedDataTree;
});

export const getDataTreeWidgetsById = createSelector(getDataTree, (dataTree) =>
  Object.values(dataTree.PAGE).reduce((acc, entity) => {
    if (
      entity &&
      "ENTITY_TYPE" in entity &&
      entity.ENTITY_TYPE === ENTITY_TYPE.WIDGET
    ) {
      acc[entity.widgetId] = entity;
    }
    return acc;
  }, {} as Record<string, DataTreeWidget>),
);

export const getDataTreeItem = createSelector(
  getDataTreeWidgetsById,
  (_state: any, itemId: string) => itemId,
  (map, itemId) => map[itemId],
);

// For autocomplete. Use actions cached responses if
// there isn't a response already
export const getDataTreeForAutocomplete = createSelector(
  getDataTree,
  getEvaluationType,
  (tree, evaluationType) => {
    if (evaluationType === "headless") {
      return produce(tree, (draft) => {
        delete draft.GLOBAL.Global;
        delete draft.GLOBAL.theme;
        delete draft.GLOBAL.icons;
      });
    }
    return tree;
  },
);

export const getMergedDataTree = createSelector(
  getDataTreeForAutocomplete,
  (_state: unknown, scope: ApplicationScope) => scope,
  (dataTree, scope) => getMergedDataTreeFunction(scope, dataTree),
);

function applyApiScope(
  apiScope: ApiScope,
  tree: DataTree,
  mergedDataTree: Record<string, unknown>,
) {
  for (const actionName of apiScope.previousV1ActionNames) {
    const entity = tree.PAGE[apiScope.apiName] as DataTreeAction | undefined;
    const { output } = entity?.actionOutputs[actionName] ?? {
      output: null,
    };
    mergedDataTree[actionName] = { output };
  }
  for (const inScopeVar of Object.keys(apiScope.v2ComputedScope ?? {})) {
    mergedDataTree[inScopeVar] = apiScope.v2ComputedScope?.[inScopeVar];
  }
}

export const getUserAccessibleDataTree = createSelector(
  getDataTreeForAutocomplete, // Get App data
  selectUserAccessibleScopeForApiUnion, // Get API data
  (
    _state: unknown,
    _apiId: unknown,
    _actionId: unknown,
    appScope?: ApplicationScope,
  ) => appScope ?? ApplicationScope.PAGE,
  (tree, apiScope, appScope) => {
    const scopes = resolveScopes(appScope);
    const mergedDataTree = {} as Record<string, unknown>;
    for (const { scope, prefix } of scopes) {
      const focusedScope = tree[scope];
      if (prefix) {
        const prefixedDataTree: Record<string, unknown> = {};
        for (const [key, entity] of Object.entries(focusedScope)) {
          prefixedDataTree[key] = accessibleEntityProps(entity);
        }
        mergedDataTree[prefix] = prefixedDataTree;
      } else {
        for (const [key, entity] of Object.entries(focusedScope)) {
          mergedDataTree[key] = accessibleEntityProps(entity);
        }
      }
    }

    // APPLY the API scope
    if (apiScope) {
      applyApiScope(apiScope, tree, mergedDataTree);
    }

    return mergedDataTree;
  },
);

export const getUserAccessibleApiActions = createSelector(
  getDataTreeForAutocomplete, // Get App data
  selectUserAccessibleScopeForApiUnion, // Get API data
  (dataTree, apiScope) => {
    const mergedDataTree = {} as Record<string, unknown>;
    if (apiScope) {
      applyApiScope(apiScope, dataTree, mergedDataTree);
    }
    return mergedDataTree;
  },
);

export const getUserAccessibleDataTreeGrouped = createSelector(
  getMergedDataTree,
  (dataTree) => {
    const groupedEntities = {
      [ENTITY_TYPE.WIDGET]: {} as Record<string, Record<string, unknown>>,
      [ENTITY_TYPE.TIMER]: {} as Record<string, Record<string, unknown>>,
      [ENTITY_TYPE.STATE_VAR]: {} as Record<string, Record<string, unknown>>,
      [ENTITY_TYPE.ACTION]: {} as Record<string, Record<string, unknown>>,
      [ENTITY_TYPE.EMBEDDING]: {} as Record<string, Record<string, unknown>>,
      [ENTITY_TYPE.GLOBAL]: {} as Record<string, Record<string, unknown>>,
    };

    for (const [name, entity_] of Object.entries(dataTree)) {
      const entity = entity_ as DataTreeEntity;
      if (!("ENTITY_TYPE" in entity)) {
        continue;
      }
      switch (entity.ENTITY_TYPE) {
        case ENTITY_TYPE.WIDGET:
        case ENTITY_TYPE.TIMER:
        case ENTITY_TYPE.STATE_VAR:
        case ENTITY_TYPE.ACTION:
        case ENTITY_TYPE.GLOBAL:
        case ENTITY_TYPE.EMBEDDING: {
          const ae = accessibleEntityProps(entity);
          if (ae && Object.keys(ae).length) {
            groupedEntities[entity.ENTITY_TYPE][name] = ae;
          }
          break;
        }
        default:
          break;
      }
    }

    return groupedEntities;
  },
);

export const getMergedDataTreeKeys = createSelector(
  [getMergedDataTree],
  (dataTree): string[] => Object.keys(dataTree),
);

export const getEmbedEntityNames = createSelector(
  getDataTree,
  (dataTree): string[] => {
    return Object.keys(dataTree.PAGE.Embed ?? {});
  },
);
