import {
  InviteAction,
  InviteOperation,
  PostInviteResponseDto,
} from "@superblocksteam/shared";
import { INVITE_USERS_TO_ORG } from "pages/routes";
import { callServer, HttpMethod } from "store/utils/client";
import { HttpError } from "store/utils/types";

export async function sendOrgInvitation({
  userEmails,
  organizationId,
  groupIds,
  onError,
  roleId,
}: {
  userEmails: string[];
  organizationId: string;
  groupIds?: string[];
  onError?: (e: HttpError) => void;
  roleId?: string;
}) {
  const operations: InviteOperation[] = userEmails.map((email) => ({
    email: email.trim(),
    action: InviteAction.ADD_MEMBER,
  }));
  const invitationResult = await callServer<PostInviteResponseDto>(
    {
      method: HttpMethod.Post,
      url: INVITE_USERS_TO_ORG(),
      params: { orgId: organizationId },
      body: {
        operations,
        groupIds,
        assignmentId: roleId,
      },
    },
    {
      notifyOnError: false,
      onError,
    },
  );
  return invitationResult;
}
