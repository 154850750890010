import { FormItemTooltip } from "@superblocksteam/shared";
import ReactMarkdown from "react-markdown";
import styled from "styled-components";

export interface DynamicFormItemProps {
  path: string;
  label: string;
  lightLabelText?: boolean;
  initialValue?: unknown;
  rules?: any[];
  disabled?: boolean;
  placeholder?: string;
  tooltip?: FormItemTooltip;
  subHeading?: string;
  readOnly?: boolean;
  loading?: boolean;
  hidden?: boolean;
  isNew?: boolean;
  immutable?: boolean;
  subtitle?: string;
  collapseValue?: string;
  showHideIcon?: boolean;
  renderIconFirst?: boolean;
  inverseValue?: boolean;
}

export const StyledReactMarkdown = styled(ReactMarkdown)`
  p {
    font-size: ${(props) => props.theme.text.sizes.default};
    margin-bottom: 4px;
  }
  p:last-child {
    margin-bottom: 0px;
  }
`;
