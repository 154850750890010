import { Heading1Css } from "styles/Typography";
import { colors } from "styles/colors";
import { styleAsClass } from "styles/styleAsClass";

export const HeaderWrapper = styleAsClass`
display: flex;
flex-direction: column;
justify-content: space-between;
align-items: start;
gap: 8px;
.page-header-title {
  ${Heading1Css}
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
}
.page-header-description {
  font-size: 12px;
  font-weight: 400;
  color: ${colors.GREY_700};
}
margin-bottom: 24px;
& + div.ant-tabs {
  margin-top: -12px;
}
`;

export const MainWrapperStyles = styleAsClass`
  .ant-breadcrumb {
    margin-top: -28px;
    margin-bottom: -8px;
  }
`;
