import { createAction } from "@reduxjs/toolkit";
import { ApplicationScope } from "@superblocksteam/shared";
import { ItemKinds } from "legacy/pages/Editor/PropertyPane/ItemKindConstants";
import { EventDefinition } from "store/slices/application/events/eventConstants";
import { showItemPropertyPane } from "../../../../legacy/actions/propertyPaneActions";

export const createEvent = createAction(
  "CREATE_CUSTOM_EVENT",
  (payload: {
    scope: ApplicationScope;
    id?: string;
    name?: string;
    open?: boolean;
  }) => ({
    payload,
  }),
);

export function createAndOpenEvent(payload: {
  scope: ApplicationScope;
  id?: string;
  name?: string;
}) {
  return createEvent({
    ...payload,
    open: true,
  });
}

export interface RenameCustomEventUpdate {
  eventId: string;
  propertyName: string;
  propertyValue: unknown;
  scope: ApplicationScope;
}

export const renameCustomEvents = createAction(
  "RENAME_CUSTOM_EVENTS",
  (updates: RenameCustomEventUpdate[]) => ({
    payload: { updates },
  }),
);

export const deleteCustomEvent = createAction(
  "DELETE_CUSTOM_EVENT",
  (id: string, scope: ApplicationScope) => ({
    payload: { id, scope },
  }),
);

export const duplicateCustomEvent = createAction(
  "DUPLICATE_CUSTOM_EVENT",
  (payload: {
    eventId: string;
    fromScope: ApplicationScope;
    toScope: ApplicationScope;
  }) => ({
    payload,
  }),
);

export const updateCustomEvent = createAction(
  "UPDATE_CUSTOM_EVENT",
  (payload: {
    updates: Record<
      EventDefinition["id"],
      Partial<Pick<EventDefinition, "arguments" | "onTrigger">>
    >;
    scope: ApplicationScope;
  }) => ({
    payload,
  }),
);

export const setCreatingCustomEvent = createAction(
  "SET_CREATING_CUSTOM_EVENT",
  (payload: boolean) => ({ payload }),
);

export const editEventPropertyPane = (
  eventId: string,
  scope: ApplicationScope,
) => showItemPropertyPane({ kind: ItemKinds.CUSTOM_EVENT, id: eventId, scope });
