import { createAction } from "@reduxjs/toolkit";
import { ActionTypeEnum, ResourceTypeEnum } from "@superblocksteam/shared";
import { defineAction } from "../../utils/action";
import slice from "./slice";

/**
 * We need to clear dirty state outside of persistApi's start reducer. This is necessary
 * because the start reducer is called whenever updateApi.success.type is fired, however
 * persistApi itself is throttled. Therefore, we should remove dirty state only when
 * we fire the persistApi and not when we end up throttling it.
 */
export const clearV2DirtyState = defineAction<{ id: string }>(
  slice.name,
  "clearV2DirtyState",
);

export const markPageLoadV2Apis = defineAction<string[]>(
  slice.name,
  "markPageLoadV2Apis",
);

export const clearResponseV2Api = defineAction<{ id: string }>(
  slice.name,
  "clearResponseV2Api",
);

export const setV2TestProfile = defineAction<{
  apiId: string;
  profile: string;
}>(slice.name, "setV2TestProfile");

export const toggleShowTestData = defineAction<{
  apiId: string;
  blockName: string;
}>(slice.name, "toggleShowTestData");

export const updateTestDataForBlock = defineAction<{
  apiId: string;
  blockName: string;
  values: Record<string, string>;
}>(slice.name, "updateTestDataForBlock");

export const resetTestDataForBlock = defineAction<{
  apiId: string;
  blockName: string;
  fieldToReset: string;
}>(slice.name, "resetTestDataForBlock");

export const clearTestDataErrorForBlock = defineAction<{
  apiId: string;
  blockName: string;
  fieldNames: Array<string>;
}>(slice.name, "clearTestDataErrorForBlock");

export const clearApiErrorsAndMetas = defineAction(
  slice.name,
  "clearApiErrorsAndMetas",
);

export const startCodeFormatting = defineAction(
  slice.name,
  "startCodeFormatting",
);

export const setBlockCodeFormatting = defineAction<{
  apiId: string;
  blockName: string;
  status: any;
  error?: any;
}>(slice.name, "setBlockCodeFormattingStatus");

export const resetApis = defineAction(slice.name, "resetApis");

export const fetchApiPermissions = createAction(
  "fetchApiPermissions/start",
  (
    apiId: string,
    type: ResourceTypeEnum.WORKFLOWS | ResourceTypeEnum.SCHEDULED_JOBS,
  ) => ({ payload: { apiId, type } }),
);

export const fetchApiPermissionsSuccess = createAction(
  "fetchApiPermissions/success",
  (payload: { apiId: string; permissions: Array<ActionTypeEnum> }) => ({
    payload,
  }),
);

export const fetchApiPermissionsError = createAction(
  "fetchApiPermissions/error",
  (error: string) => ({ payload: { error } }),
);
