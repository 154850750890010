import { ActionTypeEnum } from "@superblocksteam/shared";
import { type ApiDto } from "store/slices/apis";
import { Slice } from "store/utils/slice";
import { EntitiesStateWithMeta } from "store/utils/types";
import { Api } from "./backend-types";
import {
  ExecutionResponse,
  EnrichedExecutionResult,
  BlockFormatting,
  ApiFormatting,
} from "./types";
import type { ScopedDataTreePath } from "legacy/entities/DataTree/dataTreeFactory";

export type Binding = {
  str: string; // The path used to access the binding in the API, e.g. `user.name`
  dataTreePath: ScopedDataTreePath; // The path to the binding in the data tree, e.g. `PAGE.user.name`
};

export type ApiV2Meta = {
  applicationId?: string;
  executionResult?: ExecutionResponse;
  enrichedExecutionResult?: EnrichedExecutionResult;
  dirty?: boolean;
  saving?: boolean;
  isUpdating?: boolean;
  isDeleting?: boolean;

  needsBindingExtraction?: boolean; // API requires new bindings to be extracted
  extractingBindings?: number; // the API's bindings are currently being extracted by the saga call with this callId
  extractedBindings?: Binding[]; // API has extracted bindings
  extractedBindingsByBlock?: Record<string, Binding[]>;
  nameIteratorValue?: number;

  apiDependencies?: string[];
  cancelled?: boolean;
  cancelledByType?: CancelledByType;
  concurrentRuns?: number;
  waitingForEvaluationSince?: string;
  savingFailuresCount?: number;
  testProfile?: string; // used to general test url profile param
  editedSinceLastExecution?: boolean;
  hydratingBlocks?: Record<string, boolean>;

  codeFormatting?: "formatting" | "formatted";

  // Data for single-step runs
  showTestDataForBlock?: Record<string, boolean>;
  variableBindingsByBlock?: Record<string, string[]>;
  blockBindingsByBlock?: Record<string, string[]>;
  referencedVariableBlocksByBlock?: Record<string, string[]>;
  extractingBlockBindings?: boolean;
  testDataForBlock?: Record<string, undefined | StepRunTestData>;
  cachedTestDataForBlock?: Record<string, undefined | StepRunTestData>;
  testDataEvaluationError?: Record<
    string,
    {
      fieldErrors: Record<string, string>;
      generalErrors: string[];
    }
  >;

  singleStepRuns?: Record<
    string,
    {
      loading?: boolean;
      cancelled?: boolean;
      hasRunMostRecently?: boolean;
    }
  >;
};

export enum CancelledByType {
  MANUAL = "manual",
}

export enum TestDataType {
  EXTERNAL = "EXTERNAL",
  BLOCK_OUTPUT = "BLOCK_OUTPUT",
  VARIABLE = "VARIABLE",
}
export type StepRunTestData = Record<
  string,
  {
    value?: string | boolean | number;
    initialValue: string | boolean | number;
    type: TestDataType;
    isDirty: boolean;
  }
>;

export type ApiDtoWithPb = ApiDto & {
  apiPb: Api;
};

type Formatting = Record<
  string,
  ApiFormatting & {
    blocks: Record<string, BlockFormatting>;
  }
>;

export type ApiV2State = EntitiesStateWithMeta<ApiDtoWithPb, ApiV2Meta> & {
  formatting: Formatting;
  permissions: Record<string, ActionTypeEnum[]>;
};

export default new Slice<ApiV2State>("apisV2", {
  entities: {},
  loading: {},
  errors: {},
  meta: {},
  formatting: {},
  permissions: {},
});
