import { ApplicationScope } from "@superblocksteam/shared";
import { createSelector } from "reselect";
import { EntityLocation } from "legacy/entities/DataTree/dataTreeFactory";
import { AppStateVarScoped } from "./StateConstants";
import { StateVarMap } from "./slice";
import type { AppState } from "store/types";

const getStateVars = (state: AppState) => state.application.stateVars;

// All state vars are page-scoped for now. Revisit when adding app scope, probably need to split the selectors
export const getAllStateVars = createSelector([getStateVars], (stateVars) => ({
  ...stateVars[ApplicationScope.APP],
  ...stateVars[ApplicationScope.PAGE],
}));

export const getScopedStateVars = createSelector(
  [getStateVars, (_: AppState, scope: ApplicationScope) => scope],
  (state: StateVarMap, scope: ApplicationScope) => {
    return state[scope];
  },
);

export const getStateVarById = createSelector(
  [getAllStateVars, (stateVars, id: string) => id],
  (stateVars, id) => stateVars?.[id],
);

/**
 * Retrieves all state variables by name.
 * @deprecated Use getStateVarById or getStateVar instead.
 */
export const getStateVarsByName = createSelector(
  [getAllStateVars],
  (stateVars) => {
    return Object.fromEntries(
      Object.values(stateVars).map((stateVar) => [stateVar.name, stateVar]),
    );
  },
);

const getStateVarsByScopeThenName = createSelector(getStateVars, (scopes) => {
  const stateVarsByName = {} as Record<
    ApplicationScope,
    Record<string, AppStateVarScoped>
  >;
  for (const scope in scopes) {
    stateVarsByName[scope as ApplicationScope] = Object.fromEntries(
      Object.values(scopes[scope as ApplicationScope]).map((stateVar) => [
        stateVar.name,
        stateVar,
      ]),
    );
  }
  return stateVarsByName;
});

export const getStateVar = createSelector(
  [getStateVarsByScopeThenName, (_: AppState, loc: EntityLocation) => loc],
  (stateVars, loc) => {
    return stateVars[loc.scope][loc.name];
  },
);

export const getAllStateVarNames = createSelector(
  [getAllStateVars],
  (stateVars) => {
    return Object.values(stateVars || {}).map((stateVar) => stateVar.name);
  },
);

export const getScopedStateVarNames = (
  state: AppState,
  scope: ApplicationScope,
) =>
  Object.values(getScopedStateVars(state, scope)).map(
    (stateVar) => stateVar.name,
  );
