import {
  ActionTypeEnum,
  Agent,
  AgentDiagnosticMetadata,
  AgentStatus,
  AgentType,
  DatasourceConfiguration,
  DiagnosticType,
  ENVIRONMENT_PRODUCTION,
  ExtendedIntegrationPluginMap,
  FormTemplate,
  InitialValue,
  IntegrationKind,
  PLUGIN_ID_TO_PROTO_CLASS,
  PermissionedEntities,
  ResourceTypeEnum,
  RestApiIntegrationActionConfiguration,
  RestApiIntegrationPlugin,
  RoleTypeEnum,
  TASKS,
  TemplateResponseDto,
  TemplateStatus,
  TokenScope,
  extendsRestApiIntegrationPlugin,
  getAuthId,
  getBasePluginId,
  getRowItemsFromSectionItem,
  isAuthenticatedDatasourceConfig,
  jsonPrettyPrint,
} from "@superblocksteam/shared";
import {
  Button,
  Form,
  Input,
  Modal,
  Space,
  Tabs,
  Tooltip,
  Typography,
} from "antd";
import { produce } from "immer";
import _, { isEmpty, isEqual, isNil } from "lodash";
import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import styled, { css, useTheme } from "styled-components";
import { v4 as uuidv4, validate as validateUUID } from "uuid";
import { ReactComponent as UploadIcon } from "assets/icons/common/cloud-upload.svg";
import { ReactComponent as DangerIcon } from "assets/icons/common/dangerhalo.svg";
import { ReactComponent as WarningHaloIcon } from "assets/icons/common/warninghalo.svg";
import { ReactComponent as DocsIcon } from "assets/icons/home/docs.svg";
import { Layout } from "components/app";
import {
  mapFormValuesToObject,
  mapObjectToFormValues,
} from "components/app/DynamicForm/utils";
import { INTERCOM_LAUNCHER_CLASSNAME } from "components/app/Intercom/constants";
import { FormRef } from "components/app/SBDynamicForm";
import {
  cleanFormData,
  filterUnsupportedFields,
  flattenTemplateToListOfDisplayAndInitialValuePair,
  getPluginVersion,
} from "components/app/SBDynamicForm/utils";
import { HeaderWrapper } from "components/ui/Page";
import { Spinner } from "components/ui/Spinner";
import { CREATE_INTEGRATION } from "constants/rbac";
import { SUPERBLOCKS_UI_AGENT_BASE_URL } from "env";
import { useIsControlFlowEnabled } from "hooks/api/useIsControlFlowEnabled";
import { useSaga } from "hooks/store";
import { useAuthorizationCheck } from "hooks/ui/rbac/useAuthorizationCheck";
import { useMarkTaskComplete } from "hooks/ui/useCheckTask";
import {
  startEvaluation,
  stopEvaluation,
} from "legacy/actions/evaluationActions";
import { pageLoadSuccess } from "legacy/actions/pageActions";
import { LegacyNamedColors } from "legacy/constants/LegacyNamedColors";
import {
  DOCS_PAGE_URL,
  DocsPage,
  HOME_URL,
  INTEGRATIONS_URL,
} from "legacy/constants/routes";
import { ChecklistIcons } from "legacy/icons/ChecklistIcons";
import { getUserCurrentOrgId } from "legacy/selectors/organizationSelectors";
import Header from "pages/components/Header";
import { PageNav } from "pages/components/PageNav";
import {
  INTEGRATIONS_TITLE,
  SECRETS_MANAGEMENT_TITLE,
} from "pages/components/PageWrapper";
import ShareModal, { ShareRole } from "pages/components/ShareModal";
import ShareModalV2 from "pages/components/ShareModalV2";
import {
  INTEGRATION_URL,
  IntegrationRouteParams,
  SECRETS_MANAGEMENT_URL,
} from "pages/routes";
import { useAppDispatch, useAppSelector } from "store/helpers";
import {
  createDatasourceSaga,
  getConnectedTokensSaga,
  getOpenApiSpecSaga,
  selectDatasourceById,
  selectDatasourceMetaById,
  selectDatasourcesOfKind,
  selectOpenApiRefByDatasourceId,
  updateDatasourceSaga,
} from "store/slices/datasources";
import {
  clearSecretStoreCache,
  deleteDatasource,
  deleteDatasourceOnAgent,
  deleteDatasourceOnOrchestrator,
  reportDatasourceCreationError,
  testSecretStore,
  testV1Datasource,
  testV2Datasource,
  uploadOpenApiSpecUrl,
} from "store/slices/datasources/client";
import { getAllDatasourcesSaga } from "store/slices/datasources/sagas/getSupersetDatasources";
import { selectOrganizationById } from "store/slices/organizations/selectors";
import { orgIsOnPremise } from "store/slices/organizations/utils";
import { getTemplateByPlugin } from "store/slices/template/client";
import { colors } from "styles/colors";
import { styleAsClass } from "styles/styleAsClass";
import { fastClone } from "utils/clone";
import { UIEvent } from "utils/event";
import { generateKey } from "utils/generateKey";
import {
  PLUGIN_INTEGRATIONS_IDS,
  getPluginById,
  isPluginTestable,
} from "utils/integrations";
import logger from "utils/logger";
import {
  NotificationPosition,
  closeNotification,
  sendErrorUINotification,
  sendInfoUINotification,
  sendSuccessUINotification,
} from "utils/notification";
import { useFeatureFlag, usePreventNavigation } from "../../hooks/ui";
import { getCurrentUser } from "../../legacy/selectors/usersSelectors";
import { chooseAgents, selectAllAgents } from "../../store/slices/agents";
import { Flag } from "../../store/slices/featureFlags";
import { noActiveAgentMessage } from "../../utils/error/error";
import { BreadCrumb } from "../components/navigation/BreadCrumb";
import ApiSpecification from "./ApiSpecification";
import { ConnectionAlert } from "./ConfigurationNav";
import IntegrationFooter from "./IntegrationFooter";
import IntegrationSetupForm, {
  TitleTextWrapper,
  TitleWrapper,
} from "./IntegrationSetupForm";
import SpecUploadModal from "./SpecUploadModal";
import {
  OPENAPI_UPLOAD_NOTIFICATION_KEY,
  extractActionConfigs,
  generateOpenApiSpecRef,
  getOpenApiSpecFileName,
  isOpenApiBasedRestPlugin,
} from "./openApiUtils";
import { ConfigMeta, IntegrationPageTabKeys } from "./utils";

const { TabPane } = Tabs;

const HelpText = styleAsClass`
  color: ${colors.GREY_400};
  font-size: 11px;
  font-weight: 400;
  line-height: 16px;
  margin-bottom: 24px;
`;

const Wrapper = styled.div<{ hasTab?: boolean }>`
  width: 100%;
  padding: 40px;
  min-height: 100vh;
  background-color: ${(props) => props.theme.colors.WHITE};

  .${INTERCOM_LAUNCHER_CLASSNAME} {
    padding: 0;
  }

  .ant-layout-content {
    background: white;
  }

  .ant-layout-sider {
    border-right: unset;
  }

  .ant-tabs-nav {
    margin-bottom: 0px;
  }

  & > .ant-tabs-top > .ant-tabs-nav > .ant-tabs-nav-wrap {
    border: 1px solid ${LegacyNamedColors.GRAY_LIGHT};
    border-bottom: 0px;
    padding-left: 16px;
    border-radius: 4px 4px 0px 0px;
  }

  .in-tab-wrapper {
    ${({ hasTab }) =>
      hasTab
        ? css`
            border-top: 0px;
            margin-top: 0px;
          `
        : ``}
    border-radius: 0px 0px 4px 4px;
  }

  .ant-tabs-tab {
    padding-top: 8px;
    padding-bottom: 8px;
  }

  .ant-layout-has-sider {
    & > div:first-child {
      border-radius: 0px 0px 0px 4px;
    }

    & > main.ant-layout-content {
      border-radius: 0px 0px 4px 0px;
    }
  }
`;

const ModalDescriptionText = styled(Typography.Text)`
  color: ${LegacyNamedColors.GRAY_DARK};
`;

const FooterWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  gap: 10px;
`;

const Icon = styled.img`
  height: 40px;
  width: 40px;
`;

const DocsButton = styled(Button)`
  margin-left: 16px;

  a {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  svg {
    margin-right: 4px;
    height: 18px;
    width: 18px;
  }

  svg path {
    fill: ${({ theme }) => theme.colors.BLACK};
    transition: all 0.6s ease-in-out;
  }

  &:hover svg path {
    fill: ${({ theme }) => theme.colors.ACCENT_BLUE_500};
  }
`;

const StyledFormItem = styled(Form.Item)`
  .ant-form-item-label {
    padding: 0px;
  }
`;

const StyledButtonsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 10px;
`;

const StyledUploadButton = styled(Button)`
  &,
  &:disabled {
    background-color: ${({ theme }) =>
      `${theme.colors.ACCENT_BLUE_500}14`}; // 14 in hex represents 8% opacity
    color: ${({ theme }) => theme.colors.ACCENT_BLUE_500};
    border: 1px solid ${({ theme }) => theme.colors.ACCENT_BLUE_500};
  }
  &:hover,
  &:active,
  &:focus,
  &:disabled:hover {
    background-color: ${({ theme }) =>
      `${theme.colors.ACCENT_BLUE_600}14`}; // 8%
    color: ${({ theme }) => theme.colors.ACCENT_BLUE_600};
    border: 1px solid ${({ theme }) => theme.colors.ACCENT_BLUE_600};
    path:first-child {
      fill: ${({ theme }) => theme.colors.ACCENT_BLUE_600};
    }
    path:not(:first-child) {
      stroke: ${({ theme }) => theme.colors.ACCENT_BLUE_600};
    }
  }
  .upload-button-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
  }
  path:first-child {
    fill: ${({ theme }) => theme.colors.ACCENT_BLUE_500};
  }
  path:not(:first-child) {
    stroke: ${({ theme }) => theme.colors.ACCENT_BLUE_500};
  }
`;

const PersistAlertWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  flex-direction: row;
`;

interface AllTestResult {
  testResults: Record<string, TestResult>;
}

interface TestResult {
  datasourceConfiguration: DatasourceConfiguration | null;
  alert: any | null;
  shouldCreate: boolean;
}

const successAlert = (
  <Typography>
    <Typography.Text strong>Integration saved successfully!</Typography.Text>
  </Typography>
);

const UploadFailAlert = ({ retryUpload }: { retryUpload: () => void }) => {
  const [uploading, setUploading] = useState(false);
  const retryUploadFunc = useCallback(async () => {
    setUploading(true);
    await retryUpload();
    setUploading(false);
  }, [retryUpload]);

  return (
    <PersistAlertWrapper>
      <div>
        <Typography>
          <Typography.Text strong>
            Configurations saved successfully, but failed to upload API
            specification.
          </Typography.Text>
        </Typography>
      </div>
      <div>
        <Button
          type="primary"
          danger
          loading={uploading}
          onClick={retryUploadFunc}
        >
          Retry
        </Button>
      </div>
    </PersistAlertWrapper>
  );
};

export const IntegrationForm = ({ kind }: { kind: IntegrationKind }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const params = useParams<IntegrationRouteParams>();
  const [isDirty, setIsDirty] = useState(false);
  const [form] = Form.useForm();

  // openApi spec upload
  const [openApiSpecFile, setOpenApiSpecFile] = useState<File | null>(null);
  const [specUploadModalVisible, setSpecUploadModalVisible] = useState(false);
  const [specUpdated, setSpecUpdated] = useState(false); // updated but not saved
  const [specUploading, setSpecUploading] = useState(false);
  const enableOpenApiUI = kind === IntegrationKind.PLUGIN;
  const enableOpenApiDoc =
    useFeatureFlag(Flag.ENABLE_OPENAPI_DOC) && kind === IntegrationKind.PLUGIN;
  const nameForKind =
    kind === IntegrationKind.PLUGIN ? "integration" : "secret store";

  usePreventNavigation("system")(specUploading || specUpdated || isDirty);

  useEffect(() => {
    return () => {
      closeNotification(OPENAPI_UPLOAD_NOTIFICATION_KEY);
    };
  }, []);

  const datasourceId = useMemo(() => {
    if (!params?.datasourceId || !validateUUID(params.datasourceId ?? "")) {
      return uuidv4();
    }
    return params.datasourceId;
  }, [params]);
  const [loading, setLoading] = useState(false);
  const [clearingCache, setClearingCache] = useState(false);
  const [createdModalVisible, setCreatedModalVisible] = useState(false);
  const [loadingInitialValues, setLoadingInitialValues] = useState(false);

  const enableTemplate = useFeatureFlag(Flag.ENABLE_TEMPLATE);
  const [template, setTemplate] = useState<TemplateResponseDto | null>(null);

  const [
    createFromTemplateOptionModalVisible,
    setCreateFromTemplateOptionModalVisible,
  ] = useState(false);
  const [createAppModalVisible, setCreateAppModalVisible] = useState(false);

  const datasource = useAppSelector((state) =>
    selectDatasourceById(state, datasourceId),
  );
  const datasources = useAppSelector((state) =>
    selectDatasourcesOfKind(state, kind),
  );

  const organizationId = useSelector(getUserCurrentOrgId) ?? "";
  const organization = useAppSelector((state) =>
    selectOrganizationById(state, organizationId),
  );
  const agents = useSelector(selectAllAgents);

  // Sort the profiles by name such that ENVIRONMENT_PRODUCTION is first,
  // ENVIRONMENT_STAGING is second, and everything else is after those two.
  const profiles = useMemo(() => {
    if (_.isEmpty(organization.profiles)) {
      logger.event(UIEvent.LIST_PROFILES_IS_EMPTY);
      logger.debug("organization " + jsonPrettyPrint(organization));
    }

    return (organization.profiles ?? [])
      .slice()
      .sort((a, b) => a.key.localeCompare(b.key));
  }, [organization]);

  const productionProfile = useMemo(
    () => profiles.find((p) => p.key === ENVIRONMENT_PRODUCTION),
    [profiles],
  );
  const productionProfileId = productionProfile?.id ?? "";

  const [currentConfigurationId, setCurrentConfigurationId] = useState<
    string | undefined
  >(undefined);
  const [alerts, setAlerts] = useState<
    Record<string, ConnectionAlert | undefined>
  >({});

  const [allTestResult, setAllTestResult] = useState<AllTestResult | undefined>(
    undefined,
  );

  const pluginId = params.pluginId ?? "";
  const plugin = getPluginById(pluginId);
  const title = `${plugin?.name ?? "datasource"}`;
  const isNew = params.datasourceId === undefined;
  const currentUser = useSelector(getCurrentUser);
  const orgOwnedIntegrationsEnabled = useFeatureFlag(
    Flag.ORG_OWNED_INTEGRATIONS_ENABLED,
  );
  const isIntegrationEnableExperimentalEnabled = useFeatureFlag(
    Flag.INTEGRATION_ENABLE_EXPERIMENTAL,
  );
  // used to determine if edit and delete buttons are disabled (when org owned integration is disabled)
  const rbacV2Enabled = useFeatureFlag(Flag.ENABLE_RBAC_V2_ENFORCEMENT);

  const [canCreate] = useAuthorizationCheck([CREATE_INTEGRATION]);
  const { canShare, canDelete, canEdit } = useMemo(() => {
    const isDemoIntegration = Boolean(datasource?.demoIntegrationId);
    if (rbacV2Enabled) {
      const permissions = datasource?.permissions ?? [];
      return {
        canDelete: permissions.includes(ActionTypeEnum.DELETE),
        canShare: permissions.includes(ActionTypeEnum.SHARE),
        canEdit: isNew
          ? canCreate
          : permissions.includes(ActionTypeEnum.UPDATE) && !isDemoIntegration,
      };
    }

    if (orgOwnedIntegrationsEnabled) {
      return {
        canDelete: currentUser?.isAdmin ?? false,
        canShare: currentUser?.isAdmin ?? false,
        canEdit: !isDemoIntegration,
      };
    }
    const isOwner = currentUser?.email === datasource?.ownerEmail;
    return {
      canDelete: Boolean(isOwner || currentUser?.isAdmin),
      canShare: Boolean(isOwner || currentUser?.isAdmin),
      canEdit: !isDemoIntegration,
    };
  }, [
    datasource?.permissions,
    currentUser,
    orgOwnedIntegrationsEnabled,
    datasource?.ownerEmail,
    rbacV2Enabled,
    datasource?.demoIntegrationId,
    canCreate,
    isNew,
  ]);

  const [getDatasources] = useSaga(getAllDatasourcesSaga);
  const [createDatasource] = useSaga(createDatasourceSaga);
  const [updateDatasource] = useSaga(updateDatasourceSaga);
  useEffect(() => {
    (async () => {
      setLoadingInitialValues(true);
      await getDatasources({ organizationId });
      setLoadingInitialValues(false);
    })();
  }, [organizationId, getDatasources]);

  useEffect(() => {
    dispatch(startEvaluation({ evaluationType: "headless" }));
    dispatch(pageLoadSuccess());
    return () => {
      dispatch(stopEvaluation());
    };
  }, [dispatch]);

  // this function returns the initial values for the form based on plugin template, and the resolved template based on organization's plugin version
  const initialValuesFn = useCallback(
    (
      configuration?: DatasourceConfiguration,
    ): [any, FormTemplate | undefined] => {
      if (!plugin?.datasourceTemplate) {
        return [{}, undefined];
      }
      const pluginTemplateInitValues: Record<string, InitialValue> = {};
      const clonedDatasourceTemplate = fastClone(plugin?.datasourceTemplate);
      filterUnsupportedFields(
        clonedDatasourceTemplate,
        getPluginVersion(organization.pluginExecutionVersions, plugin?.id),
        organization.agentType,
        isIntegrationEnableExperimentalEnabled,
      );
      for (const section of clonedDatasourceTemplate.sections) {
        section.items.forEach((sectionItem) => {
          const rowItems = getRowItemsFromSectionItem(sectionItem);
          for (const item of rowItems) {
            if (!isNil(item.initialValue)) {
              pluginTemplateInitValues[item.name] = item.initialValue;
            }
          }
        });
      }

      return [
        {
          ...pluginTemplateInitValues,
          ...mapObjectToFormValues(
            configuration ?? ({} as Record<string, unknown>),
            plugin.datasourceTemplate,
          ),
          name: datasource?.name ?? "",
        },
        clonedDatasourceTemplate,
      ];
    },
    [
      plugin?.datasourceTemplate,
      plugin?.id,
      organization.pluginExecutionVersions,
      organization.agentType,
      datasource,
      isIntegrationEnableExperimentalEnabled,
    ],
  );

  const [initialValues, displayAndInitialValuePairList] = useMemo(() => {
    const tempState: Record<string, any> = {};
    let resolvedDatasourceTemplate: FormTemplate | undefined = undefined;
    if (isNew) {
      // create a default configuration for new integration
      const newConfigurationId = uuidv4();
      const initialValuesWithResolvedTemplate = initialValuesFn();
      tempState[newConfigurationId] = initialValuesWithResolvedTemplate[0];
      resolvedDatasourceTemplate = initialValuesWithResolvedTemplate[1];
    } else if (datasource?.configurations) {
      for (const configuration of datasource.configurations) {
        const initialValuesWithResolvedTemplate = initialValuesFn(
          configuration.configuration,
        );
        tempState[configuration.id] = initialValuesWithResolvedTemplate[0];
        // there will only be one resolved template, as there is only one plugin.
        resolvedDatasourceTemplate = initialValuesWithResolvedTemplate[1];
      }
    }

    // there could be multiple form items with same name from template, so we merge their display and initial values and form a list in topology order to clear based on dependencies indicated by display
    const displayAndInitialValuePairList =
      flattenTemplateToListOfDisplayAndInitialValuePair(
        resolvedDatasourceTemplate,
      );
    return [tempState, fastClone(displayAndInitialValuePairList)];
  }, [datasource?.configurations, initialValuesFn, isNew]);

  // get openapi spec
  // options from meta data
  const datasourceMeta = useAppSelector((state) =>
    selectDatasourceMetaById(state, datasourceId),
  );
  const openApiSpec = useMemo(
    () => datasourceMeta?.metadata?.openApiSpec,
    [datasourceMeta?.metadata?.openApiSpec],
  );
  const [getOpenApiSpec] = useSaga(getOpenApiSpecSaga);
  const [openApiString, setOpenApiString] = useState("");

  const integrationOpenApiSpecRef = useAppSelector((state) =>
    selectOpenApiRefByDatasourceId(state, datasourceId),
  );
  const openApiSpecRef = useMemo(
    () => plugin?.openApiSpecRef ?? integrationOpenApiSpecRef,
    [integrationOpenApiSpecRef, plugin?.openApiSpecRef],
  );

  useEffect(() => {
    if (
      openApiSpec ||
      getBasePluginId(pluginId) !== RestApiIntegrationPlugin.id
    )
      return;
    (async () => {
      if (enableOpenApiUI && openApiSpecRef) {
        try {
          const { openApiString } = await getOpenApiSpec({
            integrationId: datasourceId,
            openApiSpecRef: openApiSpecRef as string,
          });
          setOpenApiString(openApiString);
        } catch (e) {
          console.error("failed to load OpenAPI spec");
        }
      }
    })();
  }, [
    datasourceId,
    enableOpenApiUI,
    getOpenApiSpec,
    openApiSpec,
    openApiSpecRef,
    pluginId,
  ]);

  // this maps config id to the configuration form
  const [configIdToFormData, setConfigIdToFormData] = useState<
    Record<string, any> | undefined
  >(undefined);

  const [configIdToConfigMeta, setConfigIdToConfigMeta] = useState<
    Record<string, ConfigMeta> | undefined
  >(undefined);

  const configMetas = useMemo(() => {
    if (!configIdToConfigMeta) {
      return undefined;
    }
    return Object.values(configIdToConfigMeta).sort(
      (a, b) =>
        Number(b.isDefault) - Number(a.isDefault) ||
        a.created.getTime() - b.created.getTime(),
    );
  }, [configIdToConfigMeta]);

  const setAllAlerts = useCallback(
    (values: any) => {
      if (configMetas) {
        setAlerts(
          produce((draft) => {
            configMetas.forEach((configMeta) => {
              draft[configMeta.id] = values;
            });
          }),
        );
      }
    },
    [configMetas],
  );
  const setAlertForConfiguration = useCallback(
    (configurationId: any, values: any) => {
      setAlerts(
        produce((draft) => {
          draft[configurationId] = values;
        }),
      );
    },
    [setAlerts],
  );

  useEffect(() => {
    // initialize the maps used to render config nav and form
    if (!isEmpty(initialValues) && configIdToFormData === undefined) {
      setConfigIdToFormData(initialValues);
      const configIdToConfigMetaInit: Record<string, ConfigMeta> = {};

      const usedProfileIdsSet = new Set();
      let defaultConfigId = "";
      if (isNew) {
        const configId = Object.keys(initialValues)[0];
        configIdToConfigMetaInit[configId] = {
          id: configId,
          isDefault: true,
          profileIds: [...profiles.map((p) => p.id)],
          created: new Date(),
          originalProfileIds: [...profiles.map((p) => p.id)],
        };
        defaultConfigId = configId;
      } else {
        const existingProfileIds = profiles.map((p) => p.id);
        datasource?.configurations.forEach((configuration) => {
          // TODO: remove this once we have a better way to handle this on backend
          const profileIds = configuration.profileIds?.filter((profileId) =>
            existingProfileIds.includes(profileId),
          );
          if (!configuration.isDefault && isEmpty(profileIds)) return;
          configIdToConfigMetaInit[configuration.id] = {
            id: configuration.id,
            isDefault: configuration.isDefault,
            profileIds: [...(profileIds ?? [])],
            created: new Date(configuration.created),
            originalProfileIds: [...(profileIds ?? [])],
          };
          if (!configuration.isDefault) {
            configuration.profileIds?.forEach((profileId) => {
              usedProfileIdsSet.add(profileId);
            });
          } else {
            defaultConfigId = configuration.id;
          }
        });
        const profilesIdsForDefault = profiles
          .filter((p) => !usedProfileIdsSet.has(p.id))
          .map((p) => p.id);
        configIdToConfigMetaInit[defaultConfigId].profileIds =
          profilesIdsForDefault;
      }
      setConfigIdToConfigMeta(configIdToConfigMetaInit);
      setCurrentConfigurationId(defaultConfigId);
    }
  }, [
    initialValues,
    configIdToFormData,
    datasource?.configurations,
    profiles,
    isNew,
  ]);

  const alertComponent = useCallback((environment?: string): any => {
    if (environment) {
      return <Typography>{environment}</Typography>;
    }

    return <Typography>Successfully tested the connection.</Typography>;
  }, []);

  const profileIdToKey = useCallback(
    (profileId: string | undefined) => {
      return profiles.find((p) => {
        return p.id === profileId;
      })?.key;
    },
    [profiles],
  );

  const isControlFlowEnabled = useIsControlFlowEnabled();
  const controlFlowOverrideEnabled = useFeatureFlag(
    Flag.OVERRIDE_CONTROL_FLOW_ON,
  );
  const isOnPremise = orgIsOnPremise(organization);

  const getValidAgentsForProfiles = useCallback(
    (profileIds: string[]) => {
      if (isControlFlowEnabled && !isOnPremise) {
        // return a fake Agent - not ideal, but refactoring all the code to gracefully handle 0 agents is not worth the work since
        // this code will be much simpler when controller is completely removed
        return {
          agents: [
            {
              id: "fake-agent-id",
              key: "fake-agent-key",
              environment: ENVIRONMENT_PRODUCTION,
              status: AgentStatus.ACTIVE,
              version: "1.0.0",
              versionExternal: "1.0.0",
              supportedPluginVersions: {},
              url: SUPERBLOCKS_UI_AGENT_BASE_URL,
              type: AgentType.MULTITENANT,
              updated: new Date(),
              created: new Date(),
              tags: {},
            } as Agent,
          ],
          profileId: profileIds[0],
        };
      }

      for (const profileId of profileIds) {
        const profileKey = profileIdToKey(profileId);
        if (!profileKey) continue;
        const availableAgents = chooseAgents(
          agents,
          organization.agentType,
          profileKey,
          isControlFlowEnabled,
          controlFlowOverrideEnabled,
        );
        if (availableAgents.length > 0) {
          return {
            agents: availableAgents,
            profileId: profileId,
          };
        }
      }
      return {
        agents: [],
        profileId: undefined,
      };
    },
    [
      agents,
      organization.agentType,
      profileIdToKey,
      isOnPremise,
      isControlFlowEnabled,
      controlFlowOverrideEnabled,
    ],
  );

  const runTestIfTestable = useCallback(
    async (
      configMeta: ConfigMeta,
      values: Record<string, string>,
    ): Promise<TestResult> => {
      if (isEmpty(configMeta.profileIds)) {
        return {
          datasourceConfiguration: null,
          shouldCreate: false,
          alert: null,
        };
      }
      const { agents, profileId } = getValidAgentsForProfiles(
        configMeta.profileIds,
      );
      const targetEnvironment = profileIdToKey(profileId);

      if (!agents?.length || !targetEnvironment) {
        return {
          datasourceConfiguration: null,
          shouldCreate: false,
          alert: noActiveAgentMessage(organization.agentType),
        };
      }

      if (!plugin) {
        return {
          datasourceConfiguration: null,
          shouldCreate: false,
          alert: "Cannot find plugins.",
        };
      }

      if (datasourceId && !values) {
        return {
          datasourceConfiguration: null,
          shouldCreate: false,
          alert: null,
        };
      }
      if (!isPluginTestable(plugin, openApiSpec)) {
        const tpe = PLUGIN_ID_TO_PROTO_CLASS[plugin.id] || null;

        return {
          datasourceConfiguration: mapFormValuesToObject(
            values,
            tpe,
            plugin.datasourceTemplate,
          ),
          shouldCreate: true,
          alert: null,
        };
      }

      const eventContext = {
        pluginId: plugin.id,
        datasourceId: datasourceId,
        profileId,
        configurationId: configMeta.id,
        integrationName: values?.name,
      };

      try {
        const tpe = PLUGIN_ID_TO_PROTO_CLASS[plugin.id] || null;
        const formValues = mapFormValuesToObject(
          values,
          tpe,
          plugin.datasourceTemplate,
        );

        const { validateDatasourceConfigurationByTypeSchema } = await import(
          "@superblocksteam/schemas/dist/jsonschemas/DatasourceConfigurationByTypeSchema"
        );

        if (
          !Object.keys(PLUGIN_ID_TO_PROTO_CLASS).includes(plugin.id) &&
          !isOpenApiBasedRestPlugin(plugin.id)
        ) {
          validateDatasourceConfigurationByTypeSchema({
            [plugin.id]: formValues,
          });
        }
        const datasourceConfiguration = formValues;

        const tags: AgentDiagnosticMetadata = {
          superblocks_agent_id: agents[0].id,
          superblocks_org_id: organization.id,
          superblocks_org_name: organization.name,
          pluginId: plugin.id,
          datasourceId: datasourceId,
          configurationId: configMeta.id,
          type: DiagnosticType.AGENT,
        };

        // TODO(aayush): We're testing without the superblocksMetadata field to
        //  avoid teaching the controller about syncedFromProfileId.
        const testDatasourceConfig = _.omit(
          datasourceConfiguration,
          "superblocksMetadata",
        );

        const testActionConfigs = isOpenApiBasedRestPlugin(plugin.id)
          ? extractActionConfigs(openApiSpec)
          : undefined;

        //TODO(alex): support more than one test case
        const testActionConfig =
          testActionConfigs?.[0] as RestApiIntegrationActionConfiguration;

        testDatasourceConfig.name =
          form.getFieldValue("datasourceName") ?? ("" as string);

        let testStatus: {
          success: boolean;
          message?: string;
          systemError?: string;
        } = {
          success: false,
        };

        if (kind === IntegrationKind.SECRET) {
          const resp = await testSecretStore({
            agents,
            organization,
            datasourceSlug: form.getFieldValue("datasourceSlug"),
            profileId: configMeta.profileIds[0],
            provider: testDatasourceConfig.provider,
          });
          testStatus = resp?.success
            ? {
                success: true,
              }
            : {
                success: false,
                message: resp?.error,
              };
        } else if (isControlFlowEnabled) {
          const resp = await testV2Datasource({
            integrationType: getBasePluginId(plugin.id),
            datasourceConfig: testDatasourceConfig,
            actionConfig: testActionConfig,
            datasourceId,
            agents,
            organization,
            configurationId: configMeta.id,
            environment: targetEnvironment,
          });
          testStatus = resp?.success
            ? {
                success: true,
              }
            : {
                success: false,
                message: resp?.error,
              };
        } else {
          testStatus = await testV1Datasource(
            agents,
            organization,
            targetEnvironment,
            datasourceId,
            {
              plugin,
              datasourceConfig: testDatasourceConfig,
            },
            tags,
          );
        }

        if (!testStatus.success) {
          const alert = testStatus.message ?? testStatus.systemError;
          logger.event(UIEvent.TESTED_INTEGRATION, {
            integrationError: alert,
            ...eventContext,
          });
          if (PLUGIN_INTEGRATIONS_IDS.has(plugin.id)) {
            await reportDatasourceCreationError({
              id: datasourceId,
              name: (testDatasourceConfig?.name as string) ?? "",
              organizationId: organizationId,
              pluginId: plugin.id,
              pluginName: plugin.name,
              error: testStatus.message ?? testStatus.systemError,
            });
          }
          return {
            datasourceConfiguration: null,
            shouldCreate: false,
            alert,
          };
        }
        logger.event(UIEvent.TESTED_INTEGRATION, eventContext);
        return {
          datasourceConfiguration,
          shouldCreate: true,
          alert: null,
        };
      } catch (e: any) {
        logger.event(UIEvent.TESTED_INTEGRATION, {
          integrationError: e.message ?? e,
          ...eventContext,
        });
        return {
          datasourceConfiguration: null,
          shouldCreate: false,
          alert: e.message,
        };
      }
    },
    [
      openApiSpec,
      datasourceId,
      getValidAgentsForProfiles,
      organization,
      organizationId,
      plugin,
      profileIdToKey,
      isControlFlowEnabled,
      form,
      kind,
    ],
  );

  const getConnectionTestFailureModalText = useCallback(
    (allTestResult: AllTestResult) => {
      const numFailures = Object.values(allTestResult.testResults).filter(
        (result) => {
          return Boolean(result.alert);
        },
      ).length;
      return `${numFailures} configurations`;
    },
    [],
  );

  const runAllTests = useCallback(async (): Promise<AllTestResult> => {
    const configToTestResult: Record<string, TestResult> = {};
    if (!configIdToFormData) return { testResults: {} };
    for (const configMeta of configMetas ?? []) {
      const formDataCleaned: Record<string, string> = fastClone(
        configIdToFormData[configMeta.id],
      );
      cleanFormData(formDataCleaned, displayAndInitialValuePairList);
      configToTestResult[configMeta.id] = await runTestIfTestable(
        configMeta,
        formDataCleaned,
      );
    }
    const pass = Object.values(configToTestResult).every(
      (testResult) => !testResult.alert,
    );
    if (pass) {
      setAllAlerts(undefined);
      return {
        testResults: configToTestResult,
      };
    }

    // Since at least one of the profiles failed their test, open the connection
    // failure modal.
    setLoading(false);
    const result = {
      testResults: configToTestResult,
    };
    setConnectionTestFailureModalText(
      getConnectionTestFailureModalText(result),
    );

    return result;
  }, [
    configIdToFormData,
    getConnectionTestFailureModalText,
    configMetas,
    displayAndInitialValuePairList,
    runTestIfTestable,
    setAllAlerts,
  ]);

  const onIntegrationTest = useCallback(
    async (
      formValues: Record<string, string>,
      configMeta: ConfigMeta,
    ): Promise<TestResult> => {
      setLoading(true);
      const testResult = await runTestIfTestable(configMeta, formValues);
      if (!testResult.alert) {
        setAlertForConfiguration(configMeta.id, {
          type: "success",
          message: alertComponent(),
        });
      } else {
        setAlertForConfiguration(configMeta.id, {
          type: "error",
          message: alertComponent(testResult.alert),
        });
      }
      setLoading(false);
      return testResult;
    },
    [runTestIfTestable, setAlertForConfiguration, alertComponent],
  );

  const onClearCache = useCallback(
    async (configMeta: ConfigMeta) => {
      if (!datasource?.slug) {
        return;
      }
      setClearingCache(true);
      const { agents } = getValidAgentsForProfiles(
        configMeta?.profileIds ?? [],
      );
      if (!agents || !agents.length) {
        return;
      }
      const resp = await clearSecretStoreCache({
        agents,
        organization,
        datasourceSlug: datasource.slug,
        configurationId: configMeta.id,
      });
      if (!resp.success) {
        setAllAlerts({ type: "error", message: alertComponent(resp.error) });
      } else {
        setAllAlerts({
          type: "success",
          message: alertComponent("Successfully cleared cache"),
        });
      }
      setClearingCache(false);
      return resp;
    },
    [
      datasource?.slug,
      organization,
      setAllAlerts,
      getValidAgentsForProfiles,
      alertComponent,
    ],
  );

  const onDelete = useCallback(async () => {
    if (!datasourceId) {
      return false;
    }

    setLoading(true);
    try {
      const { agents } = getValidAgentsForProfiles([productionProfileId]);
      if (!plugin) {
        setAllAlerts({ message: "Cannot find plugins.", type: "error" });
        return;
      }

      let currentResult;
      if (
        isControlFlowEnabled &&
        kind !== IntegrationKind.SECRET &&
        agents?.length
      ) {
        for (const configMeta of Object.values(configIdToConfigMeta ?? [])) {
          currentResult = await deleteDatasourceOnOrchestrator({
            datasourceId,
            organization,
            pluginName: ExtendedIntegrationPluginMap[plugin?.id],
            agents,
            configurationId: configMeta.id,
          });
          if (!currentResult.success) {
            const message = currentResult.message ?? currentResult.systemError;
            setAllAlerts({
              message: `Failed to delete a datasource on worker: ${message}`,
              type: "error",
            });
            setLoading(false);
            return;
          }
        }
      } else if (kind !== IntegrationKind.SECRET && agents?.length) {
        const tags: AgentDiagnosticMetadata = {
          superblocks_agent_id: agents[0].id,
          superblocks_org_id: organization.id,
          superblocks_org_name: organization.name,
          pluginId: plugin?.id,
          datasourceId: datasourceId,
          profileId: productionProfileId,
          type: DiagnosticType.AGENT,
        };

        currentResult = await deleteDatasourceOnAgent(
          agents,
          organization,
          ENVIRONMENT_PRODUCTION,
          datasourceId,
          tags,
        );

        const deleteDatasourceOnAgentResult = currentResult;
        if (!deleteDatasourceOnAgentResult?.success) {
          const message =
            deleteDatasourceOnAgentResult?.message ??
            deleteDatasourceOnAgentResult?.systemError;
          setAllAlerts({
            message: `Failed to delete a datasource on agent: ${message}`,
            type: "error",
          });
          setLoading(false);
          return;
        }
      }
      // even if the delete on agent fails, we still want to delete the datasource on the server
      // delete datasource on server
      await deleteDatasource(datasourceId);

      setIsDirty(false); // clears navigation blocker
      navigate({
        pathname:
          kind === IntegrationKind.SECRET
            ? SECRETS_MANAGEMENT_URL
            : INTEGRATION_URL,
      });
    } catch (e: any) {
      setAllAlerts({ message: e.message, type: "error" });
    }
  }, [
    datasourceId,
    navigate,
    isControlFlowEnabled,
    getValidAgentsForProfiles,
    productionProfileId,
    plugin,
    organization,
    setAllAlerts,
    configIdToConfigMeta,
    kind,
  ]);

  const [checkTask] = useMarkTaskComplete(TASKS.CONNECT_INTEGRATION);

  const [saveAnyway, setSaveAnyway] = useState(false);

  const updateConfigIdToConfigMetaAfterSave = useCallback(() => {
    if (!configIdToConfigMeta) return;
    let defaultConfigId;
    const configIdsToDelete: string[] = [];
    setConfigIdToConfigMeta(
      produce(configIdToConfigMeta, (draft: Record<string, ConfigMeta>) => {
        Object.entries(configIdToConfigMeta).forEach(
          ([configId, configMeta]) => {
            draft[configId].originalProfileIds = [...configMeta.profileIds];
            if (configMeta.isDefault) {
              defaultConfigId = configId;
            } else if (isEmpty(configMeta.profileIds)) {
              configIdsToDelete.push(configMeta.id);
            }
          },
        );
        configIdsToDelete.forEach((configId) => {
          delete draft[configId];
        });
      }),
    );
    if (
      currentConfigurationId &&
      configIdsToDelete.includes(currentConfigurationId)
    ) {
      setCurrentConfigurationId(defaultConfigId);
    }
  }, [configIdToConfigMeta, currentConfigurationId]);

  const uploadOpenApiSpecAfterSave = useCallback(
    async ({
      disableSuccessNotification,
    }: {
      disableSuccessNotification?: boolean;
    }) => {
      let uploadSuccess = true;
      // save openApiSpec
      if (enableOpenApiUI && openApiSpecFile && specUpdated) {
        setSpecUploading(true);
        sendInfoUINotification({
          message: "Uploading OpenAPI spec...",
          placement: NotificationPosition.top,
          key: OPENAPI_UPLOAD_NOTIFICATION_KEY,
          duration: 1000, // will close once finish uploading
          style: {
            width: "260px",
            animationDuration: "0s",
          },
        });

        try {
          const response = await uploadOpenApiSpecUrl({
            file: openApiSpecFile,
            newName: generateOpenApiSpecRef(datasourceId, openApiSpecFile.name),
            integrationId: datasourceId,
          });
          if (!response.ok) {
            throw new Error("Failed to upload OpenAPI spec.");
          }
          setSpecUpdated(false);
          closeNotification(OPENAPI_UPLOAD_NOTIFICATION_KEY);
          if (!disableSuccessNotification) {
            closeNotification(OPENAPI_UPLOAD_NOTIFICATION_KEY);
            sendSuccessUINotification({
              message: "OpenAPI spec uploaded successfully.",
              placement: NotificationPosition.top,
              key: OPENAPI_UPLOAD_NOTIFICATION_KEY,
              style: {
                animationDuration: "0s",
              },
            });
          }
          uploadSuccess = true;
        } catch (e) {
          closeNotification(OPENAPI_UPLOAD_NOTIFICATION_KEY);
          sendErrorUINotification({
            message: "Failed to upload OpenAPI spec.",
            style: {
              animationDuration: "0s",
            },
            placement: NotificationPosition.top,
            duration: 10,
          });
          uploadSuccess = false;
        } finally {
          setSpecUploading(false);
        }
      }
      return uploadSuccess;
    },
    [datasourceId, enableOpenApiUI, openApiSpecFile, specUpdated],
  );

  const retryUpload = useCallback(async () => {
    closeNotification(OPENAPI_UPLOAD_NOTIFICATION_KEY);
    const success = await uploadOpenApiSpecAfterSave({
      disableSuccessNotification: false,
    });
    if (success) {
      setAllAlerts({
        type: "success",
        message: successAlert,
      });
    }
  }, [setAllAlerts, uploadOpenApiSpecAfterSave]);

  const finishCreation = useCallback(
    async ({
      allTestResult,
      saveAnyway,
      configIdToFormData,
    }: {
      allTestResult: AllTestResult;
      saveAnyway?: boolean; // if profile tests failed but user still wants to save anyway regardless
      configIdToFormData?: Record<string, any>;
    }) => {
      if (!configIdToFormData) return;
      let shouldCreate = true;
      for (const [configId, testResult] of Object.entries(
        allTestResult.testResults,
      )) {
        const IsEmptyConfig = isEmpty(
          configIdToConfigMeta?.[configId]?.profileIds,
        );
        if (!testResult.shouldCreate && !IsEmptyConfig) {
          shouldCreate = false;
          break;
        }
      }
      if (!shouldCreate) {
        // This means that at least one of the profiles failed their test. We show
        // the appropriate error on those profiles.
        for (const [configId, testResult] of Object.entries(
          allTestResult.testResults,
        )) {
          if (isEmpty(configIdToConfigMeta?.[configId]?.profileIds)) continue; // do not show alert for config that has no profiles
          if (testResult.alert) {
            setAlertForConfiguration(configId, {
              type: "error",
              message: alertComponent(testResult.alert),
            });
          } else {
            setAlertForConfiguration(configId, {
              type: "success",
              message: alertComponent(),
            });
          }
        }

        if (!saveAnyway) {
          // showing profile alerts but still proceed to save
          setLoading(false);
          return;
        }
      }
      if (!saveAnyway) {
        // do not setAllAlerts if save anyway
        let haveDatasourceConfigsForAllProfiles = true;
        for (const [configId, testResult] of Object.entries(
          allTestResult.testResults,
        )) {
          if (isEmpty(configIdToConfigMeta?.[configId]?.profileIds)) continue; // do not show alert for config that has no profiles
          haveDatasourceConfigsForAllProfiles =
            haveDatasourceConfigsForAllProfiles &&
            Boolean(testResult.datasourceConfiguration);
        }

        if (datasourceId && !haveDatasourceConfigsForAllProfiles) {
          setAllAlerts({
            type: "error",
            message: "Failed to get datasource configuration.",
          });
          setLoading(false);
          return;
        }
      }

      // saveAnyway cannot skip plugin failure
      if (!plugin) {
        setAllAlerts({
          type: "error",
          message: "Failed to get plugins.",
        });
        setLoading(false);
        return;
      }

      try {
        const configurationIdToDatasourceConfig: Record<
          string,
          DatasourceConfiguration
        > = {};
        const tpe = PLUGIN_ID_TO_PROTO_CLASS[plugin.id] || null;
        for (const configurationId of Object.keys(allTestResult.testResults)) {
          configurationIdToDatasourceConfig[configurationId] = {
            ...(saveAnyway
              ? mapFormValuesToObject(
                  configIdToFormData[configurationId],
                  tpe,
                  plugin.datasourceTemplate,
                )
              : allTestResult.testResults[configurationId]
                  .datasourceConfiguration),
            name: form.getFieldValue("datasourceName"),
          };
        }

        const configMetasWithoutEmptyProfiles = configMetas?.filter(
          (configMeta) =>
            configMeta?.isDefault || !isEmpty(configMeta?.profileIds),
        );

        let uploadSuccess = true;
        if (!isNew && configIdToConfigMeta && configMetasWithoutEmptyProfiles) {
          // update datasource
          await updateDatasource({
            id: datasourceId,
            name: form.getFieldValue("datasourceName"),
            configurations: configMetasWithoutEmptyProfiles.map(
              (configMeta) => ({
                id: configMeta.id,
                isDefault: configIdToConfigMeta[configMeta.id].isDefault,
                integrationId: datasourceId,
                configuration:
                  openApiSpecFile && pluginId === RestApiIntegrationPlugin.id
                    ? {
                        ...configurationIdToDatasourceConfig[configMeta.id],
                        openApiSpecRef: generateOpenApiSpecRef(
                          datasourceId,
                          openApiSpecFile.name,
                        ),
                      }
                    : // Fill newly created config with openApiSpecRef
                    openApiSpecRef
                    ? {
                        ...configurationIdToDatasourceConfig[configMeta.id],
                        openApiSpecRef,
                      }
                    : configurationIdToDatasourceConfig[configMeta.id],
                profileIds: configIdToConfigMeta[configMeta.id].isDefault
                  ? []
                  : configIdToConfigMeta[configMeta.id]?.profileIds,
                created: configIdToConfigMeta[configMeta.id].created,
              }),
            ),
          });
          await getDatasources({ organizationId });
          updateConfigIdToConfigMetaAfterSave();
          uploadSuccess = await uploadOpenApiSpecAfterSave({
            disableSuccessNotification: false,
          });
        } else if (configIdToConfigMeta && configMetasWithoutEmptyProfiles) {
          // create datasource
          await createDatasource({
            id: datasourceId,
            name: form.getFieldValue("datasourceName"),
            slug: form.getFieldValue("datasourceSlug"),
            pluginId: plugin.id,
            kind,
            configurations: configMetasWithoutEmptyProfiles.map(
              (configMeta) => ({
                id: configMeta.id,
                isDefault: configIdToConfigMeta[configMeta.id].isDefault,
                integrationId: datasourceId,
                configuration:
                  openApiSpecFile && pluginId === RestApiIntegrationPlugin.id
                    ? {
                        ...configurationIdToDatasourceConfig[configMeta.id],
                        openApiSpecRef: generateOpenApiSpecRef(
                          datasourceId,
                          openApiSpecFile.name,
                        ),
                      }
                    : configurationIdToDatasourceConfig[configMeta.id],
                profileIds: configIdToConfigMeta[configMeta.id].isDefault
                  ? []
                  : configIdToConfigMeta[configMeta.id]?.profileIds,
                created: configIdToConfigMeta[configMeta.id].created,
              }),
            ),
          });
          await getDatasources({ organizationId });
          updateConfigIdToConfigMetaAfterSave();
          uploadSuccess = await uploadOpenApiSpecAfterSave({
            disableSuccessNotification: true,
          });

          if (enableTemplate) {
            const templates: TemplateResponseDto[] = await getTemplateByPlugin(
              pluginId,
            );

            const listedTemplates = templates.filter(
              (template) => template.status === TemplateStatus.LISTED,
            );

            if (listedTemplates != null && !isEmpty(listedTemplates)) {
              setTemplate(templates[0]);
              setCreateFromTemplateOptionModalVisible(true);
            } else {
              setCreatedModalVisible(true);
            }
          } else {
            setCreatedModalVisible(true);
          }
        }
        if (!saveAnyway) {
          if (uploadSuccess) {
            setAllAlerts({
              message: successAlert,
              type: "success",
            });
          } else {
            setAllAlerts({
              message: <UploadFailAlert retryUpload={retryUpload} />,
              type: "error",
            });
          }
        }
        checkTask();
        setIsDirty(false);
      } catch (e: any) {
        setAllAlerts({ message: e.message, type: "error" });
      }
      setLoading(false);
    },
    [
      plugin,
      configIdToConfigMeta,
      setAlertForConfiguration,
      alertComponent,
      datasourceId,
      setAllAlerts,
      configMetas,
      isNew,
      checkTask,
      form,
      updateDatasource,
      updateConfigIdToConfigMetaAfterSave,
      uploadOpenApiSpecAfterSave,
      openApiSpecFile,
      pluginId,
      openApiSpecRef,
      createDatasource,
      kind,
      getDatasources,
      organizationId,
      retryUpload,
      setCreateFromTemplateOptionModalVisible,
      enableTemplate,
      setTemplate,
    ],
  );

  const [wasDatasourceNameEdited, setWasDatasourceNameEdited] = useState(
    form.getFieldValue("datasourceName") !== (datasource?.name ?? ""),
  );

  useEffect(() => {
    form.setFieldsValue({
      datasourceName: datasource?.name ?? "",
      datasourceSlug: datasource?.slug ?? "",
    });
    setWasDatasourceNameEdited(false);
  }, [datasource?.name, datasource?.slug, form]);

  const validateDatasourceName = useCallback(async () => {
    try {
      await form.validateFields();
      return true;
    } catch (errors) {
      return false;
    }
  }, [form]);

  const onIntegrationSubmit = useCallback(async () => {
    if (!configIdToConfigMeta || !configIdToFormData) return;

    setLoading(true);
    const allTestResult = await runAllTests();
    const configIdToFormDataFiltered = fastClone(configIdToFormData);
    Object.keys(configIdToFormDataFiltered).forEach((configId) => {
      cleanFormData(
        configIdToFormDataFiltered[configId],
        displayAndInitialValuePairList,
      );
    });

    setAllTestResult(allTestResult);
    return finishCreation({
      allTestResult,
      saveAnyway: false,
      configIdToFormData: configIdToFormDataFiltered,
    });
  }, [
    configIdToConfigMeta,
    configIdToFormData,
    runAllTests,
    finishCreation,
    displayAndInitialValuePairList,
  ]);

  const handleNameChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setIsDirty(true);
      form.setFieldsValue({
        datasourceName: e?.target?.value,
        datasourceSlug: datasource
          ? datasource?.slug
          : generateKey(e?.target?.value),
      });
      if (e?.target?.value !== datasource?.name) {
        setWasDatasourceNameEdited(true);
      } else {
        setWasDatasourceNameEdited(false);
      }
    },
    [datasource, form],
  );

  const newNameValidator = useCallback(
    async (rule: any, value: any) => {
      if (isNew && value) {
        const ds = datasources.find((d) => d?.integration?.slug === value);
        if (ds) {
          return Promise.reject("Key is already in use");
        }
        return Promise.resolve();
      }
    },
    [isNew, datasources],
  );

  const [connectionTestFailureModalText, setConnectionTestFailureModalText] =
    useState<string>();

  const SUPPORT_EMAIL = "support@superblocks.com";

  const onSave = useCallback(
    async (unusedConfigConfirmed?: boolean) => {
      // check if there are any configs with zero profiles
      const hasUnusedConfig = configMetas?.some(
        (configMeta) => !configMeta.isDefault && isEmpty(configMeta.profileIds),
      );

      if (hasUnusedConfig && !unusedConfigConfirmed) {
        setShowUnusedConfigModal(true);
        return;
      }

      await onIntegrationSubmit();
    },
    [configMetas, onIntegrationSubmit],
  );

  const onSkip = useCallback(() => {
    setCreatedModalVisible(false);
    navigate({ pathname: HOME_URL });
  }, [navigate]);

  const onCancel = useCallback(() => {
    setIsDirty(false);
    setSpecUploading(false);
    setSpecUpdated(false);
    navigate({
      pathname:
        kind === IntegrationKind.SECRET
          ? SECRETS_MANAGEMENT_URL
          : INTEGRATION_URL,
    });
  }, [navigate, kind]);

  const [showUnusedConfigModal, setShowUnusedConfigModal] = useState(false);

  const onCancelUnusedConfigConfirm = useCallback(() => {
    setShowUnusedConfigModal(false);
  }, []);

  const unusedConfigModal = useMemo(() => {
    if (!configMetas) return null;
    const unusedConfigCount = configMetas?.filter(
      (configMeta) => !configMeta.isDefault && isEmpty(configMeta.profileIds),
    ).length;
    return (
      <Modal
        open={showUnusedConfigModal}
        onCancel={onCancelUnusedConfigConfirm}
        footer={null}
        width={450}
        title={
          <TitleWrapper>
            <WarningHaloIcon width={30} height={30} />
            <TitleTextWrapper>{`Remove unused configurations?`}</TitleTextWrapper>
          </TitleWrapper>
        }
      >
        <ModalDescriptionText>
          {`${
            unusedConfigCount > 1
              ? `${unusedConfigCount} configurations aren't`
              : "One configuration is not"
          } used by any Profiles and will be removed. Make
        sure you've entered the right information before continuing.`}
        </ModalDescriptionText>
        <FooterWrapper>
          <Button onClick={onCancelUnusedConfigConfirm}>Cancel</Button>
          <Button
            type="primary"
            onClick={() => {
              setShowUnusedConfigModal(false);
              onSave(true);
            }}
            data-test="empty-config-modal-continue"
          >
            Continue
          </Button>
        </FooterWrapper>
      </Modal>
    );
  }, [configMetas, onCancelUnusedConfigConfirm, onSave, showUnusedConfigModal]);

  const connectionTestFailureModal = useMemo(() => {
    return (
      <Modal
        data-test="connection-test-failure-modal"
        open={Boolean(connectionTestFailureModalText)}
        closable
        onCancel={() => {
          setConnectionTestFailureModalText(undefined);
        }}
        title={
          <TitleWrapper>
            <DangerIcon width={30} height={30} />
            <TitleTextWrapper>{`Connection tests failed`}</TitleTextWrapper>
          </TitleWrapper>
        }
        footer={null}
        width={450}
      >
        <Space direction="vertical" style={{ marginBottom: "20px" }}>
          <div>
            <ModalDescriptionText>
              <p>
                {`Your ${nameForKind} wasn't saved because tests failed for ${connectionTestFailureModalText}. Please fix errors and try again.`}
              </p>
              <p>
                Learn more about{" "}
                <Typography.Link
                  href={DOCS_PAGE_URL(DocsPage.TROUBLESHOOTING_CONNECTIONS)}
                  target="_blank"
                >
                  {" "}
                  Common Connection Issues{" "}
                </Typography.Link>{" "}
                or{" "}
                <Typography.Link
                  href={`mailto:${SUPPORT_EMAIL}`}
                  target="_blank"
                >
                  {" "}
                  contact support.
                </Typography.Link>
              </p>
            </ModalDescriptionText>
          </div>
        </Space>
        <StyledButtonsWrapper>
          <Button
            data-test="integration-test-failure-modal-save"
            key="save"
            type="primary"
            danger
            onClick={async () => {
              if (allTestResult) {
                setSaveAnyway(true);
                await finishCreation({
                  allTestResult,
                  saveAnyway: true,
                  configIdToFormData,
                });
              }
              setConnectionTestFailureModalText(undefined);
            }}
            loading={specUploading}
          >
            {isNew ? "Create anyway" : "Save anyway"}
          </Button>
          <Button
            data-test="integration-test-failure-modal-close"
            key="close"
            type="primary"
            danger
            onClick={() => {
              setSaveAnyway(false);
              setConnectionTestFailureModalText(undefined);
            }}
            loading={specUploading}
          >
            Close
          </Button>
        </StyledButtonsWrapper>
      </Modal>
    );
  }, [
    allTestResult,
    configIdToFormData,
    connectionTestFailureModalText,
    finishCreation,
    isNew,
    specUploading,
    nameForKind,
  ]);

  const [shareModalVisible, setShareModalVisible] = useState(false);

  const isIntegrationModified = useMemo(() => {
    return (
      wasDatasourceNameEdited || !isEqual(initialValues, configIdToFormData)
    );
  }, [configIdToFormData, initialValues, wasDatasourceNameEdited]);

  const [activeTab, setActiveTab] = useState<string>(
    IntegrationPageTabKeys.Configurations,
  );

  const configIdToFormRefs = useRef<Record<string, React.RefObject<FormRef>>>(
    {},
  );
  const [getConnectedTokens] = useSaga(getConnectedTokensSaga);

  // load connected tokens for all configurations
  useEffect(() => {
    for (const configMeta of configMetas ?? []) {
      const configuration = datasource?.configurations?.find(
        (config) => config.id === configMeta.id,
      );
      if (
        configuration &&
        isAuthenticatedDatasourceConfig(configuration.configuration)
      ) {
        const authId = getAuthId(
          configuration.configuration.authType,
          configuration.configuration.authConfig,
          configuration.integrationId,
          configuration.id,
        );
        const tokenScope = configuration.configuration?.authConfig
          ?.tokenScope as TokenScope;
        getConnectedTokens({
          integrationId: datasourceId,
          integrationConfigurationId: configMeta.id,
          authId,
          tokenScope,
        });
      }
    }
  }, [datasourceId, getConnectedTokens, configMetas, datasource]);

  const configurationsForm = configMetas?.map((configMeta) => {
    const profile =
      profiles.find((p) => p.id === configMeta.profileIds[0]) ?? profiles[0]; // used for fetch credential from workflow
    return (
      configIdToFormData &&
      configIdToConfigMeta &&
      initialValues &&
      plugin &&
      plugin.datasourceTemplate && (
        <IntegrationSetupForm
          key={configMeta.id}
          isNew={isNew}
          alerts={alerts}
          activeConfigurationId={currentConfigurationId}
          loading={loading || specUploading}
          loadingInitialValues={loadingInitialValues}
          formTemplate={plugin.datasourceTemplate}
          initialValues={
            initialValues[configMeta.id] ?? configIdToFormData[configMeta.id]
          }
          onRunTest={onIntegrationTest}
          onClearCache={onClearCache}
          clearingCache={clearingCache}
          hasTest={isPluginTestable(plugin, openApiSpec)}
          pluginId={plugin.id}
          datasourceId={datasourceId}
          datasourceName={form.getFieldValue("datasourceName")}
          organizationId={organizationId}
          setCurrentConfigurationId={setCurrentConfigurationId}
          organization={organization}
          setConfigIdToFormData={setConfigIdToFormData}
          configIdToFormData={configIdToFormData}
          profile={profile}
          profiles={profiles}
          configMeta={configMeta}
          configMetas={configMetas}
          setConfigIdToConfigMeta={
            setConfigIdToConfigMeta as (
              value:
                | Record<string, ConfigMeta>
                | ((configIdToConfigMeta: Record<string, ConfigMeta>) => void),
            ) => void
          }
          initialValuesFn={initialValuesFn}
          setIsDirty={setIsDirty}
          configIdToFormRefs={configIdToFormRefs}
          kind={kind}
          readOnly={!canEdit}
        />
      )
    );
  });

  const showUploadButton =
    enableOpenApiUI && pluginId === RestApiIntegrationPlugin.id;

  const showApiSpecificationTab =
    enableOpenApiUI &&
    enableOpenApiDoc &&
    // Checking if the plugin is, or extends, a REST API Integration plugin.
    extendsRestApiIntegrationPlugin(pluginId);

  const hasSpec = useMemo(
    () => Boolean(openApiSpec || openApiSpecRef),
    [openApiSpec, openApiSpecRef],
  );

  const rootBreadcrumb = useMemo(() => {
    switch (kind) {
      case IntegrationKind.SECRET:
        return {
          title: SECRETS_MANAGEMENT_TITLE,
          link: SECRETS_MANAGEMENT_URL,
        };
      case IntegrationKind.PLUGIN:
      default:
        return {
          title: INTEGRATIONS_TITLE,
          link: INTEGRATIONS_URL,
        };
    }
  }, [kind]);

  const isAssignRolesEnabled = useFeatureFlag(
    Flag.ENABLE_RBAC_ROLE_ASSIGNMENTS,
  );

  return (
    <Layout Header={<Header />} Sider={<PageNav />}>
      <Wrapper data-test="integration-form" hasTab={showApiSpecificationTab}>
        <BreadCrumb paths={[rootBreadcrumb, { title }]} />
        <div className={HeaderWrapper} style={{ flexDirection: "row" }}>
          <div
            className="page-header-title"
            style={{ display: "flex", flexDirection: "row" }}
          >
            <Icon src={plugin?.iconLocation} alt="x" />
            {title}
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "8px",
            }}
          >
            {plugin?.docsUrl && (
              <DocsButton>
                <a href={plugin.docsUrl} target="_blank" rel="noreferrer">
                  <DocsIcon />
                  View Docs
                </a>
              </DocsButton>
            )}
            {showUploadButton && (
              <StyledUploadButton
                onClick={() => {
                  setSpecUploadModalVisible(true);
                }}
                disabled={
                  Boolean(datasource?.demoIntegrationId) || specUploading
                }
              >
                <div className="upload-button-content">
                  {specUploading ? (
                    <Spinner size="small" spinning={true} />
                  ) : (
                    <UploadIcon />
                  )}
                  {openApiSpecFile
                    ? openApiSpecFile.name
                    : openApiSpecRef
                    ? getOpenApiSpecFileName(openApiSpecRef)
                    : "Upload OpenAPI"}
                </div>
              </StyledUploadButton>
            )}
            {!isNew && kind !== IntegrationKind.SECRET && (
              <Tooltip
                title={
                  canShare
                    ? undefined
                    : "You do not have permission to share this integration."
                }
              >
                <Button
                  type="primary"
                  disabled={!canShare}
                  onClick={() => setShareModalVisible(true)}
                >
                  Share
                </Button>
              </Tooltip>
            )}
          </div>
        </div>
        <Form
          form={form}
          initialValues={{
            datasourceName: datasource?.name ?? "",
            datasourceSlug: datasource?.slug ?? "",
          }}
          layout={"vertical"}
          requiredMark={false}
          validateTrigger={"onBlur"}
        >
          <StyledFormItem
            label="Name"
            name="datasourceName"
            style={{ marginBottom: "12px" }}
            rules={[
              { required: true, message: `Please provide ${nameForKind} name` },
            ]}
          >
            <Input
              id="name"
              placeholder={
                kind === IntegrationKind.SECRET
                  ? "Example Secrets Manager"
                  : "Example API"
              }
              onChange={handleNameChange}
            />
          </StyledFormItem>
          {kind === IntegrationKind.SECRET && (
            <>
              <StyledFormItem
                label="Key"
                name="datasourceSlug"
                style={{ marginBottom: "1px" }}
                rules={
                  isNew
                    ? [
                        {
                          validator: newNameValidator,
                          message: "Key is already in use.",
                        },
                      ]
                    : []
                }
              >
                <Input
                  placeholder={"example_secrets_manager"}
                  disabled={true}
                  id="datasourceSlug"
                  data-test={"integration-key-create"}
                />
              </StyledFormItem>
              <span className={HelpText}>
                Use this key to reference this secret store in code. It cannot
                be edited.
              </span>
            </>
          )}
        </Form>
        {showApiSpecificationTab ? (
          <Tabs
            activeKey={activeTab}
            defaultActiveKey={IntegrationPageTabKeys.Configurations}
            onChange={setActiveTab}
          >
            <TabPane
              tab="Configurations"
              key={IntegrationPageTabKeys.Configurations}
              data-test="integration-tab-configurations"
            >
              {configurationsForm}
            </TabPane>
            <TabPane
              tab={
                hasSpec ? (
                  "API Specification"
                ) : (
                  <Tooltip
                    title={
                      showUploadButton
                        ? "Please upload an OpenAPI specification first."
                        : ""
                    }
                  >
                    <div>API Specification</div>
                  </Tooltip>
                )
              }
              key={IntegrationPageTabKeys.OpenApiSpec}
              data-test="integration-tab-openApiSpec"
              disabled={!hasSpec}
            >
              <ApiSpecification
                integrationId={datasourceId}
                openApiSpec={openApiSpec}
                openApiSpecName={
                  openApiSpecFile?.name
                    ? openApiSpecFile.name
                    : getOpenApiSpecFileName(openApiSpecRef ?? "")
                }
                openApiString={openApiString}
              />
            </TabPane>
          </Tabs>
        ) : (
          configurationsForm
        )}
        {configMetas && (
          <IntegrationFooter
            loading={loading || specUploading}
            readOnly={!canEdit}
            onFinish={onSave}
            onCancel={onCancel}
            isNew={isNew}
            configMeta={configIdToConfigMeta?.[currentConfigurationId ?? ""]}
            validateDatasourceName={validateDatasourceName}
            formRef={configIdToFormRefs.current[currentConfigurationId ?? ""]}
            pluginName={title}
            onDelete={onDelete}
            isIntegrationModified={isIntegrationModified}
            kind={kind}
            integrationName={datasource?.name}
            createFromTemplateOptionModalVisible={
              createFromTemplateOptionModalVisible
            }
            setCreateFromTemplateOptionModalVisible={
              setCreateFromTemplateOptionModalVisible
            }
            createAppModalVisible={createAppModalVisible}
            setCreateAppModalVisible={setCreateAppModalVisible}
            template={template}
            organizationId={organizationId}
            pluginId={pluginId}
            datasourceId={datasourceId}
            canDelete={canDelete}
          />
        )}

        {plugin && (
          <Modal
            open={createdModalVisible}
            closable
            onCancel={() => {
              setCreatedModalVisible(false);
              navigate({
                pathname:
                  kind === IntegrationKind.SECRET
                    ? `${SECRETS_MANAGEMENT_URL}/${pluginId}/${datasourceId}`
                    : `${INTEGRATION_URL}${pluginId}/${datasourceId}`,
              });
            }}
            title={
              <TitleWrapper>
                <ChecklistIcons.BADGE_ICON
                  width={24}
                  height={24}
                  color={theme.colors.SUCCESS}
                />
                <TitleTextWrapper>{`${
                  nameForKind.charAt(0).toUpperCase() + nameForKind.slice(1)
                } created`}</TitleTextWrapper>
              </TitleWrapper>
            }
            footer={null}
            width={450}
          >
            <Space direction="vertical" style={{ marginBottom: "20px" }}>
              <div>
                <Typography.Text
                  style={{
                    color: LegacyNamedColors.GRAY_DARK,
                  }}
                >
                  {`Your ${nameForKind} ${
                    saveAnyway ? "" : "passed all connection tests and "
                  }was created
                  successfully.`}
                </Typography.Text>
              </div>
            </Space>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <Button
                data-test="integration-created-confirm"
                key="submit"
                type="primary"
                onClick={onSkip}
                loading={specUploading}
              >
                Start building
              </Button>
            </div>
          </Modal>
        )}
        {connectionTestFailureModal}
        {unusedConfigModal}
        {isAssignRolesEnabled ? (
          <ShareModalV2
            isVisible={shareModalVisible}
            resourceId={datasourceId}
            resourceDisplayName={datasource?.name || "Integration"}
            organizationId={organizationId}
            setShareModalVisible={setShareModalVisible}
            resourceType={ResourceTypeEnum.INTEGRATIONS}
            roleType={RoleTypeEnum.INTEGRATIONS}
            inEditMode={true}
          />
        ) : (
          <ShareModal
            isVisible={shareModalVisible}
            resourceId={datasourceId}
            resourceDisplayName={datasource?.name || "Integration"}
            organizationId={organizationId}
            setShareModalVisible={setShareModalVisible}
            roles={[ShareRole.CONFIGURATOR, ShareRole.BUILD_WITH]}
            resourceType={PermissionedEntities.INTEGRATION}
          />
        )}
        <SpecUploadModal
          integrationId={datasourceId}
          visible={specUploadModalVisible}
          setVisible={setSpecUploadModalVisible}
          setOpenApiSpecFile={setOpenApiSpecFile}
          setSpecUpdated={setSpecUpdated}
          setConfigIdToFormData={setConfigIdToFormData}
          configIdToFormData={configIdToFormData}
          setOpenApiString={setOpenApiString}
        />
      </Wrapper>
    </Layout>
  );
};
