import { TASKS } from "@superblocksteam/shared";
import { Tooltip, Typography } from "antd";
import React, { useCallback, useState } from "react";
import { useSelector } from "react-redux";
import { ReactComponent as WorkflowIllustration } from "assets/icons/home/workflow-illustration.svg";
import { Button } from "components/ui/Button";
import useCreateNewApi, {
  CreateApiSuccessOptions,
} from "hooks/api/useCreateNewApi";
import { ApiTriggerType } from "store/slices/apis";
import { selectUserChecklist } from "store/slices/user";
import { EntityType } from "utils/entity";
import { getColorForType, getIconForType } from "../EntityCard";
import {
  CardBody,
  CardTitleRow,
  CreateEntityIconWrapper,
  ICON_SIZE,
  StyledCard,
} from "./CommonComponents";
import { CommonCardProps, CreateWorkflowProps, CreateSteps } from "./common";

export const CreateWorkflowCard = (
  props: CommonCardProps & CreateWorkflowProps,
) => {
  const {
    highlight,
    setGeneratedEntityType,
    processFieldValues,
    resetFields,
    setWorkflowApiId,
    setActiveStep,
    isDisabled,
  } = props;

  const [isCreating, setIsCreating] = useState(false);
  const createWorkflow = useCreateNewApi(
    {
      triggerType: ApiTriggerType.WORKFLOW,
    },
    CreateApiSuccessOptions.NONE,
  );
  const handleCreateNew = useCallback(
    async (ev: React.MouseEvent<HTMLElement, MouseEvent>) => {
      if (isDisabled) return;
      ev.stopPropagation();
      setIsCreating(true);
      const values = await processFieldValues();
      const api = await createWorkflow({
        apiName: values.name,
        folder: values.folder,
      });
      if (api) {
        setWorkflowApiId(api.id);
        setActiveStep(CreateSteps.WORKFLOW_VARIABLES);
        resetFields();
      }
    },
    [
      processFieldValues,
      setActiveStep,
      createWorkflow,
      resetFields,
      setWorkflowApiId,
      isDisabled,
    ],
  );
  const checklist = useSelector(selectUserChecklist);
  const workflowNotDone = !checklist.find(
    (task) => task.id === TASKS.DEPLOY_WORKFLOW,
  )?.completed;

  return (
    <Tooltip
      title={isDisabled ? "You do not have permission to create workflows" : ""}
    >
      <StyledCard
        highlight={highlight}
        color={getColorForType(EntityType.WORKFLOW)}
        onMouseEnter={(e) => {
          setGeneratedEntityType(EntityType.WORKFLOW);
        }}
        disabled={isCreating || isDisabled}
        onClick={handleCreateNew}
      >
        <WorkflowIllustration />
        <CardBody>
          <CreateEntityIconWrapper
            className="entity-type-icon"
            color={getColorForType(EntityType.WORKFLOW)}
            size={ICON_SIZE}
          >
            {getIconForType(EntityType.WORKFLOW)}
          </CreateEntityIconWrapper>
          <CardTitleRow
            entityType={EntityType.WORKFLOW}
            taskNotDone={workflowNotDone}
          />
          <Typography.Paragraph>
            Build REST endpoints to call from within existing admin tools or
            Superblocks Apps
          </Typography.Paragraph>

          <Button
            type="default"
            block
            disabled={isCreating}
            onClick={handleCreateNew}
            data-test="create-workflow"
          >
            Create Workflow
          </Button>
        </CardBody>
      </StyledCard>
    </Tooltip>
  );
};
