import { Col, Row, Space, Typography } from "antd";
import React from "react";
import styled from "styled-components";
import { ReactComponent as PlayIcon } from "assets/icons/home/play-duotone.svg";
import { MainWrapper } from "components/app";
import { Button } from "components/ui/Button";
import Link from "components/ui/Link";
import { HeaderWrapper } from "components/ui/Page";
import { Entity } from "pages/Home/EntityCard";
import Applicatons from "../Home/assets/applications.png";

const StyledImage = styled.img`
  max-width: 60%;
  height: auto;
`;

const Text = styled(Typography.Text)`
  font-size: 20px;
`;

const WatchVideoButton = styled(Typography.Link)`
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 500;

  svg {
    margin-right: 8px;
    path {
      fill: ${(props) => props.theme.colors.ACCENT_BLUE_500};
    }
  }
`;

interface Props {
  title: string;
  description: string;
  callToActionButtonText: string;
  onClickCallToAction: () => void;
  onClickDemoVideo: () => void;
  dataTest?: string;
  demoApp?: Entity;
  demoAppButtonText?: string;
}

const LandingPage = ({
  title,
  description,
  callToActionButtonText,
  onClickCallToAction,
  onClickDemoVideo,
  dataTest,
  demoApp,
  demoAppButtonText,
}: Props) => {
  return (
    <MainWrapper paddingRight="0px" paddingBottom="0px" data-test={dataTest}>
      <div className={HeaderWrapper}>
        <div className="page-header-title"> {title} </div>
      </div>
      <Row justify="space-between">
        <Col span={6}>
          <Space direction="vertical" size={20}>
            <Text>{description}</Text>
            <WatchVideoButton onClick={onClickDemoVideo}>
              <PlayIcon /> Watch 5 min demo
            </WatchVideoButton>
            <div>
              <Button
                type="primary"
                onClick={onClickCallToAction}
                data-test="create-entity"
              >
                {callToActionButtonText}
              </Button>
              {demoApp && (
                <Link
                  to={demoApp?.editUrl ?? ""}
                  className="entity-link"
                  style={{ marginLeft: "12px" }}
                >
                  <Button>{demoAppButtonText}</Button>
                </Link>
              )}
            </div>
          </Space>
        </Col>
        <StyledImage src={Applicatons} />
      </Row>
    </MainWrapper>
  );
};

export default LandingPage;
