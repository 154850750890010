import { Modal } from "antd";
import React from "react";
import { ReactComponent as ErrorCircleIcon } from "assets/icons/common/error-circle.svg";
import { colors } from "styles/colors";
import { styleAsClass } from "styles/styleAsClass";
import {
  DangerButton,
  DangerButtonCssString,
  PrimaryButtonCssString,
  SecondaryButton,
  SecondaryButtonCssString,
} from "./Button";

export const DefaultModalWidth = 384;

export const ModalWrapClass = styleAsClass`
  .ant-modal-content{
    font-size: 13px;
    input {
      font-size: 13px;
    }
  }
  .ant-modal-header {
    padding: 14px 12px;
    font-size: 15px;
    line-height: 20px;
    font-weight: 600;
    color: ${colors.GREY_900};
    height: 48px;
  }
  .ant-modal-title {
    font-size: 15px;
    line-height: 20px;
    font-weight: 600;
    color: ${colors.GREY_900};
  }

  .ant-modal-close-x {
    width: 48px;
    height: 48px;
    line-height: 48px;
    font-size: 13px;
  }

  .ant-modal-body {
    padding: 16px;
  }
`;

export const ModalInnerWrapper = styleAsClass`
  display: flex;
  flex-direction: column;
  font-size: 12px;
`;
export const FooterWrapper = styleAsClass`
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  gap: 12px;
`;

export const FooterWrapperWide = styleAsClass`
  margin-top: 20px;
  display: grid;
  justify-content: space-between;
  gap: 10px;
  grid-template-columns: 1fr 1fr;
`;

const ModalTitleWrapper = styleAsClass`
  display: flex;
  align-items: center;
  gap: 8px;
  margin: -6px 0px;
`;

const DeleteMessage = styleAsClass`
  font-size: 13px !important;
  line-height: 20px;
  color: ${colors.GREY_500};
  margin-bottom: 20px;
  display: block;
  b {
    font-weight: 500;
  }
`;

const ErrorWrapper = styleAsClass`
  color: ${colors.RED_500};
`;

export const ConfirmModalClass = styleAsClass`
  width: 384px;
  &.ant-modal-confirm .ant-modal-body {
    padding: 16px;
  }
  .ant-modal-confirm-body {
    display: grid;
    gap: 0px;
    align-items: center;
    grid-template-columns: 32px 1fr;
    grid-template-rows: auto;

    & > svg:first-child {
      margin-top: -8px;
    }

    .ant-modal-confirm-title {
      margin-top: -6px;
      padding: 4px 0px;
    }

    & > .ant-modal-confirm-title:first-child {
      grid-column: 1 / span 2; // only title and no icon, title takes full width of first row
    }

    & > .ant-modal-confirm-title:nth-child(2) ,  & > .ant-modal-confirm-content:nth-child(2) {
      margin-left: 8px;
      margin-top: -6px;
    }
    
    .ant-modal-confirm-content:empty {
      border: none !important;
      padding: 0px !important;
      margin: 0px !important;
    }

    & > .ant-modal-confirm-content:only-child {
      grid-column: 1 / span 2;
    }

    & > .ant-modal-confirm-content:nth-child(3) {
      margin-top: 8px;
      grid-column: 1 / span 2; // this is content, and there is icon and title, so span across both columns
    }

    & > .ant-modal-confirm-title + .ant-modal-confirm-content {
      border-top: 1px solid ${colors.GREY_100};
      padding: 16px 16px 0 16px;
      margin-left: -16px;
      margin-right: -16px;
      margin-top: 8px;
      grid-column: 1 / span 2; // only title and content, content takes full width of second ro
    }
      
  }

  .ant-modal-confirm-body > svg:first-child {
    flex: 0 0 32px;
  }

  .ant-modal-confirm-body .ant-modal-confirm-title {
    font-size: 15px;
    line-height: 20px;
    font-weight: 600;
    color: ${colors.GREY_900};
    width: 100%;
  }

  .ant-modal-confirm-body .ant-modal-confirm-content {
    margin-top: 0px;
    font-size: 13px;
    line-height: 20px;
    .ant-typography {
     color: ${colors.GREY_500};
    }
    color: ${colors.GREY_500};
    word-break: break-word;
  }

  .ant-modal-confirm-btns {
    margin-top: 20px !important;
    display: grid;
    justify-content: space-between;
    gap: 10px;
    grid-template-columns: 1fr 1fr;
  }

  .ant-btn.ant-btn-default {
    ${SecondaryButtonCssString}
  }

  .ant-btn.ant-btn-primary {
    ${PrimaryButtonCssString}
  }

  .ant-btn.ant-btn-default.ant-btn-dangerous {
    ${DangerButtonCssString}
  }

  .ant-modal-close-x {
    width: 48px;
    height: 48px;
    font-size: 13px;
    line-height: 48px;
    color: ${colors.GREY_300};
  }
`;

export const DeleteModal = ({
  open,
  title,
  children,
  onDelete,
  onCancel,
  isDeleting,
  error,
  dataTestName,
  confirmText = "Delete",
  disableDelete,
  width,
}: {
  open: boolean;
  title: string;
  children: React.ReactNode;
  onCancel: () => void;
  onDelete: () => void;
  isDeleting: boolean;
  error?: string;
  dataTestName?: string;
  confirmText?: string;
  disableDelete?: boolean;
  width?: number;
}) => {
  return (
    <Modal
      width={width ?? DefaultModalWidth}
      wrapClassName={ModalWrapClass}
      open={open}
      onCancel={onCancel}
      footer={null}
      title={
        <div className={ModalTitleWrapper}>
          <ErrorCircleIcon />
          <div>{title}</div>
        </div>
      }
      destroyOnClose={true}
    >
      <div>
        <div className={`${DeleteMessage}`}>{children}</div>
        {error && <div className={ErrorWrapper}>{error}</div>}
        <div className={FooterWrapperWide}>
          <SecondaryButton
            onClick={onCancel}
            loading={isDeleting}
            data-test={`cancel-delete-${dataTestName ?? ""}`}
          >
            Cancel
          </SecondaryButton>
          <DangerButton
            onClick={onDelete}
            loading={isDeleting}
            data-test={`confirm-delete-${dataTestName ?? ""}`}
            disabled={disableDelete}
          >
            {confirmText}
          </DangerButton>
        </div>
      </div>
    </Modal>
  );
};
