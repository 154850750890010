import {
  FormItemDisplay,
  FormRow,
  FormTemplate,
  InitialValue,
  isFormItem,
} from "@superblocksteam/shared";
import { Alert } from "antd";

import { get, isEmpty } from "lodash";
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useSelector } from "react-redux";
import ExpandIconButton from "components/ui/IconButtons/ExpandIconButton";
import { FullWidthSpace } from "components/ui/Space";
import { useFeatureFlag } from "hooks/ui";
import { Flag } from "store/slices/featureFlags";
import { selectOnlyOrganization } from "store/slices/organizations";
import { colors } from "styles/colors";
import { fastClone } from "utils/clone";
import { AutocompleteConfiguration } from "../CodeEditor/EditorConfig";
import { StyledReactMarkdown } from "./DynamicFormItem";
import { TemplatedDynamicFormItem } from "./TemplatedDynamicFormItem";
import {
  DynamicFormWrapper,
  HeaderWrapper,
  Section,
} from "./TemplatedDynamicFormStyledComponents";
import { filterUnsupportedFields, getPluginVersion } from "./utils";
import { FormRef, SBDynamicForm } from ".";

interface Props {
  autocompleteConfiguration: AutocompleteConfiguration;
  autocompleteAdditionalData?: Record<string, Record<string, unknown>>;
  values: Record<string, unknown>;
  onChange: (value: any) => void;
  getValue: (path: string) => unknown;
  formTemplate: FormTemplate;
  showAdvancedSections: boolean;
  children?: React.ReactNode;
  pluginId: string;
  readOnly?: boolean;
  datasourceId: string;
  configurationId?: string;
  isNew?: boolean;
  subtitle?: string;
  isResizable: boolean;
  onPasswordVisibilityChange?: (fieldName: string, visible: boolean) => void;
  actionId?: string;
  apiId?: string;
}

const TemplatedDynamicForm = forwardRef<FormRef, Props>(
  (
    {
      autocompleteConfiguration,
      autocompleteAdditionalData,
      values,
      onChange,
      getValue,
      formTemplate,
      showAdvancedSections,
      children,
      pluginId,
      readOnly,
      datasourceId,
      configurationId,
      isNew,
      isResizable,
      apiId,
      actionId,
      onPasswordVisibilityChange,
    },
    ref,
  ) => {
    const [resolvedFormTemplate, setResolvedFormTemplate] =
      useState(formTemplate);
    const [collapsedSectionsByIndex, setCollapsedSectionsByIndex] = useState<
      Record<number, boolean>
    >(
      formTemplate.sections.reduce((acc, section, index) => {
        if (section.defaultCollapsed && section.isCollapsible) {
          acc[index] = true;
        }
        return acc;
      }, {} as Record<number, boolean>),
    );

    const organization = useSelector(selectOnlyOrganization);
    const wrapperRef = useRef<HTMLDivElement>(null);
    const [formError, setFormError] = useState<string>("");
    const isIntegrationEnableExperimentalEnabled = useFeatureFlag(
      Flag.INTEGRATION_ENABLE_EXPERIMENTAL,
    );
    const formErrorAlert = Alert({
      closable: false,
      type: "error",
      message: formError,
      showIcon: true,
    });
    // Filter out/update unsupported plugin form items
    useEffect(() => {
      if (organization) {
        const clonedFormTemplate = fastClone(formTemplate);
        filterUnsupportedFields(
          clonedFormTemplate,
          getPluginVersion(organization.pluginExecutionVersions, pluginId),
          organization.agentType,
          isIntegrationEnableExperimentalEnabled,
        );
        setResolvedFormTemplate(clonedFormTemplate);
      }
    }, [
      formTemplate,
      organization,
      pluginId,
      isIntegrationEnableExperimentalEnabled,
    ]);

    const shouldShowFormField = useCallback(
      (
        formItemDisplay?: FormItemDisplay,
        defaultValues?: Record<string, InitialValue>,
      ): boolean => {
        let show = true;
        if (formItemDisplay?.show) {
          for (const [name, matchValues] of Object.entries(
            formItemDisplay.show,
          )) {
            const value = get(values, name, get(defaultValues, name));
            show = show && matchValues.includes(String(value));
          }
        }
        return show;
      },
      [values],
    );

    const [formItems, defaultValues] = useMemo(() => {
      setFormError("");
      const formSections = [];
      const visibleDefaultValues: Record<string, InitialValue> = {};
      const allDefaultValues: Record<string, InitialValue> = {};
      const clonedResolvedFormTemplate = fastClone(resolvedFormTemplate);
      clonedResolvedFormTemplate.sections =
        clonedResolvedFormTemplate.sections.filter(
          (section) =>
            section.name.startsWith("advanced:") === showAdvancedSections,
        );
      filterUnsupportedFields(
        clonedResolvedFormTemplate,
        getPluginVersion(organization.pluginExecutionVersions, pluginId),
        organization.agentType,
        isIntegrationEnableExperimentalEnabled,
      );
      const totalVisibleCount = clonedResolvedFormTemplate.sections.reduce(
        (c, s) =>
          shouldShowFormField(s.display)
            ? c +
              s.items.reduce((ic, i) => {
                if (isFormItem(i)) {
                  return shouldShowFormField(i.display) ? ic + 1 : ic;
                } else {
                  return (
                    ic +
                    (i as FormRow).rowItems.reduce(
                      (innerIc, innerI) =>
                        shouldShowFormField(innerI.display)
                          ? innerIc + 1
                          : innerIc,
                      0,
                    )
                  );
                }
              }, 0)
            : c,
        0,
      );
      for (let i = 0; i < clonedResolvedFormTemplate.sections.length; i++) {
        const section = clonedResolvedFormTemplate.sections[i];
        if (!shouldShowFormField(section.display)) {
          for (const item of section.items) {
            if (isFormItem(item) && item.initialValue) {
              allDefaultValues[item.name] = item.initialValue as InitialValue;
            } else {
              for (const rowItem of (item as FormRow).rowItems) {
                if (rowItem.initialValue) {
                  allDefaultValues[rowItem.name] =
                    rowItem.initialValue as InitialValue;
                }
              }
            }
          }
          continue;
        }
        const formItems: Array<JSX.Element> = [];
        for (const sectionItem of section.items) {
          const isRow = !isFormItem(sectionItem);
          const rowItems = isRow
            ? (sectionItem as FormRow).rowItems
            : [sectionItem];
          const rowFormItems = [];
          for (const item of rowItems) {
            if (isFormItem(item) && item.initialValue) {
              allDefaultValues[item.name] = item.initialValue as InitialValue;
            }
            if (
              isFormItem(item) &&
              item.secondaryName &&
              item.secondaryInitialValue
            ) {
              allDefaultValues[item.secondaryName] =
                item.secondaryInitialValue as InitialValue;
            }
            if (!shouldShowFormField(item.display, allDefaultValues)) {
              // Note that if an item is just hidden it should still be rendered.
              // If it shouldn't be rendered at all, use the display rules.
              continue;
            }
            // Note that if an item is just hidden it should still be rendered.
            // If it shouldn't be rendered at all, use the display rules.
            if (item.initialValue) {
              visibleDefaultValues[item.name] =
                item.initialValue as InitialValue;
            }
            if (item.secondaryName && item.secondaryInitialValue) {
              visibleDefaultValues[item.secondaryName] =
                item.secondaryInitialValue as InitialValue;
            }
            // Only resize if it's the only item, ignoring empty sections
            const conditionalRef =
              isResizable && totalVisibleCount === 1 ? wrapperRef : undefined;
            const formItemToRender = (
              <TemplatedDynamicFormItem
                key={item.name}
                formItem={item}
                wrapperRef={conditionalRef}
                readOnly={readOnly}
                defaultValues={allDefaultValues}
                datasourceId={datasourceId}
                configurationId={configurationId}
                pluginId={pluginId}
                setFormError={setFormError}
                isNew={isNew}
                onPasswordVisibilityChange={onPasswordVisibilityChange}
                actionId={actionId}
                apiId={apiId}
              />
            );

            rowFormItems.push(
              isRow ? <div>{formItemToRender}</div> : formItemToRender,
            );
          }
          if (isRow && rowFormItems.length) {
            formItems.push(
              <div
                style={{
                  width: "100%",
                }}
              >
                <div
                  className="dynamic-form-row"
                  style={{
                    width: "100%",
                    display: "grid",
                    gridColumnGap: 10,
                    // this will split items evenly, and break into next line if width is < 250px
                    gridTemplateColumns: `repeat(auto-fit, minmax(250px, 1fr))`,
                    ...sectionItem.gridCss,
                  }}
                >
                  {rowFormItems}
                </div>
                <div style={{ color: colors.GREY_400 }}>
                  <StyledReactMarkdown linkTarget="_blank">
                    {sectionItem.subtitle ?? ""}
                  </StyledReactMarkdown>
                </div>
              </div>,
            );
          } else {
            // only push one formItem
            rowFormItems.forEach((item) => formItems.push(item));
          }
        }
        const isCollapsible = section.isCollapsible === true;
        const asTitle = section.asTitle !== false; // defaults to true
        if (formItems.length) {
          formSections.push(
            <Section
              key={section.name}
              data-name={section.name}
              direction="vertical"
              size={10}
              $hasBorder={
                section.borderThreshold !== undefined &&
                formItems.length > section.borderThreshold
              }
            >
              {Boolean(section.sectionHeader) && (
                <HeaderWrapper
                  $isCollapsible={isCollapsible}
                  onClick={() =>
                    isCollapsible &&
                    setCollapsedSectionsByIndex((prev) => ({
                      ...prev,
                      [i]: !prev[i],
                    }))
                  }
                >
                  {isCollapsible && (
                    <ExpandIconButton
                      isExpanded={!collapsedSectionsByIndex[i]}
                    />
                  )}
                  <div
                    style={{
                      fontSize: 14,
                      color: asTitle ? "black" : "grey",
                      fontWeight: asTitle ? "bold" : "normal",
                    }}
                  >
                    {section.sectionHeader}
                  </div>
                </HeaderWrapper>
              )}
              {!isCollapsible || !collapsedSectionsByIndex[i]
                ? formItems
                : null}
            </Section>,
          );
        }
      }
      return [formSections, visibleDefaultValues];
    }, [
      resolvedFormTemplate,
      organization.pluginExecutionVersions,
      organization.agentType,
      pluginId,
      showAdvancedSections,
      shouldShowFormField,
      isResizable,
      readOnly,
      datasourceId,
      configurationId,
      isNew,
      collapsedSectionsByIndex,
      onPasswordVisibilityChange,
      isIntegrationEnableExperimentalEnabled,
      apiId,
      actionId,
    ]);

    return (
      <DynamicFormWrapper ref={wrapperRef}>
        <SBDynamicForm
          autocompleteConfiguration={autocompleteConfiguration}
          autocompleteAdditionalData={autocompleteAdditionalData}
          data={values}
          defaultValues={defaultValues}
          onChange={onChange}
          ref={ref}
          getValue={getValue}
        >
          <FullWidthSpace
            direction="vertical"
            size={26}
            style={{ paddingBottom: "16px" }}
          >
            {formItems}
          </FullWidthSpace>
          {children}
        </SBDynamicForm>
        {!isEmpty(formError) ? formErrorAlert : null}
      </DynamicFormWrapper>
    );
  },
);

TemplatedDynamicForm.displayName = "DynamicForm";
export default TemplatedDynamicForm;
