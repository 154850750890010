import { ApplicationScope } from "@superblocksteam/shared";
import { isUndefined, uniq } from "lodash";
import { createCachedSelector } from "re-reselect";
import { createSelector } from "reselect";
import { MAX_SAVE_FAILURES } from "legacy/constants/editorConstants";

import { ScopedDataTreePath } from "legacy/entities/DataTree/dataTreeFactory";
import getApiExecuteOnPageLoad from "store/slices/apisV2/utils/get-api-execute-on-pageload";
import {
  getV2ApiId,
  getV2ApiName,
} from "store/slices/apisV2/utils/getApiIdAndName";
import { EntitiesErrorType } from "store/utils/types";
import { getEntityName, pathToScopedEntityName } from "utils/dottedPaths";
import invertDependencies from "utils/invertDependencies";
import slice from "./slice";
import type { AppState } from "store/types";

export const selectAllV2Apis = createSelector(
  slice.selector,
  (state) => state.entities,
);

export const selectV2ApiCount = createSelector(slice.selector, (state) => {
  return Object.values(state.entities).length;
});

export const selectV2ApiById = createCachedSelector(
  slice.selector,
  (state: unknown, apiId: string | undefined) => apiId,
  (slice, apiId) => (apiId ? slice.entities[apiId] : undefined),
)((state, apiId) => apiId ?? "undefined");

export const getApiV2RepoConnection = createCachedSelector(
  slice.selector,
  (state: unknown, apiId: string | undefined) => apiId,
  (slice, apiId) => (apiId ? slice.entities[apiId]?.repoConnection : undefined),
)((state, apiId) => apiId ?? "undefined");

export const selectAllV2ApiNames = createSelector(selectAllV2Apis, (apis) =>
  Object.values(apis).map((api) => getV2ApiName(api)),
);

export const selectAllV2NonStreamingApiNames = createSelector(
  selectAllV2Apis,
  (apis) => Object.values(apis).map((api) => api.apiPb.metadata.name),
);

export const selectV2ApiMetaById = createCachedSelector(
  slice.selector,
  (state: unknown, apiId: string | undefined) => apiId,
  (state, apiId) => (apiId ? state.meta[apiId] : undefined),
)((state: unknown, apiId: string | undefined) => apiId ?? "undefined");

export const selectV2ApiExtractedBindings = createSelector(
  slice.selector,
  (state) => {
    const idToName = Object.fromEntries(
      Object.values(state.entities).map((api) => [
        getV2ApiId(api),
        getV2ApiName(api),
      ]),
    );
    return Object.fromEntries(
      Object.entries(state.meta).map(([id, api]) => {
        return [
          id,
          {
            id,
            name: idToName[id],
            bindings: api.extractedBindings,
          },
        ];
      }),
    );
  },
);

export const selectV2ApiExtractedBindingsSingle = createCachedSelector(
  slice.selector,
  (_: unknown, apiId: string) => apiId,
  (state, apiId) => state.meta[apiId]?.extractedBindings ?? [],
)((_, apiId) => apiId);

export const selectV2ApiBindingsByBlockById = createSelector(
  slice.selector,
  (state: unknown, apiId: string | undefined) => apiId,
  (state, apiId) => {
    return apiId ? state?.meta?.[apiId]?.extractedBindingsByBlock ?? {} : {};
  },
);

export const selectV2ApisDirty = createSelector(
  slice.selector,
  (state) =>
    !state.errors.stale &&
    Object.values(state.meta).filter((meta) => meta.dirty || meta.saving)
      .length > 0,
);

export const selectV2ApiRunCancelledById = createCachedSelector(
  slice.selector,
  (state: unknown, apiId: string) => apiId,
  (state, apiId) => Boolean(state.meta[apiId]?.cancelled),
)((state, apiId) => apiId);

export const selectV2ApiCancelledByTypeById = createCachedSelector(
  slice.selector,
  (state: unknown, apiId: string) => apiId,
  (state, apiId) => state.meta[apiId]?.cancelledByType,
)((state, apiId) => apiId);

export const selectV2ApiLoadingById = createCachedSelector(
  slice.selector,
  (state: unknown, apiId: string) => apiId,
  (state, apiId) => {
    return (
      Boolean(state.loading[apiId]) ||
      Boolean((state.meta[apiId]?.concurrentRuns ?? 0) > 0) ||
      Boolean(state.meta[apiId]?.waitingForEvaluationSince)
    );
  },
)((state, apiId) => apiId);

export const selectV2ApiConcurrentRuns = createCachedSelector(
  slice.selector,
  (state: unknown, apiId: string) => apiId,
  (state, apiId) => {
    return state.meta[apiId]?.concurrentRuns ?? 0;
  },
)((state, apiId) => apiId);

export const selectIsV2ApiFormattingOnManualRun = createCachedSelector(
  slice.selector,
  (state: unknown, apiId: string) => apiId,
  (state, apiId) => {
    return Boolean(state.formatting[apiId]?.manualRun);
  },
)((state, apiId) => apiId);

export const selectV2ApiBlockFormattingStatusById = createCachedSelector(
  slice.selector,
  (state: unknown, apiId: string, blockName: string) => apiId,
  (state: unknown, apiId: string, blockName: string) => blockName,
  (state, apiId, blockName) => {
    if (!state.formatting[apiId]) {
      return undefined;
    }
    return state.formatting[apiId].blocks[blockName];
  },
)((state, apiId) => apiId);

export const selectV2ApiHasAnyBlockLoading = createCachedSelector(
  slice.selector,
  (state: unknown, apiId: string) => apiId,
  (state, apiId) => {
    return (
      state.meta[apiId]?.singleStepRuns != null &&
      Object.values(state.meta[apiId].singleStepRuns ?? {}).some(
        (val) => val.loading,
      )
    );
  },
)((state, apiId) => apiId);

export const selectV2ApiBlockLoadingById = createCachedSelector(
  slice.selector,
  (state: unknown, apiId: string, blockName: string) => apiId,
  (state: unknown, apiId: string, blockName: string) => blockName,
  (state, apiId, blockName) => {
    return Boolean(state.meta[apiId]?.singleStepRuns?.[blockName]?.loading);
  },
)((state, apiId) => apiId);

export const selectV2ApiBlockIsShowingSingleStepResults = createCachedSelector(
  slice.selector,
  (state: unknown, apiId: string, blockName: string) => apiId,
  (state: unknown, apiId: string, blockName: string) => blockName,
  (state, apiId, blockName) => {
    return Boolean(
      state.meta[apiId]?.singleStepRuns?.[blockName]?.hasRunMostRecently,
    );
  },
)((state, apiId, blockName) => `${apiId}_${blockName}`);

export const selectV2ApiBlockCancelledById = createCachedSelector(
  slice.selector,
  (state: unknown, apiId: string, blockName: string) => apiId,
  (state: unknown, apiId: string, blockName: string) => blockName,
  (state, apiId, blockName) => {
    return Boolean(state.meta[apiId]?.singleStepRuns?.[blockName]?.cancelled);
  },
)((state, apiId, blockName) => `${apiId}_${blockName}`);

export const selectV2ApisByIds = createSelector(
  selectAllV2Apis,
  (state: unknown, apiIds: string[]) => apiIds,
  (apis, apiIds) => apiIds.map((apiId) => apis[apiId]),
);

export const selectV2ApiMeta = createSelector(
  slice.selector,
  (state) => state.meta,
);

export const selectV2ApiNameToIdMap = createSelector(
  selectAllV2Apis,
  (apis) => {
    return Object.values(apis).reduce((result: Record<string, string>, api) => {
      result[getV2ApiName(api)] = getV2ApiId(api);
      return result;
    }, {}) as Record<string, string>;
  },
);

// TODO make sure v2 apis get loaded into the entityDependencyMap
const selectEntityDependencyMap = (state: AppState) =>
  state.legacy.evaluations.dependencies.entityDependencyMap;

const selectEntityDependentNames = createSelector(
  selectEntityDependencyMap,
  (entityDepMap) => {
    return Object.values(entityDepMap).reduce((previous, current) => {
      current.forEach((value) => previous.add(value as ScopedDataTreePath));
      return previous;
    }, new Set<ScopedDataTreePath>());
  },
);

export const selectPageLoadV2Apis = createSelector(
  slice.selector,
  selectV2ApiExtractedBindings,
  selectEntityDependentNames,
  (state, apiDepMap, entityDepNames) => {
    const apis = Object.values(state.entities);
    // Add Widget dependencies
    const dependentNames = new Set<string>(entityDepNames);
    // Add APIs that are explicitly called
    // Remove APIs that are explicitly not called
    apis.forEach((api) => {
      const executeOnPageLoad = getApiExecuteOnPageLoad(api?.apiPb);
      if (executeOnPageLoad === true) {
        dependentNames.add(`${ApplicationScope.PAGE}.${getV2ApiName(api)}`);
      } else if (executeOnPageLoad === false) {
        dependentNames.delete(`${ApplicationScope.PAGE}.${getV2ApiName(api)}`);
      }
    });

    // Add API dependencies based on if they are depended on by the above
    const apiBindings = Object.fromEntries(
      Object.values(apiDepMap).map((api) => [api.name, api]),
    );

    let apisToCheck = Object.values(apiBindings);
    do {
      const foundDependencies: string[] = [];
      for (const api of apisToCheck) {
        if (dependentNames.has(`${ApplicationScope.PAGE}.${api.name}`)) {
          (api.bindings ?? [])
            .map((binding) => pathToScopedEntityName(binding.dataTreePath))
            .filter((dep) => !dependentNames.has(dep))
            .filter((depName) => {
              const unscopedName = getEntityName(depName);
              // Allow if it's not an API or if the API is not turned explicitly off.
              const api = state.entities[apiBindings[unscopedName]?.id];
              return !api || getApiExecuteOnPageLoad(api?.apiPb) !== false;
            })
            .forEach((dep) => {
              dependentNames.add(dep);
              foundDependencies.push(dep);
            });
        }
      }

      apisToCheck = foundDependencies
        .map((depName) => {
          const apiName = getEntityName(depName as ScopedDataTreePath);
          return apiBindings[apiName];
        }) // Get the bindings for the dependency
        .filter(Boolean); // Filter out any that are not APIS
    } while (apisToCheck.length > 0);

    // There are three cases that can trigger an API to run on load:
    // 1. If the user has explicitly enabled this
    // 2. Without any user choice, it requires that a component -> API dependency exists
    // 3. Without any user choice, for APIB, it requires that an APIA -> APIB dependency exists and that APIA or an ancestor follows rules 1 or 2
    const result = apis.filter((api) => {
      const executeOnPageLoad = getApiExecuteOnPageLoad(api?.apiPb);
      return (
        executeOnPageLoad ||
        (dependentNames.has(
          `${ApplicationScope.PAGE}.${getV2ApiName(api)}` as const,
        ) &&
          isUndefined(executeOnPageLoad))
      );
    });
    return result;
  },
);

export const selectInverseV2ApiMap = createSelector(
  selectV2ApiExtractedBindings,
  (apiDepMap) => {
    const apis = Object.values(apiDepMap);
    const apisToCheck = apis.map((api) => api.name);
    const nameToBindings = Object.fromEntries(
      apis.map((api) => [api.name, (api.bindings ?? []).map((b) => b.str)]),
    );

    return invertDependencies(apisToCheck, nameToBindings);
  },
);

export const selectStaleV2ApiError = createSelector(slice.selector, (state) =>
  Boolean(state.errors.stale),
);

export const selectV2ApiSavingError = createSelector(slice.selector, (state) =>
  Object.values(state.errors).some(
    (error) => error?.type === EntitiesErrorType.SAVE_ERROR,
  ),
);

export const selectV2ApiSavingFailureMaxReached = createSelector(
  slice.selector,
  (state) =>
    Object.values(state.meta).some(
      (meta) => (meta.savingFailuresCount ?? 0) >= MAX_SAVE_FAILURES,
    ),
);

export const selectV2ApisSaving = createSelector(slice.selector, (state) =>
  Object.values(state.meta).some((meta) => meta.saving),
);

export const selectAllLoadingApiV2Names = createSelector(
  slice.selector,
  (state) => {
    const result = Object.entries(state.meta)
      .map(([apiId, meta]) => {
        if (
          state.loading[apiId] ||
          (typeof meta.concurrentRuns === "number" &&
            meta.concurrentRuns > 0) ||
          meta.waitingForEvaluationSince
        ) {
          return state.entities[apiId]?.apiPb?.metadata.name;
        }
        return false;
      })
      .filter(Boolean);
    return result as string[];
  },
);

export const selectV2TestProfile = createCachedSelector(
  slice.selector,
  (state: unknown, apiId: string | undefined) => apiId,
  (slice, apiId) => (apiId ? slice.meta[apiId]?.testProfile : undefined),
)((state, apiId) => apiId ?? "undefined");

export const selectV2ApiShowTestData = createCachedSelector(
  slice.selector,
  (state: unknown, apiId: string | undefined) => apiId,
  (state: unknown, apiId: string | undefined, blockName: string) => blockName,
  (slice, apiId, blockName) =>
    apiId
      ? slice.meta[apiId]?.showTestDataForBlock?.[blockName] ?? false
      : false,
)((state, apiId, blockName) => `${apiId}_${blockName}`);

export const selectV2ApiBlockBindingsByBlock = createSelector(
  slice.selector,
  (state: unknown, apiId: string | undefined) => apiId,
  (state, apiId) => {
    return apiId ? state?.meta?.[apiId]?.blockBindingsByBlock ?? {} : {};
  },
);

export const selectV2ApiVariableBindingsByBlock = createSelector(
  slice.selector,
  (state: unknown, apiId: string | undefined) => apiId,
  (state, apiId) => {
    return apiId ? state?.meta?.[apiId]?.variableBindingsByBlock ?? {} : {};
  },
);

export const selectAllInternalBindingsForBlock = createCachedSelector(
  slice.selector,
  (state: unknown, apiId: string) => apiId,
  (state: unknown, apiId: string, blockName: string) => blockName,
  (slice, apiId, blockName) => {
    const referencedVariablesByBlock =
      slice.meta[apiId]?.referencedVariableBlocksByBlock;
    if (
      !referencedVariablesByBlock?.[blockName] ||
      !referencedVariablesByBlock?.[blockName].length
    ) {
      return {
        blockBindings:
          slice.meta[apiId]?.blockBindingsByBlock?.[blockName] ?? [],
        referencedVariableBlocks: [],
      };
    }
    const blockBindingsByBlock = slice.meta[apiId]?.blockBindingsByBlock ?? {};
    let allBlockBindings: string[] = [];
    let allReferencedVariableBlocks: string[] = [];
    // For each variable block, get its computed dependency values
    // continue recursively until we have all the computed dependency values
    const computeNestedDeps = (blockName: string) => {
      const blockBindings = blockBindingsByBlock[blockName] ?? [];
      const referencedVariableBlocks =
        referencedVariablesByBlock?.[blockName] ?? [];
      allBlockBindings = [...allBlockBindings, ...blockBindings];
      allReferencedVariableBlocks = [
        ...allReferencedVariableBlocks,
        ...referencedVariableBlocks,
      ];
      if (referencedVariableBlocks.length === 0) {
        return;
      }
      referencedVariableBlocks.forEach((varBlock) => {
        computeNestedDeps(varBlock);
      });
    };
    computeNestedDeps(blockName);
    return {
      blockBindings: uniq(allBlockBindings),
      referencedVariableBlocks: uniq(allReferencedVariableBlocks),
    };
  },
)((state, apiId, blockName) => `${apiId}_${blockName}`);

export const selectTestDataForBlock = createCachedSelector(
  slice.selector,
  (state: unknown, apiId: string) => apiId,
  (state: unknown, apiId: string, blockName: string) => blockName,
  (slice, apiId, blockName) => slice.meta[apiId]?.testDataForBlock?.[blockName],
)((state, apiId, blockName) => `${apiId}_${blockName}`);

export const selectTestDataErrorsForBlock = createCachedSelector(
  slice.selector,
  (state: unknown, apiId: string) => apiId,
  (state: unknown, apiId: string, blockName: string) => blockName,
  (slice, apiId, blockName) =>
    slice.meta[apiId]?.testDataEvaluationError?.[blockName],
)((state, apiId, blockName) => `${apiId}_${blockName}`);

export const selectApiExecutionResult = createCachedSelector(
  selectV2ApiMetaById,
  (apiMeta) => apiMeta?.executionResult,
)((state, apiId) => apiId);

export const selectApiPermissions = createCachedSelector(
  slice.selector,
  (_: unknown, apiId: string) => apiId,
  (state, apiId) => state.permissions[apiId] ?? [],
)((_, apiId) => apiId);
