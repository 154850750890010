import { ApplicationScope } from "@superblocksteam/shared";
import { put, select } from "redux-saga/effects";
import { runEventHandlers } from "legacy/actions/widgetActions";
import { EventType } from "legacy/constants/ActionConstants";
import { ApiInfo } from "legacy/constants/ApiConstants";
import {
  getApiInfoById,
  getDeveloperPreferences,
} from "legacy/selectors/sagaSelectors";
import { findError, findV2Error } from "legacy/utils/ApiNotificationUtility";
import { createRunEventHandlersPayload } from "legacy/utils/actions";
import { selectApiRunCancelledById as selectV1ApiRunCancelledById } from "store/slices/apis/selectors";
import {
  ApiExecutionResponseDto,
  ApiExecutionSagaPayload,
} from "store/slices/apis/types";
import {
  selectV2ApiById,
  selectV2ApiCancelledByTypeById,
  selectV2ApiRunCancelledById,
} from "store/slices/apisV2/selectors";
import { ApiDtoWithPb, CancelledByType } from "store/slices/apisV2/slice";
import { ExecutionResponse } from "store/slices/apisV2/types";
import { getV2ApiName } from "store/slices/apisV2/utils/getApiIdAndName";
import { addNewPromise } from "store/utils/resolveIdSingleton";

export type TriggerFrontendEventHandlersPayload = Pick<
  ApiExecutionSagaPayload,
  "callStack" | "spanId" | "manualRun"
> & {
  apiId: string;
  version: "v1" | "v2";
  result: ApiExecutionResponseDto | ExecutionResponse | undefined;
};

export function* triggerFrontendEventHandlersSaga({
  apiId,
  version,
  result,
  callStack,
  spanId,
  manualRun,
}: TriggerFrontendEventHandlersPayload) {
  const developerPreferences: ReturnType<typeof getDeveloperPreferences> =
    yield select(getDeveloperPreferences);
  const executionError =
    version === "v1"
      ? findError(result as ApiExecutionResponseDto)
      : findV2Error(result as ExecutionResponse);
  const systemError =
    result && "systemError" in result ? result.systemError : undefined;
  const hasError = executionError || systemError;

  const isCancelled: boolean = yield select(
    version === "v1"
      ? selectV1ApiRunCancelledById
      : selectV2ApiRunCancelledById,
    apiId,
  );

  const cancelledByType: ReturnType<typeof selectV2ApiCancelledByTypeById> =
    version === "v2"
      ? yield select(selectV2ApiCancelledByTypeById, apiId)
      : undefined;

  const apiResponseInfo: ApiInfo | undefined = yield select(
    getApiInfoById,
    apiId,
  );

  const apiName = yield* getApiName(version, result, apiId);

  if (
    isCancelled &&
    cancelledByType === CancelledByType.MANUAL && // non-manual cancels are triggered elsewhere
    developerPreferences.application.triggerFrontendEventHandlersOnManualRun &&
    apiResponseInfo?.onCancel &&
    apiResponseInfo.onCancel.length > 0
  ) {
    const { callback, promise: waitForEvents } = callbackPromise();
    const callbackId = addNewPromise(callback);
    yield put(
      runEventHandlers(
        createRunEventHandlersPayload({
          steps: apiResponseInfo.onCancel,
          // TODO(API_SCOPE): update when custom events support app scope
          currentScope: ApplicationScope.PAGE,
          type: EventType.ON_CANCEL,
          entityName: apiName ?? "<API>",
          callStack: callStack || [],
          callbackId,
          spanId,
        }),
      ),
    );
    yield waitForEvents;
  } else if (
    !isCancelled &&
    !(
      manualRun &&
      !developerPreferences.application.triggerFrontendEventHandlersOnManualRun
    )
  ) {
    if (
      !hasError && // dont show success if there was an execution error or a system error
      apiResponseInfo?.onSuccess &&
      apiResponseInfo.onSuccess.length > 0
    ) {
      const { callback, promise: waitForEvents } = callbackPromise();
      const callbackId = addNewPromise(callback);
      yield put(
        runEventHandlers(
          createRunEventHandlersPayload({
            steps: apiResponseInfo.onSuccess,
            // TODO(API_SCOPE): update when custom events support app scope
            currentScope: ApplicationScope.PAGE,
            type: EventType.ON_SUCCESS,
            entityName: apiName ?? "<API>",
            callStack: callStack || [],
            callbackId,
            spanId,
          }),
        ),
      );
      yield waitForEvents;
    } else if (
      executionError && // only check execution error, not systemError because systemError's are handled elsewhere
      apiResponseInfo?.onError &&
      apiResponseInfo.onError.length > 0
    ) {
      const { callback, promise: waitForEvents } = callbackPromise();
      const callbackId = addNewPromise(callback);
      yield put(
        runEventHandlers(
          createRunEventHandlersPayload({
            steps: apiResponseInfo.onError,
            // TODO(API_SCOPE): update when custom events support app scope
            currentScope: ApplicationScope.PAGE,
            type: EventType.ON_ERROR,
            entityName: apiName ?? "<API>",
            callStack: callStack || [],
            callbackId,
            spanId,
            additionalNamedArguments: {
              error: executionError,
            },
          }),
        ),
      );
      yield waitForEvents;
    }
  }
}

function* getApiName(
  version: string,
  result: ApiExecutionResponseDto | ExecutionResponse | undefined,
  actionId: string,
) {
  let apiName;
  if (version === "v1") {
    apiName = (result as ApiExecutionResponseDto)?.apiName;
  } else {
    const api: ApiDtoWithPb = yield select((state) =>
      selectV2ApiById(state, actionId),
    );
    apiName = getV2ApiName(api);
  }
  return apiName;
}

type Result = {
  callback: () => void;
  promise: Promise<void>;
};

/**
 * Transforms a callback into a promise.
 * @example
 * const { callback, promise } = callbackPromise();
 * // do something async that eventually calls callback();
 * await promise;
 * // do something after the callback is called
 * @returns A function that returns a promise and a callback that resolves the promise.
 */
function callbackPromise(): Result {
  let resolvedAlready = false;
  const result: Partial<Result> = {
    callback: () => {
      resolvedAlready = true;
    },
  };
  result.promise = new Promise<void>((resolve) => {
    if (resolvedAlready) {
      resolve();
    }
    result.callback = () => resolve();
  });
  return result as Result;
}
