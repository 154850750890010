import { RepositoryWithEntitiesDto } from "@superblocksteam/shared";
import { Button, Dropdown, Menu } from "antd";
import moment from "moment";
import React, { useCallback, useState } from "react";
import { useNavigate } from "react-router";
import { ReactComponent as EditIcon } from "assets/icons/common/dotdotdot.svg";
import { SpanTruncMiddle } from "components/ui/SpanTruncMiddle";
import { REPOSITORY_DETAILS_URL } from "pages/routes";
import { useAppSelector } from "store/helpers";
import {
  deleteRepositorySaga,
  refetchRepositorySaga,
  selectRepoLoading,
} from "store/slices/repositories";
import { colors } from "styles/colors";
import { styleAsClass } from "styles/styleAsClass";
import { useSaga } from "../../hooks/store";
import { getProviderIcon } from "./utils";

const TableWrapper = styleAsClass`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;

  border-radius: 4px;
  border: 1px solid ${colors.GREY_100};

  th {
    text-align: left;
  }

  tr:first-child {
    cursor: default;
  }

  tr {
    display: flex;
    width: 100%;

    th:nth-child(1),
    td:nth-child(1) {
      flex: 4; // 40% of total width
    }

    th:nth-child(2),
    th:nth-child(3),
    td:nth-child(2),
    td:nth-child(3) {
      flex: 2.5; // 22.5% of total width each
    }

    th:nth-child(4),
    td:nth-child(4) {
      flex: 1; // 15% of total width
    }
  }
`;

const Cell = styleAsClass`
  display: flex;
  padding: 12px 18px;
  align-items: flex-start;
  align-self: center;
  gap: 8px;
  flex: 1 0 0;
  font-size: 13px;
  line-height: 16px;
  overflow: hidden;
  white-space: nowrap
`;

const Row = styleAsClass`
  transition: background-color 0.2s ease-in-out;
  height: 48px;
  &:hover {
    background-color: ${colors.GREY_25};
  }
  &:not(:last-child) {
    border-bottom: 1px solid ${colors.GREY_100};
  }
  &[data-focused="true"] button{
    display: "flex";
  }

  &[data-focused="false"] button {
    display: none;
  }

  &:hover button {
    display: flex;
  }
`;

const ClickableRow = styleAsClass`
  cursor: pointer;
`;

const HeaderCell = styleAsClass`
  color: ${colors.GREY_700};
  font-weight: 500;
`;

const EmptyResultCell = styleAsClass`
  justify-content: center;
  color: ${colors.GREY_300};
  font-size: 12px;
`;

const ActionCell = styleAsClass`
  color: ${colors.GREY_700};
  font-weight: 500;
  min-width: 48px;
  max-width: 48px;
  padding: 12px 9px;
`;

const NameCell = styleAsClass`
  color: #000;
  text-align: center;
  font-weight: 600;
  cursor: pointer;
`;

interface RepositoriesListProps {
  repositories: RepositoryWithEntitiesDto[];
  canManage: boolean;
}

const EditButton = styleAsClass`
  display: flex;
  padding: 8px;
  justify-content: center;
  align-items: center;
  height: 32px;
  width: 32px;
  border: 1px solid;
  background: transparent;
  box-shadow: 0px 0px 0px;
  border-color: transparent;
  &.ant-btn:hover {
    border-color: transparent;
    background: transparent;
  }
  &[data-focused=true].ant-btn:focus {
    border-color: ${colors.GREY_100};
  }

  &[data-focused=false].ant-btn:focus {
    border-color: "transparent";
  }
`;

function humanizeTimestamp(timestamp: Date) {
  const momentTimestamp = moment(timestamp);

  if (moment().diff(momentTimestamp, "hours") < 24) {
    return momentTimestamp.fromNow();
  }

  return momentTimestamp.format("MMMM D, YYYY");
}

function getLastSynced(repository: RepositoryWithEntitiesDto) {
  const lastSynced = repository.entities.reduce((acc, entity) => {
    const lastSynced = new Date(entity.lastSyncedTo);
    if (entity.lastSyncedTo && lastSynced.getTime() > acc.getTime()) {
      return lastSynced;
    }
    return acc;
  }, new Date(0));
  if (lastSynced.getTime() === new Date(0).getTime()) {
    return "Never";
  } else return humanizeTimestamp(lastSynced);
}

const RepoRow = ({
  repository,
  canManage,
}: {
  repository: RepositoryWithEntitiesDto;
  canManage: boolean;
}) => {
  const [focused, setFocused] = useState(false);
  const [deleteRepo] = useSaga(deleteRepositorySaga);
  const [refetchRepo] = useSaga(refetchRepositorySaga);
  const name = `${repository.owner}/${repository.name}`;
  const isRepoLoading = useAppSelector((state) =>
    selectRepoLoading(state, repository?.id),
  );
  const navigate = useNavigate();

  const handleRemoveRepo = async () => {
    deleteRepo({ repositoryId: repository.id });
  };

  const handleRefetchRepo = async () => {
    refetchRepo({ repositoryId: repository.id });
  };

  const toRepoDetailPage = useCallback(
    (id: string) => {
      canManage && navigate({ pathname: REPOSITORY_DETAILS_URL(id) });
    },
    [navigate, canManage],
  );

  const menu = (
    <Menu>
      <Menu.Item key="1" onClick={handleRefetchRepo}>
        Reread configuration
      </Menu.Item>
      <Menu.Item key="2" onClick={handleRemoveRepo}>
        Remove repository
      </Menu.Item>
    </Menu>
  );

  const openRepoDetailPage = useCallback(
    () => toRepoDetailPage(repository.id),
    [repository.id, toRepoDetailPage],
  );

  const stopPropagation = useCallback((e: any) => {
    e?.stopPropagation();
  }, []);

  const ProviderIcon = getProviderIcon(repository.provider);

  return (
    <tr
      className={`${Row} ${canManage ? ClickableRow : ""}`}
      data-focused={focused}
      key={name}
      onClick={openRepoDetailPage}
    >
      <td className={`${Cell} ${NameCell}`}>
        <ProviderIcon style={{ marginTop: "-2px" }} />
        <SpanTruncMiddle text={name} />
      </td>
      <td className={Cell}>{getLastSynced(repository)}</td>
      {/* TODO(aayush): This column will say "Error" if any of the connected appflobs
       have had an error syncing to this repo.*/}
      {/* <Cell>{"Connected"}</Cell> */}
      {canManage && (
        <td className={`${Cell} ${ActionCell}`} onClick={stopPropagation}>
          <Dropdown
            overlay={menu}
            trigger={["click"]}
            onOpenChange={setFocused}
          >
            <Button
              className={EditButton}
              icon={<EditIcon />}
              data-focused={focused}
              loading={isRepoLoading}
            />
          </Dropdown>
        </td>
      )}
    </tr>
  );
};

const RepositoriesList = ({
  repositories,
  canManage,
}: RepositoriesListProps) => {
  return (
    <table className={TableWrapper}>
      <tr className={Row}>
        <td className={`${Cell} ${HeaderCell}`}>Name</td>
        <td className={`${Cell} ${HeaderCell}`}>Last Synced</td>
        <td className={`${Cell} ${ActionCell}`}></td>
      </tr>

      {repositories.length === 0 && (
        <tr className={Row} key="no-repos">
          <td className={`${Cell} ${EmptyResultCell}`}>
            No repositories found.
          </td>
        </tr>
      )}
      {repositories.map((repository) => (
        <RepoRow
          key={repository.id}
          repository={repository}
          canManage={canManage}
        />
      ))}
    </table>
  );
};

export default RepositoriesList;
