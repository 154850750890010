import { RoleTypeEnum } from "@superblocksteam/shared";
import { Button, Dropdown } from "antd";
import Fuse from "fuse.js";
import { debounce } from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { ReactComponent as MoreIcon } from "assets/icons/common/dotdotdot.svg";
import RecommendedTable from "components/ui/RecommendedTable";
import { SearchContainer, SearchInput } from "components/ui/SearchSection";
import { getCurrentOrgId } from "legacy/selectors/organizationSelectors";
import { colors } from "styles/colors";
import { useListRolesQuery } from "../../store/slices/reduxApi/rbac";
import {
  filterAndTransformHighights,
  formatNestedRoleTableData,
  getRoleTableColumns,
  RoleTableItem,
} from "./Shared";

export const OrganizationRoles = () => {
  const organizationId = useSelector(getCurrentOrgId);
  const { data: roles, isLoading } = useListRolesQuery({
    type: RoleTypeEnum.ORGANIZATION,
    organizationId,
  });
  const [searchTerm, setSearchTerm] = useState("");
  const onSearchChangeDebounced = useMemo(
    () => debounce(setSearchTerm, 100),
    [],
  );

  const columns = useMemo(
    () => getRoleTableColumns(roles, "organization"),
    [roles],
  );

  const rowData = useMemo(() => {
    return formatNestedRoleTableData(roles, columns);
  }, [roles, columns]);

  const [expandedState, setExpandedState] = useState<Record<string, boolean>>(
    {},
  );
  const [filteredItems, setFilteredItems] =
    useState<Array<RoleTableItem>>(rowData);

  const fuse = useMemo(
    () =>
      new Fuse(rowData, {
        shouldSort: false,
        threshold: 0.1,
        distance: 100,
        ignoreLocation: true,
        minMatchCharLength: 1,
        findAllMatches: true,
        keys: ["name", "children.name"],
        includeMatches: true,
      }),
    [rowData],
  );

  useEffect(() => {
    if (!searchTerm) {
      setFilteredItems(rowData);
      setExpandedState({});
      return;
    }

    /*
      Filter out the nested children and expand all of the top-level items whose children matche the search term
      Also add the matched inidices to each item for highlighting
    */
    const results = fuse.search(searchTerm);
    const updatedExpandedState: Record<string, boolean> = {};

    const withFilteredChildren: Array<RoleTableItem> = results.map((result) => {
      const item = result.item;
      const matches = result.matches || [];
      const topLevelHiglights = matches.find(
        (match) => match.key === "name",
      )?.indices;

      const filteredChildren = item.children
        .map((child: any) => {
          const highlights = matches.find(
            (match) =>
              match.key === "children.name" && match.value === child.name,
          )?.indices;
          return {
            ...child,
            highlights: filterAndTransformHighights(searchTerm, highlights),
          };
        })
        .filter((child) => child.highlights != null);

      if (filteredChildren.length > 0) {
        updatedExpandedState[item.name] = true;
      }
      return {
        ...item,
        highlights: filterAndTransformHighights(searchTerm, topLevelHiglights),
        children: filteredChildren,
      } as RoleTableItem;
    });

    setFilteredItems(withFilteredChildren);
    setExpandedState(updatedExpandedState);
  }, [searchTerm, fuse, rowData]);

  const menuItems = useMemo(() => {
    return [
      {
        key: "expand-all",
        label: "Expand all",
        onClick: () =>
          setExpandedState(
            Object.fromEntries(rowData.map((item) => [item.name, true])),
          ),
      },
      {
        key: "collapse-all",
        label: "Collapse all",
        onClick: () => setExpandedState({}),
      },
    ];
  }, [rowData]);

  return (
    <div>
      <div className={SearchContainer}>
        <SearchInput
          placeholder="Search permissions by name"
          onChange={(e) => onSearchChangeDebounced(e.target.value)}
        />
        <Dropdown menu={{ items: menuItems }} trigger={["click"]}>
          <Button
            icon={
              <MoreIcon
                style={{
                  position: "relative",
                  top: "-3px",
                  color: colors.GREY_500,
                }}
              />
            }
          />
        </Dropdown>
      </div>
      <RecommendedTable
        columns={columns}
        data={filteredItems}
        canExpandRows={true}
        expandedState={expandedState}
        loading={isLoading}
        uniqueKey="name"
        useFixedColumnWidths={true}
      />
    </div>
  );
};
