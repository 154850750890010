import React from "react";
import { colors } from "styles/colors";
import { styleAsClass } from "styles/styleAsClass";

const CheckboxWrapper = styleAsClass`
  display: flex;
  gap: 8px;
  align-items: center;
  div:nth-child(2) {
    flex: 1;
    width: calc(100% - 24px);
  }
`;

const CheckboxCss = styleAsClass`
  display: block;
  position: relative;
  height: 16px;
  width: 16px;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  span {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    border: 1px solid ${colors.GREY_100};
    border-radius: min(4px, 4px);
    background-color: ${colors.WHITE};
    border-color: ${colors.GREY_100};
    box-shadow: none;
    color:${colors.WHITE};
  }

  span:hover {
    background-color: ${colors.GREY_25};
    border-color: ${colors.GREY_200};
    box-shadow: none;
    color: ${colors.WHITE};
  }

  input:checked:not(:disabled) + span, 
  input.partial-checked:not(:disabled) + span {
    background-color: ${colors.ACCENT_BLUE_500};
    border-color: ${colors.ACCENT_BLUE_500};
    box-shadow: none;
    color: ${colors.WHITE};
  }

  input:checked:not(:disabled) + span:hover,
  input.partial-checked:not(:disabled) + span:hover {
    background-color: ${colors.ACCENT_BLUE_600};
    border-color: ${colors.ACCENT_BLUE_600};
    box-shadow: none;
    color: ${colors.WHITE};
  }

  input:disabled + span {
    background-color: ${colors.GREY_100};
    border-color: ${colors.GREY_100};
    box-shadow: none;
    color: ${colors.GREY_300};
    cursor: not-allowed;
  }

  span:after {
    content: "";
    position: absolute;
  }

  input:checked + span:after {
    display: block;
    height: 100%;
    width: 100%;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23FFFFFF'/%3e%3c/svg%3e");
  }

  input.partial-checked + span:after {
    display: block;
    height: 100%;
    width: 100%;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath d='M4 7h8v2H4z' fill='%23FFFFFF'/%3e%3c/svg%3e");
  }
`;

export const Checkbox = ({
  checked,
  partialChecked,
  children,
  style,
  className,
  dataTest,
  onClick,
  disabled,
}: {
  checked: boolean;
  partialChecked?: boolean;
  children?: React.ReactNode;
  style?: React.CSSProperties;
  className?: string;
  dataTest?: string;
  onClick?: (e: React.MouseEvent<HTMLDivElement>) => void;
  disabled?: boolean;
}) => {
  return (
    <div
      className={`${CheckboxWrapper} ${className ?? ""}`}
      style={style}
      data-test={dataTest}
      onClick={disabled ? undefined : onClick}
    >
      <div className={CheckboxCss}>
        {/* the input does not display, we handle checked status by onClick, using readonly to suppress console warnings */}
        <input
          type="checkbox"
          checked={checked}
          readOnly
          disabled={disabled}
          className={partialChecked ? "partial-checked" : undefined}
        />
        <span></span>
      </div>
      {children && <div>{children}</div>}
    </div>
  );
};
