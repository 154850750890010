import {
  FormComponentType,
  ObservabilityDatasource,
  PostSinksRequestDto,
  PutSinksRequestDto,
} from "@superblocksteam/shared";
import { Input, Space, Typography, Modal, Form, Button, Select } from "antd";
import React, { useCallback, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import DropdownSelect from "components/ui/DropdownSelect";
import { ConfirmModalClass } from "components/ui/Modal";
import { selectOnlyOrganization } from "../../store/slices/organizations";
import {
  sendErrorUINotification,
  sendSuccessUINotification,
} from "../../utils/notification";
import {
  createObservabilitySink,
  deleteObservabilitySink,
  updateObservabilitySink,
} from "../Permissions/client";
import { Sink } from "./sink";

type ObservabilityModalProps<TConfig> = {
  sink: Sink<TConfig>;
  newSink: boolean;
  iconLocation: string;
  isOpen: boolean;
  onOk: () => void;
  onCancel: () => void;
};

const ObservabilityModalIcon = styled.img`
  height: 30px;
  max-width: 30px;
  margin: 2px 0;
`;

const StyledModal = styled(Modal)`
  .ant-modal-header {
    padding: 12px 16px;
  }

  .ant-modal-footer {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
  }
`;

const { confirm } = Modal;

export default function ObservabilityModal<TConfig>({
  sink,
  newSink,
  isOpen,
  onOk,
  onCancel,
}: ObservabilityModalProps<TConfig>): React.ReactElement {
  const [sinkForm] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const organization = useSelector(selectOnlyOrganization);

  const createSink = useCallback(
    (config: TConfig) => {
      const body: PostSinksRequestDto = {
        observability: {
          type: sink.type,
          datasource: ObservabilityDatasource.LOGS,
          config: { [sink.key]: config },
          organizationId: organization.id,
        },
      };
      setLoading(true);
      const future = createObservabilitySink(body);
      return future
        .then((resp) => {
          sendSuccessUINotification({
            message: `Created the ${sink.label} observability integration.`,
          });
          onOk();
          return resp;
        })
        .catch((error) => {
          sendErrorUINotification({ message: error });
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [onOk, organization.id, sink.key, sink.label, sink.type],
  );

  const updateSink = useCallback(
    (config: TConfig) => {
      const body: PutSinksRequestDto = {
        observability: {
          type: sink.type,
          datasource: ObservabilityDatasource.LOGS,
          config: { [sink.key]: config },
          organizationId: organization.id,
        },
      };
      if (!sink.persistentId) {
        return;
      }
      setLoading(true);
      const future = updateObservabilitySink(sink.persistentId, body);
      return future
        .then((resp) => {
          sendSuccessUINotification({
            message: `Updated the ${sink.label} observability integration.`,
          });
          onOk();
          return resp;
        })
        .catch((error) => {
          sendErrorUINotification({ message: error });
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [onOk, organization.id, sink.key, sink.label, sink.persistentId, sink.type],
  );

  const handleCreateSink = useCallback(() => {
    sinkForm.validateFields().then((config) => {
      for (const field of sink.template.fields) {
        switch (field.componentType) {
          case FormComponentType.DROPDOWN: {
            // populate the default value
            if (config[field.key] === undefined) {
              config[field.key] = field.dropdownProperty?.defaultOption.value;
            }
          }
        }
      }

      createSink(config);
    });
  }, [createSink, sink.template.fields, sinkForm]);

  const handleUpdateSink = useCallback(() => {
    sinkForm.validateFields().then((config) => {
      updateSink(config);
    });
  }, [sinkForm, updateSink]);

  const deleteSink = useCallback(() => {
    if (!sink.persistentId) {
      return;
    }
    setLoading(true);
    const future = deleteObservabilitySink(sink.persistentId);
    return future
      .then((resp) => {
        sendSuccessUINotification({
          message: `Removed the ${sink.label} observability integration.`,
        });
        onOk();
        return resp;
      })
      .catch((error) => {
        sendErrorUINotification({ message: error });
      })
      .finally(() => {
        setLoading(false);
      });
  }, [onOk, sink.label, sink.persistentId]);

  const showRemoveGroupConfirm = useCallback(() => {
    confirm({
      title: `Are you sure you want to remove ${sink.label} observability integration?`,
      icon: <></>,
      okText: "Remove",
      okType: "danger",
      onOk() {
        deleteSink();
      },
      className: `${ConfirmModalClass}`,
    });
  }, [deleteSink, sink.label]);

  return (
    <StyledModal
      title={
        <Space size={6}>
          <ObservabilityModalIcon src={sink.iconLocation} />
          <Typography.Text>{`Connect ${sink.label}`}</Typography.Text>
        </Space>
      }
      open={isOpen}
      onCancel={() => {
        sinkForm.resetFields();
        onCancel();
      }}
      cancelButtonProps={{ style: { display: "none" } }}
      destroyOnClose
      okText={newSink ? "Create integration" : "Save integration"}
      footer={[
        <Button
          key="create"
          type="primary"
          loading={loading}
          onClick={() => {
            if (newSink) {
              handleCreateSink();
            } else {
              handleUpdateSink();
            }
          }}
        >
          {newSink ? "Create integration" : "Save integration"}
        </Button>,
        !newSink && (
          <Button
            key="remove"
            danger
            loading={loading}
            onClick={showRemoveGroupConfirm}
          >
            Remove
          </Button>
        ),
      ]}
    >
      <Form form={sinkForm} layout="vertical" name="create_new_form">
        {sink.template.fields.map((field) => {
          switch (field.componentType) {
            case FormComponentType.INPUT_TEXT: {
              return (
                <Form.Item
                  key={field.key}
                  name={field.key}
                  label={field.label}
                  initialValue={sink.getConfiguration(field.key)}
                  rules={[
                    {
                      required: field.required,
                      message: `${field.label} is required`,
                    },
                    () => ({
                      validator(_, value) {
                        const { ok, error } = field.validator(
                          field.label,
                          value,
                        );
                        if (ok) {
                          return Promise.resolve();
                        }
                        return Promise.reject(new Error(error));
                      },
                    }),
                  ]}
                >
                  {field.sensitive ? <Input.Password /> : <Input />}
                </Form.Item>
              );
            }
            case FormComponentType.DROPDOWN: {
              return (
                <Form.Item key={field.key} name={field.key} label={field.label}>
                  <DropdownSelect
                    defaultActiveFirstOption={true}
                    defaultValue={
                      sink.getConfiguration(field.key) ??
                      field.dropdownProperty?.defaultOption.value
                    }
                  >
                    {field.dropdownProperty?.options.map((option) => (
                      <Select.Option key={option.value} value={option.value}>
                        {option.label}
                      </Select.Option>
                    ))}
                  </DropdownSelect>
                </Form.Item>
              );
            }
            default: {
              return null;
            }
          }
        })}
      </Form>
    </StyledModal>
  );
}
