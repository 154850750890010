import { Menu } from "antd";
import styled from "styled-components";

export const AddNewIntegrationButton = styled.a`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.ACCENT_BLUE_500} !important;
  span {
    margin-left: 4px;
  }
`;

export const StyledShortcutMenu = styled(Menu)`
  margin-top: 2px;
  min-width: 100px;
  max-height: 270px;
  overflow: auto;

  // When a menu item is disabled, it should not be clickable but the cursor should not change to not-allowed, it's ugly
  // The opacity should be 0.5 to indicate that it's disabled without the user having to hover over it
  &&& .ant-dropdown-menu-item-disabled {
    cursor: pointer;
    opacity: 0.5;
  }
`;

export const Icon = styled.img`
  margin-right: 10px;
  height: 14px;
  width: 14px;
`;

export const AiShortcutOption = styled.span`
  display: flex;
  align-items: center;
  font-weight: 400;
  justify-content: space-between;
  svg {
    margin-right: 6px;
  }
`;

export const AiShortcutMenuTitle = styled.span`
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: ${(props) => props.theme.colors.GREY_600};
`;

export const AiShortcutOptionSubtext = styled.span`
  color: ${(props) => props.theme.colors.GREY_300};
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
`;
