import { useCallback } from "react";
import { useSelector } from "react-redux";
import {
  BUILDER_PAGE_TAB_API_ACTION_URL,
  BUILDER_PAGE_TAB_API_EMPTY_ACTION_URL,
  BUILDER_PAGE_TAB_API_INPUTS_URL,
  BUILDER_PAGE_TAB_API_RESPONSE_URL,
  BUILDER_PAGE_TAB_API_URL,
  BUILDER_PAGE_TAB_ITEM_KIND_PROPERTY_URL,
  BUILDER_PAGE_URL,
  EditorRoute,
} from "legacy/constants/routes";
import { getCurrentRoutePathWithParams } from "legacy/selectors/routeSelectors";

type GetPathOptions = {
  applicationId?: string;
  actionId?: string;
  apiId?: string;
  entityId?: string;
  property?: string;
  currentRoute?: string;
  entityType?: string;
};

// This is a non-hook helper function exported out of this simple hook module because we need to avoid
// circular dependencies related to the router code. In an ideal world, it would live next to router code.
export function getEditorBasePath(
  typeOfRoute: EditorRoute,
  options: GetPathOptions,
) {
  switch (typeOfRoute) {
    case EditorRoute.EditApplication:
      return BUILDER_PAGE_URL(options.applicationId, options.currentRoute);
    case EditorRoute.EditApi:
      return BUILDER_PAGE_TAB_API_URL(
        options.applicationId,
        options.apiId,
        options.currentRoute,
      );
    case EditorRoute.EditApiActionBase:
      return BUILDER_PAGE_TAB_API_EMPTY_ACTION_URL(
        options.applicationId,
        options.apiId,
        options.currentRoute,
      );
    case EditorRoute.EditApiAction:
      return BUILDER_PAGE_TAB_API_ACTION_URL(
        options.applicationId,
        options.apiId,
        options.actionId,
        options.currentRoute,
      );
    case EditorRoute.EditApiInputs:
      return BUILDER_PAGE_TAB_API_INPUTS_URL(
        options.applicationId,
        options.apiId,
        options.currentRoute,
      );
    case EditorRoute.EditApiResponse:
      return BUILDER_PAGE_TAB_API_RESPONSE_URL(
        options.applicationId,
        options.apiId,
        options.currentRoute,
      );
    case EditorRoute.EditEntityProperty:
      return BUILDER_PAGE_TAB_ITEM_KIND_PROPERTY_URL(
        options.applicationId,
        options.entityType,
        options.entityId,
        options.property,
        options.currentRoute,
      );
    default:
      return BUILDER_PAGE_URL(options.applicationId, options.currentRoute);
  }
}

export function useGetEditorPath() {
  const currentRoutePath = useSelector(getCurrentRoutePathWithParams);

  return useCallback(
    (route: EditorRoute, options: GetPathOptions = {}) => {
      return getEditorBasePath(route, {
        currentRoute: currentRoutePath,
        ...options,
      });
    },
    [currentRoutePath],
  );
}
