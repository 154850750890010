import { capitalize } from "lodash";
import styled, { css, createGlobalStyle } from "styled-components";
import {
  AUTOCOMPLETE_TOOLTIP_MAX_HEIGHT,
  AUTOCOMPLETE_TOOLTIP_WIDTH,
} from "autocomplete/constants";
import { colors } from "styles/colors";
import { getOpenAiAssistantShortcutString } from "utils/navigator";
import {
  WidgetType,
  WidgetTypesArr,
} from "./../../../legacy/constants/WidgetConstants";

import { EditorSize, EditorTheme } from "./EditorConfig";

export const EDITOR_LINE_HEIGHT = 19;
export const EDITOR_PADDING_Y = 4;

const lightbulbIconData = `"data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath opacity='0.12' d='M4.9188 10.4389C4.32355 9.97606 3.84144 9.38381 3.509 8.70703C3.17657 8.03024 3.00252 7.28667 3.00005 6.53265C2.98755 3.82015 5.1688 1.5639 7.8813 1.5014C8.93109 1.47652 9.96208 1.78278 10.828 2.37676C11.694 2.97074 12.3509 3.82227 12.7057 4.8106C13.0605 5.79893 13.0952 6.87388 12.8047 7.883C12.5143 8.89212 11.9135 9.78417 11.0876 10.4327C10.9051 10.5737 10.7573 10.7546 10.6555 10.9615C10.5537 11.1684 10.5005 11.3958 10.5001 11.6264V12.0014C10.5001 12.134 10.4474 12.2612 10.3536 12.355C10.2598 12.4487 10.1327 12.5014 10.0001 12.5014H6.00005C5.86745 12.5014 5.74027 12.4487 5.6465 12.355C5.55273 12.2612 5.50005 12.134 5.50005 12.0014V11.6264C5.49849 11.3975 5.44543 11.172 5.34481 10.9664C5.24419 10.7608 5.09859 10.5805 4.9188 10.4389Z' fill='%2327BBFF'/%3E%3Cpath d='M5.5 14.5H10.5M8.50586 3.54688C9.11697 3.65047 9.68086 3.94119 10.1197 4.37891C10.5585 4.81664 10.8507 5.37978 10.9559 5.99063M4.9188 10.4389C4.32355 9.97606 3.84144 9.38381 3.509 8.70703C3.17657 8.03024 3.00252 7.28667 3.00005 6.53265C2.98755 3.82015 5.1688 1.5639 7.8813 1.5014C8.93109 1.47652 9.96208 1.78278 10.828 2.37676C11.694 2.97074 12.3509 3.82227 12.7057 4.8106C13.0605 5.79893 13.0952 6.87388 12.8047 7.883C12.5143 8.89212 11.9135 9.78417 11.0876 10.4327C10.9051 10.5737 10.7573 10.7546 10.6555 10.9615C10.5537 11.1684 10.5005 11.3958 10.5001 11.6264V12.0014C10.5001 12.134 10.4474 12.2612 10.3536 12.355C10.2598 12.4487 10.1327 12.5014 10.0001 12.5014H6.00005C5.86745 12.5014 5.74027 12.4487 5.6465 12.355C5.55273 12.2612 5.50005 12.134 5.50005 12.0014V11.6264C5.49849 11.3975 5.44543 11.172 5.34481 10.9664C5.24419 10.7608 5.09859 10.5805 4.9188 10.4389Z' stroke='%2327BBFF' stroke-width='1.25' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E"`;

export const CODEMIRROR_HINT_CLASS = "CodeMirror-Tern-hint-doc";

export const EditorStyles = css`
  /* This fixes incompatibility with Ant design reset */
  .CodeMirror-scroll,
  .CodeMirror-sizer,
  .CodeMirror-gutter,
  .CodeMirror-gutters,
  .CodeMirror-linenumber {
    box-sizing: content-box !important;
  }
  .cm-s-neo pre {
    padding: 0;
  }

  .cm-s-neo .CodeMirror-gutters {
    border: none;
    border-right: 10px solid transparent;
    background-color: transparent;
  }

  .cm-s-neo .CodeMirror-linenumber {
    padding: 0;
  }

  // Cursor in Code Widget
  .cm-s-neo .CodeMirror-cursor {
    border-left: 1px solid ${colors.ACCENT_BLUE_500};
  }

  .ai-assistant-marker {
    content: " ";
    position: absolute;
    left: 0;
    top: 0;
    width: 16px;
    height: 16px;
    background-image: url(${lightbulbIconData});
    cursor: pointer;
    &:hover {
      opacity: 0.6;
    }
  }

  &&&& ::selection {
    // Prevents code turning white when selection of code starts from outside the code editor
    // Invalidates ::selection made in global.less by antd library
    color: inherit;
  }
`;

const LegacyThemeStyles = css`
  /* neo theme for codemirror, modified for superblocks */

  /* Color scheme */

  .cm-s-neo.CodeMirror {
    background-color: #ffffff;
    color: #2e383c;
    line-height: 1.4375;
  }
  .cm-s-neo .cm-comment {
    color: #c0c0c0;
  }
  .cm-s-neo .cm-property {
    color: #7a7a7a;
  }
  .cm-s-neo .cm-atom,
  .cm-s-neo .cm-number {
    color: #ccabd4;
  }
  .cm-s-neo .cm-node,
  .cm-s-neo .cm-tag {
    color: #9c3328;
  }
  .cm-s-neo .cm-string {
    color: #18a0fb;
  }
  .cm-s-neo .cm-variable,
  .cm-s-neo .cm-qualifier {
    color: #929adc;
  }
  .cm-s-neo .cm-keyword {
    color: #91c9e4;
    font-weight: bold;
  }

  .cm-s-neo .cm-builtin {
    color: #e5ab64;
  }

  .cm-s-neo .CodeMirror-linenumber {
    color: ${(props) => props.theme.palettes.gray[7]};
  }

  .cm-s-neo .CodeMirror-guttermarker {
    color: #1d75b3;
  }
  .cm-s-neo .CodeMirror-guttermarker-subtle {
    color: #e0e2e5;
  }

  .cm-s-neo .CodeMirror-selected {
    background-color: #e8f2ff !important;
  }
`;

// Some of these colors aren't part of our design system,
// but were chosen for their contrast & accessibility
const ThemeStyles = css`
  /* neo theme for codemirror, modified for Superblocks */

  .cm-s-neo.CodeMirror {
    background-color: #ffffff;
    color: ${colors.GREY_800};
    line-height: 1.4375;
  }
  .cm-s-neo .cm-comment {
    color: ${colors.GREY_300};
  }
  .cm-s-neo .cm-property {
    color: #643adf; // Purple
  }
  .cm-s-neo .cm-atom,
  .cm-s-neo .cm-number {
    color: #008d39; // Green
  }
  .cm-s-neo .cm-node,
  .cm-s-neo .cm-tag {
    color: #9c3328;
  }
  .cm-s-neo .cm-string {
    color: #e77123; // Orange
  }
  .cm-s-neo .cm-variable,
  .cm-s-neo .cm-qualifier {
    color: #2f384c; // Grey
  }
  .cm-s-neo .cm-keyword {
    color: #0087e0; // Blue
  }

  .cm-s-neo .cm-def {
    color: ${colors.GREY_800};
  }

  .cm-s-neo .cm-builtin {
    color: #643adf; // Purple
  }

  .cm-s-neo .cm-operator {
    color: ${colors.GREY_500};
  }

  .cm-s-neo .CodeMirror-gutters {
    padding-right: 2px;
  }

  .cm-s-neo .CodeMirror-linenumber {
    color: ${colors.GREY_200};
  }

  .cm-s-neo .CodeMirror-guttermarker {
    color: #1d75b3;
  }
  .cm-s-neo .CodeMirror-guttermarker-subtle {
    color: #e0e2e5;
  }

  .cm-s-neo .CodeMirror-activeline-gutter {
    background-color: ${colors.GREY_25};
  }
  .cm-s-neo .CodeMirror-activeline-background {
    background-color: ${colors.GREY_25};
  }

  .cm-s-neo .CodeMirror-activeline-gutter .CodeMirror-linenumber {
    color: ${colors.GREY_400};
  }

  .cm-s-neo .CodeMirror-selected {
    background-color: rgba(0, 153, 255, 0.24); !important; // light blue
  }
`;

export const CodeEditorHintStyles = createGlobalStyle`
  .CodeMirror-hints {
    position: absolute;
    z-index: 20;
    overflow: hidden;
    list-style: none;
    margin: 0;
    padding: 0px 0px;
    font-size: ${(props) =>
      props.theme.legacy.appStyles.autocomplete.fontSize}px;
    max-height: 20em;
    -ms-overflow-style: none;
    scrollbar-width: none;
    overflow-y: scroll;
    background: ${(props) =>
      props.theme.legacy.appColors.autocomplete.normal.background};
    color: ${(props) => props.theme.legacy.appColors.autocomplete.normal.text};
    border: 1px solid !important;
    border-radius: 4px !important;
    border-color: ${(props) =>
      props.theme.legacy.appColors.textField.normal.border} !important;
  }

  .CodeMirror-hints::-webkit-scrollbar {
    display: none;
  }

  .CodeMirror-hint {
    line-height: 25px;
    background: ${(props) =>
      props.theme.legacy.appColors.autocomplete.normal.background};
    color: ${(props) => props.theme.legacy.appColors.autocomplete.normal.text};
    cursor: pointer;
    min-width: 160px;
    max-width: 640px;
    /* override inline "padding-right: 0px;" that codemirror adds to each element in some cases */
    padding-right: 60px !important;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: ${(props) =>
      props.theme.legacy.appStyles.autocomplete.fontSize}px;
  }

  .datasource-hint {
    padding: 5px;
    display: block;
    width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  li.CodeMirror-hint-active {
    background: ${(props) =>
      props.theme.legacy.appColors.autocomplete.active.background};
    color: ${(props) => props.theme.legacy.appColors.autocomplete.active.text};
    font-weight: bold;
    border-radius: 1px;
  }

  .CodeMirror-Tern-completion {
    padding-left: 4px !important;

    &:hover {
      background: ${(props) =>
        props.theme.legacy.appColors.autocomplete.active.background};
      color: ${(props) =>
        props.theme.legacy.appColors.autocomplete.active.text};
    }
  }

  .CodeMirror-Tern-completions-ai-assist {
    padding-left: 24px !important;
  }

  // add svg icon to the left of the AI assist hint
  .CodeMirror-Tern-completions-ai-assist:before {
    content: "";
    position: absolute;
    left: 4px;
    top: 3px;
    width: 16px;
    height: 16px;
    background-image: url(${lightbulbIconData});
  }

  .CodeMirror-Tern-completion:after {
    position: absolute;
    right: 4px;
    bottom: 4px;
    font-size: 12px;
    line-height: 16px;
    text-align: right;
    color: #b2b2b2;
    box-sizing: border-box;
  }

  .CodeMirror-Tern-completions-unknown:after {
    content: "";
  }

  .CodeMirror-Tern-completions-ai-assist:after {
    content: "${getOpenAiAssistantShortcutString()}";
  }

  .CodeMirror-Tern-completions-object:after {
    content: "Object";
  }

  .CodeMirror-Tern-completions-fn:after {
    content: "function";
  }

  .CodeMirror-Tern-completions-array:after {
    content: "Array";
  }

  .CodeMirror-Tern-completions-number:after {
    content: "number";
  }

  .CodeMirror-Tern-completions-string:after {
    content: "string";
  }

  .CodeMirror-Tern-completions-bool:after {
    content: "boolean";
  }

  ${(WidgetTypesArr as Array<WidgetType>).map(
    (widgetType) => `
    .CodeMirror-Tern-completions-${widgetType}:after {
      content: "${capitalize(
        widgetType.replace("_WIDGET", "").replace("_", " "),
      )}";
    }
  `,
  )}

  .CodeMirror-Tern-completions-timer:after {
    content: "Timer";
  }

  .CodeMirror-Tern-completions-state:after {
    content: "Variable";
  }

  .CodeMirror-Tern-tooltip {
    z-index: 1051 !important;
  }

  .${CODEMIRROR_HINT_CLASS} {
    display: none;

    &&.visible:empty {
      padding: 0 !important;
    }

    &.visible {
      display: block;
      background: ${(props) =>
        props.theme.legacy.appColors.autocomplete.normal.background} !important;
      color: ${(props) =>
        props.theme.legacy.appColors.autocomplete.active.text} !important;
      max-height: ${AUTOCOMPLETE_TOOLTIP_MAX_HEIGHT}px;
      width: ${AUTOCOMPLETE_TOOLTIP_WIDTH}px;
      font-family: ${(props) => props.theme.legacy.appStyles.fontFamily};
      font-size: 12px;
      padding: 10px !important;
      border: 1px solid !important;
      border-radius: 4px !important;
      border-color: ${(props) =>
        props.theme.legacy.appColors.textField.normal.border} !important;
      box-shadow: 0px 0px 0px 0px !important;
      overflow-x: none;
      overflow-y: auto;
      -ms-overflow-style: none; /* for Internet Explorer, Edge */
      scrollbar-width: none; /* for Firefox */

      h5 {
        font-size: 12px;
        font-weight: ${(props) =>
          props.theme.legacy.appStyles.label.heading.fontWeight};
      }
      pre {
        white-space: pre-wrap;
      }
    }
  }

  .CodeMirror-Tern-hint-doc::-webkit-scrollbar {
    display: none; /* for Chrome, Safari, and Opera */
  }

  .CodeMirror-focused {
    .cm-matchhighlight {
      background-color: #f5f5f5;
    }
  }

  .expanded-autocomplete-row {
    display: flex;
    align-items: center;
    padding: 2px 4px;
  }

  .expanded-autocomplete-icon {
    width: 16px;
    height: 16px;
    margin-right: 6px;
  }

  .expanded-autocomplete-text {
    font-size: 12px;
  }
`;

// Python editor will show tab (\t) as visible arrorws, the following base64 image is an error
const visibleTabsStyle = css`
  .cm-tab {
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAMCAYAAAAkuj5RAAAAAXNSR0IArs4c6QAAAGFJREFUSMft1LsRQFAQheHPowAKoACx3IgEKtaEHujDjORSgWTH/ZOdnZOcM/sgk/kFFWY0qV8foQwS4MKBCS3qR6ixBJvElOobYAtivseIE120FaowJPN75GMu8j/LfMwNjh4HUpwg4LUAAAAASUVORK5CYII=);
    background-position: right;
    background-repeat: no-repeat;
  }
`;

export const EditorWrapper = styled.div<{
  editorTheme?: EditorTheme;
  hasError: boolean;
  isFocused: boolean;
  customDisabled: boolean;
  size: EditorSize;
  height?: string | number;
  minHeight?: string;
  maxHeight?: string;
  borderLess?: boolean;
  monospace?: boolean;
  fontSize?: string;
  visibleTabs?: boolean;
  isNewCodeEditorFeatureFlagEnabled?: boolean;
  showLineNumbers?: boolean;
}>`
  ${EditorStyles};
  ${(props) =>
    props.isNewCodeEditorFeatureFlagEnabled ? ThemeStyles : LegacyThemeStyles};

  opacity: ${(props) => (props.customDisabled ? 0.6 : 1)};

  width: 100%;
  ${(props) =>
    props.size === EditorSize.COMPACT && props.isFocused
      ? css`
          z-index: 5;
          position: absolute;
          right: 0;
          left: 0;
          top: 0;
        `
      : css`
          position: relative;
        `}
  min-height: ${(props) => props.minHeight ?? "32px"};
  height: ${(props) => `${props.height}` || "auto"};
  border: 1px solid
    ${(props) =>
      props.isNewCodeEditorFeatureFlagEnabled ? colors.GREY_100 : "white"};
  background-color: ${(props) =>
    props.theme.legacy.appColors.textField.normal.background};
  border: 1px solid;
  border-radius: 4px;
  overflow: hidden;
  border-color: ${(props) =>
    props.borderLess
      ? "transparent"
      : props.hasError
      ? props.theme.legacy.appColors.textField.error.border
      : props.theme.colors.GREY_100};
  :hover {
    border-color: ${(props) =>
      props.hasError
        ? props.theme.legacy.appColors.textField.error.border
        : props.theme.colors.GREY_100};
  }
  display: flex;
  flex-direction: row;
  text-transform: none;
  && {
    .CodeMirror,
    .CodeMirror-scroll,
    .CodeMirror-gutter {
      min-height: ${(props) => props.minHeight ?? "auto"};
      max-height: ${(props) => props.maxHeight ?? "auto"};
    }
    .ai-assistant {
      width: 10px;
    }
    .CodeMirror-wrap,
    .CodeMirror-lines {
      font-family: ${(props) =>
        props.monospace
          ? "var(--font-monospace);"
          : props.theme.legacy.appStyles.fontFamily};
      font-size: ${(props) =>
        props.fontSize
          ? props.fontSize
          : props.theme.legacy.appStyles.textField.fontSize}px;
      font-weight: ${(props) =>
        props.theme.legacy.appStyles.textField.fontWeight};

      .CodeMirror-linewidget {
        position: absolute;
        top: 0px;
        .CodeMirror-activeline-placeholder {
          color: ${(props) => props.theme.colors.GREY_200};
          white-space: nowrap;
        }
        .CodeMirror-activeline-placeholder-button {
          background: none;
          border: none;
          padding: 0px;
          padding-left: 5px;
          cursor: pointer;
          font-weight: bold;
          color: ${(props) => props.theme.colors.ACCENT_BLUE_500};
          &:hover {
            opacity: 0.8;
          }
        }
      }
    }

    ${(props) =>
      !props.isNewCodeEditorFeatureFlagEnabled || !props.showLineNumbers
        ? `.CodeMirror-wrap { padding: 0px 8px; }`
        : ""}

    // Cursor in the Superblocks editor
    .CodeMirror-cursor {
      border-left: 1px solid ${colors.GREY_400};
    }

    // Cursor in VIM
    && {
      .cm-fat-cursor .CodeMirror-cursor {
        background: ${colors.GREY_200};
      }
    }

    .cm-s-duotone-light.CodeMirror {
      background: ${(props) =>
        props.theme.legacy.appColors.textField.normal.background};
      color: ${(props) =>
        props.theme.legacy.appColors.textField.normal.text} !important;
      & {
        span.cm-operator {
          color: ${(props) =>
            props.theme.legacy.appColors.textField.normal.text};
        }
      }
    }
    .cm-binding {
      ${(props) =>
        props.hasError
          ? css`
              color: ${props.theme.legacy.colors.error};
            `
          : css`
              color: ${props.editorTheme === EditorTheme.DARK
                ? props.theme.legacy.colors.bindingTextDark
                : props.theme.legacy.colors.bindingText};
            `}
      font-weight: 600;
    }
    .cm-sb-error,
    .cm-sb-error-binding {
      /* Reused from the codemirror lint.css addon */
      background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAADCAYAAAC09K7GAAAAAXNSR0IArs4c6QAAAAZiS0dEAP8A/wD/oL2nkwAAAAlwSFlzAAALEwAACxMBAJqcGAAAAAd0SU1FB9sJDw4cOCW1/KIAAAAZdEVYdENvbW1lbnQAQ3JlYXRlZCB3aXRoIEdJTVBXgQ4XAAAAHElEQVQI12NggIL/DAz/GdA5/xkY/qPKMDAwAADLZwf5rvm+LQAAAABJRU5ErkJggg==");
      background-position: left bottom;
      background-repeat: repeat-x;
    }

    .cm-sb-error {
      font-weight: 600;
    }

    .null-highlight {
      background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAADCAYAAAC09K7GAAAAAXNSR0IArs4c6QAAAAZiS0dEAP8A/wD/oL2nkwAAAAlwSFlzAAALEwAACxMBAJqcGAAAAAd0SU1FB9sJFhQXEbhTg7YAAAAZdEVYdENvbW1lbnQAQ3JlYXRlZCB3aXRoIEdJTVBXgQ4XAAAAMklEQVQI12NkgIIvJ3QXMjAwdDN+OaEbysDA4MPAwNDNwMCwiOHLCd1zX07o6kBVGQEAKBANtobskNMAAAAASUVORK5CYII=);
      background-position: left bottom;
      background-repeat: repeat-x;
    }
    .CodeMirror-matchingbracket {
      text-decoration: none;
      color: black !important;
      background-color: #e8f2ff;
    }
    .datasource-highlight {
      background-color: rgba(104, 113, 239, 0.1);
      border: 1px solid rgba(104, 113, 239, 0.5);
      padding: 2px;
      border-radius: 2px;
      margin-right: 2px;
    }
    .CodeMirror {
      flex: 1;
      line-height: ${EDITOR_LINE_HEIGHT}px;
      z-index: 0;
      border-radius: 4px;
      height: auto;
    }
    ${(props) => props.visibleTabs && visibleTabsStyle}
    ${(props) =>
      props.customDisabled &&
      css`
        .CodeMirror-cursor {
          display: none !important;
        }
      `}
    .CodeMirror pre.CodeMirror-placeholder {
      color: ${(props) => props.theme.legacy.appColors.textField.placeholder};
      white-space: nowrap;
      max-width: 100%;
      overflow-x: clip !important; // !important is needed to override codemirror's default style for placeholder
    }
    ${(props) =>
      props.size === EditorSize.COMPACT &&
      css`
        .CodeMirror-hscrollbar {
          -ms-overflow-style: none;
          scrollbar-width: none;
          &::-webkit-scrollbar {
            display: none;
          }
        }
      `}
  }
  && {
    .CodeMirror-lines {
      background-color: ${(props) => props.customDisabled && "#f0f0f0"};
      padding-top: 5px;
    }
    .CodeMirror-wrap {
      background-color: ${(props) => props.customDisabled && "#f0f0f0"};
    }
    .CodeMirror-activeline-gutter,
    .CodeMirror-activeline-background {
      background-color: ${(props) => props.customDisabled && "#f0f0f0"};
    }
  }
  .bp5-popover-target {
    padding-right: 10px;
    padding-top: 5px;
  }
  .leftImageStyles {
    width: 20px;
    height: 20px;
    margin: 5px;
  }
  .linkStyles {
    margin: 5px;
    margin-right: 11px;
  }
`;

export const EditorJsExprModeIndicator = styled.div`
  color: ${(props) => props.theme.colors.BLACK};
  background-color: ${(props) => props.theme.colors.GREY_25};
  padding: 3px 7px;
  border-right: 1px solid ${(props) => props.theme.colors.GREY_100};
  pointer-events: none;
  user-select: none;
  &::before {
    content: "JS";
  }
`;

export const IconContainer = styled.div`
  border-radius: 4px 0 0 4px;
  margin: 0;
  height: 30px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #eef2f5;
  svg {
    height: 20px;
    width: 20px;
    path {
      fill: #979797;
    }
  }
`;

export const DynamicAutocompleteInputWrapper = styled.div<{
  isActive: boolean;
  isNotHover: boolean;
  isError: boolean;
}>`
  width: 100%;
  height: 100%;
  position: relative;
  border-radius: 2px;
  > span:first-of-type {
    width: 30px;
    position: absolute;
    right: 0px;
  }

  .expand-editor-btn {
    display: none;
  }
  &:hover {
    .expand-editor-btn {
      display: flex;
    }
  }
`;
