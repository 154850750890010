import { CheckOutlined, LinkOutlined } from "@ant-design/icons";
import React, { useCallback } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { useCopyToClipboard } from "hooks/ui";
import { colors } from "styles/colors";
import { styleAsClass } from "styles/styleAsClass";

const CopyLinkWrapper = styled.div`
  text-align: left;
`;

const CopiedWrapper = styled.div`
  color: ${({ theme }) => theme.colors.ACCENT_GREEN};
`;

const NotCopiedWrapper = styleAsClass`
  color: ${colors.ACCENT_BLUE_600};
  &:hover {
    color: ${colors.ACCENT_BLUE_700};
  }
`;
interface Props {
  url: string;
  text: string;
}

const CopyLink = (props: Props) => {
  const [tokenCopied, setTokenCopied] = useCopyToClipboard(2000);

  const copyLink = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation();
      setTokenCopied(props.url);
    },
    [setTokenCopied, props.url],
  );

  return (
    <CopyLinkWrapper key="share-link">
      <Link to="#" onClick={copyLink}>
        {tokenCopied ? (
          <CopiedWrapper>
            <CheckOutlined /> Link copied
          </CopiedWrapper>
        ) : (
          <div className={NotCopiedWrapper}>
            <LinkOutlined /> {props.text}
          </div>
        )}
      </Link>
    </CopyLinkWrapper>
  );
};

export default CopyLink;
